import React from 'react';
import moment from 'moment';
import { DatePicker } from '@mantine/dates';
import { Select, TextInput, MultiSelect } from '@mantine/core';

import { IPeriodicScheduleEditPayload } from '../../../../types';
import { IMasterData, ICompetencyByClassSubject, IPeriodicAssessmentSchedules } from '../../../../libs/data-access/types';

interface IProps {
    classes: IMasterData[];
    subjectList: IMasterData[];
    assessmentTypes: IMasterData[];
    competencyByClassSubject: ICompetencyByClassSubject[];
    selectedScheduleToEdit: IPeriodicScheduleEditPayload;
    handleNewScheduleParamsChangeForEdit: (
        key: keyof IPeriodicScheduleEditPayload,
        value: string | number | string[]
    ) => void;
    handleUpdateDatesForScheduleParamsForEdit: (
        key: "start_Date" | "end_Date",
        value: string | null
    ) => void;
};

export default function EditSchedule({
    classes,
    subjectList,
    assessmentTypes,
    competencyByClassSubject,
    selectedScheduleToEdit,
    handleNewScheduleParamsChangeForEdit,
    handleUpdateDatesForScheduleParamsForEdit
}: IProps) {

    console.log("Selected Schedule ", selectedScheduleToEdit)
    return (
        <React.Fragment>
            <div className="flex justify-between gap-3 mb-3">
                <div className="flex flex-col grow gap-3">
                    <Select
                        searchable
                        size='md'
                        placeholder="Select"
                        label="Select Assessment"
                        data={assessmentTypes?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={selectedScheduleToEdit.periodic_Assessment_Id ? String(selectedScheduleToEdit.periodic_Assessment_Id) : undefined}
                        onChange={e => handleNewScheduleParamsChangeForEdit("periodic_Assessment_Id", Number(e))}
                    />

                    <DatePicker
                        label="Start Date"
                        size='md'
                        placeholder='Select date'
                        clearable={false}
                        value={selectedScheduleToEdit.start_Date ? new Date(selectedScheduleToEdit.start_Date) : null}
                        onChange={e => {
                            handleUpdateDatesForScheduleParamsForEdit("end_Date", null)
                            handleUpdateDatesForScheduleParamsForEdit("start_Date", moment(e).format("YYYY-MM-DD"))}}
                    />

                    <Select
                        searchable
                        size='md'
                        placeholder="Select"
                        label="Grade"
                        data={classes?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={selectedScheduleToEdit.class_Id ? String(selectedScheduleToEdit.class_Id) : undefined}
                        onChange={e => handleNewScheduleParamsChangeForEdit("class_Id", Number(e))}
                    />
                </div>
                <div className="flex flex-col grow gap-3">
                    <TextInput
                        size="md"
                        label="No. of Questions"
                        value={selectedScheduleToEdit.number_Of_Questions}
                        onChange={(e) => handleNewScheduleParamsChangeForEdit("number_Of_Questions", e.target.value)}
                    />

                    <DatePicker
                        label="Due Date"
                        size='md'
                        placeholder='Select date'
                        clearable={false}
                        minDate={selectedScheduleToEdit.start_Date ? new Date(selectedScheduleToEdit.start_Date) : new Date(moment().add(1, "day").format())}
                        value={selectedScheduleToEdit.end_Date ? new Date(selectedScheduleToEdit.end_Date) : null}
                        onChange={e => handleUpdateDatesForScheduleParamsForEdit("end_Date", moment(e).format("YYYY-MM-DD"))}
                    />

                    <Select
                        size='md'
                        placeholder="Select"
                        label="Subject"
                        data={subjectList?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={selectedScheduleToEdit.subject_Id ? String(selectedScheduleToEdit.subject_Id) : undefined}
                        onChange={e => handleNewScheduleParamsChangeForEdit("subject_Id", Number(e))}
                    />
                </div>
            </div>

            {/* COMPETENCIES */}
            <>
                <MultiSelect
                    searchable
                    size='md'
                    placeholder="Select"
                    label="Competencies"
                    data={competencyByClassSubject?.map(each => ({
                        value: String(each.competancy_Id),
                        label: each.competancy,
                        group: each.assessment_Type
                    }))}
                    value={selectedScheduleToEdit.scheduleCompetancy.map(each => String(each.competancy_Id))}
                    onChange={e => handleNewScheduleParamsChangeForEdit("scheduleCompetancy", e)}
                />
                {/* <div>
                        {newScheduleParams.scheduleCompetancy?.map((each, index) => <Badge
                            key={index + "com"}
                            size="lg"
                            radius="xl"
                            color="teal"
                            rightSection={<MdOutlineCancel />}
                        >
                            {each?.competency_name ?? ""}
                        </Badge>)}
                    </div> */}
            </>
        </React.Fragment>
    )
}
