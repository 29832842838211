import React, { useState } from 'react'
import { useMentorReports } from '../../../../../libs/data-access';
import { calculateCurrentMonth, handleDownload, showToast } from '../../../../../utils/utility';
import { ISchoolWiseClassroomMathObservationReport } from '../../../../../libs/data-access/types';
import moment from 'moment';

export default function useSchoolWiseClassroomMathObservation() {
    const {
        fetchClassroomWiseObservationFast
    } = useMentorReports();

    const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
    const [selectStartDate, setSelectedStartDate] = useState<string | undefined>(undefined)
    const [selectEndDate, setSelectedEndDate] = useState<string | undefined>(undefined)
    const [schoolWiseClassroomMathObservationReport, setSchoolWiseClassroomMathObservationReport] = useState<ISchoolWiseClassroomMathObservationReport[]>([]);
    // data for CSV download
    const [schoolWiseClassroomMathObservationCSV, setSchoolWiseClassroomMathObservationCSV] = useState<{ headers: any[], content: any[] }>();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleSelectMonth = (value: string) => {
        setSelectedMonth(value);
        setSchoolWiseClassroomMathObservationReport([]);
    };

    const formatSchoolWiseClassroomMathObservationReport = (visitCompliance: ISchoolWiseClassroomMathObservationReport[]) => {

        const headers = [
            { label: "District", key: "district" },
            { label: "Block", key: "block" },
            { label: "Cluster", key: "cluster" },
            { label: "School Name", key: "school_name" },
            { label: "UDISE", key: "udise" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के सभी चरणों का उपयोग किया जा रहा है ", key: "question_1_option_1" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के कुछ चरणों का उपयोग किया जा रहा है ", key: "question_1_option_2" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका का उपयोग  किया जा रहा है  ", key: "question_1_option_3" },
            { label: "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option हाँ,  गतिविधि को शिक्षण योजना के चरणों के अनुसार कराया गया  ", key: "question_2_option_1" },
            { label: "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option नहीं, गतिविधि को शिक्षण योजना के चरणों के अनुसार  नहीं कराया गया ", key: "question_2_option_2" },
            { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री व्यवस्थित और बच्चों की पहुँच में थी। ", key: "question_3_option_1" },
            { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री लगी थी पर बच्चों की पहुँच मेंनहीं थी।  ", key: "question_3_option_2" },
            { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा-कक्ष में कोई प्रिंट सामग्री प्रदर्शित नहीं थी।", key: "question_3_option_3" },
        ];

        const data = visitCompliance?.map(each => Object.assign({
            district: each.district_Name || "",
            block: each.block_Name || "",
            cluster: each.cluster_School_Name || "",
            school_name: each?.school_Name || "",
            udise: each.udisE_Code || "",
            question_1_option_1: each?.q1_Option1 || "",
            question_1_option_2: each?.q1_Option2 || "",
            question_1_option_3: each?.q1_Option3 || "",
            question_2_option_1: each?.q2_Option1 || "",
            question_2_option_2: each?.q2_Option2 || "",
            question_3_option_1: each?.q3_Option1 || "",
            question_3_option_2: each?.q3_Option2 || "",
            question_3_option_3: each?.q3_Option3 || "",

        }));

        setSchoolWiseClassroomMathObservationCSV({
            headers: headers,
            content: data
        });

    };

    /* API CALLS */
    const fetchSchoolWiseClassroomMathObservation = async () => {
        setIsLoading(true);
        const response = await handleDownload(
            `https://report.nipunharyana.in/api/report/dashboard/observation/ObservationDownLoadResultV2?GetMonthYearFromDate1=${selectStartDate}&GetMonthYearFromDate2=${moment(selectStartDate).add(1, "day").format('YYYY-MM-DD')}&DistrictId=0&BlockId=0&QuestionScopeId=0&SubjectId=3`,
            `School__wise_Classroom_Math_Observation_Report_for_${selectStartDate}-${selectEndDate}.csv`
        ).catch(
            (error) => {
                console.log("Error while downloading report: ", error);
            }
        );
        if (response) {
        } else {
            showToast({
                type: "error",
                title: "Error while fetching report",
                message: "Something went wrong!"
            });

        };
        setIsLoading(false);
    };

    return {
        isLoading,
        selectStartDate,
        selectEndDate,
        setSelectedStartDate,
        setSelectedEndDate,
        schoolWiseClassroomMathObservationReport,
        schoolWiseClassroomMathObservationCSV,
        fetchSchoolWiseClassroomMathObservation,
    };
}
