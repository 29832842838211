import React, { useContext, useEffect, useState } from 'react'
import { useStateAdmin, useTeacherTraining } from '../../../../libs/data-access';
import useMasterData from '../../../Hooks/useMasterData';
import { IMediaType, INewTestQuestionOption, ITrainingSlotList, questionOptions, testQuestions } from '../../../../libs/data-access/types';
import { showToast } from '../../../../utils/utility';
import { handleValidSlotTestNewQuestion, handleValidTestQuestionList } from '../../../../utils/validators';
import { AppContext } from '../../../../AppContext';
import { useNavigate } from 'react-router-dom';

const DefaultQuestionOptions = {
    base64OptionImage: "",
    option_Text: "",
    is_Correct: false
}
const DefaultTestQuestions = {
    base64QuestionImage: "",
    question_Text: "",
    question_Type_Id: 1,
    media_Type_Id: 1,
    option_Media_Type_Id: 1,
    question_Marks: 1,
    question_Media_Url: "",
    questionOptions: [DefaultQuestionOptions]
}
const DefaultNewSlotQuestion = {
    schedule_Header_Test_Id: 0,
    question_Group_Name: "",
    state_Admin_User_Id: 0,
    testQuestions: [DefaultTestQuestions]
}

type IFilterChange = "schedule_Header_Test_Id" | "question_Group_Name" | "question_Type_Id" | "media_Type_Id" | "selectedSlotHeader";

export default function useSlotQuestionBank() {

    const MEDIA_TYPE_QUESTION = 0;
    const MEDIA_TYPE_OPTION = 1;
    const navigate = useNavigate()
    const {
        user
    } = useContext(AppContext);

    const {
        fetchStateAdmin
    } = useStateAdmin();
    const {
        fetchTeacherTrainingData,
        PutTeacherTrainingSlotTest,
        PostTeacherTrainingSlotTest
    } = useTeacherTraining()

    const {
        questionType,
        GetQuestionType,
    } = useMasterData();

    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
    const [isLoadingSlotList, setIsLoadingSlotList] = useState<boolean>(false)
    const [isAddingNewOption, setIsAddingNewOption] = useState<boolean>(false)
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
    const [mediaTypeForQuestion, setMediaTypeForQuestion] = useState<IMediaType[]>([]);
    const [mediaTypeForOptions, setMediaTypeForOptions] = useState<IMediaType[]>([]);
    const [slotList, setSlotList] = useState<ITrainingSlotList[]>([]);
    const [selectedSlotHeader, setSelectedSlotHeader] = useState<number>(0);
    const [newTestQuestionOption, setNewTestQuestionOption] = useState<INewTestQuestionOption>(DefaultNewSlotQuestion)
    const [newTestQuestion, setNewTestQuestion] = useState<testQuestions>(DefaultTestQuestions)
    const [newTestQuestionList, setNewTestQuestionList] = useState<testQuestions[]>([])
    const [newTestOption, setNewTestOption] = useState<questionOptions>(DefaultQuestionOptions)
    const [newTestOptionList, setNewTestOptionList] = useState<questionOptions[]>([])
    const [selectedDeleteItemIndex, setSelectedDeleteItemIndex] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (isFirst) {
            setIsFirst(false);
        } else {
            GetQuestionType();
            getSlotList()
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_QUESTION);
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_OPTION);
        }
    }, [isFirst]);

    // HANDLER FUNCTION

    const handleAddQuestion = () => {
        const newQuestion = {
            ...newTestQuestion,
            questionOptions: newTestOptionList
        }
        const valid = handleValidSlotTestNewQuestion(newQuestion)
        if (valid) {
            setNewTestQuestionList([...newTestQuestionList, newQuestion])
            setNewTestOption(DefaultQuestionOptions)
            setNewTestQuestion(DefaultTestQuestions)
            setNewTestOptionList([])
            setIsConfirmed(false)
        }
    }

    const handleDeleteTestFromList = () => {
        if (selectedDeleteItemIndex !== undefined) {
            const arr = newTestQuestionList;
            arr.splice(selectedDeleteItemIndex, 1)
            setNewTestQuestionList(JSON.parse(JSON.stringify(arr)))
            setIsOpenDeleteModal(false)
            setSelectedDeleteItemIndex(undefined)
        }
        else {
            showToast({
                type: 'warning',
                message: "fail to delete question from  test question list"
            })
        }
    }

    const handleSubmitQuestionList = () => {
        let testQuestions: INewTestQuestionOption = {
            ...newTestQuestionOption,
            state_Admin_User_Id: user?.user_Id,
            testQuestions: newTestQuestionList
        }
        const valid = handleValidTestQuestionList(testQuestions)
        if (valid) {
            postSlotTestQuestions(testQuestions)
        }
    }

    //  API FUNCTION


    const handleFetchMediaTypeForQuestion = async (mediaType: number) => {
        const response = await fetchStateAdmin(`Question/GetMediaTypeForQuestionOrOption/${mediaType}`);
        if (response.isSuccess) {
            // console.log("Response for media type: ", response.data);
            const data: IMediaType[] | undefined = response.data;
            if (data) {
                mediaType === 0
                    ?
                    setMediaTypeForQuestion(data)
                    :
                    setMediaTypeForOptions(data);
            }
        } else {
            showToast({
                type: "error",
                title: "Error Ocurred!",
                message: "Could not fetch media types"
            });
        }
    }

    const getSlotList = async () => {
        setIsLoadingSlotList(true)
        const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetail?Is_Current_Header=false`)
        if (res.isSuccess) {
            setSlotList(res?.data)
        }
        else {
            showToast({
                type: "error",
                message: res?.message ?? "error while getting slot list"
            })
        }
        setIsLoadingSlotList(false)
    }

    const postSlotTestQuestions = async( payload :INewTestQuestionOption) => {
        setIsBtnLoading(true)
        const res = await PostTeacherTrainingSlotTest(`CreateTeacherTrainingTestQuestion`,payload)
         if(res.isSuccess){
            showToast({
                type:"success",
                message: "question list successfully added to slot test"
            })
            setNewTestQuestionOption(DefaultNewSlotQuestion)
            setNewTestQuestionList([])
            navigate(`/state/training-slot/question-bank`)
         }
         else{
            showToast({
            type:"error",
            message: res?.message ?? "error while uploading question list to slot"
            })
         }
        setIsBtnLoading(false)
    }
    return {
        isConfirmed,
        setIsConfirmed,
        questionType,
        mediaTypeForQuestion,
        mediaTypeForOptions,
        slotList,
        newTestQuestionOption,
        setNewTestQuestionOption,
        selectedSlotHeader,
        setSelectedSlotHeader,
        newTestOption,
        setNewTestOption,
        newTestQuestion,
        setNewTestQuestion,
        isLoadingSlotList,
        isAddingNewOption,
        setIsAddingNewOption,
        newTestOptionList,
        setNewTestOptionList,
        handleAddQuestion,
        newTestQuestionList,
        isOpenDeleteModal,
        setIsOpenDeleteModal,
        selectedDeleteItemIndex,
        setSelectedDeleteItemIndex,
        handleDeleteTestFromList,
        handleSubmitQuestionList
    }
}