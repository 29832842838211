import React, { useState } from 'react'
import { Tabs } from '@mantine/core';

import PageLayout from '../../../../PageLayout';
import { EPageTitle } from '../../../../libs/ui';

import usePeriodicAssessment, { PeriodicAssessmentFilterContext } from './Hooks/usePeriodicAssessment';
import PeriodicAssessmentFilter from './Components/PeriodicAssessmentFilter';
import PeriodicAnnualAssessment from './Pages/PeriodicAnnualAssessment';

export default function PeriodicAssessment() {

    const {
        filters,
        handleChangeFilters,
        handleSearch,
        handleIsSearching,
        isSearching,
        setIsSearching,
        isPeriodicResultLoading,
        handleResetFilters,
        // activeTab,
        // setActiveTab
    } = usePeriodicAssessment();

    return (
        <PageLayout>
            <EPageTitle
                title="Periodic Assessment"
            />

            <PeriodicAssessmentFilterContext.Provider
                value={{
                    ...filters,
                    handleChangeFilters,
                    handleIsSearching,
                    isSearching,
                    isPeriodicResultLoading,
                }}
            >
                <PeriodicAssessmentFilter
                    handleResetFilters={handleResetFilters}
                />
                <PeriodicAnnualAssessment/>
            </PeriodicAssessmentFilterContext.Provider>
        </PageLayout>
    )
}