import React, { useContext, useEffect, useState } from 'react'
import { useDashboardDate } from '../../../../../libs/data-access';
import { DashboardFilterContext } from '../../../DashboardFilterContext';
import { ILearningIndicator, IReportLevel } from '../../../../../libs/data-access/types';
import moment from 'moment';
import { calculateYearForDashboard, groupByKey } from '../../../../../utils/utility';

export default function useClassroomLearningIndicator() {

  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);

  const {
    fetchDashboardData
  } = useDashboardDate();

  const [isFirst, setIsFirst] = useState<boolean>(true)
  const [isLoadingLearningIndicator, setIsLoadingLearningIndicator] = useState<boolean>(false)
  const [reportLevel, setReportLevel] = useState<IReportLevel>({ levelNames: "state", districtName: "haryana", districtId: 0, blockName: "" });
  const [activeButtonValue, setActiveButtonValue] = useState<"hindi" | "math" | undefined>(undefined)

  const [learningIndicator, setLearningIndicator] = useState<ILearningIndicator | undefined>(undefined)

  let noDataOption = {
    labels: ["No Data Available"],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
      },
    },
  }
  useEffect(() => {
    if (isSearching) {
      setActiveButtonValue("hindi")
    }
  }, [isSearching])

  useEffect(() => {
    if (activeButtonValue) {
      getLearningIndicator();
    }
  }, [activeButtonValue, isSearching]);

  // Handler function


  // API function

  const getLearningIndicator = async () => {
    setIsLoadingLearningIndicator(true)
    const res = await fetchDashboardData(
      `GetDataForPiChartTeacherAndLearningIndicator?SubjectId=${
        activeButtonValue === "math" ? 3 : 2
      }&DistrictId=${selectedDistrict ?? 0}&BlockId=${
        selectedBlock ?? 0
      }&ClusterId=${selectedCluster ?? 0}&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
      let formattedData = groupByKey(res?.data, "Question");
      //  console.log(formattedData)
      Object.keys(formattedData).forEach((eachQuestion) => {
        const data = formattedData[eachQuestion]?.sort(
          (a: any, b: any) => a.Value - b.Value
        );
        formattedData = { ...formattedData, [eachQuestion]: data };
      });

      // Function to extract the serial number from 'ColName'
      const extractSerialNumber = (colName:any) => {
        const match = colName.match(/^\d+/);
        return match ? parseInt(match[0], 10) : 0; // Default to 0 if no match
      }

      // Function to sort the data based on the serial number in 'ColName'
      const sortBySerialNumber = (data:any) => {
        // Go through each key in the data object
        for (const key in data) {
          if (Array.isArray(data[key])) {
            // Sort the array based on the extracted serial number
            data[key].sort(
              (a:any, b:any) =>
                extractSerialNumber(a.ColName) - extractSerialNumber(b.ColName)
            );
          }
        }
      }

      // Sort the data based on serial numbers in 'ColName'
      sortBySerialNumber(formattedData);

      setLearningIndicator(formattedData);
      setReportLevel({
        levelNames: selectedCluster
          ? "cluster"
          : selectedBlock
          ? "block"
          : selectedDistrict
          ? "district"
          : "state",
        districtName:
          districts && selectedDistrict
            ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name
            : "Haryana",
        districtId: selectedDistrict ? +selectedDistrict : 0,
        blockName:
          blocks && selectedBlock
            ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name
            : "",
      });
    }
    else {
      setLearningIndicator(undefined)
      // showToast({
      //     type: "error",
      //     message: res?.message || "Fail to get learning indicator"
      // })
    }
    setIsLoadingLearningIndicator(false)
  }

  return {
    isLoadingLearningIndicator,
    activeButtonValue,
    setActiveButtonValue,
    learningIndicator,
    noDataOption

  }
}
