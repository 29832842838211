import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useTeacherData() {

    const fetchTeacherData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Teacher${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    const postTeacherData = async (endpoint: string, payload?: any) => {
        try {

            let response = await axios.post(`${BASE_URL}Teacher${endpoint}`, payload && payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    const deleteTeacherData = async (endpoint: string) => {
        try {

            let response = await axios.delete(`${BASE_URL}Teacher${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    return {
        fetchTeacherData,
        postTeacherData,
        deleteTeacherData
    };
}