import React from 'react'
import axios from 'axios';

import { FastAPI } from '../../constant';
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useWorkingDaysData() {


    const fetchWorkingDaysData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${FastAPI}WorkingDate${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    const postWorkingDaysData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${FastAPI}WorkingDate${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const putWorkingDaysData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${FastAPI}WorkingDate${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const patchWorkingDaysData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.patch(`${FastAPI}WorkingDate${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const deleteWorkingDaysData = async (
        endpoint: string
    ) => {

        try {

            let response = await axios.delete(`${FastAPI}WorkingDate${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        fetchWorkingDaysData,
        postWorkingDaysData,
        putWorkingDaysData,
        patchWorkingDaysData,
        deleteWorkingDaysData
    };
}
