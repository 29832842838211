import axios from "axios";

import { BASE_URL } from "../../constant";
import { ILoginParams } from "../../../../types";
import { handleFormatResponse, handleFormatResponseForError } from "../../utility";


export function useAuth() {

    const handleLogin = async (
        payload: ILoginParams
    ) => {
        try {
            
            let response = await axios.post(`${BASE_URL}Login/UserDashboardLogin`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        handleLogin
    };
}
