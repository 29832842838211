import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Dropzone } from '@mantine/dropzone';
import { Input } from '@mantine/core';
import { BsImageFill } from 'react-icons/bs';

import { EButton } from '../Button/EButton';
import { showToast } from '../../../../utils/utility';
import { FileInput } from '@mantine/core/lib/FileInput';

type Props = {
  onSelect: (file: string) => void;
  onClear: () => void;
  dimensionsToMatch?: IDimension;
  onSelectPreview?: (preview: string | undefined) => void;
  acceptedFileType?:
  | 'image'
  | 'video'
  | 'pdf'
  | 'zip'
  | 'doc'
  | 'excel'
  | 'csv'
  | 'ppt'
  | 'exe';
  label?: string;
  required?: boolean;
  maxSizeInKB?: number;
};

interface IDimension {
  width: number;
  height: number;
};

export const ESelectFileForQuestionBank = ({
  onSelect,
  onClear,
  dimensionsToMatch,
  onSelectPreview,
  acceptedFileType,
  label,
  required,
  maxSizeInKB
}: Props) => {

  const QUALITY = 0.9;

  const openFileRef = useRef<any>(null);

  const [selectedFiles, setSelectedFiles] = useState<File>();
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [dimensions, setDimensions] = useState<IDimension>({
    width: dimensionsToMatch?.width ?? 0,
    height: dimensionsToMatch?.height ?? 0
  });
  const [compressedFile, setCompressedFile] = useState<File | any>(null);

  const allMimeTypes = [
    { type: 'image', mime: 'image/png' },
    { type: 'image', mime: 'image/gif' },
    { type: 'image', mime: 'image/jpeg' },
    { type: 'image', mime: 'image/svg+xml' },
    { type: 'image', mime: 'image/webp' },
    { type: 'video', mime: 'video/mp4' },
    { type: 'zip', mime: 'application/zip' },
    { type: 'csv', mime: 'text/csv' },
    { type: 'pdf', mime: 'application/pdf' },
    { type: 'doc', mime: 'application/msword' },
    {
      type: 'doc',
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    { type: 'excel', mime: 'application/vnd.ms-excel' },
    {
      type: 'excel',
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    { type: 'ppt', mime: 'application/vnd.ms-powerpoint' },
    {
      type: 'ppt',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    { type: 'exe', mime: 'application/vnd.microsoft.portable-executable' },
  ];

  // create a preview as a side effect, whenever selected file is changed
  // useEffect(() => {
  //   if (!selectedFiles) {
  //     setPreview(undefined)
  //     return
  //   }

  //   const objectUrl = URL.createObjectURL(selectedFiles);
  //   console.log("objectURl", objectUrl);
  //   setPreview(objectUrl);
  //   onSelectPreview && onSelectPreview(objectUrl);

  //   // free memory when ever this component is unmounted
  //   return () => URL.revokeObjectURL(objectUrl)
  // }, [selectedFiles]);

  const mimeTypes = useMemo(() => {
    return allMimeTypes
      .filter((m) => m.type === acceptedFileType)
      .map((m) => m.mime);
  }, [acceptedFileType]);

  // file selection
  const onFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e: any) => {
      // console.log("Selected File: ", file);
      const image = new Image();
      image.src = e.target.result;
      image.onload = (e) => {
        // if (image.width !== 900 || image.height !== 900) {
        //   showToast({
        //     time: 4000,
        //     type: "warning",
        //     title: "Image Dimension not Correct!",
        //     message: "Please provide a image with dimension of 900 x 900"
        //   });
        // } else {
        if (+file.size <= 3145728) {
          let compressedFile: File;

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          ctx!.drawImage(image, 0, 0, canvas.width, canvas.height);

          canvas.toBlob(blob => {
            compressedFile = new File([blob!], `compressed_${file.name}`, { type: file.type });
            reader.readAsDataURL(compressedFile);
            reader.onload = (e) => {
              let URL: string | undefined = e.target?.result ? String(e.target?.result) : undefined;
              if (URL) {
                let base64 = URL.split(",")[1];
                // console.log("Base64URL: ", base64);
                // console.log("Compressed File: ", compressedFile);
                onSelect(base64);
              }
            };
          }, "image/jpeg", QUALITY);

          setSelectedFiles(file);
          setDimensions({ width: image.width, height: image.height });
        }
        else {
          showToast({
            time: 4000,
            type: "warning",
            title: "Image Size not Correct!",
            message: "Please provide a image with size of less then 3 mb"
          });
        }
        // };
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <div>
      <input
        hidden
        ref={openFileRef}
        type="file"
        onChange={e => onFileSelect(e)}
        accept="image/*"
      />
      {/* <Input.Wrapper label={label || 'Select File'} required={required} hidden>
        <div>
          <Dropzone
            multiple={false}
            openRef={openFileRef}
            onDrop={(e) => {
              if (e[0].size > (maxSizeInKB ? maxSizeInKB : 102400)) {
                showToast({
                  message: "File size should be less than 100KB",
                  type: "error"
                })
              } else {
                onSelect(e[0]);
                setSelectedFiles(e[0]);
              }
            }}
            accept={allMimeTypes
              .filter((e) => e.type === 'image' || e.type === 'pdf')
              .map((e) => e.mime)}
          >
          </Dropzone>
        </div >
      </Input.Wrapper > */}

      <BsImageFill
        className="cursor-pointer"
        onClick={() => {
          if (openFileRef.current !== null) {
            openFileRef.current.click();
          }
        }}
      />

    </div >
  );
};
