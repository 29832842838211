import { useEffect, useState, useContext } from 'react';

import { showToast } from '../../../utils/utility';
import { AppContext } from './../../../AppContext';
import useMasterData from '../../Hooks/useMasterData';
import { ITeacherToClassAllocation } from '../../../types';
import { defaultSelectedTeacher } from '../../../initialStates';
import { useSchoolData, useTeacherData } from '../../../libs/data-access';
import { handleTeacherAllocation } from '../../../utils/validators';
import { IAllTeacherListBySchoolId } from '../../../libs/data-access/types';

export default function useTeacherAllocationBySchool() {

    const {
        user
    } = useContext(AppContext);

    const {
        classesBasedOnSchool,
        getClassesBasedOnSchoolId,
        sectionBasedOnSchoolAndClass,
        getSectionBySchoolAndClassId
    } = useMasterData();
    const { fetchTeacherData } = useTeacherData();
    const { postSchoolData } = useSchoolData();

    const [schoolCodeToBeSearched, setSchoolCodeToBeSearched] = useState<number>(0);


    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const [allTeacherListBySchoolId, setAllTeacherListBySchoolId] = useState<IAllTeacherListBySchoolId[]>([]);
    const [selectedTableIndex, setSelectedTableIndex] = useState<number>(-1);
    const [isOpenUpdateAllocationModal, setIsOpenUpdateAllocationModal] = useState<boolean>(false);
    const [selectedTeacher, setSelectedTeacher] = useState<IAllTeacherListBySchoolId>(defaultSelectedTeacher);

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);


    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            getAllTeacherListBySchoolID();
        }
    }, [isFirstLoad]);

    useEffect(() => {
        if (
            user.details.schoolUserDetails?.school_Id // logged in school id
            &&
            selectedTeacher?.currentAllocation?.selectedClass_id
        ) {
            getSectionBySchoolAndClassId(user.details.schoolUserDetails?.school_Id, selectedTeacher.currentAllocation.selectedClass_id);
            setSelectedTeacher(prev => ({ ...prev, section_Id: 0 }));
        }
    }, [selectedTeacher?.currentAllocation?.selectedClass_id]);

    useEffect(() => {
        // console.log("Selected Teacher: ", allTeacherListBySchoolId[selectedTableIndex]);
        setSelectedTeacher({
            allocation: allTeacherListBySchoolId[selectedTableIndex]?.allocation,
            designation: allTeacherListBySchoolId[selectedTableIndex]?.designation,
            employee_Code: allTeacherListBySchoolId[selectedTableIndex]?.employee_Code,
            employee_Name: allTeacherListBySchoolId[selectedTableIndex]?.employee_Name,
            school_Teacher_Id: allTeacherListBySchoolId[selectedTableIndex]?.school_Teacher_Id,
            teacher_Id: allTeacherListBySchoolId[selectedTableIndex]?.teacher_Id,
        });
        setIsChecked(false);
    }, [selectedTableIndex]);


    /* HANDLER FUNCTIONS */
    const handleDisableSelectedClass = (classID: number) => {
        const toBeDisabled: boolean = selectedTeacher.allocation?.filter(allocation => allocation.class_Id === classID)?.length ? true : false;
        return toBeDisabled;
    };

    const handleDisableSelectedSection = (sectionID: number) => {
        const classToBeDisabled = selectedTeacher.allocation?.filter(allocation => allocation.class_Id === selectedTeacher?.currentAllocation?.selectedClass_id)?.[0]?.class_Id;
        const sectionToBeDisabled: boolean = selectedTeacher.allocation?.filter(allocation => allocation.section_Id === sectionID)?.length ? true : false;

        if (
            classToBeDisabled === selectedTeacher?.currentAllocation?.selectedClass_id
            &&
            sectionToBeDisabled
        ) {
            return true;
        } else {
            return false;
        }
    };

    /* API CALLS */
    //  GET ALL TEACHER LIST BY SCHOOL ID 
    const getAllTeacherListBySchoolID = async () => {

        if (user.details.schoolUserDetails?.school_Id) {

            setIsLoading(true);
            let response = await fetchTeacherData(`/GetTeacherListBySchoolId/${user.details.schoolUserDetails.school_Id}`);
            if (response.isSuccess) {
                // console.log("Response all teacher list: ", response.data.response);
                const data: IAllTeacherListBySchoolId[] | undefined = response.data;
                data && setAllTeacherListBySchoolId(data);
            } else {
                console.log("Error for fetch teacher list: ", response?.error);
                showToast({
                    time: 5000,
                    type: "warning",
                    title: "Oops!",
                    message: response.data || "Could not fetch teachers under this school"
                });
            };
            getClassesBasedOnSchoolId(user.details.schoolUserDetails.school_Id);
            setIsLoading(false);

        } else {

            showToast({
                time: 5000,
                type: "warning",
                title: "School is missing!",
                message: "We could not access school. Please login again."
            });

        };

    };

    // TEACHER ADD ALLOCATION BY SCHOOL 
    const postClassAllocationToTeacher = async () => {

        if (!user.details.schoolUserDetails?.school_Id) {
            showToast({
                type: "warning",
                title: "Please login again!",
                message: "School could not be found"
            });
            return;
        };

        let payload: ITeacherToClassAllocation = {
            school_Teacher_Id: selectedTeacher?.school_Teacher_Id,
            class_Id: selectedTeacher?.currentAllocation?.selectedClass_id ? selectedTeacher?.currentAllocation?.selectedClass_id : 0,
            section_Id: selectedTeacher?.currentAllocation?.selectedSection_id ? selectedTeacher?.currentAllocation?.selectedSection_id : 0,
            created_By: user.details.schoolUserDetails.school_Id
            // allocatedClass: selectedTeacher?.allocation,
        };
        // console.log("Payload for teacher allocation: ", payload);
        let isValidated = handleTeacherAllocation(payload);
        if (isValidated) {
            setIsLoading(true);
            let response = await postSchoolData(`/Principal/TeacherToClassSectionAllocationSave`, payload);
            if (response.isSuccess) {
                // console.log("Response for assessment types: ", response?.data);
                getAllTeacherListBySchoolID();
                setSelectedTeacher(defaultSelectedTeacher);
                setSelectedTableIndex(-1);
            } else {
                console.log("Error for teacher allocation with class-section: ", response.error);
                showToast({
                    type: "error",
                    title: "Oops!",
                    message: response.message || `Error in Allocation class to Teacher`
                });
            }
            setIsLoading(false);
        };

    };

    // TEACHER UPDATE ALLOCATION 

    // TODO: allocation [0] .class_id
    // const putClassAllocationToTeacher = async () => {
    //     let payload: ITeacherToClassAllocationUpdate = {
    //         class_Id: selectedTeacher?.allocation[0].class_Id,
    //         section_Id: selectedTeacher?.allocation[0].section_Id,
    //         created_By: 12 // TODO: make this dynamic(Principal ID)
    //     }
    //     let id = selectedTeacher?.teacher_Class_Section_Id;
    //     // console.log("Payload for edit: ", payload, " ID: ", id);

    //     let isValidated = handleEditTeacherAllocation(payload);
    //     if (isValidated) {
    //         setIsLoading(true);
    //         let response = await putSchoolData(`/Principal/TeacherToClassSectionAllocationUpdate?Id=${id}`, payload);
    //         if (response.isSuccess) {
    //             // console.log("Response for assessment types: ", response?.data);
    //             setSelectedTableIndex(-1);
    //             setIsOpenUpdateAllocationModal(false);
    //             getAllTeacherListBySchoolID();
    //         } else {
    //             console.log("Error for assessment types: ", response.error);
    //             setSelectedTableIndex(-1);
    //             setIsOpenUpdateAllocationModal(false);
    //             showToast({
    //                 message: `Error in Update Allocation class to Teacher`,
    //                 type: "error"
    //             });
    //         };
    //         setIsLoading(false);
    //     };
    // };

    return {
        schoolCodeToBeSearched,
        setSchoolCodeToBeSearched,

        classesBasedOnSchool,
        sectionBasedOnSchoolAndClass,
        getSectionBySchoolAndClassId,
        getAllTeacherListBySchoolID,
        allTeacherListBySchoolId,
        handleDisableSelectedClass,
        handleDisableSelectedSection,
        isChecked,
        setIsChecked,
        isOpenUpdateAllocationModal,
        setIsOpenUpdateAllocationModal,
        isLoading,
        setIsLoading,
        selectedTableIndex,
        setSelectedTableIndex,
        selectedTeacher,
        setSelectedTeacher,
        postClassAllocationToTeacher,
        // putClassAllocationToTeacher
    }
}
