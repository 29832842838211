import React, { useContext, useState } from "react";
import { camelCaseToTitleCase, handleColor } from "../../../utils/utility";
import ReactApexChart from "react-apexcharts";
import { IReportLevel } from "../../../libs/data-access/types";
import { RiFileList3Line } from "react-icons/ri";
import { CSVLink } from "react-csv";
import { BiDownload } from "react-icons/bi";
import moment from "moment";
import { DashboardFilterContext } from "../../Dashboard/DashboardFilterContext";
import { Modal, Tooltip } from "@mantine/core";
import { EButton, ETable } from "../../../libs/ui";
import InformationToolTip from "../../../libs/ui/src/Stat/InformationToolTip";
type IProps = {
  selectedDistrict: string;
  reportLevel: IReportLevel;
  uniqueId: string;
  overAllStats: {
    label: string;
    value: number;
    overAll: any;
    toolTipLabel?: string;
  };
  downloadableData?: any;
};

export default function RingChartData({
  overAllStats,
  selectedDistrict,
  reportLevel,
  uniqueId,
  downloadableData,
}: IProps) {
  const { selectedMonth } = useContext(DashboardFilterContext);

  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState<boolean>(false);
  const backgroung = handleColor(
    overAllStats?.value ? +overAllStats?.value : 0
  );

  let options = {
    chart: {
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {

            offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            color: "#111",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "solid",
      colors:[backgroung]
    },
    labels: ["Percent"],
  };

  let allHeaders: { label: string; key: string }[] = [];

  if (downloadableData) {
    allHeaders =
      downloadableData?.Map[0] &&
      Object.keys(downloadableData?.Map[0]).map((e) => ({
        label: e?.replaceAll("_", " "),
        key: e,
      }));
  }

  return (
    <div>
      <div className=" h-full w-full flex flex-col items-center ">
        
        <div className="cursor-pointer">
          {overAllStats?.value ? (
            <ReactApexChart
              options={options}
              series={[overAllStats?.value]}
              type="radialBar"
              height={250}
            />
          ) : (
            <ReactApexChart
              options={options}
              series={[0]}
              type="radialBar"
              height={250}
            />
          )}
        </div>
        <p className="text-center font-bold text-lg text-slate-900 -mt-2 mb-3 flex items-center">{overAllStats?.label} <InformationToolTip customClassName="ml-3" toolTipLabel={overAllStats?.toolTipLabel}/> </p>
            
        {downloadableData ? (
          <div className="map-link-container flex justify-end">
          <EButton variant="light" className="btn-light" onClick={()=>setIsOpenDetailsModal(true)}>
            View
          </EButton>
            </div>
            
        ) : (
          <></>
        )}
      </div>

      <Modal
        size="xxl"
        opened={isOpenDetailsModal}
        onClose={() => setIsOpenDetailsModal(false)}
        //   title={camelCaseToTitleCase(overAllStats?.label)}
        withCloseButton={false}
      >
        <div className="flex justify-between mb-8 gap-5 ">
          <h3>{camelCaseToTitleCase(overAllStats?.label)}</h3>

          <CSVLink
            className="text-blue-500 text-sm hover:underline "
            data={
              overAllStats
                ? downloadableData?.Map?.concat([overAllStats?.overAll])
                : downloadableData?.Map
            }
            headers={allHeaders ?? []}
            filename={`${overAllStats?.label}${moment(selectedMonth).format(
              "MMM"
            )}-${moment().format("YYYY")}.csv`}
          >
            <BiDownload size={30} />
          </CSVLink>
        </div>
        <ETable>
          <thead>
            <tr>
              {allHeaders?.map((e) => (
                <th>{e?.label ?? "-"}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {downloadableData?.Map?.map((e: any) => (
              <tr>
                {allHeaders?.map((each, index) => (
                  <td>{e[each?.key] ?? "-"}</td>
                ))}
              </tr>
            ))}
            {overAllStats && (
              <tr>
                {allHeaders?.map((each, index) => (
                  <td>{overAllStats?.overAll[each?.key] ?? "-"}</td>
                ))}
              </tr>
            )}
          </tbody>
        </ETable>
      </Modal>
    </div>
  );
}
