import React, { useContext, useEffect, useState } from 'react'
import JSZip from "jszip";
import { showToast } from '../../../utils/utility';
import moment from 'moment';
import { useMasterFetch } from '../../../libs/data-access';
import { BASE64_INITIALS } from '../../../initialStates';
import { AppContext } from '../../../AppContext';
import useMasterData from '../../Hooks/useMasterData';
import { ROLES } from '../../../Routes/routes';

const useDownloadAssessement = () => {

const { fetchMasterData } = useMasterFetch();

const { user, role } = useContext(AppContext);

  const {
    getDistrictByStateId,
    districts,
  } = useMasterData();

  const [isFirst, setIsFirst] = useState<boolean>(true);
    const [selectedDistrict, setSelectedDistrict] = useState<
      string | undefined | null
    >(undefined);


  const [mentorImages,setMentorImages] = useState<any>([])
  const [filterData,setFilterData] = useState<any>({
    search:"",
    fromDate:null,
    toDate:null
  })

console.log("filterData",filterData)

  const [loading,setLoading] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);



    // HANDLER FUNCTIONS
  const handleFetchTickets = async(current?:any,district?:any) => {
    setMentorImages([]);
    setLoading(true)

    const response = await fetchMasterData(
      `GetMentorDetailsWithImage?district_id=${
       district ? district : selectedDistrict ? +selectedDistrict : 0
      }&search=${
        filterData?.search?.length > 0 ? filterData?.search : ""
      }&fromDate=${
        filterData?.fromDate
          ? moment(filterData?.fromDate)?.format("YYYY-MM-DD")
          : ""
      }&toDate=${
        filterData?.toDate
          ? moment(filterData?.toDate)?.format("YYYY-MM-DD")
          : ""
      }&offset=${
        current ? 0 : currentPage * itemsPerPage
      }&pageSize=${itemsPerPage}`
    );

    if (response.isSuccess) {
      setTotalRecords(response?.data[0]?.total);
      setMentorImages(response.data);
    } else {
      console.error("Error while fetching tickets: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch tickets.",
      });
    }
    setLoading(false);

  };

    const onSearchFunc = () => {
      setCurrentPage(0)
    handleFetchTickets(true);
    
  };



    // filter data handle change

  const filterHandleChange = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }) => {
    setFilterData((prev: any) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };


    const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const [totalRecords, setTotalRecords] = useState(11);

    const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);



  // Handle click event to download images as ZIP
  const handleDownload = async () => {
    const zip = new JSZip();
    // Convert base64 images to Blob and add to zip
    mentorImages.forEach((image:any) => {
      // const base64Data = image.mentor_Pic_Data.split(",")[1]; // Remove the data URL part
      const base64Data = image?.mentor_Pic_Data;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });
const fileName = `${moment(image?.created_On).format("YYYY-MM-DD_HH-mm")}.png`;
      zip.file(fileName, blob);
    });

    // Generate and create Blob URL
    const zipContent = await zip.generateAsync({ type: "blob" });
    const url = URL.createObjectURL(zipContent);

    // Create and click anchor element to trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = "images.zip";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Release Blob URL
    URL.revokeObjectURL(url);
  };


  //     useEffect(() => {
  //   if (isFirstLoad) {
  //     setIsFirstLoad(false);
  //   } else {
  //     handleFetchTickets();
  //   }
  // }, [isFirstLoad]);

      useEffect(() => {
    if (mentorImages?.length > 0) {
      handleFetchTickets();
    }
  }, [currentPage]);


    useEffect(() => {
      if (isFirst) {
        setIsFirst(false);
      } else {
        getDistrictByStateId(1);

        let districtBlockId = null;

        if (role?.includes(ROLES.BLOCK)) {
          
          districtBlockId = String(user?.details?.blockUserDetails?.district_Id)

          setSelectedDistrict(districtBlockId);
          
        }
        if (role?.includes(ROLES.DISTRICT)) {
          
          districtBlockId =  String(user?.details?.districtUserDetails?.district_Id)
          
          setSelectedDistrict(districtBlockId);
        }

        handleFetchTickets(false, districtBlockId);
      }
    }, [isFirst]);

  return {
    handleDownload,
    currentPage,
    itemsPerPage,
    totalRecords,
    nextPage,
    prevPage,
    setCurrentPage,

    mentorImages,
    filterData,
    filterHandleChange,
    loading,
    onSearchFunc,
    user,
    role,
    districts,
    selectedDistrict,
    setSelectedDistrict
  };
}

export default useDownloadAssessement