import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from "../../utility";

export function useStateAdmin() {

    const fetchStateAdmin = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}StateAdmin/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const postStateAdmin = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}StateAdmin/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const deleteStateAdmin = async (
        endpoint: string,
    ) => {
        try {

            let response = await axios.delete(`${BASE_URL}StateAdmin/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };
    
    const putStateAdmin = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}StateAdmin/${endpoint}`,payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        fetchStateAdmin,
        postStateAdmin,
        deleteStateAdmin,
        putStateAdmin
    };
}
