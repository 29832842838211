import React from 'react'
import SpotQuestionFilter from './spotQuestionFilter';
import { Checkbox, Space } from '@mantine/core';
import AddQuestionFrame from '../../../QuestionBank/Components/Uploader/AddQuestionFrame';
import useSpotAddQuestion from '../hook/useSpotAddQuestion';
import AddedOptionList from '../../../QuestionBank/Components/Uploader/AddedOptionList';
import AddOptionFrame from '../../../QuestionBank/Components/Uploader/AddOptionFrame';
import { EButton } from '../../../../libs/ui';
import { MdAddCircle } from 'react-icons/md';
import AddQuestionFrameSpot from './AddQuestionFrameSpot';
import AddOptionFrameSpot from './AddOptionFrameSpot';
import { useLocation } from 'react-router-dom';

const UploadQuestionFormSpot = () => {
  const location = useLocation();
     const {
       classes,
       subjectList,
       allGroups,
       handleChangeQuestionFilterParams,
       filterValue,
       questionType,
       mediaTypeForQuestion,
       mediaTypeForOptions,
       questionDetails,
       handleChangeQuestionDetails,
       handleChangeOptionDetails,

       addQuestion,
       addOption,
       deleteQuestion,
       deleteOption,
       handleSetCorrectOption,
       handleSetCorrectOptionMultiple,
       setIsConfirmed,
       isConfirmed,
       isLoading,
       handleSaveQuestion,
     } = useSpotAddQuestion();
  return (
    <div className="">
      <div className="grid grid-cols-12 grid-rows-1 gap-3">
        <div className="col-span-full">
          {/* FILTER SECTION */}
          <SpotQuestionFilter
            classes={classes}
            subjectList={subjectList}
            allGroups={allGroups}
            handleChangeQuestionFilterParams={handleChangeQuestionFilterParams}
            filterValue={filterValue}
            questionType={questionType}
            mediaTypeForQuestion={mediaTypeForQuestion}
            mediaTypeForOptions={mediaTypeForOptions}
          />

          {/* QUESTION DETAILS */}
          <Space h={24} />
          <div className="grid auto-rows-max overflow-y-auto max-h-screen">
            {questionDetails?.map((item: any, index: number) => {
              return (
                <div className="question-container">
                  {/* ADD QUESTION */}
                  <AddQuestionFrameSpot
                    questionFilters={item}
                    questionDetails={item}
                    handleChangeQuestionDetails={handleChangeQuestionDetails}
                    index={index}
                    deleteQuestion={deleteQuestion}
                  />

                  {/* OPTION MANAGEMENT */}
                  <div>
                    <div className="text-xl font-bold">Options</div>
                    <p className="text-sm opacity-50">
                      Enter at least 2 options and select the correct answer
                    </p>
                    <div className="options-container mt-2">
                      {/* ADDED OPTION LIST */}

                      {/* <AddedOptionListSpot
                        optionList={[]}
                        questionFilters={filterValue}
                        handleSetCorrectOption={() => null}
                        handleSetCorrectOptionMultiple={() => null}
                        handleRemoveSpecificOption={() => null}
                        handleRemoveCurrentOption={() => null}
                        index={index}
                      /> */}

                      {/* ADD NEW OPTION */}
                      {item?.spotQuestionOptions?.map(
                        (child: any, childIndex: number) => {
                          return (
                            <AddOptionFrameSpot
                              questionFilters={item}
                              optionDetails={child}
                              handleChangeOptionDetails={
                                handleChangeOptionDetails
                              }
                              optionList={[]}
                              handleAddNewOption={() => null}
                              removeOption={deleteOption}
                              index={index}
                              childIndex={childIndex}
                              handleSetCorrectOption={handleSetCorrectOption}
                              handleSetCorrectOptionMultiple={
                                handleSetCorrectOptionMultiple
                              }
                            />
                          );
                        }
                      )}

                      <div className="input-item empty">
                        <a
                          className="flex gap-1 items-center font-semibold text-secondary-500 p-2 cursor-pointer"
                          onClick={() => addOption(index)}
                        >
                          <MdAddCircle /> Add Option
                        </a>
                      </div>

                      {/* )} */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Space h={44} />
      <div className="flex justify-between items-center">
        {/* DISCLAIMER */}
        <Checkbox
          className="w-3/4"
          label={
            <span className="text-sm text-slate-400">
              {`Are you sure you want to save?`}
            </span>
          }
          checked={isConfirmed}
          onChange={(e) => {
            setIsConfirmed(e.target.checked);
          }}
        />
        <div className="flex">
          <EButton
            type={"submit"}
            disabled={location?.state}
            loading={isLoading}
            className="btn-blue mr-2"
            onClick={() => addQuestion()}
          >
            Add Question
          </EButton>
          <EButton
            type={"submit"}
            disabled={
              !isConfirmed ||
              !filterValue.selectedGrade ||
              !filterValue.selectedSubject ||
              !filterValue.WeekStartDate ||
              !filterValue.WeekEndDate ||
              !filterValue.question_Group_Instruction_Id ||
              !filterValue.questionType ||
              !filterValue.media_Type_Id ||
              !filterValue.option_Media_Type_Id
            }
            loading={isLoading}
            className="btn-blue"
            onClick={() => handleSaveQuestion()}
          >
            Save
          </EButton>
        </div>
      </div>
    </div>
  );
}

export default UploadQuestionFormSpot