import React from "react";
import "./ECard.scss";
import InformationToolTip from "../Stat/InformationToolTip";

interface IProps {
  title?: string;
  header?: JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[];
  footer?: JSX.Element | JSX.Element[];
}

export function ECard(props: any) {
  return (
    <div className="card">
      <div className="card--head" style={props?.noHeader ? {display:'none'} : {}}>
        <div className="title flex">
          {props.title}
          {props.informationToolTip ? (
            <InformationToolTip
              customClassName="ml-2"
              toolTipLabel={props?.toolTipLabel}
            />
          ) : null}
        </div>
        <div>{props.header}</div>
      </div>
      <div className={`card--body ${props.customClass}`}>{props.children}</div>
      {props.foot && <div className="card--foot">{props.footer}</div>}
    </div>
  );
}
