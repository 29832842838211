import React from 'react'
import { Skeleton } from '@mantine/core';
import { EButton, EEmpty, EInfoPanel, ESectionDivider, ETable, EVerticalFormSection } from '../../../../../libs/ui';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import MapData from '../../../../../libs/maps/MapData';
import useUniqueStudentsSpotTested from '../Hooks/useUniqueStudentsSpotTested';
import { EPlaceholder } from '../../../../../libs/ui/src/Placeholder/EPlaceholder';
import { ECard } from '../../../../../libs/ui/src/Card/ECard';
import { BiDownload } from 'react-icons/bi';

export default function UniqueStudentSpotTested() {
    const {
      isLoading,
      isMapLoading,
      isLoadingDownloadReport,
      spotAssessmentUniqueStudentData,
      spotAssessmentUniqueStudentCSV,
      keysForTableHeader,
      keysForCompetency,
      spotAssessmentMapData,
      totalForData,
    } = useUniqueStudentsSpotTested();

    return (
      <div className="flex flex-col gap-7">
        {/* Map  */}
        {
          spotAssessmentUniqueStudentData?.length ? (
            isMapLoading ? (
              <Skeleton radius={"md"} height={250} />
            ) : (
              spotAssessmentMapData && (
                <MapData
                  // title="District-level choropleth map"
                  title=""
                  data={
                    spotAssessmentMapData
                      ? spotAssessmentMapData?.map((each) => ({
                          district: each.District_Name ?? "",
                          average: +each?.Assessment_Student_Percentage,
                        }))
                      : []
                  }
                  isLoading={false}
                  setSelectedDistrict={() => {}}
                />
              )
            )
          ) : (
            <></>
          )
          // <EPlaceholder
          //     iconSrc={require("../../../../../assets/icons/icon-placeholder.svg").default}
          //     title="Create Report"
          //     description="Please select a month and other filters and press search to generate a report."
          // />
        }

        {/* Table to displayed */}
        <div>
          {isLoading ? (
            <Skeleton radius={"md"} height={250} />
          ) : spotAssessmentUniqueStudentData?.length ? (
            <ECard
              title="Total unique students spot tested"
              header={
                <CSVLink
                  style={{}}
                  className="text-blue-600 text-sm hover:underline"
                  // data={[]}
                  data={spotAssessmentUniqueStudentCSV?.content ?? []}
                  // headers={[]}
                  headers={spotAssessmentUniqueStudentCSV?.headers ?? []}
                  filename={`Spot_Assessment_Unique_Student_${moment().format(
                    "MMM"
                  )}-${moment().format("YYYY")}.csv`}
                >
                  <EButton className="btn-blue">
                    Download Report <BiDownload className="ml-2" size={22} />
                  </EButton>
                </CSVLink>
              }
            >
              <ETable>
                <thead className="border">
                  <tr>
                    <th>
                      {keysForTableHeader
                        ? keysForTableHeader.includes("School_Name")
                          ? "School Name"
                          : keysForTableHeader.includes("Cluster_School_Name")
                          ? "Cluster Name"
                          : keysForTableHeader.includes("Block_Name")
                          ? "Block Name"
                          : keysForTableHeader.includes("District_Name")
                          ? "District Name"
                          : "Name"
                        : "Name"}
                    </th>
                    <th>Total Student</th>
                    <th>Total Assessment</th>
                    <th>Assessment Student Percentage</th>
                  </tr>
                </thead>
                <tbody className="border">
                  {spotAssessmentUniqueStudentData
                    .filter((e) => e?.District_Name !== "")
                    ?.map((each) => (
                      <tr key={each + "spot"}>
                        <td>
                          {keysForTableHeader
                            ? keysForTableHeader.includes("School_Name")
                              ? each?.School_Name
                              : keysForTableHeader.includes(
                                  "Cluster_School_Name"
                                )
                              ? each?.Cluster_School_Name
                              : keysForTableHeader.includes("Block_Name")
                              ? each?.Block_Name
                              : keysForTableHeader.includes("District_Name")
                              ? each?.District_Name
                              : "Name"
                            : "Name"}
                        </td>
                        <td>{each?.Total_Student}</td>
                        <td>{each?.Total_Assessment}</td>
                        <td>{`${each?.Assessment_Student_Percentage}%`}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>{totalForData?.name}</td>
                    <td>{totalForData?.Total_Student}</td>
                    <td>{totalForData?.Total_Assessment}</td>
                    <td>{`${totalForData?.Assessment_Student_Percentage}%`}</td>
                  </tr>
                </tbody>
              </ETable>
            </ECard>
          ) : (
            <EPlaceholder
              iconSrc={
                require("../../../../../assets/icons/icon-placeholder.svg")
                  .default
              }
              title="Create Report"
              description="Please select a month and other filters and press search to generate a report."
            />
          )}
        </div>
        {/* DATA for excel */}
        {/* <div className="mt-5">
                {isLoadingDownloadReport
                    ?
                    <Skeleton radius={"md"} height={250} />
                    :
                    spotAssessmentUniqueStudentCSV?.content
                        ?
                        <EVerticalFormSection
                            title=""
                            rightPanel={<>

                            </>}
                        >
                            <div>
                                <EInfoPanel
                                    title=" Your report is ready to download, Please click on Download File to download report"
                                >
                                    <CSVLink
                                        style={
                                            {

                                            }
                                        }
                                        className="text-blue-600 text-sm hover:underline"
                                        // data={[]}
                                        data={spotAssessmentUniqueStudentCSV?.content ?? []}
                                        // headers={[]}
                                        headers={spotAssessmentUniqueStudentCSV?.headers ?? []}
                                        filename={`Spot_Assessment_Unique_Student_${moment().format("MMM")}-${moment().format("YYYY")}.csv`}
                                    >Download File</CSVLink>
                                </EInfoPanel>
                            </div>
                        </EVerticalFormSection>
                        :
                        <></>}
            </div> */}
      </div>
    );
};

