import React, { useState } from "react";
import { Space } from "@mantine/core";

import CSVReader from "../CSVReader";
import PageLayout from "../../../../PageLayout";
import QuestionBankFilter from "../QuestionBankFilter";
import { ESectionDivider, ETable } from "../../../../libs/ui";

export default function UploadQuestionFromCSV() {
  const [JSONdataFromCSV, setJSONdataFromCSV] = useState<any[]>([]);

  return (
    <PageLayout>
      <div className="grid grid-cols-12 grid-rows-1">
        <div className="col-span-8">
          {/* FILTER SECTION */}
          <QuestionBankFilter
            handleGetSelectedParameters={(params:any) =>
              console.log("Selected params: ", params)
            }
          />

          <Space h={30} />

          <CSVReader
            handleCSVData={(data) => setJSONdataFromCSV(data)}
            setJSONdataFromCSV={setJSONdataFromCSV}
          />
        </div>
      </div>

      {/* ADDED QUESTION LIST */}
      <Space h={50} />
      {JSONdataFromCSV.length ? (
        <>
          <ESectionDivider title="Added Question List" />
          <ETable>
            <thead>
              <tr>
                {JSONdataFromCSV[0]?.map((heading: string, index: number) => (
                  <th className="capitalize" key={index + "hd"}>
                    {heading.replaceAll("_", " ")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {JSONdataFromCSV.slice(1)?.map((each, index) => (
                <tr key={index + "data"}>
                  {each?.map((data: string, i: number) => (
                    <td key={i + "data"}>{data}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </ETable>
        </>
      ) : (
        <></>
      )}
    </PageLayout>
  );
}
