import React, { useEffect, useState } from 'react'
import useMasterData from '../../Hooks/useMasterData';
import { useStateAdmin } from '../../../libs/data-access';
import { showToast } from '../../../utils/utility';
import moment from 'moment';

const UseOrfQuestionList = () => {

    const { subjectList, getSubject, classes, getClasses } = useMasterData();
     const { fetchStateAdmin } = useStateAdmin();

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isLoading,setIsLoading] = useState<boolean>(false)

    const [orfData,setOrfData] = useState([])

    console.log("orfData",orfData)

    const [orfFilter, setOrfFilter] = useState({
      classID: null,
      subject: null,
      startDate: null,
      endDate:null,
    });

    const handleChangeOrf = (name:any,value:any) => {
        setOrfFilter((prev)=>{
            return {
                ...prev,
                [name]:value
            }
        })
    }

    const handleSearch = async() => {
     setIsLoading(true);
     const res = await fetchStateAdmin(
       `Question/GetORFQuestionMaster?classID=${Number(
         orfFilter?.classID
       )}&subject=${Number(orfFilter?.subject)}&startDate=${moment(
         orfFilter?.startDate
       )?.format("YYYY-MM-DD")}&endDate=${moment(orfFilter?.endDate).format(
         "YYYY-MM-DD"
       )}`
     );
     if (res.isSuccess) {
       setOrfData(res?.data);
     } else {
       setOrfData([]);
       showToast({
         type: "error",
         message: res?.message || "Fail to get orf question list",
       });
     }
     setIsLoading(false);
    }

        useEffect(() => {
          if (isFirstLoad) {
            setIsFirstLoad(false);
          } else {
            getSubject();
            getClasses();
          }
        }, [isFirstLoad]);

  return {
    classes,
    subjectList,
    orfFilter,
    handleChangeOrf,
    isLoading,
    handleSearch,
    orfData,
  };
}

export default UseOrfQuestionList