import React from 'react'
import { FastAPI } from '../../constant';
import axios from 'axios';
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useDetailsKpiData() {

    const fetchDetailKpi = async (endpoint: string) => {
        try {
    
            let response = await axios.get(`${FastAPI}DetailKpi/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;
    
        } catch (error: any) {
    
            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;
    
        };
    };  
    
    return{
        fetchDetailKpi
    }
    }
    
