import React from "react";
import { GiCancel } from "react-icons/gi";
import { FiVideoOff } from "react-icons/fi";
import { TiTickOutline } from "react-icons/ti";
import { BsFillCameraVideoFill, BsInfoCircle } from "react-icons/bs";
import { MdAddCircle, MdOutlineHideImage } from "react-icons/md";
import { Checkbox, Radio, Space, Textarea, Image, TextInput, Tooltip, Alert } from "@mantine/core";

import "./UploadQuestionForm.scss";
import {
  EButton,
  ESelectFileForQuestionBank
} from "../../../../libs/ui";
import QuestionBankFilter from "../QuestionBankFilter";

import { useUploadQuestionForm } from "../../Hooks/useUploadQuestionForm";
import { BASE64_INITIALS } from "../../../../initialStates";
import AddQuestionFrame from "./AddQuestionFrame";
import AddOptionFrame from "./AddOptionFrame";
import AddedOptionList from "./AddedOptionList";

export default function UploadQuestionForm() {
  const {
    isConfirmed,
    setIsConfirmed,
    setQuestionFilters,
    questionDetails,
    handleChangeQuestionDetails,
    isAddingNewOption,
    setIsAddingNewOption,
    optionDetails,
    handleChangeOptionDetails,
    handleAddNewOption,
    optionList,
    setOptionList,
    setOptionDetails,
    handleRemoveCurrentOption,
    handleRemoveSpecificOption,
    handleSetCorrectOption,
    questionList,
    handleAddAnotherQuestion,
    handleRemoveSelectedQuestion,
    handleSaveQuestion,
    isLoading,
    questionFilters,
    handleSetCorrectOptionMultiple,

    assessmentTypes,
        subjectList,
        classes,
        competencyList,
        competencyByClassSubject,
        questionType,
        spotAssessmentData,
        mediaTypeForQuestion,
        mediaTypeForOptions,
        handleChangeQuestionFilterParams,
        handleClearQuestionFilters,
        scrollRef
  } = useUploadQuestionForm();

  return (
    <div className="">
      <div ref={scrollRef} className="grid grid-cols-12 grid-rows-1 gap-3">
        <div className="col-span-full">
          {/* FILTER SECTION */}
          <QuestionBankFilter
            handleGetSelectedParameters={(params:any) => setQuestionFilters(params)}
            questionFilters={questionFilters}
            setQuestionFilters={setQuestionFilters}
            assessmentTypes={assessmentTypes}
        subjectList={subjectList}
        classes={classes}
        competencyList={competencyList}
        competencyByClassSubject={competencyByClassSubject}
        questionType={questionType}
        spotAssessmentData={spotAssessmentData}
        mediaTypeForQuestion={mediaTypeForQuestion}
        mediaTypeForOptions={mediaTypeForOptions}
        handleChangeQuestionFilterParams={handleChangeQuestionFilterParams}
        handleClearQuestionFilters
          />

          {/* QUESTION DETAILS */}
          <Space h={24} />
          <div className="question-container">
            {/* ADD QUESTION */}
            <AddQuestionFrame
              questionFilters={questionFilters}
              questionDetails={questionDetails}
              handleChangeQuestionDetails={handleChangeQuestionDetails}
            />

            {/* OPTION MANAGEMENT */}
            <div>
              <div className="text-xl font-bold">Options</div>
              <p className="text-sm opacity-50">
                Enter at least 2 options and select the correct answer
              </p>
              <div className="options-container mt-2">
                {/* ADDED OPTION LIST */}
                {optionList.length
                  ?
                  <>
                  <AddedOptionList
                    optionList={optionList}
                    questionFilters={questionFilters}
                    handleSetCorrectOption={handleSetCorrectOption}
                    handleSetCorrectOptionMultiple={handleSetCorrectOptionMultiple}
                    handleRemoveSpecificOption={handleRemoveSpecificOption}
                    handleRemoveCurrentOption={handleRemoveCurrentOption}
                  />
                  </>
                  : <></>}

                {/* ADD NEW OPTION */}
                {isAddingNewOption || !optionList.length
                  ?
                  <AddOptionFrame
                    questionFilters={questionFilters}
                    optionDetails={optionDetails}
                    handleChangeOptionDetails={handleChangeOptionDetails}
                    optionList={optionList}
                    handleAddNewOption={handleAddNewOption}
                    removeOption={handleRemoveCurrentOption}
                  />
                  :
                  <></>}

                {
                !isAddingNewOption
                 ? <div className="input-item empty">
                  <a
                    className="flex gap-1 items-center font-semibold text-secondary-500 p-2 cursor-pointer"
                    onClick={() => setIsAddingNewOption(true)}
                  >
                    <MdAddCircle /> Add Option
                  </a>
                </div> : <></>}
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Space h={44} />
      <div className="flex justify-between items-center">
        {/* DISCLAIMER */}
        <Checkbox
          className="w-3/4"
          label={
            <span className="text-sm text-slate-400">
              {`Are you sure you want to save?`}
            </span>
          }
          checked={isConfirmed}
          onChange={(e) => {
            setIsConfirmed(e.target.checked);
          }}
        />
        <div className="flex">
          <EButton
            type={"submit"}
            disabled={!isConfirmed}
            loading={isLoading}
            className="btn-blue"
            onClick={() => handleSaveQuestion()}
          >
            Save
          </EButton>
        </div>
      </div>
    </div>
  );
}
