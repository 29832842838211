import { Tabs } from '@mantine/core';
import React, { useState } from 'react';

import PageLayout from '../../../../PageLayout';
import { EPageTitle } from '../../../../libs/ui';
import UniqueSchoolVisit from './Sections/UniqueSchoolVisit';
import ClassroomObservation from './Sections/ClassroomObservation';
import UniqueSchoolVisitMentorWise from './Sections/UniqueSchoolVisitMentorWise';

export default function Thresholds() {
    const [activeTab, setActiveTab] = useState<string | null>('first');

    return (
        <PageLayout>
            <EPageTitle
                title="Threshold"
            />

            <Tabs value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="first">Classroom Observation</Tabs.Tab>
                    <Tabs.Tab value="second">Unique School Visit</Tabs.Tab>
                    <Tabs.Tab value="third"> Mentor wise Unique School Visit</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="first" pt="xs">
                    <ClassroomObservation />
                </Tabs.Panel>
                <Tabs.Panel value="second" pt="xs">
                    <UniqueSchoolVisit />
                </Tabs.Panel>
                <Tabs.Panel value="third" pt="xs">
                    <UniqueSchoolVisitMentorWise />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}
