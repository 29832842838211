import React, { useContext, useEffect, useState } from "react";
import HaryanaMap from "./HaryanaMap";
import DistrictWithMap from "./DistrictWithMap";
import { IMasterData, IReportLevel } from "../data-access/types";
import { Button, Card, Modal, Tooltip } from "@mantine/core";
import ReactApexChart from "react-apexcharts";
import { camelCaseToTitleCase } from "../data-access/utility";
import { handleColor } from "../../utils/utility";
import { CSVLink } from "react-csv";
import moment from "moment";
import { BiDownload } from "react-icons/bi";
import { DashboardFilterContext } from "../../Pages/Dashboard/DashboardFilterContext";
import { BsList, BsListTask } from "react-icons/bs";
import { MdPreview } from "react-icons/md";
import { ETable } from "../ui";
import { HiOutlineClipboardList } from "react-icons/hi";
import { RiFileList3Line } from "react-icons/ri";
import { ROLES } from "../../Routes/routes";
import { AppContext } from "../../AppContext";
import useMasterData from "../../Pages/Hooks/useMasterData";
import { BsInfoCircle } from 'react-icons/bs';
import InformationToolTip from "../ui/src/Stat/InformationToolTip";

type IProps = {
  mapData: {
    name: string;
    value: number;
  }[];
  selectedDistrict: string;
  reportLevel: IReportLevel;
  uniqueId: string;
  overAllStats?: {
    label: string;
    toolTipLabel?: string;
    value: number;
    overAll?: any;
  };
  hideChart?: boolean;
  downloadableData?: any;
};

export default function ColorBaseMap({
  mapData,
  overAllStats,
  selectedDistrict,
  reportLevel,
  uniqueId,
  downloadableData,
  hideChart
}: IProps) {
  const { selectedMonth } = useContext(DashboardFilterContext);
  const {
    blocks,
    getBlocksByDistrictId,
    }=useMasterData() 

  const backgroung = handleColor(
    overAllStats?.value ? +overAllStats?.value : 0
  );
  const [isOpenDetailModal, setIsOpenDetailsModal] = useState<boolean>(false);
  const { user, role } = useContext(AppContext);
  useEffect(()=>{
     if(reportLevel?.levelNames == "block" || reportLevel?.levelNames == "cluster"){
      getBlocksByDistrictId(reportLevel?.districtId)
     }
  },[])
  
  const options = {
    chart: {
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "85%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          value: {
            offsetY: -3,
            fontSize: "16px",
            fontWeight: "bold",
          },
          name: {
            show: false,
            offsetY: -0,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -23,
      },
    },
    fill: {
      type: "gradient",
      colors: [backgroung],
      gradient: {
        shade: "light",
        shadeIntensity: 0.2,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
      pattern: {
        width: 10,
        strokeWidth: 10,
      },
    },
  };

  let allHeaders: { label: string; key: string }[] = [];

  let content: any[] = [];

  if (downloadableData) {
    allHeaders =
      downloadableData?.Map[0] &&
      Object.keys(downloadableData?.Map[0]).map((e) => ({
        label: e.replaceAll("_", " "),
        key: e,
      }));
  }


  console.log("allheader",allHeaders)
   console.log("overallstate", overAllStats);
   console.log("downloadableData", downloadableData);
  return (
    <div className={`h-full w-full rounded-md ${overAllStats ? `` : ``}`}>
      {overAllStats && !hideChart ? (
        <Card className="">
          <div className="text-lg font-bold pb-2 flex flex-1">
            {camelCaseToTitleCase(overAllStats?.label)}
            <InformationToolTip
              customClassName="ml-1"
              toolTipLabel={overAllStats?.toolTipLabel}
            />
            {/* <div>
              <Tooltip label={overAllStats?.toolTipLabel}>
      <p><BsInfoCircle/></p>
    </Tooltip>
              </div> */}
          </div>
          <div className="flex items-start mt-6">
            <div className="map-link-container">
              <Button
                onClick={() => setIsOpenDetailsModal(true)}
                className="map-link btn-light"
                size="xs"
              >
                <p className="text-sm">View</p>
              </Button>
              <CSVLink
                data={
                  overAllStats?.overAll
                    ? downloadableData?.Map.concat([overAllStats?.overAll])
                    : downloadableData?.Map ?? ""
                }
                headers={allHeaders ?? []}
                filename={`${overAllStats?.label}${moment(selectedMonth).format(
                  "MMM"
                )}-${moment().format("YYYY")}.csv`}
              >
                {/* <BiDownload size={30} /> */}
                <Button
                  onClick={() => {}}
                  className="map-link btn-light"
                  size="xs"
                >
                  <p className="text-sm">Download</p>
                </Button>
              </CSVLink>
            </div>

            <ReactApexChart
              options={options}
              series={[overAllStats?.value]}
              type="radialBar"
            />
          </div>

          {/* {downloadableData ? (
            <RiFileList3Line
              className="text-slate-600 cursor-pointer text-sm hover:underline absolute top-3 left-4"
              onClick={() => setIsOpenDetailsModal(true)}
              size={30}
            />
          ) : (
            <></>
          )}
          {downloadableData ? (
            <CSVLink
              className="text-blue-500 text-sm hover:underline absolute top-3 right-4"
              data={
                overAllStats.overAll
                  ? downloadableData?.Map.concat([overAllStats?.overAll])
                  : downloadableData?.Map
              }
              headers={allHeaders ?? []}
              filename={`${overAllStats?.label}${moment(selectedMonth).format(
                "MMM"
              )}-${moment().format("YYYY")}.csv`}
            >
              <BiDownload size={30} />
            </CSVLink>
          ) : (
            <></>
          )} */}

          {/* <p className="m-0 font-semibold p-0">{camelCaseToTitleCase(overAllStats?.label)}</p> */}
        </Card>
      ) : (
        <></>
      )}
      {reportLevel?.levelNames == "state" ? (
        <div className="px-6">
          <HaryanaMap
            uniqueId={`map${uniqueId}`}
            mapData={mapData}
            stats={{
              label: overAllStats?.label ?? "",
              value: overAllStats?.value ?? 0,
            }}
          />
        </div>
      ) : reportLevel?.levelNames == "district" ? (
        <>
          <DistrictWithMap
            uniqueId={`map${uniqueId}`}
            selectedDistrict={selectedDistrict}
            mapData={mapData}
            stats={{
              label: overAllStats?.label ?? "",
              value: overAllStats?.value ?? 0,
            }}
            isLoading={false}
            districtMapData={[]}
          />
        </>
      ) : (
        <>
          <DistrictWithMap
            uniqueId={`map${uniqueId}`}
            selectedDistrict={
              role?.includes(ROLES.BLOCK)
                ? user?.details?.blockUserDetails?.district_Name || ""
                : selectedDistrict
            }
            mapData={blocks?.map((e) =>
              e?.name == reportLevel?.blockName
                ? {
                    value: overAllStats?.value ?? 0,
                    name: reportLevel?.blockName || "",
                  }
                : { value: 400, name: e?.name || "" }
            )}
            // mapData={ role?.includes(ROLES.BLOCK) ? [{value:overAllStats?.value,name: user?.details?.blockUserDetails?.block_Name || ""}] : [{value:overAllStats?.value,name: reportLevel?.blockName ||""}]}

            stats={{
              label: overAllStats?.label ?? "",
              value: overAllStats?.value ?? 0,
            }}
            isLoading={false}
            districtMapData={[]}
          />
        </>
      )}

      {/* Color code Condition
         # to denote a active block and handle other blocks color
         # if you put the value of map data is 400 then the tooltip is not shown and the color of block can be handle by value(400) from colorHandler function 
      */}

      <Modal
        size="xxl"
        opened={isOpenDetailModal}
        onClose={() => setIsOpenDetailsModal(false)}
        title={camelCaseToTitleCase(overAllStats?.label ?? "Title")}
      >
        <ETable>
          <thead>
            <tr>
              {allHeaders?.map((e) => (
                <th>{e?.label ?? "-"}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {downloadableData?.Map?.map((e: any) => (
              <tr>
                {allHeaders?.map((each, index) => (
                  <td>{e[each?.key] ?? "-"}</td>
                ))}
              </tr>
            ))}
            {overAllStats?.overAll && (
              <tr>
                {allHeaders?.map((each, index) => (
                  <td>{overAllStats?.overAll[each?.key] ?? "-"}</td>
                ))}
              </tr>
            )}
          </tbody>
        </ETable>
      </Modal>
    </div>
  );
}
