import { useState } from 'react';

import { useMasterFetch, useSchoolData } from '../../libs/data-access';
import { CompetencyListIMasterData, IClusterSchool, ICompetencyByClassSubject, IMasterData, ISpotCompetencyList, SessionIMasterData } from '../../libs/data-access/types';

export default function useMasterData() {

    const { fetchMasterData } = useMasterFetch();
    const { fetchSchoolData } = useSchoolData();


    const [assessmentTypes, setAssessmentTypes] = useState<IMasterData[]>([]);
    const [months, setMonths] = useState<IMasterData[]>([]);
    const [classes, setClasses] = useState<IMasterData[]>([]);
    const [classesBasedOnSchool, setClassesBasedOnSchool] = useState<IMasterData[]>([]);
    const [section, setSection] = useState<IMasterData[]>([]);
    const [sectionBasedOnSchoolAndClass, setSectionBasedOnSchoolAndClass] = useState<IMasterData[]>([]);
    const [subjectList, setSubjectList] = useState<IMasterData[]>([]);
    const [questionType, setQuestionType] = useState<CompetencyListIMasterData[]>([]);
    const [competencyList, setCompetencyList] = useState<CompetencyListIMasterData[]>([]);
    const [competencyByClassSubject, setCompetencyByClassSubject] = useState<ICompetencyByClassSubject[]>([]);
    const [spotCompetencyByClassSubject, setSpotCompetencyByClassSubject] = useState<any>([]);
    const [spotCompetencyList, setSpotCompetencyList] = useState<ISpotCompetencyList[]>([]);
    const [spotAssessmentData, setSpotAssessmentData] = useState<IMasterData[]>([]);
    const [districts, setDistricts] = useState<IMasterData[]>([]);
    const [blocks, setBlocks] = useState<IMasterData[]>([]);
    const [clusterSchools, setClusterSchools] = useState<IClusterSchool[]>([]);
    const [allRole, setAllRoles] = useState<IMasterData[]>([]);
    const [designationByRoleId, setDesignationByRoleID] = useState<IMasterData[]>([]);
    const [sessions, setSessions] = useState<SessionIMasterData[]>([]);

    // MASTER API CALLS
    const getAssessmentTypes = async () => {
        let response = await fetchMasterData("GetAssessmentType");
        if (response.isSuccess) {
            const data: IMasterData[] | undefined = response.data;
            // console.log("Response for assessment types: ", response.data);
            data && setAssessmentTypes(data);
        } else {
            console.log("Error for assessment types: ", response.error);
        }
    };

    const getMonths = async () => {
        let response = await fetchMasterData("Months");
        if (response.isSuccess) {
            // console.log("Response for months: ", response.data);
            // setSalutations(response.data.response);
        } else {
            console.log("Error for months: ", response.error);
        }
    };

    const getClasses = async () => {
        let response = await fetchMasterData("GetAllClass");
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] | undefined = response.data;
            data && setClasses(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getSession = async (IsCurrentSession:boolean) => {
        let response = await fetchMasterData(`GetSession/${IsCurrentSession}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: SessionIMasterData[] | undefined = response.data;
            data && setSessions(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getClassesBasedOnSchoolId = async (SchoolId: number) => {
        let response = await fetchMasterData(`GetClassBySchoolId/${SchoolId}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] | undefined = response.data;
            data && setClassesBasedOnSchool(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getSectionByClassId = async () => {
        let response = await fetchMasterData("GetSection");
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] = response.data;
            setSection(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getSectionBySchoolAndClassId = async (
        SchoolId: number,
        ClassId: number
    ) => {
        let response = await fetchMasterData(`GetSectionBySchoolClass/${SchoolId}/${ClassId}`);
        if (response.isSuccess) {
            // console.log("Response for section list: ", response.data);
            const data: IMasterData[] | undefined = response.data;
            data && setSectionBasedOnSchoolAndClass(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getSubject = async () => {
        let response = await fetchMasterData("Subject/GetAllSubjects?isPeriodic=true");
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] | undefined = response.data;
            data && setSubjectList(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const GetQuestionType = async () => {
        let response = await fetchMasterData("GetQuestionType");
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: CompetencyListIMasterData[] | undefined = response.data;
            data && setQuestionType(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getWeeksData = async () => {
        let response = await fetchMasterData("SpotAssessment/GetWeeks");
        if (response.isSuccess) {
            // console.log("Response for assessment types: ", response.data);
            const data: IMasterData[] | undefined = response.data;
            data && setSpotAssessmentData(data);
        } else {
            console.log("Error for assessment types: ", response.error);
        }
    };

    const getCompetency = async (
        subjectId: number,
        subjectTopicId: number
    ) => {
        let response = await fetchMasterData(`Subject/GetSubjectCompetancy/${subjectId}/${subjectTopicId}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: CompetencyListIMasterData[] | undefined = response.data;
            data && setCompetencyList(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };

    const getCompetencyBasedOnClassAndSubject = async (
        ClassId: number,
        SubjectId: number,
    ) => {
        let response = await fetchMasterData(`Subject/GetCompetancyByClassSubjectId/${ClassId}/${SubjectId}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: ICompetencyByClassSubject[] | undefined = response.data;
            data && setCompetencyByClassSubject(data);
        } else {
            console.log("Error for class list: ", response.error);
        }
    };


        const getSpotCompetencyBasedOnClassAndSubject = async (
          ClassId: number,
          SubjectId: number
        ) => {
          const response = await fetchSchoolData(
            `/GetSpotCompetencyByClassSubjectId/${ClassId}/${SubjectId}`
          );
          if (response.isSuccess) {
            // console.log("Response for fetch cluster schools: ", response.data);
            setSpotCompetencyByClassSubject(response.data);
          } else {
            console.error(
              "Error while fetching cluster schools: ",
              response.error
            );
          }
        };


    const getSpotCompetencyList = async (
        ClassId: number,
        SubjectId: number[],
        month: string,
    ) => {
        setSpotCompetencyList([])
        let allCompetency: ISpotCompetencyList[] = [];

        for (const eachSubjectID of SubjectId) {

            let response = await fetchMasterData(`Subject/GetSpotCompetancyByMonthClassSubjectId/${month}/${ClassId}/${eachSubjectID}`);
            if (response.isSuccess) {
                const data: ISpotCompetencyList[] | undefined = response.data?.map((each: ISpotCompetencyList) => ({ ...each, group: eachSubjectID }))
                if (data) {
                    allCompetency = [...allCompetency, ...data];
                };
            } else {
                console.log("Error for class list: ", response.error);
            };
        };
        const uniqueCompetencies = Array.from(
          new Map(
            allCompetency.map((item) => [item.competency_Id, item])
          ).values()
        );
        setSpotCompetencyList(uniqueCompetencies);
    };

    const getDistrictByStateId = async (
        stateID: number
    ) => {
        setDistricts([])
        let response = await fetchMasterData(`GetDistrictByStateId/${stateID}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] = response.data;
            data && setDistricts(data);
        } else {
            console.log("Error for district by state id: ", response.error);
        }
    };

    const getBlocksByDistrictId = async (
        districtID: number
    ) => {
        setBlocks([])
        let response = await fetchMasterData(`Blocks/BlocksByDistrictId/${districtID}`);
        if (response.isSuccess) {
            // console.log("Response for class list: ", response.data);
            const data: IMasterData[] = response.data;
            data && setBlocks(data);
        } else {
            console.log("Error for blocks by district id: ", response.error);
        }
    };

    const getClusterByBlockId = async (block: number) => {
        setClusterSchools([]);
        const response = await fetchSchoolData(`/GetClusterSchoolListByBlockId/${block}`);
        if (response.isSuccess) {
            // console.log("Response for fetch cluster schools: ", response.data);
            setClusterSchools(response.data);
        } else {
            console.error("Error while fetching cluster schools: ", response.error);
        };
    };

    const getAllRoles = async () => {
        setAllRoles([])
        let response = await fetchMasterData(`GetAllRoles`);
        if (response?.isSuccess) {
            const data: IMasterData[] = response.data.map((e: any) => ({ id: e?.role_Id, name: e?.role_Name }));;
            data && setAllRoles(data);
        } else {
            console.log("Error while getting roles list")
        }
    }

    const getDesignationByRoleId = async (
        roleId: number
    ) => {
        setDesignationByRoleID([])
        let response = await fetchMasterData(`GetDesignationByRole/${roleId}`);
        if (response?.isSuccess) {
            const data: IMasterData[] = response.data.map((e: any) => ({ id: e?.designation_Id, name: e?.designation }));;
            data && setDesignationByRoleID(data);
        } else {
            console.log("Error while getting roles list")
        }
    }

    return {
      assessmentTypes,
      getAssessmentTypes,
      months,
      getMonths,
      classes,
      getClasses,
      classesBasedOnSchool,
      getClassesBasedOnSchoolId,
      section,
      getSectionByClassId,
      sectionBasedOnSchoolAndClass,
      getSectionBySchoolAndClassId,
      subjectList,
      getSubject,
      competencyList,
      setCompetencyList,
      getCompetency,
      competencyByClassSubject,
      getCompetencyBasedOnClassAndSubject,
      spotCompetencyList,
      getSpotCompetencyList,
      questionType,
      GetQuestionType,
      spotAssessmentData,
      getWeeksData,
      districts,
      getDistrictByStateId,
      blocks,
      getBlocksByDistrictId,
      clusterSchools,
      getClusterByBlockId,
      allRole,
      getAllRoles,
      designationByRoleId,
      getDesignationByRoleId,
      getSession,
      sessions,
      spotCompetencyByClassSubject,
      getSpotCompetencyBasedOnClassAndSubject,
    };
}
