import { useState } from 'react';

import { Tabs } from '@mantine/core';

import PageLayout from '../../../../PageLayout';
import { EPageTitle } from '../../../../libs/ui';
import DistrictWiseCompliance from './Sections/DistrictWiseCompliance';
import MentorWiseVisitCompliance from './Sections/MentorWiseVisitCompliance';
import DateWiseMentorVisitCompliance from './Sections/DateWiseMentorVisitCompliance';

export default function DashboardReports() {

    const [activeTab, setActiveTab] = useState<string | null>('first');

    return (
        <PageLayout>
            <EPageTitle
                title="Visit Compliance Reports"
            />

            <Tabs value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="first">District Wise Compliance</Tabs.Tab>
                    <Tabs.Tab value="second">Mentor wise Visit Compliance</Tabs.Tab>
                    <Tabs.Tab value="third">Date wise Mentor Visit Compliance</Tabs.Tab>
                    {/* <Tabs.Tab value="forth">Unique School Visit Compliance</Tabs.Tab> */}
                </Tabs.List>

                <Tabs.Panel value="first" pt="xs">
                    <DistrictWiseCompliance />
                </Tabs.Panel>
                <Tabs.Panel value="second" pt="xs">
                    <MentorWiseVisitCompliance />
                    </Tabs.Panel>
                <Tabs.Panel value="third" pt="xs">
                    <DateWiseMentorVisitCompliance />
                    </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}
