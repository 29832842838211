import React, { useEffect } from "react";
import { FiVideoOff } from "react-icons/fi";
import {
  Textarea,
  TextInput,
  Tooltip,
  Image,
  NumberInput,
  Checkbox,
} from "@mantine/core";
import { MdAddCircle, MdOutlineHideImage } from "react-icons/md";
import { BsFillCameraVideoFill } from "react-icons/bs";

import { ESelectFileForQuestionBank } from "../../../../libs/ui";

import { BASE64_INITIALS } from "../../../../initialStates";
import { IQuestionDetails, IQuestionFilters } from "../../../../types";
import { AiFillAudio, AiOutlineAudioMuted } from "react-icons/ai";
import { GiCancel } from "react-icons/gi";


interface IProps {
  questionFilters: any;
  questionDetails: any;
  handleChangeQuestionDetails: (
    key: any,
    value: any,
    index: number
  ) => void;
  index: number;
  deleteQuestion: (index: number) => void;
}

const AddGroupQuestionFrameSpot = ({
  questionFilters,
  questionDetails,
  handleChangeQuestionDetails,
  index,
  deleteQuestion,
}: IProps) => {
  return (
    <div>
      <div className="w-full justify-between flex ">
        <p className="text-xl font-bold">Question</p>
      </div>
      {/* <p className="text-sm opacity-50">
                Enter text, image or video question below
            </p> */}
      <p className="text-sm opacity-50">
        {questionFilters.media_Type_Id == 1
          ? "Question can only contain Text"
          : questionFilters.media_Type_Id == 4
          ? "Question needs to contain Text and Video"
          : questionFilters.media_Type_Id == 5
          ? "Question needs to contain Text and Image"
          : questionFilters.media_Type_Id == 6
          ? "Question needs to contain Text and Audio"
          : ""}
      </p>

      <div className="my-5">
        <Checkbox
          label={"Enlarge Text"}
          checked={questionDetails.enlarge}
          onChange={(e) => {
            handleChangeQuestionDetails(
              "enlarge",
              !questionDetails?.enlarge,
              index
            );
          }}
        />
      </div>

      <div className="options-container">
        <div className="input-item mt-2">
          <div className="action-icons">
            <Tooltip withArrow color="red" label="Remove this Question">
              <a
                className="cursor-pointer"
                onClick={() => deleteQuestion(index)}
              >
                <GiCancel size={20} />
              </a>
            </Tooltip>
            {/* SELECT IMAGE */}
            {questionFilters.media_Type_Id == 5 ? ( // INFO: if media type is Text/Image
              questionDetails.base64QuestionImage ? (
                <Tooltip withArrow color="red" label="Remove selected image">
                  <a>
                    <MdOutlineHideImage
                      onClick={() => {
                        handleChangeQuestionDetails(
                          "typeOfURL",
                          undefined,
                          index
                        );
                        handleChangeQuestionDetails(
                          "base64QuestionImage",
                          undefined,
                          index
                        );
                        handleChangeQuestionDetails(
                          "question_Media_Url",
                          undefined,
                          index
                        );
                      }}
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip withArrow label="Select image">
                  <a>
                    <ESelectFileForQuestionBank
                      maxSizeInKB={3000000}
                      onClear={() => {}}
                      onSelect={(file) => {
                        handleChangeQuestionDetails(
                          "base64QuestionImage",
                          file,
                          index
                        );

                        handleChangeQuestionDetails(
                          "typeOfURL",
                          "Image",
                          index
                        );
                      }}
                      onSelectPreview={(preview) =>
                        handleChangeQuestionDetails(
                          "question_Media_Url",
                          preview,
                          index
                        )
                      }
                    />
                  </a>
                </Tooltip>
              )
            ) : (
              <></>
            )}

            {/* SELECT VIDEO */}
            {questionFilters.media_Type_Id == 4 ? ( // INFO: if media type is Text/Video
              questionDetails.typeOfURL === "Video" ? (
                <Tooltip withArrow color="red" label="Remove Video URL">
                  <a>
                    <FiVideoOff
                      onClick={() => {
                        handleChangeQuestionDetails(
                          "typeOfURL",
                          undefined,
                          index
                        );
                        handleChangeQuestionDetails(
                          "base64QuestionImage",
                          undefined,
                          index
                        );
                        handleChangeQuestionDetails(
                          "question_Media_Url",
                          undefined,
                          index
                        );
                      }}
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip withArrow label="Video URL">
                  <a>
                    <BsFillCameraVideoFill
                      onClick={() =>
                        handleChangeQuestionDetails("typeOfURL", "Video", index)
                      }
                    />
                  </a>
                </Tooltip>
              )
            ) : (
              <></>
            )}
            {questionFilters.media_Type_Id == 6 ? ( // INFO: if media type is Text/ Audio
              questionDetails.typeOfURL === "Audio" ? (
                <Tooltip withArrow color="red" label="Remove Audio URL">
                  <a>
                    <AiOutlineAudioMuted
                      onClick={() => {
                        handleChangeQuestionDetails(
                          "typeOfURL",
                          undefined,
                          index
                        );
                        handleChangeQuestionDetails(
                          "base64QuestionImage",
                          "",
                          index
                        );
                        handleChangeQuestionDetails(
                          "question_Media_Url",
                          "",
                          index
                        );
                      }}
                    />
                  </a>
                </Tooltip>
              ) : (
                <Tooltip withArrow label="Audio URL">
                  <a>
                    <AiFillAudio
                      onClick={() =>
                        handleChangeQuestionDetails("typeOfURL", "Audio", index)
                      }
                    />
                  </a>
                </Tooltip>
              )
            ) : (
              <></>
            )}
          </div>

          <Textarea
            required
            size="lg"
            variant="unstyled"
            className="w-full"
            placeholder="Enter question here"
            value={questionDetails.question_Text}
            onChange={(e) => {
              handleChangeQuestionDetails(
                "question_Text",
                e.target.value.replaceAll("\n", " "),
                index
              );
            }}
          />
        </div>
        {questionDetails?.enlarge == true ? (
          <div className={`input-item mt-2 `}>
            <Textarea
              required
              size="lg"
              variant="unstyled"
              className="w-full"
              placeholder="Enter Enlarged text of the question here"
              value={questionDetails.enlargedText}
              onChange={(e) =>
                handleChangeQuestionDetails(
                  "enlargedText",
                  e.target.value.replaceAll("\n", " "),
                  index
                )
              }
            />
          </div>
        ) : null}

        {questionDetails.typeOfURL === "Image" &&
        questionDetails.base64QuestionImage ? (
          <Image
            radius="md"
            height={256}
            src={`${BASE64_INITIALS}${questionDetails.base64QuestionImage}`}
            alt="Option Image"
            className="mt-2"
          />
        ) : questionDetails.typeOfURL === "Video" ? (
          <div className="input-item mt-2">
            <TextInput
              type="url"
              size="lg"
              variant="unstyled"
              className="w-full mr-2"
              placeholder="Enter YouTube embedded URL here"
              value={questionDetails.question_Media_Url}
              onChange={(e) => {
                handleChangeQuestionDetails(
                  "question_Media_Url",
                  e.target.value,
                  index
                );
              }}
            />
          </div>
        ) : questionDetails.typeOfURL === "Audio" ? (
          <div className="input-item mt-2">
            <TextInput
              type="url"
              size="lg"
              variant="unstyled"
              className="w-full mr-2"
              placeholder="Enter Audio URL here"
              value={questionDetails.question_Media_Url}
              onChange={(e) => {
                handleChangeQuestionDetails(
                  "question_Media_Url",
                  e.target.value,
                  index
                );
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <TextInput
        maxLength={2}
        size="md"
        className="w-1/2 pr-2 mt-3"
        placeholder="Enter no. of marks for question"
        value={questionDetails.question_Marks}
        error={
          isNaN(questionDetails?.question_Marks)
            ? "Value is not a number *"
            : ""
        }
        onChange={(e) => {
          handleChangeQuestionDetails(
            "question_Marks",
            Number(e.target.value),
            index
          );
        }}
      />
    </div>
  );
};

export default AddGroupQuestionFrameSpot;
