import React, { useContext, useEffect, useState } from 'react'
import useMasterData from '../../../../Hooks/useMasterData';
import moment from 'moment';
import { calculateCurrentMonth } from '../../../../../utils/utility';
import { AppContext } from '../../../../../AppContext';
import { ROLES } from '../../../../../Routes/routes';

export default function useDashboardVisitComplianceReports() {

    const {
        user,
        role
    } =useContext(AppContext)
    
      const {
          getDistrictByStateId,
          districts,
          getBlocksByDistrictId,
          blocks,
      } = useMasterData();
    const [isFirst,setIsFirst]=useState<boolean>(true)
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(calculateCurrentMonth());
    const [selectedSession, setSelectedSession] = useState<string | undefined>(
      parseInt(moment().format("MM"), 10) > 4
        ? moment().add(1, "year").format("YYYY")
        : moment().format("YYYY")
    );
    const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(undefined);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>(undefined);
    
   
    useEffect(()=>{
        if(isFirst){
       setIsFirst(false)
        }
        else{
           getDistrictByStateId(1)
           if (role?.includes(ROLES.BLOCK)) {
            setSelectedDistrict(String(user?.details?.blockUserDetails?.district_Id))
            setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id))
           }
            if (role?.includes(ROLES.DISTRICT)) {
                setSelectedDistrict(String(user?.details?.districtUserDetails?.district_Id))
            }
        }
         },[isFirst])
    useEffect(()=>{
        if(selectedDistrict) { 
       getBlocksByDistrictId(+selectedDistrict)
    }
    },[selectedDistrict])

 
    
    // Handler function

    const handelClearFilter =()=>{
        setSelectedBlock(undefined)
        setSelectedDistrict(undefined)
    }
  
    return {
      districts,
      blocks,
      selectedMonth,
      selectedDistrict,
      selectedBlock,
      setSelectedMonth,
      setSelectedDistrict,
      setSelectedBlock,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
    };
}