import { Grid, MultiSelect, Select } from '@mantine/core'
import React, { useContext, useEffect, useState } from 'react'
import { EButton } from '../../../../libs/ui'
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from '../../../../utils/utility';
import useMasterData from '../../../Hooks/useMasterData';
import { IClusterSchool, ISchool } from '../../../../libs/data-access/types';
import { MdClear, MdSearch } from 'react-icons/md';
import { useSchoolData } from '../../../../libs/data-access';
import { AssessmentFilterContext } from './Hooks/useAssessmentFilter';
import moment from 'moment';
import { group } from 'console';
import { AppContext } from '../../../../AppContext';
import { ROLES } from '../../../../Routes/routes';

interface IProps {
  handleFetchReports: () => void;
  handleResetFilters: () => void;
  activeTab: number
};
export default function AssessmentFilter
  ({
    handleFetchReports,
    handleResetFilters,
    activeTab
  }: IProps) {

  const {
    districts,
    getDistrictByStateId,
    blocks,
    getBlocksByDistrictId,
    classes,
    getClasses,
    getSubject,
    subjectList,
    spotCompetencyList,
    getSpotCompetencyList,
    getSession,
    sessions,
  } = useMasterData();

  console.log("spotCompetencyList",spotCompetencyList);
  console.log("subjectList", subjectList);

  const {
    district,
    block,
    cluster,
    school,
    grade,
    subject,
    competency,
    month,
    handleChangeFilters,
    handleIsSearching,
    isSearching,
    session,
    selectedSession,
  } = useContext(AssessmentFilterContext);


  console.log("subject", subject);

  const {
    fetchSchoolData
  } = useSchoolData();

  const {
    user,
    role
  } = useContext(AppContext);

  const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
  const [isLoading, setISLoading] = useState<boolean>(false)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [clusterSchools, setClusterSchools] = useState<IClusterSchool[]>([]);
  const [schools, setSchools] = useState<ISchool[]>([]);
  // const [schools, setGrade] = useState<ISchool[]>([]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      getDistrictByStateId(1);
      getClasses()
      getSession(false);
      if (role?.includes(ROLES.BLOCK)) {
        handleChangeFilters("district", user?.details?.blockUserDetails?.district_Id)
        handleChangeFilters("block", user?.details?.blockUserDetails?.block_Id)
      };
      if (role?.includes(ROLES.SCHOOL)) {
        handleChangeFilters("district", user?.details?.schoolUserDetails?.district_Id)
        handleChangeFilters("block", user?.details?.schoolUserDetails?.block_Id)
        handleChangeFilters("cluster", user?.details?.schoolUserDetails?.cluster_School_Id)
        handleChangeFilters("school", user?.details?.schoolUserDetails?.school_Id)
      };
      if (role?.includes(ROLES.DISTRICT)) {
        handleChangeFilters("district", user?.details?.districtUserDetails?.district_Id)
    }
    };
  }, [isFirstLoad]);

  useEffect(() => {
    district && getBlocksByDistrictId(district);
  }, [district]);

  useEffect(() => {
    block && fetchClusterSchools(block);
  }, [block]);

  useEffect(() => {
    cluster && fetchSchools(cluster);
  }, [cluster]);
  // useEffect(() => {
  //   school && getClasses();
  // }, [school]);

  useEffect(() => {
    grade && getSubject();
  }, [grade]);

  useEffect(() => {
    subject &&
      grade &&
      getSpotCompetencyList(
        +grade,
        subject,
        `${
          month
            ? calculateYearForDashboard(+month, Number(selectedSession))
            : moment().format("YYYY")
        }-${month}-01`
      );
  }, [subject, month, selectedSession]);

  useEffect(() => {
    // if(grade == 3){
    //   handleChangeFilters("competency",subject?.filter(e => e !== 3)?.map(e => +`-${grade}${e}`))
    // }
    // else{
    handleChangeFilters("competency", spotCompetencyList?.map(e => e?.competency_Id).concat( grade !==1 && grade ? subject?.filter(e => e !== 3)?.map(e => +`-${grade}${e}`) : []))
  // }
}, [spotCompetencyList])
  // HANDLER FUNCTIONS

  const handleSelectMonth = (value: string) => {
    setSelectedMonth(value);
  };

  const handleEnableSearchButton = () => {
    if (activeTab === 1 && competency?.length) {
      return false
    }
    if (activeTab === 2 && subject?.length) {
      return false
    }
    if (activeTab === 3 && subject?.length) {
      return false
    }
    else return true;
  };

  const handleEnableCloseButton = () => {
    if (
      competency ||
      district
    ) return false
    else return true;
  };

  // API CALLS

  const fetchClusterSchools = async (block: number) => {
    setClusterSchools([]);
    const response = await fetchSchoolData(`/GetClusterSchoolListByBlockId/${block}`);
    if (response.isSuccess) {
      // console.log("Response for fetch cluster schools: ", response.data);
      setClusterSchools(response.data);
    } else {
      console.error("Error while fetching cluster schools: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch cluster schools."
      });
    };
  };

  const fetchSchools = async (clusterSchool: number) => {
    setSchools([]);
    const response = await fetchSchoolData(`/GetSchoolListByClusterSchoolId/${clusterSchool}`);
    if (response.isSuccess) {
      // console.log("Response for fetch schools: ", response.data);
      setSchools(response.data);
    } else {
      console.error("Error while fetching schools: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch schools."
      });
    };
  };

  // console.log("spotComapetency",spotCompetencyList)
  // console.log("subject",subject)
  // console.log("grade",grade)
  // console.log("subjectList",subjectList)
  // console.log("competency",competency)

  return (
    <div className="filter-container">
      <div className="flex flex-col">
        <div className="flex flex-wrap gap-2 items-end">
          <Select
            clearable
            // required={!district ? true : false}
            size="md"
            label="District"
            className="w-1/5 grow"
            disabled={
              role?.includes(ROLES.BLOCK) ||
              role?.includes(ROLES.SCHOOL) ||
              role?.includes(ROLES.DISTRICT)
                ? true
                : false
            }
            placeholder="Select"
            value={district ? String(district) : null}
            data={districts?.map((each) => ({
              value: String(each.id),
              label: each.name,
            }))}
            onChange={(e) => {
              handleChangeFilters("district", e ? Number(e) : undefined);
              handleChangeFilters("block", undefined);
              handleChangeFilters("cluster", undefined);
              handleChangeFilters("school", undefined);
            }}
          />

          <Select
            clearable
            size="md"
            label="Block"
            className="w-1/5 grow"
            placeholder="Select"
            disabled={
              !district ||
              role?.includes(ROLES.BLOCK) ||
              role?.includes(ROLES.SCHOOL)
                ? true
                : false
            }
            value={block ? String(block) : null}
            data={blocks?.map((e) => {
              return {
                value: String(e.id),
                label: e.name,
              };
            })}
            onChange={(e) => {
              handleChangeFilters("block", e ? Number(e) : undefined);
              handleChangeFilters("cluster", undefined);
              handleChangeFilters("school", undefined);
            }}
          />

          <Select
            clearable
            size="md"
            label="Cluster"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={!block || role?.includes(ROLES.SCHOOL) ? true : false}
            value={cluster ? String(cluster) : null}
            data={clusterSchools?.map((e) => {
              return {
                value: String(e.cluster_School_Id),
                label: e.cluster_School_Name,
              };
            })}
            onChange={(e) => {
              handleChangeFilters("cluster", e ? Number(e) : undefined);
              handleChangeFilters("school", undefined);
            }}
          />

          {activeTab === 1 && (
            <Select
              clearable
              size="md"
              label="School"
              placeholder="Select"
              className="w-1/5 grow"
              disabled={!cluster || role?.includes(ROLES.SCHOOL) ? true : false}
              value={school ? String(school) : null}
              data={schools?.map((e) => {
                return {
                  value: String(e.school_Id),
                  label: e.school_Name,
                };
              })}
              onChange={(e) => {
                handleChangeFilters("school", e ? Number(e) : undefined);
              }}
            />
          )}
          {/* {activeTab ===3 && 
           <div className='w-1/5 grow'>
            <div> } */}
          {/* </div>
        <div className="flex gap-2 items-end"> */}

          {/* <Select
            size="md"
            label="Session"
            required
            placeholder="Select"
            className=" w-1/5 grow"
            // disabled={ false}
            value={session ? String(session) : null}
            data={sessions?.map((e) => {
              return {
                value: String(e?.session_Id),
                label: e?.session_Name,
              };
            })}
            onChange={(e) => {
              // handleChangeFilters("subject", []);
              handleChangeFilters("session", e ? String(e) : null);
            }}
          /> */}

          <Select
            size="md"
            required
            label="Session"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={false}
            data={[
              { label: "2023-2024", value: "2024" },
              { label: "2024-2025", value: "2025" },
            ]}
            value={selectedSession ? String(selectedSession) : null}
            onChange={(e) => {
              handleChangeFilters("competency", []);
              handleChangeFilters("selectedSession", e ? e : undefined);
            }}
          />

          <Select
            size="md"
            required
            label="Month"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={false}
            data={[
              {
                label: "April",
                value: "04",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 4
                    ? true
                    : false,
              },
              {
                label: "May",
                value: "05",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 5
                    ? true
                    : false,
              },
              {
                label: "June",
                value: "06",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 6
                    ? true
                    : false,
              },
              {
                label: "July",
                value: "07",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 7
                    ? true
                    : false,
              },
              {
                label: "August",
                value: "08",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 8
                    ? true
                    : false,
              },
              {
                label: "September",
                value: "09",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 9
                    ? true
                    : false,
              },
              {
                label: "October",
                value: "10",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 10
                    ? true
                    : false,
              },
              {
                label: "November",
                value: "11",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 11
                    ? true
                    : false,
              },
              {
                label: "December",
                value: "12",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY") &&
                  parseInt(moment().format("MM"), 10) < 12
                    ? true
                    : false,
              },
              {
                label: "January",
                value: "01",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY")
                    ? true
                    : false,
              },
              {
                label: "February",
                value: "02",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY")
                    ? true
                    : false,
              },
              {
                label: "March",
                value: "03",
                disabled:
                  selectedSession == moment().add(1, "year").format("YYYY")
                    ? true
                    : false,
              },
            ]}
            value={month ? String(month) : null}
            onChange={(e) => {
              handleChangeFilters("competency", []);
              handleChangeFilters("month", e ? e : undefined);
            }}
          />

          {activeTab && (
            <Select
              size="md"
              label="Grade"
              required
              placeholder="Select"
              className=" w-1/5 grow"
              // disabled={ false}
              value={grade ? String(grade) : null}
              data={classes?.map((e) => {
                return {
                  value: String(e?.id),
                  label: e?.name,
                };
              })}
              onChange={(e) => {
                handleChangeFilters("grade", e ? Number(e) : undefined);
                handleChangeFilters("subject", []);
                handleChangeFilters("competency", []);
              }}
            />
          )}
          {activeTab && (
            <MultiSelect
              label="Subject"
              size="md"
              required
              placeholder="Select"
              className="w-1/5 grow"
              value={subject?.map((e) => String(e))}
              data={subjectList?.map((e) => {
                return {
                  value: String(e?.id),
                  label: e?.name,
                };
              })}
              disabled={!grade ? true : false}
              onChange={(e) => {
                handleChangeFilters("competency", []);
                handleChangeFilters(
                  "subject",
                  e ? e?.map((e) => +e) : undefined
                );
              }}
            />
          )}
          {activeTab && <div className="w-1/5 grow"></div>}
          {activeTab !== 1 && <div className="w-1/5 grow"></div>}

          {/* <Select
            size="md"
            label="Subject"
            placeholder="Select"
            disabled={!grade ? true : false}
            value={subject ? String(subject) : null}
            data={subjectList?.map(e => {
              return {
                value: String(e?.id),
                label: e?.name
              }
            })}
            onChange={e => {
              handleChangeFilters("subject", e ? Number(e) : undefined)
              handleChangeFilters("competency", [])
            }}
          /> */}
        </div>
        <Grid>
          <Grid.Col span={6}>
            {activeTab === 1 && (
              <MultiSelect
                label="Competency"
                required
                size="md"
                placeholder="Pick all that you like"
                nothingFound="No any competency found"
                data={spotCompetencyList
                  ?.map((e) => {
                    return {
                      value: String(e?.competency_Id),
                      label: e?.competency,
                      group: e?.group
                        ? subjectList?.find(
                            (each) => each?.id == (e?.group ? +e?.group : 0)
                          )?.name
                        : "",
                    };
                  })
                  .concat(
                    subject
                      ?.filter((e) => e !== 3)
                      ?.map((each) => ({
                        value: `-${grade}${each}`,
                        label: `${
                          subjectList?.find((eachList) => eachList?.id == each)
                            ?.name
                        } - ORF - ${grade == 3 ? `60 WPM` : `45 WPM`}`,
                        group: subjectList?.find(
                          (eachList) => eachList?.id == each
                        )?.name,
                      }))
                  )}
                disabled={!subject?.length ? true : false}
                value={competency?.map((e) => String(e))}
                onChange={(e) =>
                  handleChangeFilters(
                    "competency",
                    e ? e?.map((e) => +e) : undefined
                  )
                }
              />
            )}
          </Grid.Col>
          <Grid.Col span={6} className="flex items-end gap-3 justify-end">
            {!handleEnableCloseButton() ? (
              <EButton
                size="md"
                className="btn-light"
                rightIcon={<MdClear />}
                disabled={
                  isSearching ||
                  role?.includes(ROLES.DISTRICT) ||
                  role?.includes(ROLES.BLOCK) ||
                  role?.includes(ROLES.SCHOOL)
                    ? true
                    : false
                }
                onClick={handleResetFilters}
              >
                Clear
              </EButton>
            ) : (
              <></>
            )}
            <EButton
              size="md"
              className="btn-blue"
              rightIcon={<MdSearch />}
              disabled={handleEnableSearchButton() ? true : false}
              onClick={() => handleIsSearching(!isSearching)}
              // onClick={() => {
              //   month != "09"
              //     ? handleIsSearching(!isSearching)
              //     : showToast({
              //         type: "error",
              //         message: "Data not found",
              //       });
              // }}
              loading={isSearching}
            >
              Search
            </EButton>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
}
