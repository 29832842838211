import React from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EDrawer, ELabelView, EPageTitle, ETable } from '../../libs/ui'
import useNotification from './hook/useNotifcation'
import { SurveyFilterContext } from '../Survey/SurveyFilterContext'
import SurveyFilter from '../Survey/Component/SurveyFilter'
import { Table } from '@mantine/core'
import { BsPlus } from 'react-icons/bs'
import { MdNotificationAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

export default function Notification() {
  const navigate = useNavigate()
  const searchButton = true;
  const {
     selectedDistrict,
     setSelectedDistrict,
     selectedRole,
     setSelectedRole,
     setIsSearching,
     isSearching,
     selectedNotification,
     setSelectedNotification
  }=useNotification()
  return (
    <PageLayout>
      <SurveyFilterContext.Provider
      value={{
        selectedDistrict,
        setSelectedDistrict,
        selectedRole,
        setSelectedRole,
        searchButton,
        isSearching,
        setIsSearching
      }}
    >
      <EPageTitle 
      title={'Notification'}
      end={
      <EButton
      className="btn-blue"
      onClick={()=>navigate('/new-notification')}
      > 
      <MdNotificationAdd size={22} className='mr-3' /> New Notification
      </EButton>
      }
      />
      <SurveyFilter withBlocks />
      <div className='mt-6'>
      <ETable>
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {[1,2,3,4,5].map((e)=><tr>
            <td>dfw</td>
            <td onClick={()=>setSelectedNotification(2)}>dsfsdf</td>
            <td>Tfdsdf</td>
          </tr>)}
        </tbody>
      </ETable>
      </div>
      </SurveyFilterContext.Provider>
       <EDrawer
        position="right"
        title='Notification Details' 
        opened={!!selectedNotification}
        onClose={() => setSelectedNotification(undefined)}
        drawerContent={
        <>
        <ELabelView label={'Title'} value={'New notification'}/>
        <ELabelView label={'Description'} value={'New Description'}/>
        <ELabelView label={''} value={'New '}/>
        </>}/>
    </PageLayout>
    )
}