import React from "react";
import { ESectionDivider } from "../../../../libs/ui";
import { Grid, Skeleton } from "@mantine/core";
import ColorBaseMap from "../../../../libs/maps/ColourBaseMap";
import useSchoolVisitsComplaince from "./hook/useSchoolVisitsComplaince";
import { ECard } from "../../../../libs/ui/src/Card/ECard";

export default function SchoolVisitsCompliance() {
  const {
    isLoadingMentorVisitsCompliance,
    isLoadingUniqueSchoolVisitCoverage,
    uniqueSchoolVisitCoverage,
    mentorVisitsCompliance,
    districts,
    selectedBlock,
    selectedDistrict,
    reportLevel,
  } = useSchoolVisitsComplaince();

  return (
    <ECard title="School Visit" header={<></>}>
      <Grid>
        {/* <Grid.Col span={1}></Grid.Col> */}
        <Grid.Col span={4} >
          {isLoadingUniqueSchoolVisitCoverage ? (
            <Skeleton height={440} radius="md" />
          ) : (
            <ColorBaseMap
              mapData={
                uniqueSchoolVisitCoverage
                  ? uniqueSchoolVisitCoverage?.Map?.map((e) => ({
                      name: e?.Name,
                      value: e?.Percentage_of_Schools_visited,
                    }))
                  : []
              }
              overAllStats={{
                label: "Unique School Visit Coverage",
                value:
                  uniqueSchoolVisitCoverage?.OverAll
                    ?.Percentage_of_Schools_visited ?? 0,
                overAll: uniqueSchoolVisitCoverage?.OverAll,
                toolTipLabel: "% of unique schools visited at least once by the mentors"
              }}
              selectedDistrict={
                reportLevel?.districtName || ""
              }
              reportLevel={reportLevel}
              uniqueId={"uniqueSchoolVisits"}
              downloadableData={uniqueSchoolVisitCoverage}
            />
          )}
        </Grid.Col>
        <Grid.Col span={1} className="border-r"></Grid.Col>
        {/* <Grid.Col span={1} ></Grid.Col> */}
        <Grid.Col span={4}>
          {isLoadingMentorVisitsCompliance ? (
            <Skeleton height={440} radius="md" />
          ) : (
            <ColorBaseMap
              mapData={
                mentorVisitsCompliance
                  ? mentorVisitsCompliance?.Map?.map((e) => ({
                      name: e?.Name,
                      value: e?.Percentage_mentors_completing_targets,
                    }))
                  : []
              }
              overAllStats={{
                label: "Mentor Unique School Visit Compliance",
                value:
                  mentorVisitsCompliance?.OverAll
                    ?.Percentage_mentors_completing_targets ?? 0,
                overAll: mentorVisitsCompliance?.OverAll,
                toolTipLabel: "% of mentors completing their unique school visit targets"
              }}
              selectedDistrict={
              reportLevel?.districtName || ""
              }
              reportLevel={reportLevel}
              uniqueId={"MentorVisitCompliance"}
              downloadableData={mentorVisitsCompliance}
            />
          )}
        </Grid.Col>
        {/* <Grid.Col span={1}></Grid.Col> */}
      </Grid>
    </ECard>
  );
}


{/* <div>
<div>
<ESectionDivider title='School Visit' />
<Grid>
<Grid.Col span={4}>
  {
     isLoadingUniqueSchoolVisitCoverage
      ?
      <Skeleton height={440} radius="md" />
      :
      <ColorBaseMap
        mapData=
        {uniqueSchoolVisitCoverage
          ?
          uniqueSchoolVisitCoverage?.Map?.map(e =>
          ({
            name: e?.Name,
            value: e?.Percentage_of_Schools_visited
          })
          )
          :
          []
        }
        overAllStats={{
          label: 'Unique School Visit Coverage',
          value: uniqueSchoolVisitCoverage?.OverAll?.Percentage_of_Schools_visited ?? 0,
          overAll :uniqueSchoolVisitCoverage?.OverAll
        }}
        selectedDistrict={districts ? districts.filter(e => e?.id == + reportLevel?.id)[0]?.name : ""}
        reportLevel={reportLevel}
        uniqueId={'uniqueSchoolVisits'}
        downloadableData={uniqueSchoolVisitCoverage}
      />}
</Grid.Col>
<Grid.Col span={4}>
  {
    isLoadingMentorVisitsCompliance
      ?
      <Skeleton height={440} radius="md" />
      :
      <ColorBaseMap
        mapData=
        {mentorVisitsCompliance
          ?
          mentorVisitsCompliance?.Map?.map(e =>
          ({
            name: e?.Name,
            value: e?.Percentage_mentors_completing_targets
          })
          )
          :
          []
        }
        overAllStats={{
          label: 'Mentor Unique School Visit Compliance',
          value: mentorVisitsCompliance?.OverAll?.Percentage_mentors_completing_targets ?? 0,
          overAll: mentorVisitsCompliance?.OverAll
        }}
        selectedDistrict={districts ? districts.filter(e => e?.id == + reportLevel?.id)[0]?.name : ""}
        reportLevel={reportLevel}
        uniqueId={'MentorVisitCompliance'}
        downloadableData={mentorVisitsCompliance}
      />} */}
