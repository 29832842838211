import React from 'react'
import PageLayout from '../../../PageLayout'
import { EButton, ELabelView, EPageTitle, ESectionDivider, ETable } from '../../../libs/ui'
import useSurveyDetails from './hooks/useSurveyDetails'
import { useNavigate } from 'react-router-dom'
import { FiArrowLeft, FiEdit } from 'react-icons/fi'
import InfoLabel from '../../../libs/ui/src/InfoLabel/InfoLabel'
import moment from 'moment'
import { Badge, Grid } from '@mantine/core'
import { handleCheckDateRange, handleCheckEndDateRange } from '../../../utils/utility'

export default function SurveyDetails() {
  const {
    isLoading,
    districts,
    surveyDetails
  } = useSurveyDetails()
  const navigate = useNavigate()
  return (
    <PageLayout>
      <EPageTitle title='Survey Details'
        end={
          <div className='flex gap-3'>
            {/* {
              handleCheckDateRange(surveyDetails?.survey_Start_Date ?? "") ?
                
              <EButton
              className="btn-green"
              onClick={() => navigate(`/survey`)}>
              <FiEdit  className='mr-2'/>
               Edit
            </EButton>
                :
                handleCheckEndDateRange(surveyDetails?.survey_End_Date ?? "") ?
                  <></>
                  :
                  <></>
            } */}
            <EButton
              className="btn-blue"
              onClick={() => navigate(`/survey`)}>
              <FiArrowLeft className='mr-2' />  Back
            </EButton>
          </div>}
      />
      {
        surveyDetails &&
        <div className='p-4'>
          <Grid >
            <Grid.Col span={4}>
              <ELabelView label='Title' value={surveyDetails?.survey_Title ?? " - "} />
            </Grid.Col>
            <Grid.Col span={4}>
            {surveyDetails?.survey_Description ? <ELabelView label='Description' value={surveyDetails?.survey_Description || " - "} /> : ""}
            </Grid.Col>
            <Grid.Col span={4}>
              <ELabelView
                label='Date Range'
                value={
                  `${surveyDetails?.survey_Start_Date
                    ? moment(surveyDetails?.survey_Start_Date).format("DD-MM-YYYY") : "DD/MM/YYYY"}
           to 
           ${surveyDetails?.survey_End_Date ? moment(surveyDetails?.survey_End_Date).format("DD-MM-YYYY") : "DD/MM/YYYY"}
           `}
              />
            </Grid.Col>
            {/* <Grid.Col span={2}>
         <ELabelView label='End Date' value={surveyDetails?.survey_End_Date ? moment(surveyDetails?.survey_End_Date).format("DD-MM-YYYY") :"DD/MM/YYYY"}/>
          </Grid.Col> */}
          </Grid>
          <Grid className='mt-3 mb-3'>
            <Grid.Col span={4}>
              <ELabelView label='Districts' value={districts?.filter(e => surveyDetails?.district_Id?.includes(String(e?.id))).map(e => e?.name).join(", ") || " - "} />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <ELabelView label='Role' value={districts?.filter(e => surveyDetails?.district_Id?.includes(String(e?.id))).map(e => e?.name).join(", ") || " - "} />
            </Grid.Col>
            <Grid.Col span={4}>
              <ELabelView label='Designation' value={districts?.filter(e => surveyDetails?.district_Id?.includes(String(e?.id))).map(e => e?.name).join(", ") || " - "} />
            </Grid.Col> */}
          </Grid>
          <div>
            <ESectionDivider title='Question Groups' />
            {
              surveyDetails?.question_Groups?.length ?
                <div className='flex flex-col gap-5'>
                  {
                    surveyDetails?.question_Groups?.map((eachGroup, index) => (
                      <div className='border p-2 bg-slate-100 rounded-md' >
                        <Grid >
                          <Grid.Col span={5}>
                            <ELabelView label='Question Group Name' value={eachGroup?.question_Section_Title ?? " - "} />
                          </Grid.Col>
                          <Grid.Col span={5}>
                           {eachGroup?.question_Section_Description ? <ELabelView label='Group Description' value={ eachGroup?.question_Section_Description || " - "} /> : <></>}
                          </Grid.Col>
                          <Grid.Col span={2}>
                            <ELabelView label='No. of Questions' value={eachGroup?.questions?.length ? eachGroup?.questions?.length.toString() : " - "} />
                          </Grid.Col>
                        </Grid>
                        <div className='mt-4 flex flex-col gap-2 rounded-md'>
                          {eachGroup?.questions?.map((eachQuestion, index) => (
                            <div className='border-b-2 p-2 bg-white rounded-md'>
                               {/* <p>{index+1}.</p> */}
                                <Grid>
                                  <Grid.Col span={6}>
                                  {/* </Grid.Col>
                                  <Grid.Col span={4}> */}
                                  <ELabelView label='Question' value={eachQuestion?.survey_Question_Title || " - "} /> 
                                    {eachQuestion?.survey_Question_Description ? <ELabelView label='Description' value={eachQuestion?.survey_Question_Description || " - "} /> : <></>}
                                    {eachQuestion?.survey_Question_Instructions ? <ELabelView label='Instructions' value={eachQuestion?.survey_Question_Instructions|| " - "} /> : <></>}
                                  </Grid.Col>
                                  <Grid.Col span={6}>
                                   {eachQuestion?.survey_Options_List?.length ? <ELabelView label='Option List' value=''/> : <></>}
                                  {
                                  eachQuestion?.survey_Options_List?.map((option,index)=>
                                    <ELabelView  value={index + 1 + ". " + option?.survey_Options || " - "} label={''} />
                                  )}
                                  </Grid.Col>
                                </Grid>
                                {/* <Grid>{
                                  eachQuestion?.survey_Options_List?.map((option,index)=>
                                  <Grid.Col span={12/eachQuestion?.survey_Options_List?.length}>
                                    <ELabelView label={""} value={index+1+". " +  option?.survey_Options || " - "} /> 
                                    </Grid.Col>
                                  )}
                                    </Grid> */}
                            </div>

                          ))}
                        </div>
                      </div>)
                    )
                  }
                </div>
                :
                <></>
            }
          </div>
        </div>

      }
    </PageLayout>
  )
}
