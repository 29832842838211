import React, { useEffect } from "react";
import "./mapdata.scss"

import {
  SirsaDistrict,
  Fatehabad,
  Jind,
  Bhiwani,
  Kaithal,
  Jhajjar,
  Karnal,
  Rohtak,
  Mahendragrah,
  Hisar,
  Sonipat,
  Ambala,
  Gurgoan,
  Faridabad,
  Panipat,
  NuhMewat,
  Kurukshetra,
  Yamuna_Nagar,
  Palwal,
  Panchkula,
  Rewari,
  CharkhiDadri,
} from "./AllMapsIndex";
import DotsSpinner from "./Loader/DotsSpinner/DotsSpinner";
import { BsArrowLeft } from "react-icons/bs";
import { Card } from "@mantine/core";
import ReactApexChart from "react-apexcharts";
import { handleColor } from "../../utils/utility";

type IDistrictMapData = {
  Avg_Adhigam_students: number,
  Block: string,
  total_adhigam_students: number,
  total_student: number
}[]
type IProps = {
  selectedDistrict: string;
  isLoading: boolean;
  districtMapData : IDistrictMapData ;
  uniqueId: string;
  mapData:{name:string , value:number}[]
  stats ?:{label:string ,value: number }
};
export default function DistrictWithMap({
  selectedDistrict,
  stats,
  mapData,
  uniqueId,
  isLoading,
  districtMapData
}: IProps) { 
  const [selectedBlock, setSelectedBlock] = React.useState("");
  let objData :any ;
  const data = districtMapData?.forEach((e)=> {
    objData =  Object.assign({...objData,
      [e?.Block] : e?.Avg_Adhigam_students 
  } ) 
})
  
let hoverColor = "#00a67e"

const fillColor = (districtName: string) => {
    let data = mapData.filter(e => `${e?.name.toLowerCase()}${uniqueId}` == districtName)
     let val = handleColor(data[0]?.value);
    return val
}

  const ALLDistrict :any = {
    SIRSA: (
      <SirsaDistrict
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    FATEHABAD: (
      <Fatehabad
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    HISAR: (
      <Hisar
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    JIND: (
      <Jind
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    BHIWANI: (
      <Bhiwani
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e)}
      />
    ),
    ROHTAK: (
      <Rohtak
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    "CHARKHI DADRI": (
      <CharkhiDadri uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    MAHENDRAGARH: (
      <Mahendragrah
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    REWARI: (
      <Rewari
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    GURUGRAM: (
      <Gurgoan
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    NUH: (
      <NuhMewat
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    PALWAL: (
      <Palwal
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    FARIDABAD: (
      <Faridabad
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    JHAJJAR: (
      <Jhajjar
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    SONIPAT: (
      <Sonipat
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    PANIPAT: (
      <Panipat
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    KARNAL: (
      <Karnal
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    YAMUNANAGAR: (
      <Yamuna_Nagar
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    PANCHKULA: (
      <Panchkula
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    AMBALA: (
      <Ambala 
        uniqueId={uniqueId}
        mapData={mapData} 
        hoverColor={hoverColor} 
        fillColor={ e=> fillColor(e) }      />
    ),
    KURUKSHETRA: (
      <Kurukshetra
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
    KAITHAL: (
      <Kaithal
      uniqueId={uniqueId}
      mapData={mapData} 
      hoverColor={hoverColor} 
      fillColor={ e=> fillColor(e) }
      />
    ),
  };

  return (
    <div className={`w-full h-full`}>
      {isLoading ? (
        <div className="" style={{ height: "300px" }}>
          <DotsSpinner />
        </div>
      ) : (
        <>
         {/* <h1>map district </h1> */}
          <div className="">
            {ALLDistrict[selectedDistrict.toUpperCase()]} 
          </div>

        </>
      )}
    </div>
  );
}
