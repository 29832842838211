/*
* PLEASE USE BELOW VARIABLES FOR STAGING ONLY *
*/
// const API_URL = process.env.REACT_APP_STAGING_URL;

/*
* PLEASE USE BELOW VARIABLES FOR PRODUCTION ONLY *
*/

export const API_URL = process.env.REACT_APP_PROD_URL;

// export const API_URL =  process.env.REACT_APP_STAGING_URL;


export const BASE_URL = `${API_URL}api/`;

export const FastAPI = `${process.env.REACT_APP_REPORT_PROD_URL}api/`;
// export const FastAPI = `http://192.168.1.33:8000/api/`;
