import { useEffect, useState } from 'react';
import { Badge, Grid, Skeleton, Textarea } from '@mantine/core'
import moment from 'moment';

import { EButton } from '../../../libs/ui';
import { showToast } from '../../../utils/utility';
import { ISelectedTicketToBeResolved } from '../../../types';
import { useGrievanceData } from '../../../libs/data-access';
import { ITicketDetails } from '../../../libs/data-access/types';
import { DatePicker } from '@mantine/dates';

type IProps = {
  ticket: ISelectedTicketToBeResolved;
  handleReplyInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  closeSpecificTicket: () => void;
  isLoading: boolean;
  handleCloseDateInput: (e:any) => void;
};

export default function SideDrawer({
  ticket,
  handleReplyInput,
  closeSpecificTicket,
  isLoading,
  handleCloseDateInput
}: IProps) {
  const { fetchGrievanceData } = useGrievanceData();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [ticketDetails, setTicketDetails] = useState<
    ITicketDetails | undefined
  >(undefined);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      ticket.ticket_Status === "Closed"
        ? fetchTickerDetails()
        : setTicketDetails({ ...ticket, grievance_Reply: "" });
    }
  }, [isFirstLoad, ticket.ticket_Id]);

  // API CALLS
  const fetchTickerDetails = async () => {
    setIsDataLoading(true);
    const response = await fetchGrievanceData(
      `/GetGrievanceDetailsByTicketID/${ticket.ticket_Id}`
    );
    if (response.isSuccess) {
      setTicketDetails(response.data[0]);
    } else {
      console.error("Error while fetching ticket details: ", response.error);
      showToast({
        type: "error",
        title: "Something went wrong!",
        message: "Could not fetch ticket details",
      });
    }
    setIsDataLoading(false);
  };

  return (
    <div
      className="flex flex-1 flex-col gap-3 relative"
      style={{ paddingBottom: "4.8rem" }}
    >
      {isDataLoading ? (
        <div>
          <Skeleton radius="md" height={200} />
          <Skeleton className="my-3" radius="md" height={200} />
          <Skeleton radius="md" height={200} />
        </div>
      ) : (
        <>
          <p className="text-slate-500 border-b pb-4 flex justify-between">
            <span>Ticket# {ticketDetails?.ticket_Number}</span>
            <span className="ml-2">
              {ticketDetails?.date_Of_Issue
                ? moment(ticketDetails?.date_Of_Issue).format("DD/MM/YYYY")
                : " - "}
            </span>
            <Badge
              color={ticketDetails?.ticket_Status == "Open" ? "green" : "blue"}
            >
              {ticketDetails?.ticket_Status}
            </Badge>
          </p>

          <div className="flex gap-2 py-2 items-center">
            <div className="rounded-full bg-blue-600 w-12 h-12 text-white text-center text-xl flex justify-center items-center capitalize">
              {ticketDetails?.name?.charAt(0) ?? " - "}
            </div>
            <div>
              <p className="text-xl font-semibold">{ticketDetails?.name}</p>
              <p>{ticketDetails?.role_Name ?? " - "}</p>
            </div>
          </div>
          <div className="flex gap-14">
            <div className="flex flex-col gap-2">
              <p className="flex gap-10">
                <span className="font-semibold">Phone </span>
              </p>
              <p className="flex gap-10">
                <span className="font-semibold">District</span>
              </p>
              <p className="flex gap-10">
                <span className="font-semibold">Block </span>
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <span>{ticketDetails?.contact_Number || " - "}</span>
              <span>{ticketDetails?.district_Name || " - "}</span>
              <span>{ticketDetails?.block_Name || " - "}</span>
            </div>
          </div>

          <div className="flex flex-col gap-2 border-b pb-5" />

          <div>
            <p className="text-blue-800  text-md font-bold py-2">
              REMARKS / QUERY
            </p>
            <p className=" pb-5 border-b">{ticketDetails?.grievance_Query}</p>

            <p className="text-blue-800  text-md font-bold py-2">REPLY</p>

            {ticket.ticket_Status == "Open" ? (
              <>
                <Textarea
                  styles={{ input: { maxHeight: "6.5rem" } }}
                  autosize
                  size="md"
                  minRows={4}
                  maxRows={8}
                  placeholder="Type your reply here..."
                  value={ticket.reply}
                  onChange={(e) => handleReplyInput(e)}
                />

                {/* <div className="text-right w-full absolute mt-5 bottom-0 right-5"> */}
                <div className=" w-full absolute mt-5 bottom-0  flex justify-between items-end">
                  <DatePicker
                    label={
                      <span className="text-blue-800  text-md font-bold">
                        Select Close Date
                      </span>
                    }
                    placeholder="MM/DD/YYYY"
                    value={ticket?.closedTicketDate}
                    initialMonth={
                      ticketDetails?.date_Of_Issue
                        ? new Date(ticketDetails.date_Of_Issue)
                        : undefined
                    }
                    onChange={(e: any) => handleCloseDateInput(e)}
                    minDate={
                      ticketDetails?.date_Of_Issue
                        ? new Date(ticketDetails?.date_Of_Issue)
                        : undefined
                    }
                    // maxDate={
                    //   ticketDetails?.date_Of_Issue
                    //     ? new Date(
                    //         new Date(ticketDetails.date_Of_Issue).setDate(
                    //           new Date(ticketDetails.date_Of_Issue).getDate() +
                    //             7
                    //         )
                    //       )
                    //     : undefined
                    // }
                    maxDate={
                      ticketDetails?.date_Of_Issue
                        ? new Date(
                            Math.min(
                              new Date(ticketDetails.date_Of_Issue).setDate(
                                new Date(
                                  ticketDetails.date_Of_Issue
                                ).getDate() + 7
                              ),
                              new Date().getTime()
                            )
                          )
                        : undefined
                    }
                  />

                  <EButton
                    size="md"
                    loading={isLoading}
                    className="btn-blue"
                    onClick={() => closeSpecificTicket()}
                  >
                    Send & Closed Ticket
                  </EButton>
                </div>
              </>
            ) : (
              <>
                <p className="pb-4">{ticketDetails?.grievance_Reply}</p>
                <p className="text-slate-400 text-sm">
                  Closed on :{" "}
                  {ticketDetails?.date_Of_Solved
                    ? moment(ticketDetails?.date_Of_Solved).format("DD/MM/YYYY")
                    : " - "}
                </p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
