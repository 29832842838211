import React from 'react'
import PageLayout from '../../../../PageLayout';
import { EButton, EPageTitle, ESectionDivider, ETable } from '../../../../libs/ui';
import { Checkbox, MultiSelect, Select, TextInput, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { BsPlus } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import useSchedulePeriodicAssessment from '../Hooks/useSchedulePeriodicAssessment';
import moment from 'moment';
import useEditSchedulePeriodic from '../Hooks/useEditSchedulePeriodic';

export default function EditSchedulePeriodic() {

    const {
        isLoading,
        assessmentTypes,
        selectedPeriodicSchedule,
        setSelectedPeriodicSchedule,
        classes,
        subjectList,
        competencyByClassSubject,
        confirmSaveSchedule,
        setConfirmSaveSchedule,
        isSavingSchedule,
        newOrfQuestion,
        setNewOrfQuestion,
        handleEditScheduleParamsChange,
        handleEditSchedulesForPeriodicAssessment
    }= useEditSchedulePeriodic()
    
  return (
    <PageLayout>
    <EPageTitle title={'Edit Schedule Periodic Assessment'} />
    <div className="flex justify-center gap-10 mb-3">
        <div className="flex flex-col gap-3 w-full">
            <Select
                searchable={assessmentTypes?.length ? true : false}
                size='md'
                placeholder="Select"
                label="Select Assessment"
                data={assessmentTypes?.map(each => ({
                    value: String(each.id), label: each.name
                }))}
                value={selectedPeriodicSchedule?.periodic_Assessment_Id ? String(selectedPeriodicSchedule?.periodic_Assessment_Id) : undefined}
                onChange={e => {
                    selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule ,periodic_Assessment_Id : e ?  +e : 0
                    })
                }}
            />

            <DatePicker
                size='md'
                label="Start Date"
                placeholder='Select date'
                minDate={new Date()}
                clearable={false}
                value={selectedPeriodicSchedule?.startDate ? new Date(selectedPeriodicSchedule?.startDate) : null}
                onChange={e => {
                    selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule ,endDate : "" , startDate : e ?   moment(e).format("YYYY-MM-DD") : ""
                    })
                    // handleUpdateDatesForScheduleParams("endDate", null)
                    // handleUpdateDatesForScheduleParams("startDate", moment(e).format("YYYY-MM-DD"))
                }}
            />

            <Select
                searchable={classes?.length ? true : false}
                size='md'
                placeholder="Select"
                label="Grade"
                data={classes?.map(each => ({
                    value: String(each.id), label: each.name
                }))}
                value={selectedPeriodicSchedule?.class_Id ? String(selectedPeriodicSchedule?.class_Id) : null}
                onChange={e => {
                    selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule , class_Id: e ?  +e : 0 ,subject_Id: 0 ,scheduleCompetency: [] 
                    })
                    // handleNewScheduleParamsChange("class_Id", Number(e));
                    // handleNewScheduleParamsChange("subject_Id", 0);
                    // handleNewScheduleParamsChange("scheduleCompetancy", []);
                }}
            />
        </div>
        <div className="flex flex-col gap-3 w-full">
            <TextInput
                size="md"
                label="No. of Questions"
                value={selectedPeriodicSchedule?.total_Number_Of_Questions ?? 0}
                disabled
            // onChange={(e) => handleNewScheduleParamsChange("number_Of_Questions", e.target.value)}
            />

            <DatePicker
                size='md'
                label="Due Date"
                placeholder='Select date'
                clearable={false}
                minDate={selectedPeriodicSchedule?.startDate ? new Date(selectedPeriodicSchedule?.startDate) : new Date(moment().add(1, "day").format())}
                value={selectedPeriodicSchedule?.endDate ? new Date(selectedPeriodicSchedule?.endDate) : null}
                onChange={e =>{
                    selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule ,endDate : e ?  moment(e).format("YYYY-MM-DD") : ""
                    })
                    // handleUpdateDatesForScheduleParams("endDate", moment(e).format("YYYY-MM-DD"))
                }}
            />

            <Select
                searchable={subjectList?.length ? true : false}
                size='md'
                placeholder="Select"
                label="Subject"
                data={subjectList?.map(each => ({
                    value: String(each.id), label: each.name
                }))}
                value={selectedPeriodicSchedule?.subject_Id ? String(selectedPeriodicSchedule?.subject_Id) : null}
                onChange={e => {
                    selectedPeriodicSchedule &&
                    setSelectedPeriodicSchedule({
                        ...selectedPeriodicSchedule ,subject_Id : e ?  +e : 0 ,scheduleCompetency: []
                    })
                    // handleNewScheduleParamsChange("subject_Id", Number(e));
                    // handleNewScheduleParamsChange("scheduleCompetancy", []);
                }}
            />
        </div>
    </div>

    {/* COMPETENCIES */}
    <ESectionDivider title={'Competencies'} />
    <>
        <MultiSelect
            size='md'
            className='pb-8 mt-8'
            placeholder="Select Competencies"
            disabled={selectedPeriodicSchedule?.subject_Id ? false : true}
            data={competencyByClassSubject?.map(each => ({
                value: String(each.competancy_Id),
                label: each.competancy,
                group: each.assessment_Type
            }))}
            value={selectedPeriodicSchedule?.scheduleCompetency?.map(each => String(each?.competency_Id))}
            onChange={e => {
                handleEditScheduleParamsChange("scheduleCompetancy", e)
            }}
        />
        {/* <div>
                {newScheduleParams.scheduleCompetancy?.map((each, index) => <Badge
                    key={index + "com"}
                    size="lg"
                    radius="xl"
                    color="teal"
                    rightSection={<MdOutlineCancel />}
                >
                    {each?.competency_name ?? ""}
                </Badge>)}
            </div> */}
        {
            selectedPeriodicSchedule?.scheduleCompetency?.length ?
                <div className='border'>
                    <ETable>
                        <thead>
                            <tr>
                                <th>
                                    Competency Name
                                </th>
                                <th>
                                    No. of questions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedPeriodicSchedule?.scheduleCompetency?.map((each) => (
                                <tr>
                                    <td>
                                        {each?.competency}
                                    </td>
                                    <td>
                                        <TextInput
                                            placeholder='Enter no. of question'
                                            value={each?.number_Of_Question ?? null}
                                            maxLength={2}
                                            error={isNaN(+each?.number_Of_Question) ? "Value is not a number" : ""}
                                            onChange={(e) => handleEditScheduleParamsChange("updateCompetencyQuestionNumber", `${each?.competency_Id},${e?.target.value}`)}
                                        />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className="text-center bg-slate-50">
                                    Total No. of Question
                                </td>
                                <td className='text-center bg-slate-50 font-semibold text-zinc-800' >{selectedPeriodicSchedule?.total_Number_Of_Questions ?? 0}</td>
                            </tr>
                        </tbody>
                    </ETable>
                </div>
                :
                <></>}
    </>

    {/*ORF QUESTIONS */}
    {
        selectedPeriodicSchedule && selectedPeriodicSchedule.subject_Id == 3
            ?
            <></>
            :
            <>
                <ESectionDivider title={'ORF Question'} />
                <div>
                    <Textarea
                        placeholder='Enter question'
                        size='md'
                        minRows={3}
                        value={newOrfQuestion?.orF_Question_Text}
                        onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, orF_Question_Text: e?.target?.value }))}
                    />
                    <div className='flex gap-10 mt-4'>
                        <TextInput
                            size='md'
                            className='grow'
                            placeholder='Enter min word read per minutes'
                            maxLength={2}
                            error={isNaN(+newOrfQuestion?.min_Word_Read_Per_Minute) ? "Value is not a number" : ""}
                            value={newOrfQuestion?.min_Word_Read_Per_Minute}
                            onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, min_Word_Read_Per_Minute: e?.target?.value }))}
                        />
                        <TextInput
                            size='md'
                            className='grow'
                            error={isNaN(+newOrfQuestion?.max_Seconds_To_Read) ? "Value is not a number" : ""}
                            maxLength={3}
                            placeholder='Enter max seconds to read'
                            value={newOrfQuestion?.max_Seconds_To_Read}
                            onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, max_Seconds_To_Read: e?.target?.value }))}
                        />
                    </div>
                    <div className='flex justify-end mt-4 mb-4'>
                        <EButton
                            className="btn-blue"
                            size="md"
                            disabled={newOrfQuestion?.max_Seconds_To_Read && newOrfQuestion?.min_Word_Read_Per_Minute && newOrfQuestion?.orF_Question_Text ? false : true}
                            onClick={() => {
                                if (selectedPeriodicSchedule && selectedPeriodicSchedule?.orfQuestions?.length) {
                                    setSelectedPeriodicSchedule(
                                    {
                                        ...selectedPeriodicSchedule,
                                        orfQuestions:
                                            [...selectedPeriodicSchedule?.orfQuestions, 
                                                {orF_Question_Text: newOrfQuestion?.orF_Question_Text ,
                                                 max_Seconds_To_Read: newOrfQuestion?.max_Seconds_To_Read,
                                                 min_Word_Read_Per_Minute: +newOrfQuestion?.min_Word_Read_Per_Minute
                                                }]
                                    })
                                }
                                else {
                                    // setSelectedPeriodicSchedule({ ...selectedPeriodicSchedule, orfQuestions: {
                                    //              orF_Question_Text: newOrfQuestion?.orF_Question_Text,
                                    //              max_Seconds_To_Read: newOrfQuestion?.max_Seconds_To_Read,
                                    //              min_Word_Read_Per_Minute: +newOrfQuestion?.min_Word_Read_Per_Minute
                                    //             }
                                    //          })
                                }
                                setNewOrfQuestion({
                                    orF_Question_Text: "",
                                    max_Seconds_To_Read: "",
                                    min_Word_Read_Per_Minute: ""
                                })
                            }}
                        ><BsPlus size={22} /> Add ORF</EButton>
                    </div>

                    {
                        selectedPeriodicSchedule?.orfQuestions?.length ?
                            <div className='border p-1'>
                                <ETable>
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Min word read per minutes</th>
                                            <th>Max seconds to read</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            selectedPeriodicSchedule?.orfQuestions?.map((item, index) => (
                                                <tr>
                                                    <td>{item?.orF_Question_Text}</td>
                                                    <td>{item?.min_Word_Read_Per_Minute}</td>
                                                    <td>{item?.max_Seconds_To_Read}</td>
                                                    <td> {
                                                        <p
                                                            className='cursor-pointer text-danger'
                                                            onClick={() => {
                                                                let allQuestions = selectedPeriodicSchedule?.orfQuestions
                                                                allQuestions.splice(index, 1)
                                                                setSelectedPeriodicSchedule(
                                                                    {
                                                                        ...selectedPeriodicSchedule, orfQuestions: allQuestions
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <MdDelete size={22} />
                                                        </p>
                                                    }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </ETable>
                            </div>
                            :
                            <></>
                    }
                </div>
            </>
    }

    {/* footer */}
    <div className="mt-10 flex w-full justify-between">
        <Checkbox
            label={"Are you sure you want to Update?"}
            checked={confirmSaveSchedule}
            onClick={() => setConfirmSaveSchedule(!confirmSaveSchedule)}
        />
        <EButton
            className="btn-blue"
            loading={isSavingSchedule}
            disabled={!confirmSaveSchedule}
            onClick={() => handleEditSchedulesForPeriodicAssessment()}
        >
            Update
        </EButton>
    </div>
</PageLayout>
  )
}
