import React, { useContext, useEffect, useState } from 'react'
import { useDetailsKpiData } from '../../../../../libs/data-access';
import { DashboardFilterContext } from '../../../../Dashboard/DashboardFilterContext';
import { IAverageNoOfClassroomObservedByMonitorPercentage, IReportLevel, ISchoolsVisitedByMonitorPercentage, ISpotAssessmentConductedByMonitor, ITargetedClassRoomObservation, ITargetedSchoolVisit } from '../../../../../libs/data-access/types';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import moment from 'moment';

export default function useMonitoringCompliance() {
    const
  {
      fetchDetailKpi
  } = useDetailsKpiData()
  
  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);
  

  const [isLoadingAverageNoOfClassroomObservedByMonitorPercentage,setIsLoadingAverageNoOfClassroomObservedByMonitorPercentage] =useState<boolean>(false)
  const [isLoadingSchoolsVisitedByMonitorPercentage,setIsLoadingSchoolsVisitedByMonitorPercentage] =useState<boolean>(false)
  const [isLoadingSpotAssessmentConductedByMonitor,setIsLoadingSpotAssessmentConductedByMonitor] =useState<boolean>(false)
  const [isLoadingTargetedClassRoomObservation,setIsLoadingTargetedClassRoomObservation] =useState<boolean>(false)
  const [isLoadingTargetedSchoolVisit,setIsLoadingTargetedSchoolVisit] =useState<boolean>(false)
  
  const [averageNoOfClassroomObservedByMonitorPercentage,setAverageNoOfClassroomObservedByMonitorPercentage] = useState<IAverageNoOfClassroomObservedByMonitorPercentage | undefined>(undefined)
  const [schoolsVisitedByMonitorPercentage,setSchoolsVisitedByMonitorPercentage] = useState<ISchoolsVisitedByMonitorPercentage | undefined>(undefined)
  const [spotAssessmentConductedByMonitor,setSpotAssessmentConductedByMonitor] = useState<ISpotAssessmentConductedByMonitor | undefined>(undefined)
  const [targetedClassRoomObservation,setTargetedClassRoomObservation] = useState<ITargetedClassRoomObservation | undefined>(undefined)
  const [targetedSchoolVisit,setTargetedSchoolVisit] = useState<ITargetedSchoolVisit | undefined>(undefined)
  const [reportLevel, setReportLevel] = useState<IReportLevel>({levelNames:"state",districtName:"haryana",districtId:0,blockName:""});
  
  useEffect(()=>{
    if(isSearching)
    {getAverageClassroomObservations()
    getSchoolsVisitedByMonitorPercentage()
    getSpotAssessmentConductedByMonitor()
    getTargetedClassRoomObservation()
    getTargetedSchoolVisit()}
  },[isSearching])

  const getAverageClassroomObservations = async () => {
    setIsLoadingAverageNoOfClassroomObservedByMonitorPercentage(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/Monitoring/AverageNoOfClassroomObservedByMonitorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setAverageNoOfClassroomObservedByMonitorPercentage(res?.data)
        setReportLevel(
            { levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
                districtName : districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
                districtId: selectedDistrict ? +selectedDistrict  :0,
                blockName:  blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : "" 
           })
    }
    else {
        setAverageNoOfClassroomObservedByMonitorPercentage(undefined)
        showToast({
            type: "error",
            message: res?.message || "Fail to get classroom Observation"
        })
    }
    setIsLoadingAverageNoOfClassroomObservedByMonitorPercentage(false)
}

  const getSchoolsVisitedByMonitorPercentage = async () => {
    setIsLoadingSchoolsVisitedByMonitorPercentage(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/Monitoring/AverageNoOfSchoolsVisitedByMonitorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setSchoolsVisitedByMonitorPercentage(res?.data)
    }
    else {
        setSchoolsVisitedByMonitorPercentage(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingSchoolsVisitedByMonitorPercentage(false)
}

  const getSpotAssessmentConductedByMonitor = async () => {
    setIsLoadingSpotAssessmentConductedByMonitor(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/Monitoring/AverageNoOfSpotAssessmentConductedByMonitorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setSpotAssessmentConductedByMonitor(res?.data)
    }
    else {
        setSpotAssessmentConductedByMonitor(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingSpotAssessmentConductedByMonitor(false)
}

  const getTargetedClassRoomObservation = async () => {
    setIsLoadingTargetedClassRoomObservation(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/Monitoring/CompletingTheirTargetedClassRoomObservationPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setTargetedClassRoomObservation(res?.data)
    }
    else {
        setTargetedClassRoomObservation(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingTargetedClassRoomObservation(false)
}

  const getTargetedSchoolVisit = async () => {
    setIsLoadingTargetedSchoolVisit(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/Monitoring/CompletingTheirTargetedSchoolVisitPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setTargetedSchoolVisit(res?.data)
    }
    else {
        setTargetedSchoolVisit(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingTargetedSchoolVisit(false)
}

  return {
      isLoadingAverageNoOfClassroomObservedByMonitorPercentage,
      isLoadingSchoolsVisitedByMonitorPercentage,
      isLoadingSpotAssessmentConductedByMonitor,
      isLoadingTargetedClassRoomObservation,
      isLoadingTargetedSchoolVisit,
      reportLevel,
      averageNoOfClassroomObservedByMonitorPercentage,
      schoolsVisitedByMonitorPercentage,
      spotAssessmentConductedByMonitor,
      targetedClassRoomObservation,
      targetedSchoolVisit
  }
}
