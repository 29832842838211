import React from 'react'
import { useSpotAssessmentsCompleted } from '../Hooks/useSpotAssessmentsCompleted';
import { Skeleton } from '@mantine/core';
import { EButton, EInfoPanel, ESectionDivider, ETable, EVerticalFormSection } from '../../../../../libs/ui';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import MapData from '../../../../../libs/maps/MapData';
import { EPlaceholder } from '../../../../../libs/ui/src/Placeholder/EPlaceholder';
import { ECard } from '../../../../../libs/ui/src/Card/ECard';
import { BiDownload } from 'react-icons/bi';

export default function SpotAssessmentsCompleted() {
  const {
    isLoading,
    isMapLoading,
    isLoadingDownloadReport,
    spotAssessmentCompletedData,
    spotAssessmentCompletedCSV,
    keysForTableHeader,
    keysForCompetency,
    spotAssessmentMapData,
    totalForData,
  } = useSpotAssessmentsCompleted();

  return (
    <div className="flex flex-col gap-7">
      {/* Map  */}
      {spotAssessmentCompletedData?.length ? (
        isMapLoading ? (
          <Skeleton radius={"md"} height={350} />
        ) : (
          spotAssessmentMapData && (
            <MapData
              title="Spot Assessment Compliance"
              data={
                spotAssessmentMapData
                  ? spotAssessmentMapData?.map((each) => ({
                      district: each?.District_Name ?? "",
                      average: each?.PercentageOfSpotMentor,
                      // average: each?.PercentageOfSpotMentor.slice(
                      //   0,
                      //   each?.PercentageOfSpotMentor?.length - 1
                      // ),
                    }))
                  : []
              }
              isLoading={isMapLoading}
              setSelectedDistrict={() => {}}
            />
          )
        )
      ) : (
        <>
          <EPlaceholder
            iconSrc={
              require("../../../../../assets/icons/icon-placeholder.svg")
                .default
            }
            title="Create Report"
            description="Please select a month and other filters and press search to generate a report."
          />
        </>
      )}

      {/* Table to displayed */}
      <div>
        {!isLoading ? (
          spotAssessmentCompletedData?.length ? (
            <ECard
              title="Filter-wise % students"
              header={
                <CSVLink
                  style={{}}
                  className="text-blue-600 text-sm hover:underline"
                  // data={[]}
                  data={spotAssessmentCompletedCSV?.content ?? []}
                  // headers={[]}
                  headers={spotAssessmentCompletedCSV?.headers ?? []}
                  filename={`Spot_Assessment_Completed_${moment().format(
                    "MMM"
                  )}-${moment().format("YYYY")}.csv`}
                >
                  <EButton className="btn-blue">
                    Download Report <BiDownload className="ml-2" size={22} />
                  </EButton>
                </CSVLink>
              }
            >
              <ETable>
                <thead className="border">
                  <tr>
                    <th>
                      {keysForTableHeader
                        ? keysForTableHeader.includes("School_Name")
                          ? "School Name"
                          : keysForTableHeader.includes("Cluster_School_Name")
                          ? "Cluster Name"
                          : keysForTableHeader.includes("Block_Name")
                          ? "Block Name"
                          : keysForTableHeader.includes("District_Name")
                          ? "District Name"
                          : "Name"
                        : "Name"}
                    </th>
                    <th>Total Mentors/Monitors</th>
                    {/* suggested by vikash */}
                    {/* <th>Target Completed by mentor</th> */}
                    <th>Total Spot Completed</th>
                    <th>Percentage of spot completed by Mentors/Monitors </th>
                  </tr>
                </thead>
                <tbody className="border">
                  {spotAssessmentCompletedData
                    .filter((e) => e?.District_Name !== "")
                    ?.map((each) => (
                      <tr key={each + "spot"}>
                        <td>
                          {keysForTableHeader.includes("School_Name")
                            ? each?.School_Name
                            : keysForTableHeader.includes("Cluster_School_Name")
                            ? each?.Cluster_School_Name
                            : keysForTableHeader.includes("Block_Name")
                            ? each?.Block_Name
                            : keysForTableHeader.includes("District_Name")
                            ? each?.District_Name
                            : "-"}
                        </td>
                        <td>{each?.Total_Mentor || "0"}</td>
                        {/* <td>
                          {each?.TotalNumberOfMentorThoseCompletedTarget || "0"}
                        </td> */}
                        <td>{each?.Total_Spot_Completed || "0"}</td>
                        <td>{each?.PercentageOfSpotMentor || "0"}</td>
                      </tr>
                    ))}
                  <tr>
                    <td>{totalForData?.name}</td>
                    <td>{totalForData?.Total_Mentor}</td>
                    <td>{totalForData?.Total_Spot_Completed}</td>
                    <td>{`${totalForData?.PercentageOfSpotMentor}%`}</td>
                  </tr>
                </tbody>
              </ETable>
            </ECard>
          ) : (
            <></>
          )
        ) : (
          <Skeleton radius={"md"} height={250} />
        )}
      </div>
      {/* DATA for excel */}
      {/* <div className="mt-5">
        {isLoadingDownloadReport
          ?
          <Skeleton radius={"md"} height={250} />
          :
          spotAssessmentCompletedCSV?.content
            ?
            <EVerticalFormSection
              title=""
              rightPanel={<>

              </>}
            >
              <div>


                <EInfoPanel
                  title=" Your report is ready to download, Please click on Download File to download report"
                >
                  <CSVLink
                    style={
                      {

                      }
                    }
                    className="text-blue-600 text-sm hover:underline"
                    // data={[]}
                    data={spotAssessmentCompletedCSV?.content ?? []}
                    // headers={[]}
                    headers={spotAssessmentCompletedCSV?.headers ?? []}
                    filename={`Spot_Assessment_Completed_${moment().format("MMM")}-${moment().format("YYYY")}.csv`}
                  >Download File</CSVLink>
                </EInfoPanel>
              </div>
            </EVerticalFormSection>
            :
            <></>}
      </div> */}
    </div>
  );
};
