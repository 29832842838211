import React from 'react'
import PageLayout from '../../../../PageLayout'
import { EButton, EPageTitle, ESectionDivider, ETable, EVerticalFormSection } from '../../../../libs/ui'
import { Alert, Card, Checkbox, Grid, Modal, Select, Space, TextInput, Textarea, Tooltip } from '@mantine/core'
import useSlotQuestionBank from '../hooks/useSlotQuestionBank'
import { BsInfoCircle } from 'react-icons/bs'
import AddedOptionList from '../../../QuestionBank/Components/Uploader/AddedOptionList'
import UploadNewSlotTestQuestionOption from '../components/UploadNewSlotTestQuestionOption'
import AddOptionFrame from '../../../QuestionBank/Components/Uploader/AddOptionFrame'
import AddNewOptionFrame from '../components/AddNewOptionFrame'
import { MdAddCircle, MdDelete } from 'react-icons/md'
import { showToast } from '../../../../utils/utility'
import { TiTick } from 'react-icons/ti'
import { FiCornerRightUp } from 'react-icons/fi'

export default function SlotQuestionBank() {

  const {
    isConfirmed,
    setIsConfirmed,
    questionType,
    slotList,
    newTestQuestionOption,
    setNewTestQuestionOption,
    selectedSlotHeader,
    setSelectedSlotHeader,
    newTestQuestion,
    setNewTestQuestion,
    newTestOption,
    setNewTestOption,
    isLoadingSlotList,
    isAddingNewOption,
    setIsAddingNewOption,
    newTestOptionList,
    setNewTestOptionList,
    handleAddQuestion,
    newTestQuestionList,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    selectedDeleteItemIndex,
    setSelectedDeleteItemIndex,
    handleDeleteTestFromList,
    handleSubmitQuestionList
  } = useSlotQuestionBank()

  let optionList: any[] = [];
  return (
    <PageLayout>
      {/* Filter */}

      <div className="max-w-5xl ">
        <EVerticalFormSection
          title="Parameters / Filters"
          subtitle="Please select below filters to set questions to continue. These are required."
        >
          <div className="grid grid-cols-3 gap-3">
            <Select
              required
              // className="w-[33%]"
              label="Slot Name"
              placeholder="Select"
              size="md"
              data={slotList?.map(each => ({
                value: String(each?.training_Schedule_Header_Id),
                label: each?.schedule_Header_Title
              })) ?? []}
              value={String(selectedSlotHeader) || null}
              onChange={(e) => {
                setSelectedSlotHeader(e ? +e : 0)

              }}
            />
            <Select
              required
              label="Slot Test"
              placeholder="Select"
              size="md"
              data={slotList?.filter(e => e?.training_Schedule_Header_Id === selectedSlotHeader)[0]?.tests?.map(each => ({
                value: String(each?.schedule_Header_Test_Id),
                label: each?.test_Name
              })) ?? []}
              value={String(newTestQuestionOption?.schedule_Header_Test_Id) || null}
              onChange={(e) => {
                setNewTestQuestionOption({ ...newTestQuestionOption, schedule_Header_Test_Id: e ? +e : 0 })
              }}
            />
            <Select
              required
              label="Question Type"
              placeholder="Select"
              size="md"
              data={questionType?.map((each) => ({
                value: String(each.id),
                label: each.text,
              })) ?? []}
              value={newTestQuestion.question_Type_Id ? String(newTestQuestion.question_Type_Id) : null}
              onChange={(e) => {

                setNewTestQuestion({ ...newTestQuestion, question_Type_Id: e ? +e : 0 })
              }}
            />
          </div>
          <div className="grid grid-cols-2 mt-2 gap-3">
            <TextInput
              label="Question Group Name"
              placeholder="Ex. step-1"
              value={newTestQuestionOption?.question_Group_Name}
              onChange={(e) => setNewTestQuestionOption({ ...newTestQuestionOption, question_Group_Name: e?.target?.value })}
              size="md"
            />
          </div>

          {!newTestQuestionOption?.schedule_Header_Test_Id
            ?
            <>
              <Space h={34} />
              <Alert
                icon={<BsInfoCircle />}
                title="Missing Slot Details!"
                color="orange"
              >
                You need to select Slot and question type for question and options
              </Alert>
            </>
            :
            <></>}
        </EVerticalFormSection>
      </div>

      {/* QUESTION DETAILS */}
      <Space h={24} />

      {/* ADD QUESTION */}
      <div className="question-container">
        <div>
          <div className="text-xl font-bold">Question</div>
          <p className="text-sm opacity-50">Question can only contain Text</p>
          <div className='options-container'>
            <div className="input-item mt-2">
              <Textarea
                required
                size="lg"
                variant="unstyled"
                className="w-full"
                placeholder="Enter question here"
                value={newTestQuestion?.question_Text}
                onChange={(e) =>
                  setNewTestQuestion({ ...newTestQuestion, question_Text: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="text-xl font-bold -mb-5">Options
          <p className="text-sm opacity-50">Enter at least 2 options and select the correct answer</p>
        </div>
        <div className='options-container mt-2'>
          {
            newTestOptionList.length
              ?
              <UploadNewSlotTestQuestionOption
                optionList={newTestOptionList}
                questionFilters={newTestQuestion?.question_Type_Id}
                handleSetCorrectOption={(index) => {
                  const arr = newTestOptionList;
                  arr[index].is_Correct = !arr[index].is_Correct
                  setNewTestOptionList(JSON.parse(JSON.stringify(arr)))
                }}
                handleSetCorrectOptionMultiple={(index) => console.log("handle set multiple option correct", index)}
                handleRemoveSpecificOption={(index) => console.log("handle remove specific option", index)}
                handleRemoveCurrentOption={(index) => {
                  const arr = newTestOptionList;
                  arr.splice(index, 1)
                  setNewTestOptionList(JSON.parse(JSON.stringify(arr)))
                }}
              /> : <></>}

          {isAddingNewOption
            ?
            <AddNewOptionFrame
              questionFilters={newTestQuestion?.question_Type_Id}
              optionDetails={newTestOption ?? undefined}
              handleChangeOptionDetails={(text) => setNewTestOption({ ...newTestOption, option_Text: text })}
              optionList={newTestOptionList}
              handleAddNewOption={(option) => {
                setNewTestOptionList([...newTestOptionList, option])
                setIsAddingNewOption(false)
                setNewTestOption({
                  base64OptionImage: "",
                  option_Text: "",
                  is_Correct: false
                })
              }}
              removeOption={() => console.log("handle remove option")} />
            :
            <></>}

          {newTestOptionList?.length < 4 ?
             !isAddingNewOption &&
            <div className="input-item empty">
              <a
                className="flex gap-1 items-center font-semibold text-secondary-500 p-2 cursor-pointer"
                onClick={() => setIsAddingNewOption(!isAddingNewOption)}
              >
                <MdAddCircle /> Add Option
              </a>
            </div> : <></>}
        </div>

      </div>
      {/* FOOTER */}
      <Space h={44} />
      <div className="flex justify-end px-6 items-center">
        {/* DISCLAIMER */}
        {/* <Checkbox
          className="w-3/4"
          checked={isConfirmed}
          label={
            <span className="text-sm text-slate-400">
              {`Are you sure you want to add this question?`}
            </span>
          }
          onChange={(e) => {
            setIsConfirmed(e.target.checked);
          }}
        /> */}
        <div className="flex">
          <EButton
            type={"submit"}
            // disabled={!isConfirmed}
            className="btn-blue"
            onClick={() => handleAddQuestion()}
          >
            Add Question
          </EButton>
        </div>
      </div>

      {newTestQuestionList?.length ? <div>
        {/* Selected Table  */}
        <ESectionDivider title={'Selected Question for test'} />
        <div className=' mb-16'>
          <ETable>
            <thead>
              <tr>
                <th>Question</th>
                <th>Option Type</th>
                <th>Option 1</th>
                <th>Option 2</th>
                <th>Option 3</th>
                <th>Option 4</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{
              newTestQuestionList.map((e, index) => (
                <tr>
                  <td>{e?.question_Text}</td>
                  <td>{e?.question_Type_Id == 1 ? "Single answer" : "Multiple answer"}</td>
                  {
                  e?.questionOptions?.map((each) => (
                    <td>
                      <span className='flex items-start'>
                        {each?.option_Text}
                        <span>
                          {each?.is_Correct ? <Tooltip
                            withArrow
                            label="Correct option"
                          >
                            <a><TiTick className='ml-2 text-green-700' />
                            </a>
                          </Tooltip>
                            : <></>}
                        </span>
                      </span>
                    </td>
                   
                  ))    
                  }
                  {
                    e?.questionOptions?.length - 3 ? <td></td> :<></>
                  }
                  {
                    e?.questionOptions?.length - 4 ? <td></td> :<></>
                  }
                  <td>
                    <span
                      className="text-red-400 cursor-pointer hover:text-red-600"
                      onClick={() => {
                        setIsOpenDeleteModal(true)
                        setSelectedDeleteItemIndex(index)
                      }}
                    >
                      <MdDelete size={24} />
                    </span>
                  </td>
                </tr>
              ))
            }
            </tbody>
          </ETable>

          {/* Table FOOTER */}
          <Space h={44} />
          <div className="flex justify-between px-6 items-center">
            {/* DISCLAIMER */}
            <Checkbox
              className="w-3/4"
              checked={isConfirmed}
              label={
                <span className="text-sm text-slate-400">
                  {`Are you sure you want to submit this question list ?`}
                </span>
              }
              onChange={(e) => {
                setIsConfirmed(e.target.checked);
              }}
            />
            <div className="flex">
              <EButton
                type={"submit"}
                disabled={!isConfirmed}
                className="btn-blue"
                onClick={() => handleSubmitQuestionList()}
              >
                Submit Question
              </EButton>
            </div>
          </div>
        </div>
      </div> : <></>}

      <Modal
        opened={isOpenDeleteModal}
        onClose={() => { }}
        withCloseButton={false}
        title="Are you sure you want to delete ?">
        <div className='flex gap-3 justify-end'>
          <EButton
            className="btn-light"
            onClick={() => {
              setSelectedDeleteItemIndex(undefined)
              setIsOpenDeleteModal(false)
            }}
          >Cancel</EButton>
          <EButton
            className="btn-red"
            onClick={() => handleDeleteTestFromList()}
          >Delete</EButton>
        </div>
      </Modal>
    </PageLayout>
  )
}
