import React, { useContext, useEffect, useState } from 'react'
import { IEditQuestionDetails, IQuestionBankQuestionList, IQuestionFilters, ISelectedQuestionDetails } from '../../../types'
import { showToast } from '../../../utils/utility'
import { usePeriodicAssessment, useStateAdmin } from '../../../libs/data-access';
import { AppContext } from '../../../AppContext';
import { handleValidateEditQuestionParams, handleValidateQuestionParams } from '../../../utils/validators';

export default function useQuestionBankIndex(selectedFilter: IQuestionFilters) {

  const {
    fetchPeriodicAssessment
  } = usePeriodicAssessment();
  const {
    deleteStateAdmin,
    putStateAdmin
      } = useStateAdmin()

  const {
    user
} = useContext(AppContext); 

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [selectedQuestion, setSelectedQuestion] = useState<ISelectedQuestionDetails | undefined>(undefined)
  const [questionList, setQuestionList] = useState<IQuestionBankQuestionList[]>([])
  const [enlargedText,setEnlargedText] = useState<string>("")

  useEffect(()=>{
     if(selectedQuestion){
       setEnlargedText(selectedQuestion?.question_Text.split("\n")[1])
     }
  },[selectedQuestion])

  const handleSearch = () => {
    getQuestionByCompetency()
  }

  const handleEditQuestion = () => {
    if (!user.user_Id) {
      showToast({
          type: "warning",
          title: "Please login again",
          message: "User could not be found"
      });
      return;
  };
    if (selectedQuestion) {   
      let payload: IEditQuestionDetails =
      {
        question_Id: selectedQuestion?.question_Id,
        base64QuestionImage: selectedQuestion?.base64QuestionImage?.replace("data:image/jpeg;base64,",""),
        question_Instruction: selectedQuestion?.question_Instruction,
        question_Text: `${selectedQuestion?.question_Text.split("\n")[0].replace("/n","")}${enlargedText ? `\n${enlargedText}` :``}`,
        media_Type_Id: selectedQuestion?.questions_Media_Type_Id,
        option_Media_Type_Id: selectedQuestion?.options_Media_Type_Id,
        question_Media_Url: selectedQuestion?.media_Url,
        is_Active: true,
        marks: selectedQuestion?.marks,
        created_By: user.user_Id ,
        questionOptions: selectedQuestion?.questionsOptions?.map(e=>({
          question_Option_Id: e?.question_Option_Id,
          base64OptionImage: e?.base64OptionImage?.replace("data:image/jpeg;base64,",""),
          option_Text: e?.option_Text,
          is_Correct: e?.is_Correct,
          option_Media_Url:""
        }))
      }
      const isValidated = handleValidateEditQuestionParams(payload ,selectedQuestion);
      if(isValidated){
        console.log("update question payload",payload)
        updateQuestionDetails(payload)
      }
    }
  }


  const handleDeleteQuestionFromQuestionBank = () => {
    if (selectedQuestion) {
      deleteQuestionById()
    }
  }

  const getQuestionByCompetency = async () => {
    setIsLoading(true)
    const res = await fetchPeriodicAssessment(`/GetPeriodicAssessmentQuestions/${selectedFilter?.typeOfQuestionBank}/${selectedFilter?.selectedGrade}/${selectedFilter?.selectedSubject}/${selectedFilter?.selectedCompetencyLevel ? selectedFilter?.selectedCompetencyLevel : -1}`)
    if (res.isSuccess) {
      setQuestionList(res?.data)
    }
    else {
      setQuestionList([])
      showToast({
        type: "error",
        message: res?.message || "Fail to get question list"
      })
    }
    setIsLoading(false)
  }

  const deleteQuestionById = async () => {
    setIsBtnLoading(true)
    const res = await deleteStateAdmin(`Question/DeletePeriodicAssessmentQuestions/${selectedQuestion?.question_Id}`)
    if (res.isSuccess) {
      showToast({
        type: "success",
        message: "Question Deleted Successfully 🗑"
      })
      setIsOpenDeleteModal(false)
      setSelectedQuestion(undefined)
      getQuestionByCompetency()
    }
    else {
      showToast({
        type: "error",
        title: "Try again to delete",
        message: res?.message || "Fail to delete Question from bank"
      })
    }
    setIsBtnLoading(false)
  }

  const updateQuestionDetails =async (payload :IEditQuestionDetails) =>{
    setIsBtnLoading(true) 
    const res = await putStateAdmin(`Question/UpdatePeriodicAssessmentQuestion`,payload)
    if(res.isSuccess){
      showToast({
        type:"success",
        title:"Updated successfully",
        message:"Question updated in question bank"
      })
      setIsOpenEditModal(false)
      setSelectedQuestion(undefined)
      getQuestionByCompetency()
    }
    else{
        showToast({
          type:"error",
          message: res?.message || "Fail to update question"
        })
    }
    setIsBtnLoading(false)    
  } 

  return {
    isLoading,
    isBtnLoading,
    questionList,
    selectedQuestion,
    setSelectedQuestion,
    handleSearch,
    handleEditQuestion,
    handleDeleteQuestionFromQuestionBank,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
    isOpenEditModal,
    setIsOpenEditModal,
    enlargedText,
    setEnlargedText
  }
}
