import React, { useContext, useEffect, useState } from 'react'
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import { useDashboardDate, useDetailsKpiData } from '../../../../../libs/data-access';
import { IAverageClassroomObservations, IAverageSpotAssessments, IAverageUniqueSchoolVisits, IMentorsCompletingTargetedClassroomObservations, IMentorsCompletingTargetedUniqueSchoolVisit, IReportLevel } from '../../../../../libs/data-access/types';
import moment from 'moment';
import { DashboardFilterContext } from '../../../../Dashboard/DashboardFilterContext';

export default function useMentoringCompliance() {

  const
  {
      fetchDetailKpi
  } = useDetailsKpiData()
  
  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);
  
  const [isLoadingAverageClassroomObservations, setIsLoadingAverageClassroomObservations] = useState<boolean>(false)
  const [isLoadingAverageUniqueSchoolVisits, setIsLoadingAverageUniqueSchoolVisits] = useState<boolean>(false)
  const [isLoadingAverageSpotAssessments, setIsLoadingAverageSpotAssessments] = useState<boolean>(false)
  const [isLoadingMentorsCompletingTargetedClassroomObservations, setIsLoadingMentorsCompletingTargetedClassroomObservations] = useState<boolean>(false)
  const [isLoadingMentorsCompletingTargetedUniqueSchoolVisit, setIsLoadingMentorsCompletingTargetedUniqueSchoolVisit] = useState<boolean>(false)
  const [isLoadingMentorsCompletingTargetedSpotAssessment, setIsLoadingMentorsCompletingTargetedSpotAssessment] = useState<boolean>(false)

  const [averageClassroomObservations, setAverageClassroomObservations] = useState<IAverageClassroomObservations | undefined>(undefined)
  const [averageUniqueSchoolVisits, setAverageUniqueSchoolVisits] = useState<IAverageUniqueSchoolVisits | undefined>(undefined)
  const [averageSpotAssessments, setAverageSpotAssessments] = useState<IAverageSpotAssessments | undefined>(undefined)
  const [mentorsCompletingTargetedClassroomObservations, setMentorsCompletingTargetedClassroomObservations] = useState<IMentorsCompletingTargetedClassroomObservations | undefined>(undefined)
  const [mentorsCompletingTargetedUniqueSchoolVisit, setMentorsCompletingTargetedUniqueSchoolVisits] = useState<IMentorsCompletingTargetedUniqueSchoolVisit | undefined>(undefined)
  const [mentorsCompletingTargetedSpotAssessment, setMentorsCompletingTargetedSpotAssessment] = useState<IMentorsCompletingTargetedUniqueSchoolVisit | undefined>(undefined)
  const [reportLevel, setReportLevel] = useState<IReportLevel>({levelNames:"state",districtName:"haryana",districtId:0,blockName:""});

  

  useEffect(()=>{
   if(isSearching){ 
    getAverageClassroomObservations()
    getAverageUniqueSchoolVisits()
    getAverageSpotAssessments()
    getMentorsCompletingTargetedClassroomObservations()
    getMentorsCompletingTargetedUniqueSchoolVisits()
    getMentorsCompletingTargetedSpotAssessment()}
  },[isSearching])

  const getAverageClassroomObservations = async () => {
    setIsLoadingAverageClassroomObservations(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/AverageNoOfClassroomsObservedByMentorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setAverageClassroomObservations(res?.data)
        setReportLevel(
          { levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
          districtName : districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
          districtId: selectedDistrict ? +selectedDistrict  :0,
          blockName:  blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : "" 
     })
    }
    else {
        setAverageClassroomObservations(undefined)
        showToast({
            type: "error",
            message: res?.message || "Fail to get classroom Observation"
        })
    }
    setIsLoadingAverageClassroomObservations(false)
    setIsSearching(false)
}

  const getAverageUniqueSchoolVisits = async () => {
    setIsLoadingAverageUniqueSchoolVisits(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/AverageNoOfSchoolsVisitedByMentorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setAverageUniqueSchoolVisits(res?.data)
    }
    else {
        setAverageUniqueSchoolVisits(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingAverageUniqueSchoolVisits(false)
}

  const getAverageSpotAssessments = async () => {
    setIsLoadingAverageSpotAssessments(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/AverageNoOfSpotAssessmentsConductedByMentorPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setAverageSpotAssessments(res?.data)
    }
    else {
        setAverageSpotAssessments(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingAverageSpotAssessments(false)
}

  const getMentorsCompletingTargetedClassroomObservations = async () => {
    setIsLoadingMentorsCompletingTargetedClassroomObservations(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/MentorCompletingTheirTargetedClassroomObservationPercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setMentorsCompletingTargetedClassroomObservations(res?.data)
    }
    else {
        setMentorsCompletingTargetedClassroomObservations(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingMentorsCompletingTargetedClassroomObservations(false)
}

  const getMentorsCompletingTargetedUniqueSchoolVisits = async () => {
    setIsLoadingMentorsCompletingTargetedUniqueSchoolVisit(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/MentorCompletingTheirTargetedShoolObservationCompliance?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setMentorsCompletingTargetedUniqueSchoolVisits(res?.data)
    }
    else {
        setMentorsCompletingTargetedUniqueSchoolVisits(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingMentorsCompletingTargetedUniqueSchoolVisit(false)
}

  const getMentorsCompletingTargetedSpotAssessment = async () => {
    setIsLoadingMentorsCompletingTargetedSpotAssessment(true)
    const res = await fetchDetailKpi(
      `AcademicMentoring/MentorCompletingTheirSpotAssessmentTargetCompliancePercentage?DistrictId=${
        selectedDistrict ?? 0
      }&BlockId=${selectedBlock ?? 0}&ClusterId=${
        selectedCluster ?? 0
      }&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }/${selectedMonth}/01`}`
    );
    if (res.isSuccess) {
        setMentorsCompletingTargetedSpotAssessment(res?.data)
    }
    else {
        setMentorsCompletingTargetedSpotAssessment(undefined)
        // showToast({
        //     type: "error",
        //     message: res?.message || "Fail to get classroom Observation"
        // })
    }
    setIsLoadingMentorsCompletingTargetedSpotAssessment(false)
}
  
  return {
      isLoadingAverageClassroomObservations,
      isLoadingAverageUniqueSchoolVisits,
      isLoadingAverageSpotAssessments,
      isLoadingMentorsCompletingTargetedClassroomObservations,
      isLoadingMentorsCompletingTargetedUniqueSchoolVisit,
      isLoadingMentorsCompletingTargetedSpotAssessment,
      averageClassroomObservations,
      averageUniqueSchoolVisits,
      averageSpotAssessments,
      mentorsCompletingTargetedClassroomObservations,
      mentorsCompletingTargetedUniqueSchoolVisit,
      mentorsCompletingTargetedSpotAssessment,
      selectedBlock,
      selectedDistrict,
      districts,
      reportLevel
  }
}

