import React, { useEffect, useState } from 'react'
import { ITrainingSlotList } from '../../../../../../libs/data-access/types';
import { useTeacherTraining } from '../../../../../../libs/data-access';
import { error } from 'console';
import { showToast } from '../../../../../../utils/utility';

export default function useTrainingSlotIndex() {

    const {
        fetchTeacherTrainingData
    } = useTeacherTraining()
    const [isFirst, setIsFirst] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [trainingSlotList, setTrainingSlotList] = useState<ITrainingSlotList[]>([{
        training_Schedule_Header_Id: 1,
        schedule_Header_Title: "hindi 1",
        start_Date: "22-07-2023",
        end_Date: "29-07-2023",
        no_Of_Days: 0,
        training_Description: "",
        tests: []
    }]);

    useEffect(() => {
        if (isFirst) {
            setIsFirst(false)
        }
        else {
            getTrainingSlotList()
        }
    }, [isFirst]);

    const getTrainingSlotList = async () => {
        setIsLoading(true);
        setTrainingSlotList([]);
        const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetail?Is_Current_Header=${false}`);
        if (res?.isSuccess) {
            setTrainingSlotList(res?.data);
        } else {
            showToast({
                type: "error",
                message: res?.message ?? "Error while getting slot list"
            });
            console.log("Error while get slot list")
        };
        setIsLoading(false);
    };

    return {
        trainingSlotList,
        isLoading,
    };
}
