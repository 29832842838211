import { RiNumber9, RiNumber1 } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsPlus } from 'react-icons/bs';
import moment from 'moment';
import { Badge, InputBase, Modal, Select, Skeleton, Tabs, Textarea } from '@mantine/core';

import PageLayout from '../../PageLayout';
import TicketDrawer from './component/TicketDrawer';
import { EButton, EDrawer, EEmpty, EPageTitle, ETable } from '../../libs/ui';

import useTicket from './hook/useTicket';

export default function Ticket() {

    const {
        activeTab,
        setActiveTab,
        isLoading,
        setIsLoading,
        isOpenDrawer,
        setIsOpenDrawer,
        isOpenNewTicketModal,
        setIsOpenNewTicketModal,
        selectedTicket,
        setSelectedTicket,
        newTicket,
        setNewTicket,
        grievanceCategoryList,
        grievanceTicketList,
        handleSubmitQuery,
        inputError,
        setInputError
    } = useTicket();

    return (
        <PageLayout>

            <EPageTitle
                title="Tickets"
                end={<EButton className='bg-blue-600 ' onClick={() => setIsOpenNewTicketModal(true)}><BsPlus size={23} /> New Ticket</EButton>}
            />

            <Tabs
                defaultValue="open"
                value={activeTab}
                onTabChange={(value: "open" | "closed") => setActiveTab(value)}
            >
                <Tabs.List>
                    <Tabs.Tab value="open">Open Tickets</Tabs.Tab>
                    <Tabs.Tab value="closed">Closed</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="open" pt="xs">
                    {
                        isLoading
                            ?
                            <Skeleton
                                radius="md"
                                height={250}
                            />
                            :
                            grievanceTicketList.filter((e)=>e?.ticket_Status == 'Open').length > 0
                                ?
                                <ETable>
                                    <thead  >
                                        <tr>
                                            <th>Date</th>
                                            <th>Ticket #</th>
                                            {/* <th>User</th>
                                            <th>Phone</th>
                                            <th>District</th> */}
                                            <th>Category</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {grievanceTicketList.filter((e)=>e?.ticket_Status == 'Open').map((each, index) =>
                                            <tr
                                                key={index + "ticket"}
                                                onClick={() => {
                                                    setIsOpenDrawer(true)
                                                    setSelectedTicket(each)
                                                }}>
                                                <td>{moment(each.date_Of_Issue).format("DD-MM-YYYY")}</td>
                                                <td className='text-blue-600 ' >{each.ticket_Number}</td>
                                                {/* <td>
                                                    <div className='flex gap-1 items-center'>
                                                        <Avatar className='rounded-full' />
                                                        <span>{""}</span>
                                                    </div>
                                                </td>
                                                <td>{""}</td>
                                                <td>{""}</td> */}
                                                <td>{each.category_Name}</td>
                                                <td>
                                                    <p>{each.grievance_Query?.length > 25 ? each.grievance_Query.substring(0, 25) + "..." : each.grievance_Query}</p>
                                                </td>
                                                <td>
                                                <Badge color="green"> {each.ticket_Status}</Badge>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </ETable>
                                :
                                <EEmpty
                                    title="No Ticket Found"
                                    description="There are no open tickets available."
                                />
                    }
                </Tabs.Panel>

                <Tabs.Panel value="closed" pt="xs">
                    {
                        isLoading
                            ?
                            <Skeleton
                                radius="md"
                                height={250}
                            />
                            :
                            grievanceTicketList.filter((e)=>e?.ticket_Status == 'Closed').length > 0 
                                ?
                                <ETable>
                                    <thead >
                                        <tr>
                                            <th>Date</th>
                                            <th>Ticket #</th>
                                            <th>Category</th>
                                            {/* <th>User</th>
                                            <th>Phone</th> */}
                                            <th>Remarks</th>
                                            <th>Closed on</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {}
                                        {grievanceTicketList.filter((e)=>e?.ticket_Status == 'Closed').map((each, index) =>
                                            <tr
                                                key={index + "ticket"}
                                                onClick={() => {
                                                    setIsOpenDrawer(true)
                                                    setSelectedTicket(each)
                                                }}>
                                                <td>{moment(each.date_Of_Issue).format("DD-MM-YYYY")}</td>
                                                <td className='text-blue-600 ' >{each.ticket_Number}</td>
                                                <td>{each.category_Name}</td>
                                                {/* <td>
                                                    <div className='flex gap-1 items-center'>
                                                        <Avatar className='rounded-full' />
                                                        <span>{""}</span>
                                                    </div>
                                                </td>
                                                <td>{""}</td> */}
                                                <td>
                                                    <p>{each.grievance_Query?.length > 25 ? each.grievance_Query.substring(0, 25) + "..." : each.grievance_Query}</p>
                                                </td>
                                                <td>{moment(each?.date_Of_Solved).format("DD-MM-YYYY")}</td>
                                                <td>
                                                    <Badge color="green"> {each.ticket_Status}</Badge>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </ETable>
                                :
                                <EEmpty
                                    title="No Ticket Found"
                                    description="There are no closed tickets available."
                                />
                    }
                </Tabs.Panel>
            </Tabs>

            <Modal
                closeOnClickOutside={false}
                opened={isOpenNewTicketModal}
                size='lg'
                onClose={() => setIsOpenNewTicketModal(false)}
                title="New Ticket"
            >
                <div className='flex flex-col gap-3'>
                    {/* { value: 'Svelte', label: 'Svelte' }, */}
                    <Select
                        onChange={(e: any) => setNewTicket({ ...newTicket, grievance_Category_Id: +e })}
                        data={grievanceCategoryList?.map((e) => ({ value: String(e?.grievance_Category_Id), label: e?.category_Name }))}
                        label='Grievance Category' size="md" />
                    <InputBase
                        label="Your phone"
                        error={inputError}
                        maxLength={10}
                        icon={<div className='flex text-dark'> <AiOutlinePlus size={14} className='-mr-1' /><RiNumber9 className='-mr-1' size={15} /><RiNumber1 size={15} /> </div>}
                        aria-valuemax={10}
                        value={newTicket?.contact_Number}
                        onChange={(e: any) => {
                            setInputError('')
                            setNewTicket({ ...newTicket, contact_Number: e?.target.value })
                        }} size='md'
                    />

                    <Textarea label="Grievance Query"
                        value={newTicket?.grievance_Query}
                        onChange={(e) => setNewTicket({ ...newTicket, grievance_Query: e?.target?.value })}
                        size="md"
                        minRows={3}
                        maxRows={4}
                    />
                    <div className='flex justify-end'>
                        <EButton
                            loading={isLoading}
                            className="bg-blue-600 "
                            disabled={newTicket?.contact_Number && newTicket?.grievance_Category_Id && newTicket?.grievance_Query ? false : true}
                            onClick={() => handleSubmitQuery()}>Submit Query</EButton>
                    </div>
                </div>
            </Modal>

            <EDrawer
                title='Ticket Details'
                opened={isOpenDrawer}
                onClose={() => setIsOpenDrawer(false)}
                drawerContent={
                    <TicketDrawer
                        isLoading={false}
                        readOnly
                        ticket={selectedTicket}
                    />
                }
                position='right'
            />

        </PageLayout>
    )
}
