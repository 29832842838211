import React, { useEffect } from 'react';
import { GiCancel } from 'react-icons/gi';
import { Radio, Tooltip, Textarea, Image } from '@mantine/core';

import { BASE64_INITIALS } from '../../../../initialStates';
import { IQuestionOption, IQuestionFilters } from '../../../../types';

interface IProps {
  optionList: IQuestionOption[];
  questionFilters: IQuestionFilters;
  handleSetCorrectOption: (id: number) => void;
  handleSetCorrectOptionMultiple: (id: number) => void;
  handleRemoveSpecificOption: (id: number) => void;
  handleRemoveCurrentOption: () => void;
};

export default function AddedOptionList({
    optionList,
    questionFilters,
    handleSetCorrectOption,
    handleSetCorrectOptionMultiple,
    handleRemoveSpecificOption,
    handleRemoveCurrentOption
}: IProps) {
    let filter =questionFilters
    useEffect(()=>{

    },[filter])
    return (
        <>
            { 
           
                optionList.map((each, index) => <div
                    key={index + "option"}
                    className="input-item"
                >
                    <div className="radio-btn">
                        <Radio
                            size="md"
                            color="green"
                            checked={each.isCorrectOption}
                            value={String(each.id)}
                            onClick={() => {
                                questionFilters?.questionType == 1
                                    ?
                                    handleSetCorrectOption(Number(each?.id))
                                    :
                                    handleSetCorrectOptionMultiple(Number(each?.id))
                            }}
                        />
                    </div>

                    <div className="action-icons">
                        <Tooltip
                            withArrow
                            color="red"
                            label="Remove this option"
                        >
                            <a
                                className="cursor-pointer"
                                onClick={() => handleRemoveCurrentOption()}
                            >
                                <GiCancel
                                    size={20}
                                    className="cursor-pointer"
                                    onClick={() =>
                                        handleRemoveSpecificOption(Number(each?.id))
                                    }
                                />
                            </a>
                        </Tooltip>
                    </div>

                    {each.file
                        // &&
                        // !each.text
                        ?
                        <Image
                            radius="md"
                            src={`${BASE64_INITIALS}${each.file}`}
                            alt="Option Image"
                            className="mr-2"
                            onClick={() => console.log(each.url)}
                        />
                        :
                        <></>}

                    {each.text
                        // &&
                        // !each.file
                        ?
                        <Textarea
                            size="lg"
                            variant="unstyled"
                            className="w-full"
                            
                            placeholder="Enter option here..."
                            value={each.text || ""}
                        />
                        :
                        <></>}
                </div>)
            }
        </>
    )
}
