import { Space } from "@mantine/core";
import React from "react";

import "./App.scss";

interface IProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export default function PageLayout({ children, footer }: IProps) {
  return (
    <div className="page-layout">
      <div className="layout-body">{children}</div>
      <Space h={20} />
      {footer ? <div className="footer border--top">{footer}</div> : <></>}
    </div>
  );
}
