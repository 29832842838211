import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTargetDate } from '../../../libs/data-access';
import { showToast } from '../../../utils/utility';
import { IEditTarget, IMasterData, ITargetList } from '../../../libs/data-access/types';
import useMasterData from '../../Hooks/useMasterData';
import { INewTarget } from '../../../types';
import { AppContext } from '../../../AppContext';
import { handleValidNewTarget } from '../../../utils/validators';

const defaultSelectedTarget ={
  monthly_Target_Id: 0,
  target_Title: "",
  role_Id: 0,
  designation_Id: 0,
  role_Name: "",
  designation: "",
  target_Unique_School_Visit: 0,
  target_Classroom_Observation: 0,
  target_Spot_Assessment: 0,
  target_Month: "",
  target_Year: "",
}
export default function useTargetIndex() {

  const {
    fetchTargetData,
    postTargetData,
    PutTargetData
  }=useTargetDate()

  const navigate = useNavigate();

  const {
    user
   } = useContext(AppContext);

  const {
    allRole,
    getAllRoles,
    designationByRoleId,
    getDesignationByRoleId
 } = useMasterData()

  const [isFirst, setIsFirst] = useState<boolean>(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false)
  const [selectedMonth, setSelectedMonth] = useState<string>("")
  const [targetList, setTargetList] = useState<ITargetList[]>([])
  const [selectedTarget, setSelectedTarget] = useState<ITargetList>(defaultSelectedTarget)
  const  currentMonth = Number(moment().format("MM"))
  const [selectedRole, setSelectedRole] = useState<number | undefined>( undefined)
  const [selectedDesignation, setSelectedDesignation] = useState<number | undefined>( undefined)
  const [uniqueSchoolVisitTarget, setUniqueSchoolVisitTarget] = useState<number | undefined>( undefined)
  const [classroomObservationTarget, setClassroomObservationTarget] = useState<number | undefined>( undefined)

  useEffect(()=>{
      if(isFirst)
      {
        setIsFirst(false)
      }
      else{
        getAllRoles()
      }
  },[isFirst])

  useEffect(()=>{
  if(selectedRole){
    getDesignationByRoleId(selectedRole)
  }{
    setSelectedDesignation(undefined)
  }
  },[selectedRole])

  // Handler function 

  const HandleNewTarget = () => {
    
    const  payload : INewTarget = {
      target_Title: '',
      role_Id: selectedRole ?? 0,
      designation_Id: selectedDesignation ?? 0,
      target_Unique_School_Visit: uniqueSchoolVisitTarget ?? 0,
      target_Classroom_Observation: classroomObservationTarget ?? 0,
      target_Spot_Assessment: classroomObservationTarget ? classroomObservationTarget * 5  : 0,
      state_Admin_User_Id: user?.user_Id,
      getMonthYear: `${`${new Date().getFullYear()}-${selectedMonth}-01`}`
    }

    const valid = handleValidNewTarget(payload)
    if(valid){
      postNewTarget(payload)
    }
  }

  const handleUpdateTarget =()=>{
    let payload:IEditTarget ={
      monthly_Target_Id: selectedTarget?.monthly_Target_Id,
      state_Admin_User_Id: user?.user_Id,
      target_Title: selectedTarget?.target_Title,
      target_Unique_School_Visit: selectedTarget?.target_Unique_School_Visit,
      target_Classroom_Observation: selectedTarget?.target_Classroom_Observation,
      target_Spot_Assessment: (selectedTarget?.target_Classroom_Observation * 5)
    } 
     updateTarget(payload)
  }

  const getTargetList = async()=>{
     setIsLoading(true)
     const res =await fetchTargetData(`GetMonthlyMentorsTarget/true/${selectedRole}`)
     if(res.isSuccess){
       setTargetList(res?.data)
     }
     else{
      setTargetList([])
      showToast({
        type:"error",
        message : res?.message || "Fail to get target list !"
      })
     }
     setIsLoading(false)
  }

  const postNewTarget = async(payload:INewTarget)=>{
    setIsLoadingBtn(true)
    const res = await postTargetData(`AddMonthlyMentorsTarget`,payload)
    if(res.isSuccess){
      showToast({
        type:"success",
        message: "successfully added new target"
      })
      navigate('/target')
    }
    else{
      showToast({
       type:"error",
       title:"Try again",
       message : res?.message || "Could not upload new target"
      })
    }
    setIsLoadingBtn(false)
  }

  const updateTarget =async(payload:IEditTarget)=>{
     setIsLoadingBtn(true)
     const res = await PutTargetData(`UpdateMonthlyMentorsTarget`,payload)
     if(res.isSuccess){
      showToast({
        type:"success",
        message:"Target updated successfully"
      })
      setIsOpen(false)
      setSelectedTarget(defaultSelectedTarget)
      getTargetList()
     }
     else{
      showToast({
        type:"error",
        message: res?.message ?? "Fail to update target details"
      })
     }
     setIsLoadingBtn(false)

  }
  
  return {
    isLoading,
    isLoadingBtn,
    currentMonth,
    selectedMonth,
    setSelectedMonth,
    HandleNewTarget,
    targetList,
    selectedTarget,
    setSelectedTarget,
    allRole,
    selectedRole,
    setSelectedRole,
    designationByRoleId,
    selectedDesignation,
    setSelectedDesignation,
    uniqueSchoolVisitTarget,
    setUniqueSchoolVisitTarget,
    classroomObservationTarget,
    setClassroomObservationTarget,
    isOpen,
    setIsOpen,
    defaultSelectedTarget,
    handleUpdateTarget,
    getTargetList
  }
}
