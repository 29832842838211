import React from "react";
import { EButton, ESectionDivider } from "../../../../libs/ui";
import HaryanaMap from "../../../../libs/maps/HaryanaMap";
import DistrictWithMap from "../../../../libs/maps/DistrictWithMap";
import useClassroomObservation from "./hook/useClassroomObservation";
import { Grid, Skeleton } from "@mantine/core";
import ColorBaseMap from "../../../../libs/maps/ColourBaseMap";
import { ECard } from "../../../../libs/ui/src/Card/ECard";

export default function ClassroomObservation() {
  const {
    isLoadingClassroom,
    isLoadingTeacherObservationCoverage,
    isLoadingMentorObservedCompliance,
    classroomObservation,
    teacherObservationCoverage,
    mentorObservedCompliance,
    selectedDistrict,
    selectedBlock,
    reportLevel,
    districts,
  } = useClassroomObservation();


  return (
    <ECard title="Classroom Observation" header={<></>}>
      <div>
        {/* <Grid>
          <Grid.Col span={4}>
            <div className="head flex items-center flex-row">
              <h4 className="flex-1">Coverage</h4>
              <EButton variant="subtle">View</EButton>
              <EButton variant="default">Download</EButton>
            </div>   
          </Grid.Col>
        </Grid> */}

        <Grid>
          <Grid.Col span={4} className="border-r">
            {isLoadingClassroom ? (
              <Skeleton height={440} radius="md" />
            ) : (
              <ColorBaseMap
                mapData={
                  classroomObservation
                    ? classroomObservation?.Map?.map((e) => ({
                        name: e?.Name,
                        value: e?.Percentage_of_classrooms_observed,
                      }))
                    : []
                }
                overAllStats={{
                  label: "Classroom Observation Coverage",
                  value:
                    classroomObservation?.OverAll
                      ?.Percentage_of_classrooms_observed ?? 0,
                  overAll: classroomObservation?.OverAll,
                  toolTipLabel: "% of targeted classroom observations completed by the mentors"
                }}
                selectedDistrict={
                 reportLevel?.districtName || "" 
                }
                reportLevel={reportLevel}
                uniqueId={"classroom"}
                downloadableData={classroomObservation}
              />
            )}
          </Grid.Col>
          <Grid.Col span={4} className="border-r">
            {isLoadingTeacherObservationCoverage ? (
              <Skeleton height={440} radius="md" />
            ) : (
              <ColorBaseMap
                mapData={
                  teacherObservationCoverage
                    ? teacherObservationCoverage?.Map?.map((e) => ({
                        name: e?.Name,
                        value: e?.Percentage_teachers_observed,
                      }))
                    : []
                }
                overAllStats={{
                  label: "Teacher Observation Coverage",
                  value:
                    teacherObservationCoverage?.OverAll
                      ?.Percentage_teachers_observed ?? 0,
                  overAll: teacherObservationCoverage?.OverAll,
                  toolTipLabel: "% of teachers observed at least once by the mentors"
                }}
                selectedDistrict={
                   reportLevel?.districtName ||""
                }
                reportLevel={reportLevel}
                uniqueId={"teacherObservation"}
                downloadableData={teacherObservationCoverage}
              />
            )}
          </Grid.Col>
          <Grid.Col span={4}>
            {isLoadingMentorObservedCompliance ? (
              <Skeleton height={440} radius="md" />
            ) : (
              <ColorBaseMap
                mapData={
                  mentorObservedCompliance
                    ? mentorObservedCompliance?.Map?.map((e) => ({
                        name: e?.Name,
                        value: e?.Percentage_mentor_completing_target,
                      }))
                    : []
                }
                // downloadableData={mentorObservedCompliance}
                overAllStats={{
                  label: "Mentor Classroom Observation Compliance",
                  value:
                    mentorObservedCompliance?.OverAll
                      ?.Percentage_mentor_completing_target ?? 0,
                  overAll: mentorObservedCompliance?.OverAll,
                  toolTipLabel: "% of mentors completing their classroom observation targets"
                }}
                selectedDistrict={
                  reportLevel?.districtName ?? ""
                }
                reportLevel={reportLevel}
                uniqueId={"mentorObservation"}
                downloadableData={mentorObservedCompliance}
              />
            )}
          </Grid.Col>
        </Grid>
      </div>
    </ECard>
  );
}
