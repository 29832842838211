import { useEffect, useState, useContext, useRef, RefObject } from "react";

import { showToast } from "../../../utils/utility";
import { AppContext } from './../../../AppContext';

import useMasterData from "../../Hooks/useMasterData";
import { useStateAdmin } from '../../../libs/data-access';
import { handleValidateQuestionParams } from '../../../utils/validators';
import { questionFiltersParams, questionInfo } from "../../../initialStates";
import { IQuestionDetails, IQuestionFilters, IQuestionOption, IQuestionUploadPayload, TQuestionFilters } from "../../../types";
import { Console } from "console";
import { Value } from "sass";
import { IMediaType } from "../../../libs/data-access/types";


export function useUploadQuestionForm() {
    const scrollRef = useRef<HTMLDivElement>(null)
    const MEDIA_TYPE_QUESTION = 0;
    const MEDIA_TYPE_OPTION = 1;
    const {
        user,
        targetRef,
        scrollTriger,
        setScrollTriger,
    } = useContext(AppContext);
    const {
        assessmentTypes,
        subjectList,
        getSubject,
        classes,
        getClasses,
        competencyList,
        getCompetency,
        getCompetencyBasedOnClassAndSubject,
        competencyByClassSubject,
        questionType,
        GetQuestionType,
        spotAssessmentData,
        getWeeksData,
        getAssessmentTypes
    } = useMasterData();
    const {
        postStateAdmin
    } = useStateAdmin();

    const {
        fetchStateAdmin
    } = useStateAdmin();

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);


    const [questionFilters, setQuestionFilters] = useState<any>(questionFiltersParams);
    const [questionList, setQuestionList] = useState<{
        question: IQuestionDetails;
        options: IQuestionOption[];
    }[]>([]);

    const [questionDetails, setQuestionDetails] = useState<IQuestionDetails>(questionInfo);
    const [optionDetails, setOptionDetails] = useState<IQuestionOption>({
        text: "",
        url: undefined,
        typeOfURL: undefined,
        id: undefined,
        isCorrectOption: false
    });

    console.log("questionDetails",questionDetails)
    console.log("questionFiltersInMain",questionFilters)
    console.log("optionDetails",optionDetails)

    const [optionList, setOptionList] = useState<IQuestionOption[]>([]);

    const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
    const [isAddingNewOption, setIsAddingNewOption] = useState<boolean>(false);

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            getAssessmentTypes();
        }
    }, [isFirstLoad]);

    useEffect(() => {
        setQuestionDetails((prev) => ({ ...prev, url: undefined, typeOfURL: undefined, file: undefined }))

    }, [questionFilters?.media_Type_Id])

    useEffect(() => {
        setOptionDetails((prev) => ({ ...prev, text: "", url: undefined, typeOfURL: undefined, id: undefined, isCorrectOption: false, file: undefined }))

    }, [questionFilters?.option_Media_Type_Id])
    /* 
    * HANDLER FUNCTIONS
    */
    // for question management
    const handleChangeQuestionDetails = (
        key: keyof IQuestionDetails,
        value: string | File | undefined
    ) => {
        setQuestionDetails(prev => ({ ...prev, [key]: value }));
    };

    const handleAddAnotherQuestion = () => {
        setQuestionList(prev => ([
            ...prev,
            {
                question: questionDetails,
                options: optionList
            }]
        ));

        setQuestionDetails(questionInfo);
        setOptionDetails({
            text: "",
            url: undefined,
            typeOfURL: undefined,
            id: undefined,
            isCorrectOption: false
        });
        setOptionList([]);
    };

    const resetStatesForQuestion = () => {
        setQuestionDetails({
            text: "",
            url: undefined,
            mark: 0,
            enlargedText:"",
            typeOfURL: undefined
        });
        setOptionDetails({
            text: "",
            url: undefined,
            typeOfURL: undefined,
            id: undefined,
            isCorrectOption: false
        });
        setOptionList([]);
    };

    const handleRemoveSelectedQuestion = (index: number) => {
        let availableQuestionList = JSON.parse(JSON.stringify(questionList));
        availableQuestionList.splice(index, 1);
        setQuestionList(availableQuestionList);
    };

    // for options management
    const handleChangeOptionDetails = (key: keyof IQuestionOption, value: string | File | undefined) => {
        setOptionDetails(prev => ({ ...prev, [key]: value }));
    };

    const handleAddNewOption = (option: IQuestionOption) => {
        if (
            questionFilters.option_Media_Type_Id == 1
            &&
            !option.text
        ) {
            showToast({
                type: "warning",
                message: "Please provide option text"
            });
            return;
        } else if (
            questionFilters.option_Media_Type_Id != 1
            &&
            !option.file
        ) {
            showToast({
                type: "warning",
                message: "Please provide an Image for this option"
            });
            return;
        }

        if (optionList.findIndex(q => q.id === option.id) === -1) {
            // adding new option to option lost
            setOptionList(prev => ([...prev, { ...option, id: optionList.length + 1 }]));
        } else {
            // editing existing (checked based on id) option within option list
            const updatedOptionList: IQuestionOption[] = optionList.map(each =>
                each.id === option.id
                    ?
                    Object.assign({
                        ...option,
                        text: option.text,
                        url: option.url,
                        typeOfURL: option.typeOfURL
                    })
                    :
                    each);
            setOptionList(updatedOptionList);
        }

        setOptionDetails({
            text: "",
            url: undefined,
            typeOfURL: undefined,
            id: undefined,
            isCorrectOption: false
        });
        setIsAddingNewOption(false);
    };

    const handleRemoveCurrentOption = () => {
        setOptionDetails({
            id: undefined,
            text: "",
            url: undefined,
            typeOfURL: undefined,
            isCorrectOption: false,
        });
        // setIsAddingNewOption(false);
    };

    const handleRemoveSpecificOption = (id: number) => {
        setOptionList(optionList?.filter(each => each?.id !== id));
    };

    const handleSetCorrectOption = (id: number) => {
        const updatedOptions = optionList.map(q =>
            q.id === id
                ?
                Object.assign({ ...q, isCorrectOption: true })
                :
                Object.assign({ ...q, isCorrectOption: false })
        );
        setOptionList(updatedOptions);
    };

    const handleSetCorrectOptionMultiple = (id: number) => {
        const updatedOptions: IQuestionOption[] = optionList.map(option =>
            option.id === id
                ?
                Object.assign({ ...option, isCorrectOption: !option?.isCorrectOption })
                :
                Object.assign({ ...option })
        );
        setOptionList(updatedOptions);
    };



    /* API CALLS */
    const handleSaveQuestion = async () => {

        if (!user.user_Id) {
            showToast({
                type: "warning",
                title: "Please login again",
                message: "User could not be found"
            });
            return;
        };

        setIsLoading(true);
        const payload: IQuestionUploadPayload = {
            base64QuestionImage: questionDetails.file ?? null,
            question_Instruction: "", //* no need of validation for now
            question_Text: `${questionDetails.text ? `${questionDetails?.text}${questionDetails?.enlargedText?`\n${questionDetails?.enlargedText}`:``}`:``}`,
            question_Media_Url: questionDetails.url ?? "",
            created_By: user.user_Id,
            is_Draggable: false,
            media_Type_Id: questionFilters.media_Type_Id ? Number(questionFilters.media_Type_Id) : 0,
            option_Media_Type_Id: questionFilters.option_Media_Type_Id ? Number(questionFilters.option_Media_Type_Id) : 0,
            assessment_Type_Id: questionFilters.typeOfQuestionBank ? Number(questionFilters.typeOfQuestionBank) : 0,
            question_Type_Id: questionFilters.questionType ? Number(questionFilters.questionType) : 0,
            competancy_Id: questionFilters.selectedCompetencyLevel ? Number(questionFilters.selectedCompetencyLevel) : 0,
            class_Id: questionFilters.selectedGrade ? Number(questionFilters.selectedGrade) : 0,
            subject_Id: questionFilters.selectedSubject ? Number(questionFilters.selectedSubject) : 0,
            week_Id: questionFilters.week ? Number(questionFilters.week) : 55,
            marks :questionDetails?.mark ? +questionDetails?.mark : 1,
            base64ImageQuestionOption: optionList.map(option => ({
                base64OptionImage: option.file ?? null,
                option_Text: option.text,
                is_Correct: option.isCorrectOption
            }))
        };

        const isValidated = handleValidateQuestionParams(payload, questionFilters);

        // console.group("Question");
        // console.log("isValidated: ", isValidated);
        // // console.log("Filters: ", questionFilters?.questionType);
        // // console.log("Question: ", questionDetails);
        // // console.log("Options: ", optionList);
        // console.log("Payload: ", payload);
        // console.groupEnd();

        if (isValidated) {
            // console.log("your Question is  Ready to post with all validation")
            const response = await postStateAdmin(`Question/CreateQuestionToQuestionBankSave`, payload);
            if (response.isSuccess) {
                console.log("Response for save question to question bank: ", response.data);
                resetStatesForQuestion();
                setIsConfirmed(false)
                setQuestionDetails(questionInfo)
                setQuestionFilters({...questionFilters,media_Type_Id:null,option_Media_Type_Id:null,questionType:null})
                setOptionDetails({
                    text: "",
                    url: undefined,
                    typeOfURL: undefined,
                    id: undefined,
                    isCorrectOption: false
                })
                // window.location.reload()
                showToast({
                    type: "success",
                    message: "Question saved successfully"
                });

                console.log("before scroll")
                setScrollTriger(!scrollTriger)
                
                  
                
            } else {
                console.log("Error for save question to question bank: ", response.error);
                showToast({
                    type: "error",
                    message: response.message || "Could not save question!"
                });
                setScrollTriger(!scrollTriger)
            }
        }
        setIsLoading(false);

    };


    /////////////usefilter////////////


    const [mediaTypeForQuestion, setMediaTypeForQuestion] = useState<IMediaType[]>([]);
    const [mediaTypeForOptions, setMediaTypeForOptions] = useState<IMediaType[]>([]);

    console.log("question filter in useBankfilter",questionFilters)

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            getSubject();
            getClasses();
            GetQuestionType();
            getAssessmentTypes();
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_QUESTION);
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_OPTION);
        }
    }, [isFirstLoad]);

    useEffect(() => {
        if (String(questionFilters.typeOfQuestionBank) === "4") {
            getWeeksData();
        }
    }, [questionFilters.typeOfQuestionBank]);

    useEffect(() => {
        if (questionType.length) {
            const singleOptionID = questionType.find(each => String(each.id).trim() === "2")?.id;
            handleChangeQuestionFilterParams("questionType", singleOptionID ? String(singleOptionID) : undefined);
        }
    }, [questionType]);

    useEffect(() => {
        if (questionFilters.selectedSubject && questionFilters?.selectedGrade) {
            // getCompetency(
            //     questionFilters.selectedSubject,
            //     questionFilters.selectedSubject
            // );
            getCompetencyBasedOnClassAndSubject(
                questionFilters.selectedGrade,
                questionFilters.selectedSubject
            );
            handleChangeQuestionFilterParams("selectedCompetencyLevel", "");
        }
    }, [questionFilters.selectedSubject , questionFilters?.selectedGrade]);
    // useEffect(() => {
    //     if (questionFilters.selectedSubject) {
    //         getCompetency(
    //             questionFilters.selectedSubject,
    //             questionFilters.selectedSubject
    //         );
    //         handleChangeQuestionFilterParams("selectedCompetencyLevel", "");
    //         handleChangeQuestionFilterParams("selectedSubject", "");
    //     }
    // }, [questionFilters.selectedGrade]);


    // for question filter management
    const handleChangeQuestionFilterParams = (
        key: TQuestionFilters,
        value: string | undefined
    ) => {
        setQuestionFilters((prev:any) => ({ ...prev, [key]: value }));
    };

    const handleClearQuestionFilters = () => {
        setQuestionFilters({
            typeOfQuestionBank: null,
            assessmentSeq: 0,
            week: 0,
            selectedGrade: 0,
            selectedSubject: 0,
            selectedTopic: 0,
            selectedCompetencyLevel: 0,
            questionType: 0,
            media_Type_Id: undefined,
            option_Media_Type_Id: undefined
        });
    };

    /* API CALLS */
    const handleFetchMediaTypeForQuestion = async (mediaType: number) => {
        const response = await fetchStateAdmin(`Question/GetMediaTypeForQuestionOrOption/${mediaType}`);
        if (response.isSuccess) {
            // console.log("Response for media type: ", response.data);
            const data: IMediaType[] | undefined = response.data;
            if (data) {
                mediaType === 0
                    ?
                    setMediaTypeForQuestion(data)
                    :
                    setMediaTypeForOptions(data);
            }
        } else {
            showToast({
                type: "error",
                title: "Error Ocurred!",
                message: "Could not fetch media types"
            });
        }
    };

    return {
        isConfirmed,
        setIsConfirmed,
        setQuestionFilters,
        questionDetails,
        handleChangeQuestionDetails,
        isAddingNewOption,
        setIsAddingNewOption,
        optionDetails,
        handleChangeOptionDetails,
        handleAddNewOption,
        optionList,
        setOptionList,
        setOptionDetails,
        handleRemoveCurrentOption,
        handleRemoveSpecificOption,
        handleSetCorrectOption,
        questionList,
        handleAddAnotherQuestion,
        handleRemoveSelectedQuestion,
        isLoading,
        handleSaveQuestion,
        handleSetCorrectOptionMultiple,
        questionFilters,

        assessmentTypes,
        subjectList,
        classes,
        competencyList,
        competencyByClassSubject,
        questionType,
        spotAssessmentData,
        mediaTypeForQuestion,
        mediaTypeForOptions,
        handleChangeQuestionFilterParams,
        handleClearQuestionFilters,
        scrollRef
    }
}
