import React, { useContext, useEffect, useState } from 'react'
import { useStudentLearning } from '../../../../../libs/data-access';
import { PeriodicAssessmentFilterContext } from './usePeriodicAssessment';
import { showToast } from '../../../../../utils/utility';
import { handleValidPeriodicAssessement } from '../../../../../utils/validators';

export default function usePeriodicAnnualAssessment() {
 
    const {
        fetchPeriodicAssessment
      } = useStudentLearning()
    
      const {
        handleChangeFilters,
        handleIsSearching,
        isSearching,
        district,
        block,
        cluster,
        school,
        grade,
        subject,
        month,
        session,
        periodicAssessmentID
      } = useContext(PeriodicAssessmentFilterContext);
      const [periodicAnnualData, setPeriodicAnnualData] = useState<any[]>([]);
      const [csvData, setCsvData] = useState<any[]>([]);
      
      useEffect(()=>{
          if(isSearching){
            getPeriodicAnnualReportData()
          }
      },[isSearching])

      const getPeriodicAnnualReportData = async() =>{
        let payload = {
            session:session,
            periodicAssessmentID:periodicAssessmentID,
            grade:grade,
            subject:subject,
            district:district
        }
        const valid = handleValidPeriodicAssessement(payload)
        if(valid){
            const res = await fetchPeriodicAssessment(`GetPeriodicAssessmentResultReportCompetencyWiseByFilter/${session}/${periodicAssessmentID}/${grade}/${subject}/${district}?BlockId=${block ?? 0}&ClusterSchoolId=${cluster ?? 0}`)
            if(res.isSuccess){
              if (res?.data?.length > 0) {
  
                let mainData: any = []
    
                const csvFormattedData = res?.data?.map((item:any )=> {
                    let allData: any = []
                    let rowData = {};

    
                    if (item.percentage_Of_Students_Mastery_Hindi_Competency?.length>0) {
    
                        item?.percentage_Of_Students_Mastery_Hindi_Competency?.map((child: any) => {
                            rowData = {
                              ...rowData,
                              [item?.level_Name]: item.filter_By_Name,
                              "Total students enrolled":
                                item.total_Student_Enrolled,
                              "Total students tested (All Subjects)":
                                item.total_Student_Tested,
                              "Total students tested in Hindi":
                                item.total_Student_Tested_Hindi,
                              "Students achieving mastery in Literacy":
                                item.aggregate_Student_Mastery_All_Competency_Hindi,
                              Subject: "Hindi",
                              Competency: child?.subject_Competency,
                              Percentage:
                                child?.percentage_Students_Mastery_Hindi_Competency,
                            };
                            allData.push(rowData)
                        })
                    }
    
                    if (item.percentage_Of_Students_Mastery_English_Competency?.length>0) {
    
                        item?.percentage_Of_Students_Mastery_English_Competency?.map((child: any) => {
                            rowData = {
                              ...rowData,
                              [item?.level_Name]: item.filter_By_Name,
                              "Total students enrolled":
                                item.total_Student_Enrolled,
                              "Total students tested (All Subjects)":
                                item.total_Student_Tested,
                              "Total students tested in Hindi": null,
                              "Students achieving mastery in Literacy": null,
                              "Total students tested in English":
                                item.total_Student_Tested_English,
                              "Students achieving mastery in English":
                                item.aggregate_Student_Mastery_All_Competency_English,
                              Subject: "English",
                              Competency: child?.subject_Competency,
                              Percentage:
                                child?.percentage_Students_Mastery_English_Competency,
                            };
                            allData.push(rowData)
                        })
                    }
    
                    if (item.percentage_Of_Students_Mastery_Math_Competency?.length>0) {
    
                        item?.percentage_Of_Students_Mastery_Math_Competency?.map((child: any) => {
                            rowData = {
                              ...rowData,
                              [item?.level_Name]: item.filter_By_Name,
                              "Total students enrolled":
                                item.total_Student_Enrolled,
                              "Total students tested (All Subjects)":
                                item.total_Student_Tested,
                              "Total students tested in Hindi": null,
                              "Students achieving mastery in Literacy": null,
                              "Total students tested in English": null,
                              "Students achieving mastery in English": null,
                              "Total students tested in Math":
                                item.total_Student_Tested_Math,
                              "Students achieving mastery in Numeracy":
                                item.aggregate_Student_Mastery_All_Competency_Math,
                              Subject: "Maths",
                              Competency: child?.subject_Competency,
                              Percentage:
                                child?.percentage_Students_Mastery_Math_Competency,
                            };
                            allData.push(rowData)
                        })
                    }
    
    
                    mainData = [...mainData, ...allData]
                });
                setCsvData(mainData);
            }
               setPeriodicAnnualData(res?.data)
            }
            else{
              setPeriodicAnnualData([])
              showToast({
                  type:"error",
                  message : res?.message || "Fail to fetch periodic assessment report"
              })
            }

            
        }

        handleIsSearching(false) 
      }
  return {
    isSearching,
    periodicAnnualData,
    csvData
  }
}
