const colorHandler =(value :number) =>  {
    if (value <= 20) {
        return "#FF0000"
    }
    if (value > 20 && value <= 40) {
        return "#FFAC27"
    }
    if (value > 40 && value <= 60) {
        return "#FFFF1B"
    }
    if (value > 60 && value <= 80) {
        return "#7CFF77"
    }
    if (value > 80 && value <= 100) {
        return "#00EE33"
    }
    else {
        return "#ff3333"
    }
}
 const defaultColor = "#ff3333"
 const activeColor ="#00a67e"

export {colorHandler,defaultColor ,activeColor}
