import React from 'react'
import PageLayout from '../../../../PageLayout'
import { EButton, ELabelView, EModal, EPageTitle, ESectionDivider, EVerticalFormSection } from '../../../../libs/ui'
import useSlotQuestionList from '../hooks/useSlotQuestionList'
import { Alert, Badge, Card, Checkbox, Grid, Modal, Select, Skeleton, Space, TextInput, Textarea } from '@mantine/core'
import { BsInfoCircle, BsPlus } from 'react-icons/bs'
import InfoLabel from '../../../../libs/ui/src/InfoLabel/InfoLabel'
import { TiTick } from 'react-icons/ti'
import { useNavigate } from 'react-router-dom'
import { MdDelete, MdEdit, MdUpdate } from 'react-icons/md'
import { ISlotTestQuestionListQuestion } from '../../../../libs/data-access/types'

export default function SlotQuestionList() {

    const navigate = useNavigate()

    const {
        isLoadingBtn,
        isOpenModal,
        setIsOpenModal,
        slotList,
        selectedSlotHeader,
        setSelectedSlotHeader,
        questionType,
        selectedSlotTestId,
        setSelectedSlotTestId,
        handleSearchQuestionList,
        isLoadingSlotQuestionList,
        slotTestQuestionList,
        setSlotTestQuestionList,
        selectedSlotQuestion,
        setSelectedSlotQuestion,
        DefaultSLotQuestionOption,
        handleUpdateQuestionOption,
        deleteTestQuestionById,
        isOpenDeleteModal,
        setIsOpenDeleteModal
    } = useSlotQuestionList()

    return (
        <PageLayout
        >
            <EPageTitle title={'Slot Question List'}
                end={<EButton
                    onClick={() => navigate(`/state/training-slot/add-question`)}
                    className="btn-blue">
                    <BsPlus /> Add New Questions
                </EButton>} />

            {/* Filter */}
            <div className="max-w-5xl ">
                <EVerticalFormSection
                    title="Parameters / Filters"
                    subtitle="Please select below filters to questions to continue. These are required."
                >
                    <div className="filter-container">
                        <div className='flex justify-between items-end w-full'>
                            <div className='flex gap-3'>
                        <Select
                            required
                            // className="w-[33%]"
                            label="Slot Name"
                            placeholder="Select"
                            size="md"
                            data={slotList?.map(each => ({
                                value: String(each?.training_Schedule_Header_Id),
                                label: each?.schedule_Header_Title
                            })) ?? []}
                            value={String(selectedSlotHeader) || null}
                            onChange={(e) => {
                                setSelectedSlotHeader(e ? +e : 0)
                                setSelectedSlotTestId(0)
                                setSlotTestQuestionList([])
                            }}
                        />
                        <Select
                            required
                            label="Slot Test"
                            placeholder="Select"
                            size="md"
                            data={slotList?.filter(e => e?.training_Schedule_Header_Id === selectedSlotHeader)[0]?.tests?.map(each => ({
                                value: String(each?.schedule_Header_Test_Id),
                                label: each?.test_Name
                            })) ?? []}
                            value={String(selectedSlotTestId) || null}
                            onChange={(e) => {
                                setSelectedSlotTestId(e ? +e : 0)
                                setSlotTestQuestionList([])
                            }}
                        />
                        </div>
                        <div>
                            <EButton
                                disabled={!selectedSlotTestId ? true : false}
                                loading={isLoadingSlotQuestionList}
                                onClick={() => handleSearchQuestionList()}
                                className="btn-blue">
                                Search
                            </EButton>
                        </div>
                        </div>
                    </div>
                    {slotTestQuestionList?.length ?
                        <></>
                        :
                        <>
                            <Space h={34} />
                            <Alert
                                icon={<BsInfoCircle />}
                                title="Missing Slot Details!"
                                color="orange"
                            >
                                You need to select Slot and search question and option list
                            </Alert>
                        </>
                    }
                </EVerticalFormSection>
            </div>

            {/* Slot Question List */}
            {
                isLoadingSlotQuestionList ?
                    <Skeleton height={180} radius="md" />
                    :
                    <div className='flex flex-col gap-8'>
                        {slotTestQuestionList?.map(each =>
                            <div className='bg-slate-50 p-4'>
                                <ESectionDivider classes='pt-0' title="Slot Test Question Group" />
                                <InfoLabel
                                    textSize="lg"
                                    label={'Group Name'}
                                    value={each?.question_Group_Name}
                                />
                                {
                                    each?.questions.map((question, index) =>
                                        <Card shadow='sm' className='p-4 mt-4'>
                                            <div className='flex justify-between'>
                                                <ELabelView
                                                    label={`Question ${index + 1} `}
                                                    value={question?.question_Text}
                                                />
                                                <Badge
                                                    color='blue'>
                                                    {question?.question_Type}
                                                </Badge>
                                            </div>
                                            <div
                                                className='flex flex-col gap-2 mt-4'>
                                                {
                                                    question?.questionsOptions.map((option, index) =>
                                                        <div className={option?.is_Correct ? `text-green-500 flex gap-5` : ``}>
                                                            <span>{String.fromCharCode(65 + index) + "."} {option?.option_Text} </span>
                                                            <span> {option?.is_Correct ? <><TiTick size={25} /></> : <></>}</span>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className='flex justify-end gap-3'>
                                                <EButton
                                                    variant="outline"
                                                    color="red"
                                                    loading={isLoadingBtn}
                                                    onClick={() => {
                                                        setSelectedSlotQuestion(question)
                                                        setIsOpenDeleteModal(true)
                                                    }}
                                                    rightIcon={<MdDelete />}
                                                >Delete
                                                </EButton>
                                                {/* TODO : edit api needed and all functionality is done at code level */}
                                                {/* <EButton
                                                    variant="outline"
                                                    color="green"
                                                    rightIcon={<MdEdit />}
                                                    onClick={() => {
                                                        setSelectedSlotQuestion(question)
                                                        setIsOpenModal(true)
                                                    }}
                                                >Edit</EButton> */}
                                            </div>
                                        </Card>)
                                }
                            </div>)}
                    </div>}
            {/* Delete confirmation modal */}
            <EModal
                isOpen={isOpenDeleteModal}
                setIsOpen={() => setIsOpenDeleteModal(false)}
                title='Are you shore you want to delete question ? '
                children={
                    <div className='flex justify-end gap-3'>
                        <EButton
                            className="btn-light"
                            onClick={() => setIsOpenDeleteModal(false)}
                        >Cancel</EButton>
                        <EButton
                            className="btn-red"
                            onClick={() => deleteTestQuestionById(selectedSlotQuestion?.question_Id)}
                        >Delete</EButton>
                    </div>
                } />

            {/* Edit Question Modal */}

            <Modal
                title="Edit Question"
                size="lg"
                opened={isOpenModal}
                onClose={() => {
                    setIsOpenModal(false)
                    setSelectedSlotQuestion(DefaultSLotQuestionOption)
                }}
            >
                <Textarea
                    label="Question text"
                    value={selectedSlotQuestion?.question_Text}
                    onChange={(e) => setSelectedSlotQuestion({ ...selectedSlotQuestion, question_Text: e?.target?.value })}
                />
                <Select
                    label="Question Type"
                    value={selectedSlotQuestion?.question_Type}
                    data={["Single Answer", "Multiple Choice Type"]}
                    onChange={(e) => setSelectedSlotQuestion({ ...selectedSlotQuestion, question_Type: e ? e : "" })}
                />

                <p className='mt-4 text-slate-300'>Options</p>
                {selectedSlotQuestion?.questionsOptions.map((each, index) =>
                    <Grid className='items-end'>
                        <Grid.Col span={11}>
                            <TextInput
                                label={`Option ${String.fromCharCode(65 + index) + "."} `}
                                value={each?.option_Text}
                                onChange={(value => {
                                    let arr = selectedSlotQuestion?.questionsOptions
                                    arr[index].option_Text = value?.target?.value
                                    setSelectedSlotQuestion({ ...selectedSlotQuestion, questionsOptions: arr })
                                })}
                            />
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Checkbox
                                checked={each?.is_Correct}
                                onChange={(e) => {
                                    let arr = selectedSlotQuestion?.questionsOptions
                                    arr[index].is_Correct = !arr[index].is_Correct
                                    setSelectedSlotQuestion({ ...selectedSlotQuestion, questionsOptions: arr })
                                }}
                            />
                        </Grid.Col>
                    </Grid>
                )}
                {/*footer */}
                <div className='flex justify-end gap-3 mt-4'>
                    <EButton
                        className="btn-light"
                        onClick={() => {
                            setIsOpenModal(false)
                            setSelectedSlotQuestion(DefaultSLotQuestionOption)
                        }}
                    > Cancel
                    </EButton>
                    <EButton
                        className="btn-green"
                        rightIcon={<MdUpdate />}
                        onClick={() => handleUpdateQuestionOption()}
                    >Update</EButton>
                </div>
            </Modal>
        </PageLayout>
    )
}
