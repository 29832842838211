import React, { useContext, useEffect, useState } from 'react'
import { ITeacherAllocationPayload, ITrainingSlotList } from '../../../../../../libs/data-access/types';
import { INewSchedule } from '../../../../../../types';
import { TransferListData, TransferListItem } from '@mantine/core';
import { useTeacherTraining } from '../../../../../../libs/data-access';
import { AppContext } from '../../../../../../AppContext';
import { handleValidNewSchedule } from '../../../../../../utils/validators';
import { showToast } from '../../../../../../utils/utility';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const DefaultNewSchedule = {
  training_Schedule_Header_Id: 0,
  block_Admin_User_Id: 0,
  block_Id: 0,
  training_Title: "",
  training_Start_Date: "",
  training_End_Date: "",
  training_Place: "",
  training_Start_Time: "",
  training_End_Time: "",
  training_Description: "",
  teacherTest: [
    {
      schedule_Header_Test_Id: 0
    }
  ]
}
export default function useCreateNewSchedule(slotID: string) {

  const navigate = useNavigate();

  const { user } = useContext(AppContext);

  const {
    fetchTeacherTrainingData,
    PostTeacherTrainingData
  } = useTeacherTraining();



  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [teacherList, setTeacherList] = useState<TransferListData>([[], []]);
  const [selectedTeacher, setSelectedTeacher] = useState<any[]>([]);
  const [newSchedule, setNewSchedule] = useState<INewSchedule>(DefaultNewSchedule)
  // const [createdScheduleId, setCreatedScheduleId] = useState()
  const [selectedSlotTest, setSelectedSlotTest] = useState<string[]>([])
  const [selectedPreTest, setSelectedPreTest] = useState<{ schedule_Header_Test_Id: number }[]>([])
  const [selectedPostTest, setSelectedPostTest] = useState<{ schedule_Header_Test_Id: number }[]>([])
  const [trainingSlotList, setTrainingSlotList] = useState<ITrainingSlotList[]>([])


  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
    } else {
      getTrainingSlotList()
      getTeacherListByBlock()
    }
  }, [isFirst]);

  useEffect(() => {
    let testList = selectedSlotTest?.map((each) => (
      trainingSlotList[0]?.tests.filter((e) => e?.subject_Name === each).map((item) => ({
        schedule_Header_Test_Id: item?.schedule_Header_Test_Id
      }))
    )).flat()
    setNewSchedule(
      {
        ...newSchedule, teacherTest: testList,
        block_Admin_User_Id: user?.user_Id,
        training_Title:trainingSlotList[0]?.schedule_Header_Title,
        block_Id: user?.details?.blockUserDetails ? + user?.details?.blockUserDetails?.block_Id : -1
      })
  }, [selectedSlotTest]);

  // useEffect(()=>{
  //   setNewSchedule({
  // ...newSchedule, 
  // teacherTest : selectedPreTest.concat(selectedPostTest) ,
  //  block_Admin_User_Id: user?.user_Id, 
  //  block_Id: user?.details?.blockUserDetails ? + user?.details?.blockUserDetails?.block_Id : -1
  //       })

  // },[selectedPreTest ,selectedPostTest])

  // handler function

  const handelSubmit = () => {
    let valid = handleValidNewSchedule(newSchedule ,trainingSlotList)
    if (valid) {
      let payload :INewSchedule ={
        training_Schedule_Header_Id: newSchedule?.training_Schedule_Header_Id,
        block_Admin_User_Id: newSchedule?.block_Admin_User_Id,
        block_Id: newSchedule?.block_Id,
        training_Title: newSchedule?.training_Title,
        training_Start_Date: moment(newSchedule?.training_Start_Date).format("YYYY-MM-DD"),
        training_End_Date: moment(newSchedule?.training_End_Date).format("YYYY-MM-DD"),
        training_Place: newSchedule?.training_Place,
        training_Start_Time: newSchedule?.training_Start_Time,
        training_End_Time: newSchedule?.training_End_Time,
        training_Description: newSchedule?.training_Description,
        teacherTest: newSchedule?.teacherTest
      }
      !selectedTeacher.length ?
        showToast({
          type: "warning",
          message: "Please provide Teacher list for training"
        }) :
        postNewSchedule(payload)
    }
  };

  //  API function

  const getTrainingSlotList = async () => {
    setIsLoading(true);
    setTrainingSlotList([]);
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetailByScheduleHeaderId/${slotID}`);
    if (res?.isSuccess) {
      // console.log("Selected Slot Header: ", res?.data);
      setTrainingSlotList(res?.data);
      setNewSchedule({ ...newSchedule, training_Schedule_Header_Id: res?.data[0]?.training_Schedule_Header_Id });
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule"
      });
    };
    setIsLoading(false);
  };

  const getTeacherListByBlock = async () => {
    setIsLoading(true);
    // cut paste into success block   
    setTeacherList([[], []]);
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetBlockTeacherListForTraining/${user?.details?.blockUserDetails?.block_Id}`);
    if (res?.isSuccess) {
      let teachersArray = res?.data?.map((e: any) => ({
        value: `${e?.teacher_Id}`,
        label: `${e?.employee_Name} - ${e?.employee_Code}`,
        description: `${e?.designation}`
      }));
      setTeacherList([teachersArray, []]);
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting Teacher list"
      });
      console.log("Error while getting Teacher list", res?.message);
    }
  };

  const postNewSchedule = async (payload: INewSchedule) => {
    teacherAllocateToSchedule([]);
    setIsBtnLoading(true);
    const res = await PostTeacherTrainingData(`/BlockAdmin/CreateBlockTeachersTrainingSchedule`, payload);
    if (res?.isSuccess) {
      // setCreatedScheduleId(res?.data)
      teacherAllocateToSchedule(res?.data);
      //  setNewSchedule(DefaultNewSchedule);
      //    showToast({
      //     type: "success",
      //     message: "Schedule created Successfully"
      // });
      // navigate(`/block/schedule-list`)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while creating schedule"
      });
      setIsBtnLoading(false);
    }
  };

  const teacherAllocateToSchedule = async (ScheduleID: any) => {
    let Payload: ITeacherAllocationPayload = {
      teacher_Training_Schedule_Id: +ScheduleID,
      block_Admin_User_Id: user?.user_Id,
      teacherSelection:
        selectedTeacher?.map((each) => ({
          teacher_Id: +each
        }))
    };
    const res = await PostTeacherTrainingData(`/BlockAdmin/AddTeachersForScheduledTraining`, Payload);
    if (res?.isSuccess) {
      showToast({
        type: "success",
        message: "Schedule created Successfully"
      });
      navigate(`/block/schedule-list`);
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while posting schedule"
      });

      setIsBtnLoading(false);
    }
  };

  return {
    isLoading,
    isBtnLoading,
    handelSubmit,
    trainingSlotList,
    newSchedule,
    setNewSchedule,
    teacherList,
    setTeacherList,
    selectedTeacher,
    setSelectedTeacher,
    setSelectedPreTest,
    setSelectedPostTest,
    setSelectedSlotTest,
    selectedSlotTest,
    // teacherAllocateToSchedule
  };

}
