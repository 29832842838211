import React from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EInfoPanel, EPageTitle } from '../../libs/ui'
import { BsArrowLeft, BsBack, BsInfoCircle } from 'react-icons/bs'
import { FcLeft } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import useTargetIndex from './hooks/useTargetIndex'
import { Alert, Grid, Input, Select, TextInput } from '@mantine/core'

export default function CreateNewTarget() {

  const {
    isLoading,
    isLoadingBtn,
    currentMonth,
    selectedMonth,
    setSelectedMonth,
    HandleNewTarget,
    allRole,
    selectedRole,
    setSelectedRole,
    designationByRoleId,
    selectedDesignation,
    setSelectedDesignation,
    uniqueSchoolVisitTarget,
    setUniqueSchoolVisitTarget,
    classroomObservationTarget,
    setClassroomObservationTarget
  } = useTargetIndex()


  const navigate = useNavigate()
  return (
    <PageLayout>
      <EPageTitle
        title={'Create New Target'}
        end={
          <EButton
            className="btn-blue"
            onClick={() => navigate('/target')}
          >
            <BsArrowLeft className='mr-2' /> Back
          </EButton>} />

      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between items-end w-full">
          <div className="flex gap-5">
            <Select
              label="Select month"
              placeholder="Select month"
              data={
                [
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" }
                ].map(e => ({ label: e?.label, value: e?.value, disabled: currentMonth > +e?.value ? true : false }))
              }
              value={selectedMonth}
              onChange={e => setSelectedMonth(String(e))}
            />
            <Select
              label="Role"
              placeholder='Select Role'
              value={selectedRole ? String(selectedRole) : null}
              data={
                allRole.map(e=>({label:e?.name ,value: String(e?.id)}))}
                onChange={(e)=>
                  {
                   setSelectedRole(e? +e : undefined)
                   setSelectedDesignation(undefined)
                  }}
               />

            <Select
              label="Designation"
              placeholder='Select'
              data={
                designationByRoleId?.map(e=>({
                  label: e?.name , 
                  value :String(e?.id)
                }))
              }
              nothingFound="No any designation for this role"
              value={selectedDesignation ? String(selectedDesignation) : null}
              onChange={(e)=> e ? setSelectedDesignation(+e) : undefined} 
              />
          </div>
        </div>
      </div>

      {/* Miss attribute Alert */}
      { selectedDesignation ?
       <></>
        :
        <Alert
        icon={<BsInfoCircle />}
        title="Missing Slot Details!"
        color="orange"
        className='mt-4'
      >
        You need to select month, role and designation to create a new target
      </Alert>}


      <Grid className='mt-4'>
        <Grid.Col span={6}>
          <EInfoPanel
            text={"text-sm text-slate-500 font-light"}
            children={
              <TextInput
                label="Unique School visit"
                placeholder="Enter no. of target "
                size='md'
                type='number'
                value={ uniqueSchoolVisitTarget || undefined}
                onChange={e=>setUniqueSchoolVisitTarget( e ? +e?.target.value :  undefined)}
              />
            }
            title={'The target is to achieve a specific number of unique school visits, where each school is counted only once, regardless of how many times it has been visited.'}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <EInfoPanel
            text={"text-sm text-slate-500 font-light"}
            children={
              <TextInput
                label="Classroom Observation"
                placeholder="Enter no. of target "
                size='md'
                type='number'
                value={ classroomObservationTarget || undefined}
                onChange={e=>setClassroomObservationTarget(e ? +e?.target.value : undefined)}
              />
            }
            title={"Classroom observation involves a mentor observing a teacher's interaction with students and how they deliver instruction."}
          />
        </Grid.Col>
      </Grid>
      <div className='flex justify-end mt-4 p-2'>
        <EButton
          className="btn-green"
          loading={isLoadingBtn}
          disabled={ uniqueSchoolVisitTarget && classroomObservationTarget && selectedDesignation ? false : true}
          onClick={()=>HandleNewTarget()}
        >
          Submit Target
        </EButton>
      </div>
    </PageLayout>
  )
}
