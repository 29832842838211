import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { INewTrainingSlot, ITeacherTrainingSlotTestList, ITrainingSlotList } from '../../../../libs/data-access/types';
import { groupByKey, showToast } from '../../../../utils/utility';
import { useTeacherTraining } from '../../../../libs/data-access';
import { TransferListData } from '@mantine/core';
import { AppContext } from '../../../../AppContext';

const DefaultNewTraining = {
  state_User_Id: 0,
  schedule_Header_Title: "",
  start_Date: "",
  end_Date: "",
  description: "",
  no_Of_Days: 1,
  headerTest: [
    {
      teacher_Training_Test_Id: 0
    }
  ]
}

const DefaultSelectedSlot = {
  training_Schedule_Header_Id: 0,
  schedule_Header_Title: "",
  start_Date: "",
  end_Date: "",
  no_Of_Days: 0,
  training_Description: "",
  tests: []
}
export default function useTeacherTrainingSlot() {

  const {
    fetchTeacherTrainingData,
    PostTeacherTrainingData,
    PutTeacherTrainingData,
    DeleteTeacherTrainingData
  } = useTeacherTraining()

  const {
    user
  } = useContext(AppContext);

  // const navigate =useNavigate()

  const [isSlotModalOpen, setIsSlotModalOpen] = useState<boolean>(false);
  const [isSlotEditModalOpen, setIsSlotEditModalOpen] = useState<boolean>(false);
  const [isSlotDeleteModalOpen, setIsSlotDeleteModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoadingTeacherList, setIsLoadingTeacherList] = useState<boolean>(false);
  const [teacherTrainingSlotTestList, setTeacherTrainingSlotTestList] = useState<ITeacherTrainingSlotTestList[]>([]);

  const [trainingSlotList, setTrainingSlotList] = useState<ITrainingSlotList[]>([])
  const [selectedTrainingSlot, setSelectedTrainingSlot] = useState<ITrainingSlotList>(DefaultSelectedSlot)

  const [selectedSlotTest, setSelectedSlotTest] = useState<string[]>([])
  const [newTrainingSlot, setNewTrainingSlot] = useState<INewTrainingSlot>(DefaultNewTraining)
  const [EditSlotTestForAddTest, setEditSlotTestForAddTest] = useState<{ teacher_Training_Test_Id: number; }[]>([])
  const [EditSlotTestForDeleteTest, setEditSlotTestForDelete] = useState<{
    schedule_Header_Test_Id: number;
    test_Name: string;
    test_Type: string;
    subject_Id: number;
    subject_Name: string;
  }[]>([])


  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
    }
    else {
      getTrainingSlotList()
      getTeacherTrainingSlotTestList()
    }
  }, [isFirst])

  useEffect(() => {

    const testList = selectedSlotTest?.map((each) => (
      teacherTrainingSlotTestList.filter((e) =>
        e?.subject_Name === each).map((item) =>
        ({
          teacher_Training_Test_Id: item?.teacher_Training_Test_Id
        }))
    )).flat()
    setNewTrainingSlot({ ...newTrainingSlot, headerTest: testList })

    const subjectNameToAddTestList = selectedSlotTest.filter(e =>
      !selectedTrainingSlot?.tests?.map(each =>
        each?.subject_Name).includes(e)
    )
    const testListToAdd = subjectNameToAddTestList?.map((each) => (
      teacherTrainingSlotTestList.filter((e) => e?.subject_Name === each).map((item) => ({
        teacher_Training_Test_Id: item?.teacher_Training_Test_Id
      }))
    )).flat()
    setEditSlotTestForAddTest(testListToAdd)

    const testListToDelete = selectedTrainingSlot?.tests?.filter(e => !selectedSlotTest.includes(e?.subject_Name))
    setEditSlotTestForDelete(testListToDelete)

  }, [selectedSlotTest])

  // Handler function

  const handelModalClose = () => {
    setIsSlotModalOpen(false)
    setSelectedSlotTest([])
    handleClear()
  }

  const handleClear = () => {
    setNewTrainingSlot(DefaultNewTraining)
    setSelectedSlotTest([])
  }
  const handleSubmitSlot = () => {
    let payload: INewTrainingSlot =
    {
      ...newTrainingSlot,
      state_User_Id: user?.user_Id,
    }
    if(payload?.schedule_Header_Title?.length < 4){
      showToast({
        type:"warning",
        message:"The title must be at least 4 characters long."
      })
    }
    else{
    postNewTrainingSlot(payload)
  }}

  const handleEditSlot = () => {

    const payload = {
      training_Schedule_Header_Id: selectedTrainingSlot?.training_Schedule_Header_Id,
      state_User_Id: user?.user_Id,
      schedule_Header_Title: selectedTrainingSlot?.schedule_Header_Title,
      start_Date: selectedTrainingSlot?.start_Date,
      end_Date: selectedTrainingSlot?.end_Date,
      no_Of_Days: selectedTrainingSlot?.no_Of_Days,
      description: selectedTrainingSlot?.training_Description,
      headerTest: EditSlotTestForAddTest
    }
    // const testToDelete = selectedTrainingSlot.tests.filter(e => selectedSlotTestForEditSlot.map(e=>e?.).includes(e?.subject_Id))
    putTeacherTrainingSlot(payload)
  }

  // API FUNCTION

  const getTrainingSlotList = async () => {
    setIsLoading(true)
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetail?Is_Current_Header=false`)
    if (res?.isSuccess) {
      setTrainingSlotList(res?.data)
    }
    else {
      setTrainingSlotList([])
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule"
      });
    }
    setIsLoading(false)
  }

  const getTeacherTrainingSlotTestList = async () => {
    setIsLoadingTeacherList(true)
    setTeacherTrainingSlotTestList([])
    const res = await fetchTeacherTrainingData(`/StateAdmin/GetTeacherTrainingHeaderTestList`)
    if (res?.isSuccess) {
      setTeacherTrainingSlotTestList(res?.data)
      let keyValue = groupByKey(res?.data, "Subject")
      // console.log("Return by group by Key", keyValue)
      // setTransferList([res?.data.map((e: ITeacherTrainingSlotTestList) => ({
      //   value: `${e?.teacher_Training_Test_Id}`,
      //   label: `${e?.test_Name}`,
      //   description: `${e?.subject_Name}`,
      // })),[]])
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Could not fetch tests list"
      })
    }
    setIsLoadingTeacherList(false)
  }

  const postNewTrainingSlot = async (payload: INewTrainingSlot) => {
    setIsBtnLoading(true)
    const res = await PostTeacherTrainingData(`/StateAdmin/CreateTeacherTrainingScheduleHeader`, payload)
    if (res.isSuccess) {
      showToast({
        type: "success",
        title: "Successfully created",
        message: "Teacher training slot created successfully"
      })
      handleClear()
      setIsSlotModalOpen(false);
      getTrainingSlotList()
      getTeacherTrainingSlotTestList()
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while creating teacher training slot"
      })
    }
  }

  const putTeacherTrainingSlot = async (
    payload: {
      training_Schedule_Header_Id: number;
      state_User_Id: number;
      schedule_Header_Title: string;
      start_Date: string;
      end_Date: string;
      description: string;
      headerTest: any[]
    }) => {
    const res = await PutTeacherTrainingData(`/StateAdmin/UpdateTeacherTrainingScheduleHeader`, payload)
    if (res.isSuccess) {
      showToast({
        type: "success",
        message: "Slot Details update successfully"
      })
      const array = EditSlotTestForDeleteTest?.length ? Array.from(new Set(EditSlotTestForDeleteTest.map((e => e?.subject_Id)))) : []
      // console.log("array to delete subject", array)
      if (array.length) {
        array.forEach(async (e) => {
          let res = await DeleteTeacherTrainingData(`/StateAdmin/DeleteTrainingScheduleHeaderTest/${payload?.training_Schedule_Header_Id}/${user?.user_Id}/${e}`)
          if (res.isSuccess) {
            handleClear()
            setEditSlotTestForDelete([])
            setIsSlotEditModalOpen(false)
            getTrainingSlotList()
            getTeacherTrainingSlotTestList()
            setNewTrainingSlot(DefaultNewTraining)
            setSelectedTrainingSlot(DefaultSelectedSlot)
          } else {
            showToast({
              type: "error",
              title: "Please try again to remove Test",
              message: res?.message ?? "Fail to delete test from slot"
            })
          }
        })

      }
      else {
        handleClear()
        setEditSlotTestForDelete([])
        setIsSlotEditModalOpen(false)
        setNewTrainingSlot(DefaultNewTraining)
        setSelectedTrainingSlot(DefaultSelectedSlot)
      }


      getTrainingSlotList()
      getTeacherTrainingSlotTestList()
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while updating slot details"
      })
    }
  }

  const deleteTrainingSlot =async () =>{
     const res = await DeleteTeacherTrainingData(``)
      if(res.isSuccess){
        showToast({
          type:"success",
          message:"Slot deleted successfully"
        })
        getTrainingSlotList()
        setIsSlotDeleteModalOpen(false)
      }
      else{
        showToast({
          type:"error",
          message: res?.message || "Fail to delete slot"
        })
      }
  }
  return {
    isLoading,
    isLoadingTeacherList,
    trainingSlotList,
    teacherTrainingSlotTestList,
    isSlotModalOpen,
    setIsSlotModalOpen,
    isSlotEditModalOpen,
    setIsSlotEditModalOpen,
    isSlotDeleteModalOpen,
    setIsSlotDeleteModalOpen,
    handelModalClose,
    selectedSlotTest,
    setSelectedSlotTest,
    handleSubmitSlot,
    handleEditSlot,
    newTrainingSlot,
    setNewTrainingSlot,
    deleteTrainingSlot,
    selectedTrainingSlot,
    setSelectedTrainingSlot,
    setEditSlotTestForDelete
  }
}


