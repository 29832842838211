import React from 'react'
import { EVerticalFormSection } from '../../../../libs/ui';
import { Alert, Select, Space } from '@mantine/core';
import useSpotAddQuestion from '../hook/useSpotAddQuestion';
import { DatePicker } from '@mantine/dates';
import { BsInfoCircle } from 'react-icons/bs';

interface Props {
  classes: any;
  subjectList: any;
  allGroups: any;
  handleChangeQuestionFilterParams: any;
  filterValue: any;
  questionType: any;
  mediaTypeForQuestion: any;
  mediaTypeForOptions: any;
}

const SpotQuestionFilter: React.FC<Props> = ({
  classes,
  subjectList,
  allGroups,
  handleChangeQuestionFilterParams,
  filterValue,
  questionType,
  mediaTypeForQuestion,
  mediaTypeForOptions}) => {
 

  return (
    <div className="max-w-5xl ">
      <EVerticalFormSection
        title="Parameters / Filters"
        subtitle="Please select below filters to set questions to continue. These are required."
      >
        <div className="grid grid-cols-3 gap-3">
          <Select
            required
            label="Select Grade"
            placeholder="Select"
            size="md"
            data={classes?.map((each: any) => ({
              value: String(each.id),
              label: each.name,
            }))}
            value={String(filterValue.selectedGrade) || null}
            onChange={(e) =>
              handleChangeQuestionFilterParams("selectedGrade", String(e))
            }
          />
          <Select
            required
            label="Subject"
            placeholder="Select"
            size="md"
            data={subjectList?.map((item: any) => ({
              value: String(item.id),
              label: item.name,
            }))}
            value={String(filterValue.selectedSubject) || null}
            onChange={(e) =>
              handleChangeQuestionFilterParams("selectedSubject", String(e))
            }
          />
          <DatePicker
            label="Select Start Date"
            placeholder="MM/DD/YYYY"
            value={filterValue?.WeekStartDate}
            onChange={(e: any) =>
              handleChangeQuestionFilterParams("WeekStartDate", e)
            }
            minDate={new Date()}
            maxDate={
              filterValue?.WeekEndDate
                ? new Date(filterValue.WeekEndDate)
                : undefined
            }
          />
          <DatePicker
            label="Select End Date"
            placeholder="MM/DD/YYYY"
            value={filterValue?.WeekEndDate}
            onChange={(e: any) =>
              handleChangeQuestionFilterParams("WeekEndDate", e)
            }
            minDate={
              filterValue?.WeekStartDate
                ? new Date(filterValue.WeekStartDate)
                : new Date()
            }
          />
          <Select
            required
            // className="w-[33%]"
            label="Question Group"
            placeholder="Select"
            size="md"
            data={allGroups?.map((each: any) => ({
              value: Number(each.question_Group_Instruction_Id),
              label: each.competency,
            }))}
            value={filterValue?.question_Group_Instruction_Id}
            onChange={(e: any) =>
              handleChangeQuestionFilterParams(
                "question_Group_Instruction_Id",
                Number(e)
              )
            }
          />

          <Select
            required
            label="Question Type"
            placeholder="Select"
            size="md"
            data={questionType?.map((each: any) => ({
              value: String(each.id),
              label: each.text,
            }))}
            // value={filterValue?.questionType ? String(filterValue?.questionType) : String(questionType[0]?.id)}
            value={String(filterValue?.questionType)}
            onChange={(e) => {
              handleChangeQuestionFilterParams("questionType", Number(e));
              // console.log("Select Question type", String(e));
            }}
          />

          <Select
            required
            label="Media Type for Question"
            placeholder="Select"
            size="md"
            data={mediaTypeForQuestion?.map((each: any) => ({
              value: String(each.media_Type_Id),
              label: each.media_Type,
            }))}
            value={String(filterValue.media_Type_Id)}
            onChange={(e) =>
              handleChangeQuestionFilterParams("media_Type_Id", Number(e))
            }
          />
          <Select
            required
            label="Media Type for Options"
            placeholder="Select"
            size="md"
            data={mediaTypeForOptions?.map((each: any) => ({
              value: String(each.media_Type_Id),
              label: each.media_Type,
            }))}
            value={String(filterValue.option_Media_Type_Id)}
            onChange={(e) =>
              handleChangeQuestionFilterParams(
                "option_Media_Type_Id",
                Number(e)
              )
            }
          />
        </div>

        {!filterValue.media_Type_Id || !filterValue.option_Media_Type_Id ? (
          <>
            <Space h={34} />
            <Alert
              icon={<BsInfoCircle />}
              title="Missing Media Type!"
              color="orange"
            >
              You need to select media type for question and options
            </Alert>
          </>
        ) : (
          <></>
        )}
      </EVerticalFormSection>
    </div>
  );
};

export default SpotQuestionFilter