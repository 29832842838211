import React, { useState } from 'react'
import { useMentorReports } from '../../../../../libs/data-access';
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import {IClassroomMathObservationReport } from '../../../../../libs/data-access/types';
import moment from 'moment';

export default function useClassroomMathObservation() {
     const {
    fetchMentorReport
} = useMentorReports();

const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
 const [selectedSession, setSelectedSession] = useState<string | undefined>(
   parseInt(moment().format("MM"), 10) > 4
     ? moment().add(1, "year").format("YYYY")
     : moment().format("YYYY")
 );
const [classroomMathObservationReport, setClassroomMathObservationReport] = useState<IClassroomMathObservationReport[]>([]);
// data for CSV download
const [classroomMathObservationCSV, setClassroomMathObservationCSV] = useState<{ headers: any[], content: any[] }>();

// loading
const [isLoading, setIsLoading] = useState<boolean>(false);

/* HANDLER FUNCTIONS */
const handleSelectMonth = (value: string) => {
    setSelectedMonth(value);
    setClassroomMathObservationReport([]);
};

const formatClassroomMathObservationReport = (visitCompliance: IClassroomMathObservationReport[]) => {

    const headers = [
        { label: "District", key: "district" },
        { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के सभी चरणों का उपयोग किया जा रहा है ", key: "question_1_option_1" },
        { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के कुछ चरणों का उपयोग किया जा रहा है ", key: "question_1_option_2" },
        { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका का उपयोग  किया जा रहा है  ", key: "question_1_option_3" },
        { label: "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option हाँ,  गतिविधि को शिक्षण योजना के चरणों के अनुसार कराया गया  ", key: "question_2_option_1" },
        { label: "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option नहीं, गतिविधि को शिक्षण योजना के चरणों के अनुसार  नहीं कराया गया ", key: "question_2_option_2" },
        { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री व्यवस्थित और बच्चों की पहुँच में थी। ", key: "question_3_option_1" },
        { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री लगी थी पर बच्चों की पहुँच मेंनहीं थी।  ", key: "question_3_option_2" },
        { label: "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा-कक्ष में कोई प्रिंट सामग्री प्रदर्शित नहीं थी।", key: "question_3_option_3" },
    ];

    const data = visitCompliance?.map(each => Object.assign({
        district: each.district_Name || "",
        question_1_option_1:each?.q1_Option1 || "",
        question_1_option_2:each?.q1_Option2 || "",
        question_1_option_3:each?.q1_Option3 || "",
        question_2_option_1:each?.q2_Option1 || "",
        question_2_option_2:each?.q2_Option2 || "",
        question_3_option_1:each?.q3_Option1 || "",
        question_3_option_2:each?.q3_Option2 || "",
        question_3_option_3:each?.q3_Option3 || "",
        
    }));

    setClassroomMathObservationCSV({
        headers: headers,
        content: data
    });

};

/* API CALLS */
const fetchClassroomMathObservation = async () => {

    setIsLoading(true);
    const response = await fetchMentorReport(
      `GetClassroomObservationReportMath/${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }-${selectedMonth}-01`}`
    );

    if (response.isSuccess) {

        // console.log("Response for report: ", response.data);
        setClassroomMathObservationReport(response.data);
        formatClassroomMathObservationReport(response.data);

    } else {

        console.log("Error while fetching report: ", response.error);
        showToast({
            type: "error",
            title: "Error while fetching report",
            message: response.message || "Something went wrong!"
        });

    };
    setIsLoading(false);

};

return {
  isLoading,
  selectedMonth,
  handleSelectMonth,
  setSelectedMonth,
  classroomMathObservationReport,
  classroomMathObservationCSV,
  fetchClassroomMathObservation,
  selectedSession,
  setSelectedSession,
};
}
