import React, { useState } from 'react'
import { INewSurvey, INewSurveyQuestionGroup } from '../../../types'
import { EButton, EDrawer, ELabelView, ESectionDivider, ETable } from '../../../libs/ui'
import { Modal, ScrollArea, Table } from '@mantine/core'
import { MdDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'
import InfoLabel from '../../../libs/ui/src/InfoLabel/InfoLabel'
type IProps = {
    surveyQuestionsGroup: INewSurvey
}
export default function SurveyGroup(
    {
        surveyQuestionsGroup
    }: IProps

) {

    
    const [isOpenDrawer,setIsOpenDrawer]=useState<boolean>(false)
    const [isOpenDeleteModal,setIsOpenDeleteModal]=useState<boolean>(false)
    const [selectedSurvey,setSelectedSurvey] =useState<INewSurveyQuestionGroup | undefined>(undefined)
    return (
        <>
            <Table
             withColumnBorders
             withBorder
             highlightOnHover
             >
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Question Group Title</th>
                        <th>Survey Questions Title</th>
                        <th>Survey Questions Description</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                        {surveyQuestionsGroup?.question_Groups?.map((each, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{each?.question_Section_Title}</td>
                                <td>
                                    {each?.questions?.map((e ,index)=>(
                                        <tr>
                                        <td>
                                          {index+1}. { e?.survey_Question_Title}  
                                        </td>
                                        </tr>
                                    ))}
                                </td>
                                <td>
                                    {each?.questions?.map((e ,index)=>(
                                        <tr>
                                        <td>
                                            { e?.survey_Question_Description}  
                                        </td>
                                        </tr>
                                    ))}
                                </td>
                                <td>
                                 <p 
                                 className='text-blue-400 text-sm text-center cursor-pointer'
                                 onClick={()=>{
                                    setSelectedSurvey(each)
                                    setIsOpenDrawer(true)
                                 }}
                                 >
                                    ...view details</p>
                                    </td>
                            </tr>
                        ))}
                    </tbody>
            </Table>

            <EDrawer 
    opened={isOpenDrawer} 
    position="right"
    title=' Question Details'
    onClose={()=>{
        setSelectedSurvey(undefined)
        setIsOpenDrawer(false)}} 
    drawerContent={
        <ScrollArea type="never" className='h-[92%]'>
         <div className='bg-slate-50 p-2 rounded flex flex-col gap-2'>  
         <div className='flex justify-end px-2'>  
            </div> 
         <ELabelView
          label='Question Group Title'
          value={selectedSurvey?.question_Section_Title ?? ""}
        />
        <ELabelView
          label='Question Group Description'
          value={selectedSurvey?.question_Section_Description ??""}
        />
        
        <div className='flex justify-between pr-6'>
        {/* <ELabelView
          label='Survey Start Date'
          value={selectedSurvey? ?? ""}
        />
        <ELabelView
          label='Survey End Date'
          value={selectedQuestion?.survey_Sub_Question_Type_Id.toString() ?? ""}
        /> */}
        </div>
        </div>
         
         <ESectionDivider title='Options' classes='pb-0'/>
          {
            selectedSurvey?.questions?.map((each ,index)=>(
                <div className='bg-slate-100 rounded-sm mt-2 p-2'>
                    <p>{index+1 +". "+ each?.survey_Question_Title}</p>
                </div>
            ))
          } 
          <div className='flex gap-2 justify-end mt-4 mb-10'>
            <EButton
            className="btn-red"
            onClick={()=>setIsOpenDeleteModal(true)}
            >
                Delete <MdDelete size={22} className='ml-2'/>
            </EButton>
            {/* <EButton
            className="btn-green"
            >
            Edit <FiEdit size={22} className='ml-2'/>
            </EButton> */}
          </div>
        </ScrollArea>
    }
        />

        {/* Delete Modal */}
        <Modal
        opened={isOpenDeleteModal}
        onClose={() => { }}
        withCloseButton={false}
        title="Are you sure you want to delete ?">
        <div className='flex gap-3 justify-end'>
          <EButton
            className="btn-light"
            onClick={() => {
            //   setSelectedDeleteItemIndex(undefined)
              setIsOpenDeleteModal(false)
            }}
          >Cancel</EButton>
          <EButton
            className="btn-red"
            // onClick={() => handleDeleteTestFromList()}
          >Delete</EButton>
        </div>
       </Modal>

        </>
    )
}
