import axios from 'axios';
import React from 'react'
import { BASE_URL } from '../../constant';
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useNotificationData() {
   
  const fetchNotificationData = async (endpoint: string) => {
      try {

          let response = await axios.get(`${BASE_URL}PushNotifications/${endpoint}`);
          // console.log("Response: ", response?.data);
          const formattedResponse = handleFormatResponse(response);
          return formattedResponse;

      } catch (error: any) {

          // console.log("Error: ", error);
          const formattedError = handleFormatResponseForError(error);
          return formattedError;

      };
  };  
  const postNotificationData = async (
      endpoint: string,
      payload: any
  ) => {
      try {

          let response = await axios.post(`${BASE_URL}PushNotifications/${endpoint}`, payload);
          // console.log("Response: ", response?.data);
          const formattedResponse = handleFormatResponse(response);
          return formattedResponse;

      } catch (error: any) {

          // console.log("Error: ", error);
          const formattedError = handleFormatResponseForError(error);
          return formattedError;

      }
  };
return{
  fetchNotificationData,
  postNotificationData
}
}
