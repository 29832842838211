import axios from 'axios';
import React from 'react'
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';
import { BASE_URL } from '../../constant';

export  function useSurveyData() {
   
    const fetchSurveyData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Survey/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };  
    const postSurveyData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}Survey/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    // const PutSurveyData = async (
    //     endpoint: string,
    //     payload: any
    // ) => {
    //     try {

    //         let response = await axios.put(`${BASE_URL}Survey/${endpoint}`, payload);
    //         // console.log("Response: ", response?.data);
    //         const formattedResponse = handleFormatResponse(response);
    //         return formattedResponse;

    //     } catch (error: any) {

    //         // console.log("Error: ", error);
    //         const formattedError = handleFormatResponseForError(error);
    //         return formattedError;

    //     }
    // };

    // const DeleteSurveyData = async(
    //     endpoint:string
    // )=>{

    //     try {

    //         let response = await axios.delete(`${BASE_URL}Survey/${endpoint}`);
    //         // console.log("Response: ", response?.data);
    //         const formattedResponse = handleFormatResponse(response);
    //         return formattedResponse;

    //     } catch (error: any) {

    //         // console.log("Error: ", error);
    //         const formattedError = handleFormatResponseForError(error);
    //         return formattedError;

    //     }
    // };
  
    return {
        fetchSurveyData,
        postSurveyData
  } 
}

