import React from 'react';
import { Card, Grid, Skeleton } from '@mantine/core';

interface IProps {
    stat: string | null;
    label: string;
    shadow?: "xs" | "sm" | "md" | "lg" | "xl";
    radius?: "xs" | "sm" | "md" | "lg" | "xl";
};

export function StatCard({
    stat,
    label,
    shadow,
    radius
}: IProps) {
    return (
        <Card className='grow' shadow={shadow ?? "sm"} radius={radius ?? "md"}>
            <h3>{stat ?? "-"}</h3>
            <p className="text-slate-500 text-sm">{label}</p>
        </Card>
    )
}
