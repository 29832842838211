import React, { useState } from 'react'
import { useMentorReports } from '../../../../../libs/data-access';
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import { ISchoolWiseClassroomHindiObservationReport } from '../../../../../libs/data-access/types';
import moment from 'moment';

export default function useMonthlyHindiObservation() {

    const {
        fetchMentorReport,
        fetchClassroomWiseObservationFast
    } = useMentorReports();
    const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
    const [selectedSession, setSelectedSession] = useState<string | undefined>(
      parseInt(moment().format("MM"), 10) > 4
        ? moment().add(1, "year").format("YYYY")
        : moment().format("YYYY")
    );
    const [schoolWiseClassroomHindiObservationReport, setSchoolWiseClassroomHindiObservationReport] = useState<ISchoolWiseClassroomHindiObservationReport[]>([]);
    // data for CSV download
    const [schoolWiseClassroomHindiObservationCSV, setSchoolWiseClassroomHindiObservationCSV] = useState<{ headers: any[], content: any[] }>();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleSelectMonth = (value: string) => {
        setSelectedMonth(value);
        setSchoolWiseClassroomHindiObservationReport([]);
    };

    const formatSchoolWiseClassroomHindiObservationReport = (visitCompliance: ISchoolWiseClassroomHindiObservationReport[]) => {

        const headers = [
            { label: "District", key: "district" },
            { label: "Block", key: "block" },
            { label: "Cluster", key: "cluster" },
            { label: "School Name", key: "school_name" },
            { label: "UDISE", key: "udise" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के सभी चरणों का उपयोग किया जा रहा है ", key: "question_1_option_1" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के कुछ चरणों का उपयोग किया जा रहा है ", key: "question_1_option_2" },
            { label: "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका का उपयोग नहीं किया जा रहा है ", key: "question_1_option_3" },
            { label: "Question 2.  शिक्षक संदर्शिका के कौन से सप्ताह पर अध्यापन कार्य किया जा रहा है ? ", key: "question_2" },
            { label: "Question 3. कक्षा कक्ष में शिक्षक द्वारा प्रिंट सामग्री का प्रदर्शन -> Option :  शिक्षक ने कक्षा मे प्रिंट सामग्री व्यवस्थित रूप से प्रदर्शित की और बच्चों की पहुँच में थी। ", key: "question_3_option_1" },
            { label: "Question 3. कक्षा कक्ष में शिक्षक द्वारा प्रिंट सामग्री का प्रदर्शन -> Option :  शिक्षक ने कक्षा मे प्रिंट सामग्री व्यवस्थित रूप से प्रदर्शित की लेकिन बच्चों की पहुँच में नहीं थी। ", key: "question_3_option_2" },
            { label: "Question 3. कक्षा कक्ष में शिक्षक द्वारा प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा-कक्ष में कोई प्रिंट सामग्री नहीं थी।", key: "question_3_option_3" },
            { label: "Question 4. बच्चों के आकलन एवं दोहरान कार्य के लिए गतिविधि का अवलोकन -> Option :  बच्चों का पिछले सप्ताह के पांचवे दिन का आकलन कार्य किया गया था और उसके आधार पर छठे दिन दोहरान का कार्य करवाया गया था।", key: "question_4_option_1" },
            { label: "Question 4. बच्चों के आकलन एवं दोहरान कार्य के लिए गतिविधि का अवलोकन -> Option :  बच्चों का पिछले सप्ताह के पांचवे दिन का आकलन कार्य किया गया था,लेकिन आकलन के आधार पर छठे दिन दोहरान का कार्य नहीं करवाया गया था।", key: "question_4_option_2" },
            { label: "Question 4. बच्चों के आकलन एवं दोहरान कार्य के लिए गतिविधि का अवलोकन -> Option :  पिछले सप्ताह आकलन व दोहरान का कोई ही कार्य नहीं किया गया था।", key: "question_4_option_3" },

        ];

        const data = visitCompliance?.map(each => Object.assign({
            district: each.district_Name || "",
            block: each.block_Name || "",
            cluster: each.cluster_School_Name || "",
            school_name: each?.school_Name || "",
            udise: each.udisE_Code || "",
            question_1_option_1: each?.q1_Option1 || "",
            question_1_option_2: each?.q1_Option2 || "",
            question_1_option_3: each?.q1_Option3 || "",
            question_2: each?.q2_Average_Week || "",
            question_3_option_1: each?.q3_Option1 || "",
            question_3_option_2: each?.q3_Option2 || "",
            question_3_option_3: each?.q3_Option3 || "",
            question_4_option_1: each?.q4_Option1 || "",
            question_4_option_2: each?.q4_Option2 || "",
            question_4_option_3: each?.q4_Option3 || "",

        }));

        setSchoolWiseClassroomHindiObservationCSV({
            headers: headers,
            content: data
        });

    };

    /* API CALLS */
    const fetchSchoolWiseClassroomHindiObservation = async () => {
        setIsLoading(true);
        const response = await fetchMentorReport(
          `GetClassroomObservationSchoolWiseReportHindi/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}`
        );
        if (response.isSuccess) {
            // console.log("Response for report: ", response.data);
            setSchoolWiseClassroomHindiObservationReport(response.data);
            formatSchoolWiseClassroomHindiObservationReport(response.data);

        } else {
            showToast({
                type: "error",
                title: "Error while fetching report",
                message: response.message || "Something went wrong!"
            });

        };
        setIsLoading(false);

    };

    return {
      isLoading,
      selectedMonth,
      handleSelectMonth,
      setSelectedMonth,
      schoolWiseClassroomHindiObservationReport,
      schoolWiseClassroomHindiObservationCSV,
      fetchSchoolWiseClassroomHindiObservation,
      selectedSession,
      setSelectedSession,
    };

}

