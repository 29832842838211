import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSchedule } from 'react-icons/ai';

import PageLayout from '../../../../../PageLayout';
import { EPageTitle, ETable } from '../../../../../libs/ui';

import useTrainingSlotIndex from './hooks/useTrainingSlotIndex';
import { Badge } from '@mantine/core';



export default function TrainingSlotIndex() {

  const navigate = useNavigate();

  const {
    trainingSlotList,
    isLoading
  } = useTrainingSlotIndex();

  return (
    <PageLayout>
      <EPageTitle
        title="Teacher Training Slots"
      // end={<EButton className='bg-blue-600 ' onClick={() =>setIsSlotModalOpen(true)}> <BsPlus size={23} />Create New Slot</EButton>}
      />
      <ETable>
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>No. of Days</th>
            <th>No. of test available</th>
            <th>Description</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            trainingSlotList.map((e, index) =>
              <tr
                key={index + "slot_header"}
                className='cursor-pointer'
              // onClick={() => navigate(`/create-schedule`)}
              >
                <td>{e?.schedule_Header_Title ?? "-"}</td>
                <td>{e?.start_Date ? moment(e?.start_Date).format('DD-MM-YYYY') : "-"}</td>
                <td>{e?.end_Date ? moment(e?.end_Date).format('DD-MM-YYYY') : "-"}</td>
                <td>{e?.no_Of_Days ?? "-"}</td>
                <td>{e?.tests.length ?? "-"}</td>
                <td>{e?.training_Description ?? "-"}</td>
                <td>
                  { 
                  moment().format("YYYY-MM-DD") <= moment(e?.end_Date).format("YYYY-MM-DD") ?
                   <div
                    className='text-blue-500 cursor-pointer flex gap-1 items-center'
                    onClick={() => navigate(`/create-schedule/${e.training_Schedule_Header_Id}`)}
                  >
                    <AiOutlineSchedule size={18} />
                    <span>
                      Create Schedule
                    </span>
                  </div>
                  :
                  <>
                  <Badge color='dark'>
                    Expired Slot
                  </Badge>
                  </>}
                </td>
              </tr>
            )}
        </tbody>
      </ETable>
    </PageLayout>
  )
}
