import { Radio ,Tooltip ,Image ,Textarea} from '@mantine/core';
import React from 'react'
import { GiCancel } from 'react-icons/gi';
import { BASE64_INITIALS } from '../../../../initialStates';
import { questionOptions } from '../../../../libs/data-access/types';
import { showToast } from '../../../../utils/utility';

interface IProps {
    optionList: questionOptions[];
    questionFilters: number ;
    handleSetCorrectOption: (id: number) => void;
    handleSetCorrectOptionMultiple: (id: number) => void;
    handleRemoveSpecificOption: (id: number) => void;
    handleRemoveCurrentOption: (index:number) => void;
};

export default function UploadNewSlotTestQuestionOption({
    optionList,
    questionFilters,
    handleSetCorrectOption,
    handleSetCorrectOptionMultiple,
    handleRemoveSpecificOption,
    handleRemoveCurrentOption
}:IProps) {

return (
    <>
        { 
       
       optionList.map((each, index) => <div
                key={index + "option"}
                className="input-item"
            >
                 
                {   
                 <div className="radio-btn ">
                    <Radio
                        size="md"
                        color="green"
                        checked={each.is_Correct}
                        value={String(each.option_Text)}
                        onClick={() => {
                            // questionFilters == 1
                            //     ?
                                handleSetCorrectOption(index)
                                // :
                                // handleSetCorrectOptionMultiple(index)
                        }}
                    />
                </div>}

                <div className="action-icons">
                    <Tooltip
                        withArrow
                        color="red"
                        label="Remove this option"
                    >
                        <a
                            className="cursor-pointer"
                            onClick={() => handleRemoveCurrentOption(index)}
                        >
                            <GiCancel
                                size={20}
                                className="cursor-pointer"
                                onClick={() =>
                                    handleRemoveSpecificOption(index)
                                }
                            />
                        </a>
                    </Tooltip>
                </div>


                {each.option_Text
                    // &&
                    // !each.file
                    ?
                    <span
                        className="w-full text-xl font-semibold "
                        >
                        {each.option_Text || ""}
                        </span>
                    :
                    <></>}
            </div>)
        }
    </>
)
}

