import React from 'react'
import { ISelectedTicketToBeResolved } from '../../../types';
import moment from 'moment';
import { Badge, Textarea } from '@mantine/core';
import { EButton } from '../../../libs/ui';
import { IGrievanceTicket, ITicket } from '../../../libs/data-access/types';

type IProps = {
    ticket: IGrievanceTicket;
    isLoading: boolean;
    readOnly?: boolean
  };
export default function TicketDrawer({
    ticket,
    isLoading,
    readOnly
  }: IProps) {
  return (
    <div className='flex flex-1 flex-col gap-3' >
      <p className='text-slate-500 border-b pb-4 flex justify-between'>
        <span>Ticket# {ticket.ticket_Number}</span>
        <span className='ml-2'>{ticket?.date_Of_Issue ? moment(ticket?.date_Of_Issue).format("DD/MM/YYYY") : " - "}</span>
        <Badge color={ticket.ticket_Status == "Open" ? "green" : "blue"}>{ticket.ticket_Status}</Badge>
      </p>

      <div className='flex gap-2 py-2 items-center'>
        {/* <div className='rounded-full bg-blue-600 w-12 h-12 text-white text-center text-xl flex justify-center items-center capitalize'>
          {ticket.name.charAt(0) ?? " - "}
        </div> */}
        <div>
          <p className='text-xl font-semibold'>{""}</p>
          <p>{"" ?? " - "}</p>
        </div>
      </div>
      <div className="flex gap-14">
              <div className="flex flex-col gap-2">
                <p className='flex gap-10'><span className='font-semibold'>Category Name   </span></p>
                <p className='flex gap-10'><span className='font-semibold'>Raised Date</span></p>
                <p className='flex gap-10'><span className='font-semibold'>Resolved Date   </span></p>
              </div>
              <div className="flex flex-col gap-2">
                <span>{ticket?.category_Name || " - "}</span>
                <span>{moment(ticket?.date_Of_Issue).format("DD/MM/YYYY") || " - "}</span>
                <span>{ moment(ticket?.date_Of_Solved).format("DD/MM/YYYY") =="01/01/1900" ? " - " : moment(ticket?.date_Of_Solved).format("DD/MM/YYYY") || " - "}</span>
              </div>
            </div>
      <div>
        <p className='text-blue-800  text-md font-bold py-2'>REMARKS / QUERY</p>
        <p className=' pb-5 border-b'>
          {ticket.grievance_Query}
        </p>
            <p className='text-blue-800  text-md font-bold py-2'>REPLY</p>
            {ticket.ticket_Status == "Open" ? <>
            <p className='pb-4'>
                  No any reply yet !
                </p>
            </>
              :
              <>
                <p className='pb-4'>
                  {ticket.grievance_Reply}
                </p>
                <p className='text-slate-400 text-sm'>
                  Closed on : {ticket.date_Of_Solved ? moment(ticket.date_Of_Solved).format("DD/MM/YYYY") : " - "}
                </p>
              </>
            }
          </div>
    </div>
  )
}

// import { Badge, List, Textarea } from '@mantine/core'
// import moment from 'moment';

// import { EButton } from '../../../libs/ui'
// import { ISelectedTicketToBeResolved } from '../../../types';
