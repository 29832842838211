import React from 'react';
import moment from 'moment';
import { GrList } from 'react-icons/gr';
import { FiEdit } from 'react-icons/fi';
import { IoMdDownload } from "react-icons/io";
import { BsCheck2Square } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Accordion, Badge, Card, Grid, ScrollArea, Skeleton, Switch } from '@mantine/core';

import PageLayout from '../../../../../PageLayout';
import InfoLabel from '../../../../../libs/ui/src/InfoLabel/InfoLabel';
import ScheduleListAccordian from './Components/ScheduleListAccordian';
import { EButton, EDrawer, EEmpty, EPageTitle } from '../../../../../libs/ui';

import useScheduleList from './hooks/useScheduleList';
import { FaPlus } from 'react-icons/fa';
import DetailsCard from '../../../../../libs/ui/src/Cards/DetailsCard';
import { handleCheckDateRange, handleCheckEndDateRange, showToast } from '../../../../../utils/utility';
import { CSVLink } from "react-csv";
import { BiDownload } from 'react-icons/bi';


export default function ScheduleList() {

  const navigate = useNavigate();

  const {
    isLoading,
    slotListWithGroupScheduleList,
    isOpenSideDrawer,
    setIsOpenSideDrawer,
    selectedSchedule,
    setSelectedSchedule,
    selectedSlot,
    setSelectedSlot,
    isChangingActiveStatus,
    UpdateTestActiveStatus,
    csvData,
    deleteSchedule,
  } = useScheduleList();

  // let csvData:any = [{
  //   "District_Name": "testing",
  //   "Block_Name": "testing",
  //   "School_Udise_Code": "testing",
  //   "Cluster_School_Name": "testing",
  //   "School_Code": "testing",
  //   "School_Name": "testing",
  //   "Employee_Id": "testing",
  //   "Employee_Name": "testing",
  //   "Designation": "testing",
  // }];

  return (
    <PageLayout>
      <EPageTitle
        title={"Schedule List"}
        // end={<EButton className='bg-blue-600' onClick={() => navigate(`/create-schedule`)}> <BsPlus size={23} />Create New Schedule</EButton>}
      />
      {isLoading ? (
        <div className="" style={{ height: "300px" }}>
          <Skeleton height={130} radius="md" />
        </div>
      ) : slotListWithGroupScheduleList.length ? (
        <>
          <Accordion
            chevron={<FaPlus size={16} />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
          >
            {slotListWithGroupScheduleList.map((each, index) => (
              <ScheduleListAccordian
                key={"slot-schedule" + index}
                schedule={each}
                setIsOpenSideDrawer={setIsOpenSideDrawer}
                setSelectedSlot={setSelectedSlot}
                setSelectedSchedule={setSelectedSchedule}
                deleteSchedule={deleteSchedule}
              />
            ))}
          </Accordion>
        </>
      ) : (
        // <ETable>
        //   <thead>
        //     <tr>
        //       <th>Training Title</th>
        //       <th>Start Date</th>
        //       <th>End Date</th>
        //       <th></th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {
        //       scheduleList.map((each) => (
        //         <tr>
        //           <td>{each?.training_Title ?? "-"}</td>
        //           <td>{each?.training_Start_Date ? moment(each?.training_Start_Date).format('DD-MM-YYYY') : "-"}</td>
        //           <td>{each?.training_End_Date ? moment(each?.training_End_Date).format('DD-MM-YYYY') : "-"}</td>
        //           <td>
        //             <div
        //               className='text-blue-500 cursor-pointer flex gap-1 items-center'
        //               onClick={() => {
        //                 setIsOpenSideDrawer(true)
        //                 setSelectedSchedule(each)
        //               }}
        //             >
        //               <span>
        //                 Show details
        //               </span>
        //               <HiOutlineArrowNarrowRight size={18} />
        //             </div>
        //           </td>
        //         </tr>
        //       ))
        //     }
        //   </tbody>
        // </ETable>
        <EEmpty title={"Training schedule is not available"} />
      )}

      {/* Side Drawer */}
      <EDrawer
        opened={isOpenSideDrawer}
        onClose={() => setIsOpenSideDrawer(false)}
        position="right"
        title="Schedule Details"
        drawerContent={
          <ScrollArea
            type="never"
            className="pb-10 flex flex-col justify-between"
          >
            <div>
              <Card className="bg-slate-50">
                <div className="flex">
                  {/* <EButton className="btn-green ml-auto" onClick={() => {}}>
                    <IoMdDownload />{" "}
                    <span className="pl-2">Download Details</span>
                  </EButton> */}
                  <CSVLink
                    style={{}}
                    className="text-blue-600 text-sm hover:underline ml-auto"
                    data={csvData}
                    // data={spotAssessmentResultCSV?.content ?? []}
                    // headers={downloadHeader}
                    // headers={spotAssessmentResultCSV?.headers ?? []}
                    filename={`Teacher_Schedule_Details${moment().format(
                      "MMM"
                    )}-${moment().format("YYYY")}.csv`}
                  >
                    <EButton className="btn-blue">
                      Download Details <BiDownload className="ml-2" size={22} />
                    </EButton>
                  </CSVLink>
                </div>

                <InfoLabel
                  textSize="md"
                  label="Title"
                  value={selectedSchedule?.training_Title ?? "-"}
                />
                <Grid>
                  <Grid.Col span={6}>
                    <InfoLabel
                      label="Start Date"
                      value={
                        selectedSchedule?.training_Start_Date
                          ? moment(
                              selectedSchedule?.training_Start_Date
                            ).format("DD-MM-YYYY")
                          : "-"
                      }
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <InfoLabel
                      label="End Date"
                      value={
                        selectedSchedule?.training_End_Date
                          ? moment(selectedSchedule?.training_End_Date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"
                      }
                    />
                  </Grid.Col>
                </Grid>
                <InfoLabel
                  label="Description"
                  value={selectedSchedule?.training_Description ?? "-"}
                />
                <InfoLabel
                  label="Training place"
                  value={selectedSchedule?.training_Place ?? "-"}
                />
              </Card>
              <Card className="bg-slate-50 mt-2">
                <div className="flex items-center gap-2 text-slate-600">
                  <GrList />
                  <p className="text-lg font-semibold ">Tests List</p>
                </div>
                {selectedSchedule && (
                  <>
                    {handleCheckDateRange(
                      selectedSchedule?.training_Start_Date
                    ) ? (
                      selectedSchedule?.testDetails?.map((each, index) => (
                        <DetailsCard
                          isLoading={isChangingActiveStatus}
                          isActive={each?.is_Active}
                          name={each?.test_Name}
                          subject={each?.subject_Name}
                          cardId={each?.teacher_Training_Schedule_Test_Id}
                          type={each?.test_Type}
                          handleClick={() => {
                            showToast({
                              type: "warning",
                              message:
                                "The schedule has not commenced as of now !",
                            });
                          }}
                        />
                      ))
                    ) : handleCheckEndDateRange(
                        selectedSchedule?.training_End_Date
                      ) ? (
                      selectedSchedule?.testDetails?.map((each, index) => (
                        <DetailsCard
                          isLoading={isChangingActiveStatus}
                          isActive={each?.is_Active}
                          name={each?.test_Name}
                          subject={each?.subject_Name}
                          cardId={each?.teacher_Training_Schedule_Test_Id}
                          type={each?.test_Type}
                          handleClick={() => {
                            showToast({
                              type: "warning",
                              message: "This schedule has expired!",
                            });
                          }}
                        />
                      ))
                    ) : (
                      <div>
                        {selectedSchedule?.testDetails?.map((each, index) => (
                          <DetailsCard
                            isLoading={isChangingActiveStatus}
                            isActive={each?.is_Active}
                            name={each?.test_Name}
                            subject={each?.subject_Name}
                            cardId={each?.teacher_Training_Schedule_Test_Id}
                            type={each?.test_Type}
                            handleClick={() => {
                              UpdateTestActiveStatus(
                                each?.teacher_Training_Schedule_Test_Id,
                                each?.is_Active
                              );
                            }}
                          />
                        ))}
                      </div>
                    )}
                    {handleCheckDateRange(
                      selectedSchedule?.training_Start_Date
                    ) ? (
                      // Incoming Schedule
                      <div className="flex justify-between mt-4 mb-8 gap-5">
                        <EButton
                          className="btn-green"
                          onClick={() =>
                            navigate(
                              `/edit-schedule/${selectedSlot}/${selectedSchedule?.teacher_Training_Schedule_Id}`
                            )
                          }
                        >
                          <FiEdit /> <span className="pl-2">Edit schedule</span>
                        </EButton>
                        <EButton
                          size="md"
                          className="btn-blue"
                          disabled
                          onClick={() =>
                            navigate(
                              `/mark-attendance/${selectedSchedule?.teacher_Training_Schedule_Id}`
                            )
                          }
                        >
                          <BsCheck2Square />{" "}
                          <span className="pl-2">Mark Attendance</span>
                        </EButton>
                      </div>
                    ) : handleCheckEndDateRange(
                        selectedSchedule?.training_End_Date
                      ) ? (
                      // Expired Schedule
                      <div className="flex justify-end mt-10 mb-8 gap-5">
                        <EButton
                          size="md"
                          className="btn-blue"
                          // disabled
                          onClick={() =>
                            navigate(
                              `/mark-attendance/${selectedSchedule?.teacher_Training_Schedule_Id}`,
                              {
                                state: selectedSchedule,
                              }
                            )
                          }
                        >
                          {/* <BsCheck2Square />{" "} */}
                          <span className="pl-2">Attendance List</span>
                        </EButton>
                      </div>
                    ) : (
                      // On Going Schedule
                      <div className="flex justify-between mt-4 mb-8 gap-5">
                        <EButton
                          className="btn-green"
                          // disabled
                          onClick={() =>
                            navigate(
                              `/edit-schedule/${selectedSlot}/${selectedSchedule?.teacher_Training_Schedule_Id}`
                            )
                          }
                        >
                          <FiEdit /> <span className="pl-2">Edit schedule</span>
                        </EButton>
                        <EButton
                          size="md"
                          className="btn-blue"
                          onClick={() =>
                            navigate(
                              `/mark-attendance/${selectedSchedule?.teacher_Training_Schedule_Id}`
                            )
                          }
                        >
                          <BsCheck2Square />{" "}
                          <span className="pl-2">Mark Attendance</span>
                        </EButton>
                      </div>
                    )}
                  </>
                )}
              </Card>
            </div>
          </ScrollArea>
        }
      />
    </PageLayout>
  );
}
