import React, { useContext, useEffect, useState } from 'react'
import { useDashboardDate } from '../../../../../libs/data-access';
import { DashboardFilterContext } from '../../../DashboardFilterContext';
import { IReportLevel, ISchoolVisitsMentorVisitsCompliance, ISchoolVisitsUniqueSchoolVisitCoverage } from '../../../../../libs/data-access/types';
import { calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import moment from 'moment';

export default function useSchoolVisitsComplaince() {

    const
    {
        fetchDashboardData
    } = useDashboardDate()
    
    const {
      selectedMonth,
      selectedDistrict,
      selectedBlock,
      selectedCluster,
      districts,
      blocks,
      isSearching,
      setIsLoadingSchoolVisit,
      setIsSearching,
      selectedSession,
    } = useContext(DashboardFilterContext);
    
    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isLoadingUniqueSchoolVisitCoverage, setIsLoadingUniqueSchoolVisitCoverage] = useState<boolean>(false)
    const [isLoadingMentorVisitsCompliance, setIsLoadingMentorVisitsCompliance] = useState<boolean>(false)
    const [uniqueSchoolVisitCoverage, setUniqueSchoolVisitCoverage] = useState<ISchoolVisitsUniqueSchoolVisitCoverage | undefined>(undefined)
    const [mentorVisitsCompliance, setMentorVisitsCompliance] = useState<ISchoolVisitsMentorVisitsCompliance | undefined>(undefined)
    const [reportLevel, setReportLevel] = useState<IReportLevel>({levelNames:"state",districtName:"haryana", districtId:0 ,blockName:""});
  
    // useEffect(()=>{
    //       if(isFirst){
    //         setIsFirst(false)
    //       }
    //       else{
    //         getUniqueSchoolVisitCoverage()
    //         getMentorVisitsCompliance()
    //       }
    // },[isFirst])
  
    useEffect(()=>{
      if(isSearching){
      getUniqueSchoolVisitCoverage()
      getMentorVisitsCompliance()}
    },[isSearching])
  
    const getUniqueSchoolVisitCoverage = async () => {
      setIsLoadingUniqueSchoolVisitCoverage(true)
      const res = await fetchDashboardData(
        `SchoolObservation/GetUniqueSchoolObservations?DistrictId=${
          selectedDistrict ?? 0
        }&BlockId=${selectedBlock ?? 0}&ClusterId=${
          selectedCluster ?? 0
        }&GetMonthYearFromDate=${`${
          selectedMonth
            ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
            : moment().format("YYYY")
        }/${selectedMonth}/01`}`
      );
      if (res.isSuccess) {
          setUniqueSchoolVisitCoverage(res?.data)
          setReportLevel(
              { levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
                districtName : districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
                districtId: selectedDistrict ? +selectedDistrict : 0 ,
                blockName:  blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : "" 
           })
      }
      else {
          setUniqueSchoolVisitCoverage(undefined)
          // showToast({
          //     type: "error",
          //     message: res?.message || "Fail to get classroom Observation"
          // })
      }
      setIsLoadingUniqueSchoolVisitCoverage(false)
      setIsSearching(false)
  }

    const getMentorVisitsCompliance = async () => {
      setIsLoadingMentorVisitsCompliance(true)
      const res = await fetchDashboardData(
        `SchoolObservation/GetMentorVisitCompliance?DistrictId=${
          selectedDistrict ?? 0
        }&BlockId=${selectedBlock ?? 0}&ClusterId=${
          selectedCluster ?? 0
        }&GetMonthYearFromDate=${`${
          selectedMonth
            ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
            : moment().format("YYYY")
        }/${selectedMonth}/01`}`
      );
      if (res.isSuccess) {
          setMentorVisitsCompliance(res?.data)
      }
      else {
          setMentorVisitsCompliance(undefined)
          // showToast({
          //     type: "error",
          //     message: res?.message || "Fail to get classroom Observation"
          // })
      }
      setIsLoadingMentorVisitsCompliance(false)
  }
   
    
    return {
        isLoadingUniqueSchoolVisitCoverage,
        isLoadingMentorVisitsCompliance,
        uniqueSchoolVisitCoverage,
        mentorVisitsCompliance,
        selectedBlock,
        selectedDistrict,
        districts,
        reportLevel
    }
  }
