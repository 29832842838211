import React, { Suspense, useContext, useEffect, useState } from "react";
import { EButton, EPageTitle, ESectionDivider } from "../../libs/ui";
import PageLayout from "../../PageLayout";
import Chart from "react-apexcharts";
// import MapData from '../../libs/maps/MapData';
// import StatsMap from '../../libs/maps/StatsMap';
import {
  Button,
  Card,
  Grid,
  Loader,
  ScrollArea,
  Select,
  Space,
  Table,
} from "@mantine/core";
import useDashboard from "./hooks/useDashboard";
import { DashboardFilterContext } from "./DashboardFilterContext";
import ClassroomObservation from "./Component/ClassroomObservation/ClassroomObservation";
import SchoolVisitsCompliance from "./Component/SchoolVisits/SchoolVisitsCompliance";
import { AppContext } from "../../AppContext";
import { ROLES } from "../../Routes/routes";
import { EStat } from "../../libs/ui/src/Stat/EStat";
import ClassroomLearningIndicator from "./Component/ClassroomLearningIndicator/ClassroomLearningIndicator";
import LineGraphOverall from "./Component/LineGraph/LineGraphOverall";
import StudentLearning from "./Component/StundentLearning/StudentLearning";
import { Accordion } from "@mantine/core";
import moment from "moment";

export default function Dashboard() {
  const {
    isLoadingSchoolVisit,
    setIsLoadingSchoolVisit,
    isSearching,
    setIsSearching,
    isLoadingHaryanaOverAllStats,
    reportLevel,
    districts,
    blocks,
    clusterSchools,
    handleSearch,
    selectedBlock,
    setSelectedBlock,
    selectedDistrict,
    setSelectedDistrict,
    selectedCluster,
    setSelectedCluster,
    selectedMonth,
    setSelectedMonth,
    haryanaOverAllStats,
    selectedSession,
    setSelectedSession,
  } = useDashboard();

  const { user, role } = useContext(AppContext);

  return (
    <DashboardFilterContext.Provider
      value={{
        selectedMonth,
        selectedDistrict,
        selectedBlock,
        selectedCluster,
        selectedSession,
        districts,
        blocks,
        setIsLoadingSchoolVisit,
        isSearching,
        setIsSearching,
      }}
    >
      <PageLayout>
        {/* Page Header */}
        <div className="flex flex-col justify-between gap-2">
          {/* <EPageTitle
            title={"Dashboard"}
            description="NIPUN Haryana Monitoring Dashboard"
          /> */}
          {/* FILTERS */}
          <div className="filter-container">
            {/* <h4>Filters</h4> */}
            <div className="flex justify-between gap-3 items-end">
              <Select
                label="Select Session"
                labelProps={{ className: "font-semibold" }}
                placeholder="Select Session"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              <Select
                label="Select month"
                labelProps={{ className: "font-semibold" }}
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />
              <Select
                label="Select District"
                labelProps={{ className: "font-semibold" }}
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedCluster(undefined);
                  setSelectedDistrict(e);
                }}
              />
              <Select
                label="Select Block"
                labelProps={{ className: "font-semibold" }}
                placeholder="Select block"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => {
                  setSelectedCluster(undefined);
                  setSelectedBlock(e);
                }}
              />
              <Select
                label="Select Cluster"
                labelProps={{ className: "font-semibold" }}
                placeholder="Select cluster"
                clearable
                disabled={!selectedBlock}
                data={clusterSchools?.map((each) => {
                  return {
                    label: each.cluster_School_Name,
                    value: String(each.cluster_School_Id),
                  };
                })}
                value={selectedCluster ?? null}
                onChange={(e: any) => setSelectedCluster(e)}
              />
              <div className="flex gap-3 items-end">
                <EButton
                  className="btn-blue"
                  disabled={!selectedMonth}
                  // disabled={true}
                  loading={isLoadingSchoolVisit}
                  onClick={() => {
                    handleSearch();
                  }}
                >
                  Update
                </EButton>
              </div>
            </div>
          </div>
        </div>

        {/* NIPUN Haryana at a glance */}
        {/* <ESectionDivider title="NIPUN Haryana at a glance" /> */}
        {/* STATS */}
        <div className="flex flex-row items-center justify-between mt-6 gap-6">
          <EStat
            iconSrc={require("../../assets/icons/icon-school.svg").default}
            stat={haryanaOverAllStats[0]?.School}
            label="Schools"
            toolTipLabel="Number of Government Primary Schools in the State"
          />
          <EStat
            iconSrc={require("../../assets/icons/icon-student.svg").default}
            stat={haryanaOverAllStats[0]?.Student}
            label="Students"
            toolTipLabel="Number of students studying in Bal Vatika to grade 3"
          />
          <EStat
            iconSrc={require("../../assets/icons/icon-teacher.svg").default}
            stat={haryanaOverAllStats[0]?.Employee}
            label="Teachers"
            toolTipLabel="Number of Primary Teachers (PRTs) in the State"
          />
        </div>

        {/* <ECard title="Classroom Observation" header={<></>}>
          <div>
            This is the body
          </div>
        </ECard> */}

        {/* Student Learning */}
        {/* <StudentLearning /> */}

        {/* Classroom Observation */}
        <ClassroomObservation />

        {/* <Accordion>
          <Accordion.Item value="null">
            <Accordion.Control>Classroom Observation</Accordion.Control>
            <Accordion.Panel>
              <ClassroomObservation />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion> */}

        {/* School Visits */}
        <SchoolVisitsCompliance />

        {/* <Space h={24} /> */}

        {/* Classroom Learning Indicator */}
        <ClassroomLearningIndicator />

        {/*classroom school line graph */}
        <LineGraphOverall />
      </PageLayout>
    </DashboardFilterContext.Provider>
  );
}
