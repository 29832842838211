import { Drawer, DrawerProps } from "@mantine/core";

interface IProps extends DrawerProps {
  opened: boolean;
  onClose: () => void;
  drawerContent: JSX.Element | JSX.Element[];
  title?: string;
}


export function EDrawer({ opened, onClose, drawerContent, position, size, title }: IProps) {

  return (
    <Drawer
      styles={{ drawer: { overflow: "auto" } }}
      position={position}
      opened={opened}
      onClose={onClose}
      title={
        <p className="text-3xl font-semibold">{title || "Change Requests: "}</p>
      }
      padding="xl"
      size={size || "xl"}
      lockScroll={true}
    >
      {drawerContent}
    </Drawer>
  );

}