import { useSetState } from '@mantine/hooks'
import React, { useContext, useEffect, useState } from 'react'
import { IDashboardMap, IHaryanaOverALlStats, ILearningIndicator, IReportLevel } from '../../../libs/data-access/types'
import { useDashboardDate } from '../../../libs/data-access'
import {calculateCurrentMonthForDashboard, handleColor, showToast } from '../../../utils/utility'
import useMasterData from '../../Hooks/useMasterData'
import moment from 'moment'
import { AppContext } from '../../../AppContext'
import { ROLES } from '../../../Routes/routes'

export default function useDashboard() {

    const
        {
            fetchDashboardData
        } = useDashboardDate()

    const {
        user,
        role
    } = useContext(AppContext)

    const {
        getDistrictByStateId,
        districts,
        getBlocksByDistrictId,
        blocks,
        clusterSchools,
        getClusterByBlockId
    } = useMasterData();

    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(calculateCurrentMonthForDashboard());
    const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(undefined);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>(undefined);
    const [selectedCluster, setSelectedCluster] = useState<string | undefined>(undefined);
    const [selectedSession, setSelectedSession] = useState<string | undefined>(
      parseInt(moment().format("MM"), 10) > 4
        ? moment().add(1, "year").format("YYYY")
        : moment().format("YYYY")
    );
    const [reportLevel, setReportLevel] = useState<IReportLevel>({ levelNames: "state", districtName: "haryana",districtId:0 ,blockName:"" });

    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isLoadingSchoolVisit, setIsLoadingSchoolVisit] = useState<boolean>(false)
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [isLoadingHaryanaOverAllStats, setIsLoadingHaryanaOverAllStats] = useState<boolean>(false)
    const [haryanaOverAllStats, setHaryanaOverAllStats] = useState<IHaryanaOverALlStats[]>([])

    useEffect(() => {
        if (isFirst) {
            getDistrictByStateId(1).finally(() => setIsFirst(false))
        }
        else {
            if (role?.includes(ROLES.BLOCK)) {
                setSelectedDistrict(String(user?.details?.blockUserDetails?.district_Id))
                setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id))
                // if(user?.details?.blockUserDetails?.district_Id){
                //     getBlocksByDistrictId(user?.details?.blockUserDetails?.district_Id)
                // }
                // handleSearch()
            }
            else if (role?.includes(ROLES.DISTRICT)) {
                setSelectedDistrict(String(user?.details?.districtUserDetails?.district_Id))
            }
            else{
                handleSearch()
            }
        }
    }, [isFirst])

    useEffect(() => {
        if (selectedDistrict) {
            if (role?.includes(ROLES.DISTRICT)) {
                handleSearch()
            }
            getBlocksByDistrictId(+selectedDistrict)
        }
        else {
            // setIsSearching(false)
            // getHaryanaOverAllStats()
        }
    }, [selectedDistrict])

    useEffect(() => {
        if (selectedBlock) {
            if (role?.includes(ROLES.BLOCK)) {
                if(user?.details?.blockUserDetails?.district_Id){
                    getBlocksByDistrictId(user?.details?.blockUserDetails?.district_Id).then((res)=>
                    handleSearch()
                    )
                }
            }
            getClusterByBlockId(+selectedBlock)
        }
    }, [selectedBlock])

    // handler function 
    const handleSearch = () => {
        setIsSearching(true) 
        getHaryanaOverAllStats()
    }
    // APi function

    const getHaryanaOverAllStats = async () => {
        setIsLoadingHaryanaOverAllStats(true)
        setIsLoadingSchoolVisit(true)
        const res = await fetchDashboardData(`NIPUN_Haryana_at_a_glancePr?DistrictId=${selectedDistrict ?? 0}&BlockId=${selectedBlock ?? 0}&ClusterId=${selectedCluster ?? 0}`)
        if (res.isSuccess) {
            setHaryanaOverAllStats(res?.data)
        }
        else {
            setHaryanaOverAllStats([])
            showToast({
                type: "error",
                message: res?.message || "Fail to get Nipurn haryana data indicator"
            })
        }
        setIsLoadingHaryanaOverAllStats(false)
        setIsLoadingSchoolVisit(false)
        setIsSearching(false)
    }
    return {
      reportLevel,
      isLoadingSchoolVisit,
      setIsLoadingSchoolVisit,
      isSearching,
      setIsSearching,
      districts,
      blocks,
      clusterSchools,
      handleSearch,
      selectedDistrict,
      setSelectedDistrict,
      selectedBlock,
      setSelectedBlock,
      selectedCluster,
      setSelectedCluster,
      selectedMonth,
      setSelectedMonth,
      isLoadingHaryanaOverAllStats,
      haryanaOverAllStats,
      selectedSession,
      setSelectedSession
    };
}
