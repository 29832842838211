import axios from "axios";
import { handleFormatResponse, handleFormatResponseForError } from "../../../utility";
import { BASE_URL, FastAPI } from "../../../constant";

export function useStudentLearning() {

    const fetchSpotAssessment = async (endpoint: string) => {
        try {

            let response = await axios.get(`${FastAPI}custom/dashboard/spot_assessment/${endpoint}`);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

            //  if (response?.data?.data) {
            //    const formattedResponse = handleFormatResponse(response);
            //    return formattedResponse;
            //  } else {
            //    return {
            //      isSuccess: true,
            //      message: "",
            //      data: response.data,
            //      error: "",
            //    };
            //  }

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };
    const fetchPeriodicAssessment = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Reports/Dashboard/Periodic/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    return {
        fetchSpotAssessment,
        fetchPeriodicAssessment
    };
};
