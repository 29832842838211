import React, { useEffect, useState } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Line,
  Marker,
} from "react-simple-maps";

import hrTopo from "./haryana.json";

import "./mapdata.scss";
import { Tooltip } from "react-tooltip";
import { Col, Grid } from "@mantine/core";
import { ESectionDivider } from "../ui";

type IStateMapdata = {
  average: number | string;
  district: string ;
  total?: number;
}

type IProps = {
  title: string;
  data: IStateMapdata[];
  isLoading: boolean;
  setSelectedDistrict: (district: string) => void;
  stats?: string;
  hideLegends?: boolean
};
type IContent = {
  district: string;
  EAdhigam: any;
};
// url to a valid topojson file
const MapData = ({ title, data, setSelectedDistrict, isLoading ,stats ,hideLegends }: IProps) => {
  const [content, setContent] = useState<IContent>({
    district: "",
    EAdhigam: "",
  });

 const colorValue ={
  greenValue:95,
  redValue: 90,
  yellowValue :92
 }

  useEffect(() => {
    hrTopo.objects.haryanamap.geometries.forEach((eachDistrict) => {
      let districtName = eachDistrict.properties.District;
      let distData = data?.find(
        (each) =>
          each.district.toLowerCase().trim() ===
          districtName.toLowerCase().trim()
      );
      if (distData) {
        eachDistrict.properties.REMARKS =
          Number(distData?.average);
      }
      else{
        eachDistrict.properties.REMARKS = 0
      }
    });
    // (Number(distData.e_adhigam_students)  /
    //   Number(distData.total_students)) *
    //   100 || 0;
  }, [data]);

  const handleColor =(remarks:number)=>{
     if(remarks <= 20){
       return "#FF0000"
     }
     if(remarks > 20 && remarks <= 40 ){
       return "#FFAC27"
     }
     if(remarks > 40 && remarks <=60){
       return "#FFFF1B"
     }
     if(remarks > 60 && remarks <=80){
       return "#7CFF77"
     }
     if(remarks > 80 && remarks <=100){
       return "#00EE33"
     }
     else{
        return "#ff3333"
  }}
  return (
    <div>
      <Tooltip anchorSelect="#my-tooltip" place="top">
       <p> {content?.district} </p>
          <p>{content?.EAdhigam}</p>
      </Tooltip>
      {
        title !== "" &&
        
        <ESectionDivider title={title}/>
        // <div className="bg-blue-500 shadow-md flex py-1 rounded-xs justify-center">
        //   <h3 className="m-0 text-white">{title}</h3>
        // </div>
      }
      {isLoading ? (
        <div className="bg-body" style={{ height: "300px" }}>
          <>Loading</>
        </div>
      ) : (
        <div
          
          className="bg-light-subtle flex "
          onClick={() => {
            setSelectedDistrict(content?.district)
          }}
        >
          <ComposableMap
            projection="geoAzimuthalEqualArea"
            height={120}
            width={180}
            projectionConfig={{
              rotate: [-76.8, -29.323, 0],
              scale: 1980,
            }}
          >
            <Geographies geography={hrTopo} className="bg-info-subtle ">
              {({ geographies }) =>
                geographies.map((geo, index) => (
                  <>
                    <Geography
                      key={geo?.properties?.name}
                      geography={geo}
                      onMouseEnter={() => {
                        setContent({
                          district: geo?.properties?.name,
                          EAdhigam: geo?.properties?.REMARKS +"%",
                        });
                      }}
                      onMouseLeave={() => {
                        setContent({ district: "", EAdhigam: "" });
                      }}
                      className="cursor-pointer"
                      id="my-tooltip"
                      style={{
                        default: {
                          opacity: 0.9,
                          fill: 
                           handleColor( geo?.properties?.REMARKS)
                          //   geo?.properties?.name == content?.district ? "#038aa1" :
                          //     geo?.properties?.REMARKS >= colorValue?.greenValue ? "#86C672" : geo?.properties?.REMARKS >= colorValue?.yellowValue && geo?.properties?.REMARKS < colorValue?.greenValue ? "#ff6600" : "#ff3333"
                          // // geo.properties.REMARKS <= 10 ? "#D15641" : "#97ad8c",
                        },
                        hover: {
                          fill: "#038aa1",
                        },
                        pressed: {
                          fill: "#038a",
                        },
                      }}
                    />
                    <Marker coordinates={geoCentroid(geo)} id="my-tooltip">
                      <g 
                      onClick={() => console.log(geo?.properties?.name)}
                      >
                        <text y="1" x="0" fontSize={2.0} textAnchor="middle" onMouseOver={() => setContent({
                          district: geo?.properties?.name,
                          EAdhigam: geo?.properties?.REMARKS +"%",
                        })}
                          className="cursor-pointer"
                          onMouseLeave={() => setContent({ district: "", EAdhigam: "" })}
                          onClick={() => setSelectedDistrict(content?.district)}>
                          {geo?.properties?.name.toUpperCase()}
                        </text>
                      </g>
                    </Marker>
                  </>
                ))
              }

            </Geographies>
          </ComposableMap>
         { hideLegends ? <></> : <div className="legend ">
            {/* <div className="item">
              <div className="label">{`> ${colorValue?.greenValue}%`}</div>
              <div className="item__green"></div>
            </div>
            <div className="item">
              <div className="label">{`> ${colorValue?.redValue} and < ${colorValue?.greenValue}%`}</div>
              <div className="item__orange"></div>
            </div> */}
            {[3,23,43,63,83].map(e=>{
              const color = handleColor(e)
              return ( 
              <div className="item">
              <div className="label">{`${e < 20 ? "0% - 20% " : e < 40 ? "21% - 40%" : e < 60 ? "40% - 60%" : e < 80 ? "61% - 80%" : e > 80 ?"80% - 100%" : " "}`}</div>
              <div style={{backgroundColor:color}} className="item__Square"></div>
            </div>)})}
          </div>}
        </div>
      )}
    </div>
  );
};

export default MapData;
