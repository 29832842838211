import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCheck2Square, BsUpload } from "react-icons/bs";

import PageLayout from "../../../PageLayout";
import {
  EButton,
  EDrawer,
  EEmpty,
  EModal,
  EPageTitle,
  ESectionDivider,
  ESelectFileForQuestionBank,
  ETable,
} from "../../../libs/ui";
import { DEPARTMENT_PATH } from "../../../Routes/routes";
import {
  Anchor,
  Badge,
  Card,
  Checkbox,
  Grid,
  Image,
  Modal,
  ScrollArea,
  Select,
  Skeleton,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
// import useQuestionBankIndex from "../Hooks/useQuestionBankIndex";
// import useQuestionBankFilter from "../Hooks/useQuestionBankFilter";
// import { useUploadQuestionForm } from "../Hooks/useUploadQuestionForm";
import { IQuestionFilters } from "../../../types";
import { FiDelete, FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import InfoLabel from "../../../libs/ui/src/InfoLabel/InfoLabel";
import { GrList } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import { TbReplace } from "react-icons/tb";
import { BASE64_INITIALS } from "../../../initialStates";
import { API_URL } from "../../../libs/data-access/constant";
import useQuestionBankFilter from "../../QuestionBank/Hooks/useQuestionBankFilter";
import useQuestionBankIndex from "../../QuestionBank/Hooks/useQuestionBankIndex";
import { DatePicker } from "@mantine/dates";
import useQuestionGroup from "./hook/useQuestionGroup";
import moment from "moment";

const SpotAssessementGroup = () => {
  const navigate = useNavigate();

  const {
    subjectList,
    classes,

    questionFilters,
    handleChangeGroupFilterParams,
   

    isLoading,
    isBtnLoading,
  
    selectedQuestion,
    setSelectedQuestion,
    handleSearch,
    handleEditQuestion,


    isOpenEditModal,
    setIsOpenEditModal,


    allGroups,
  } = useQuestionGroup();

 

  return (
    <PageLayout>
      <EPageTitle
        title="Question Groups"
        end={
          <EButton
            className="btn-light"
            rightIcon={<BsUpload />}
            onClick={() => navigate("/upload-spot-question-Group")}
          >
            Upload Question Groups
          </EButton>
        }
      />

      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between items-end gap-4 w-full">
          <div className="flex gap-5">
            <Select
              required
              label="Select Grade"
              placeholder="Select"
              size="md"
              data={classes?.map((each) => ({
                value: String(each.id),
                label: each.name,
              }))}
              value={String(questionFilters.selectedGrade) || null}
              onChange={(e) =>
                handleChangeGroupFilterParams("selectedGrade", String(e))
              }
            />
            <Select
              required
              label="Subject"
              placeholder="Select"
              size="md"
              data={subjectList?.map((item) => ({
                value: String(item.id),
                label: item.name,
              }))}
              value={String(questionFilters.selectedSubject) || null}
              onChange={(e) =>
                handleChangeGroupFilterParams("selectedSubject", String(e))
              }
            />
            <DatePicker
              label="Select Start Date"
              placeholder="MM/DD/YYYY"
              value={questionFilters?.WeekStartDate}
              onChange={(e: any) =>
                handleChangeGroupFilterParams("WeekStartDate", e)
              }
              maxDate={
                questionFilters?.WeekEndDate
                  ? new Date(questionFilters.WeekEndDate)
                  : undefined
              }
            />
            <DatePicker
              label="Select End Date"
              placeholder="MM/DD/YYYY"
              value={questionFilters?.WeekEndDate}
              onChange={(e: any) =>
                handleChangeGroupFilterParams("WeekEndDate", e)
              }
              minDate={
                questionFilters?.WeekStartDate
                  ? new Date(questionFilters.WeekStartDate)
                  : undefined
              }
            />
            {/* <Select
              required
              // className="w-[33%]"
              label="Question Group"
              placeholder="Select"
              size="md"
              data={allGroups?.map((each: any) => ({
                value: String(each.question_Group_Instruction_Id),
                label: each.competency,
              }))}
              value={String(selectedGroup) || null}
              onChange={(e) => {
                setSelectedGroup(String(e));
              }}
            /> */}
          </div>
          <div className="flex gap-3">
            <EButton
              className="btn-blue"
              disabled={
                questionFilters.selectedGrade.length > 0 &&
                questionFilters.selectedSubject.length > 0 &&
                questionFilters.WeekStartDate &&
                questionFilters.WeekEndDate
                  ? false
                  : true
              }
              loading={isLoading}
              // disabled={true}
              onClick={handleSearch}
            >
              Search
            </EButton>
          </div>
        </div>
      </div>

      {/* TAble list */}
      <div className="mt-10">
        {isLoading ? (
          <Skeleton height={280} />
        ) : (
          <div>
            {!allGroups?.length ? (
              // <></>
              <EEmpty title={"Select required filters and tap to search"} />
            ) : (
              <ETable>
                <thead>
                  <tr>
                    {/* <th>Question Image</th> */}
                    <th>Group</th>
                    <th>Total Question</th>
                    <th>Minimum Attempt Question</th>
                    <th>Group Instruction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allGroups?.map((item: any) => (
                    <tr>
                      {/* {e?.base64QuestionImage ? (
                          <td>
                            <Image
                              height={100}
                              width={110}
                              src={e?.base64QuestionImage}
                              fit="contain"
                            />
                          </td>
                        ) : (
                          <td>--</td>
                        )} */}

                      <td>{item?.competency}</td>
                      <td>{item?.total_Question}</td>
                      <td>{item?.min_Attempt_Question}</td>
                      <td>{item?.question_Group_Instruction}</td>
                      <td>
                        <p
                          className="text-green-500 flex cursor-pointer"
                          onClick={() => {
                            setSelectedQuestion({
                              ...item,
                              week_Start_Date: moment(
                                item?.week_Start_Date
                              ).toDate(),
                              week_End_Date: moment(
                                item?.week_End_Date
                              ).toDate(),
                            });
                          }}
                        >
                          View details...
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ETable>
            )}
          </div>
        )}
      </div>

      {/* SELECTED QUESTION INFORMATION */}
      <EDrawer
        opened={!!selectedQuestion}
        position="right"
        onClose={() => setSelectedQuestion(undefined)}
        title="Group Details"
        drawerContent={
          <ScrollArea className="h-full pb-10 pr-2 flex flex-col justify-between">
            <div>
              <Card className="bg-slate-50">
                <InfoLabel
                  textSize="md"
                  label="Question Group Instruction"
                  value={selectedQuestion?.question_Group_Instruction ?? "-"}
                />
                <InfoLabel
                  label="class Name"
                  value={selectedQuestion?.class_Name}
                />
                <InfoLabel
                  label="Subject Name"
                  value={selectedQuestion?.subject_Name ?? "-"}
                />

                <InfoLabel
                  label="Week Start Date"
                  value={
                    selectedQuestion?.week_Start_Date
                      ? moment(selectedQuestion?.week_Start_Date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }
                />

                <InfoLabel
                  label="Week End Date"
                  value={
                    selectedQuestion?.week_End_Date
                      ? moment(selectedQuestion?.week_End_Date).format(
                          "DD/MM/YYYY"
                        )
                      : "-"
                  }
                />

                <InfoLabel
                  label="Total Question"
                  value={selectedQuestion?.total_Question ?? "-"}
                />

                <InfoLabel
                  label="Minimum Attempt Question"
                  value={selectedQuestion?.min_Attempt_Question ?? "-"}
                />
                {/* {selectedQuestion?.base64QuestionImage ? (
                  <Image
                    width={240}
                    height={240}
                    src={selectedQuestion?.base64QuestionImage}
                    fit="contain"
                  />
                ) : (
                  <></>
                )} */}
                {/* {
                                    selectedQuestion?.base64QuestionImage ?
                                        <Anchor
                                            // href={selectedQuestion?.base64QuestionImage}
                                            target="_blank"
                                            className='text-xs'
                                            onClick={() => window.open(selectedQuestion?.base64QuestionImage, "_blank")}
                                        >
                                            Tap to view question media
                                        </Anchor>
                                        : <></>
                                } */}
                {/* <InfoLabel label='Description' value={selectedQuestion?.question_Instruction ?? "-"} /> */}
              </Card>
              {/* <Card className="bg-slate-50 mt-2">
                <div className="flex items-center gap-2 text-slate-600">
                  <GrList />
                  <p className="text-lg font-semibold ">Options List</p>
                </div>
                {
                  // selectedSchedule?.tests.filter((e) => e?.test_Type.toLowerCase() === "pre") &&
                  selectedQuestion?.questionsOptions?.map(
                    (each: any, index: number) => (
                      <Card className="bg-slate-100 p-3 my-2" withBorder>
                        <div className="flex justify-end items-center ">
                          {each?.is_Correct ? (
                            <Badge color="green">{"Correct Answer"}</Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                        {each?.option_Text ? (
                          <InfoLabel
                            textSize="sm"
                            label="Text"
                            value={each?.option_Text}
                          />
                        ) : (
                          <></>
                        )}
                        {each?.base64OptionImage ? (
                          <>
                           
                            <Image
                              height={200}
                              width={200}
                              fit="contain"
                              src={each?.base64OptionImage}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        
                      </Card>
                    )
                  )
                }
              </Card> */}
            </div>

            <div className="flex justify-end mt-3 gap-5 px-2">
              {/* <EButton
                size="md"
                className="btn-red"
                onClick={() => setIsOpenDeleteModal(true)}
              >
                <MdDelete /> <span className="pl-2">Delete</span>
              </EButton> */}
              {selectedQuestion?.options_Media_Type_Id == 3 ||
              selectedQuestion?.options_Media_Type_Id == 5 ? (
                <></>
              ) : (
                <EButton
                  className="btn-green"
                  onClick={() => {
                    // navigate("/upload-spot-question-bank", {
                    //   state: {
                    //     filters: { ...questionFilters, group: selectedGroup },
                    //     questionDetail: selectedQuestion,
                    //   },
                    // });
                    setIsOpenEditModal(true);
                  }}
                >
                  <FiEdit /> <span className="pl-2">Edit Group</span>
                </EButton>
              )}
            </div>
          </ScrollArea>
        }
      />

      {/* Delete question modal */}
      {/* <Modal
        opened={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        withCloseButton={false}
        title="Are you sure you want to delete ?"
      >
        <div className="flex gap-3 justify-end">
          <EButton
            className="btn-light"
            onClick={() => {
              setIsOpenDeleteModal(false);
            }}
          >
            Cancel
          </EButton>
          <EButton
            className="btn-red"
            loading={isBtnLoading}
            onClick={() => handleDeleteQuestionFromQuestionBank()}
          >
            Delete
          </EButton>
        </div>
      </Modal> */}

      {/* Edit Question Modal */}
      <Modal
        opened={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        size="xl"
        withCloseButton={false}
      >
        <div className="flex flex-col gap-4 justify-between">
          {/* <ScrollArea> */}
          {/* <Grid>
            <Grid.Col span={6}>
              <InfoLabel
                label="Assessment"
                value={selectedQuestion?.assessment_Type}
              />
              <InfoLabel
                label="Media type"
                value={selectedQuestion?.media_Type}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <InfoLabel
                label="Question type"
                value={
                  selectedQuestion?.question_Type_Id == 1
                    ? "Single Answer"
                    : selectedQuestion?.question_Type_Id == 2
                    ? "Multiple Answer"
                    : "-"
                }
              />
              <InfoLabel
                label="Option media type"
                value={selectedQuestion?.option_Media_Type}
              />
            </Grid.Col>
          </Grid> */}
          {/* Question */}
          <Grid className="border">
            <Grid.Col span={8}>
              <Textarea
                label="Question Group Instruction"
                value={selectedQuestion?.question_Group_Instruction}
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      question_Group_Instruction: e?.target?.value,
                    });
                  }
                }}
              />

              <TextInput
                label="Minimum Attempt Question"
                value={selectedQuestion?.min_Attempt_Question}
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      min_Attempt_Question: e?.target?.value,
                    });
                  }
                }}
              />

              <Select
                required
                label="Grade"
                placeholder="Select"
                size="md"
                data={classes?.map((each) => ({
                  value: String(each.id),
                  label: each.name,
                }))}
                value={String(selectedQuestion?.class_Id) || null}
                // onChange={(e) =>
                //   handleChangeGroupFilterParams("selectedGrade", String(e))
                // }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      class_Id: String(e),
                    });
                  }
                }}
              />
              <Select
                required
                label="Subject"
                placeholder="Select"
                size="md"
                data={subjectList?.map((item) => ({
                  value: String(item.id),
                  label: item.name,
                }))}
                value={String(selectedQuestion?.subject_Id) || null}
                // onChange={(e) =>
                //   handleChangeGroupFilterParams("selectedSubject", String(e))
                // }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      subject_Id: String(e),
                    });
                  }
                }}
              />
              <DatePicker
                label="Select Start Date"
                placeholder="MM/DD/YYYY"
                value={selectedQuestion?.week_Start_Date}
                // value={new Date(selectedQuestion?.week_Start_Date)}
                // onChange={(e: any) =>
                //   handleChangeGroupFilterParams("WeekStartDate", e)
                // }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      week_Start_Date: e,
                    });
                  }
                }}
                maxDate={
                  selectedQuestion?.week_End_Date
                    ? new Date(selectedQuestion.week_End_Date)
                    : undefined
                }
              />
              <DatePicker
                label="Select End Date"
                placeholder="MM/DD/YYYY"
                value={selectedQuestion?.week_End_Date}
                // value={new Date(selectedQuestion?.week_End_Date)}
                // onChange={(e: any) =>
                //   handleChangeGroupFilterParams("WeekEndDate", e)
                // }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      week_End_Date: e,
                    });
                  }
                }}
                minDate={
                  selectedQuestion?.week_Start_Date
                    ? new Date(selectedQuestion.week_Start_Date)
                    : undefined
                }
              />

              {/* <Textarea
                label="Enlarged Text"
                value={enlargedText}
                onChange={(e) => {
                  setEnlargedText(e?.target?.value.replaceAll("\n", " "));
                }}
              />
              {selectedQuestion?.media_Type == "Text/Image" ? (
                <>
                  <p className="text-xs text-slate-400 mt-3">Question Image</p>
                  <div className="flex justify-between items-center">
                    <Image
                      style={{ objectFit: "contain" }}
                      className=""
                      fit="contain"
                      height={240}
                      width={250}
                      src={selectedQuestion?.base64QuestionImage}
                      alt="ques.. ?"
                    />
                    <TbReplace size={32} />
                    <div className="bg-slate-100 w-[120px] h-[130px] p-10 items-center justify-center flex rounded">
                      <ESelectFileForQuestionBank
                        maxSizeInKB={2000000}
                        onClear={() => {}}
                        onSelect={(file) => {
                          if (selectedQuestion) {
                            setSelectedQuestion({
                              ...selectedQuestion,
                              base64QuestionImage: `${BASE64_INITIALS}${file}`,
                            });
                          }
                        }}
                        dimensionsToMatch={{ width: 500, height: 500 }}
                        onSelectPreview={(preview) => {}}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {selectedQuestion?.media_Type == "Text/Video" ? (
                <div>
                  <TextInput
                    label="Media URL"
                    value={selectedQuestion?.media_Url}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion({
                          ...selectedQuestion,
                          media_Url: e?.target?.value,
                        });
                      }
                    }}
                  />
                  <Anchor href={selectedQuestion?.media_Url} target="_blank">
                    <p className="text-xs mt-2">Open media link</p>
                  </Anchor>
                </div>
              ) : (
                <></>
              )}
              {selectedQuestion?.media_Type == "Text/Audio" ? (
                <div>
                  <TextInput
                    label="Media URL"
                    value={selectedQuestion?.media_Url}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion({
                          ...selectedQuestion,
                          media_Url: e?.target?.value,
                        });
                      }
                    }}
                  />
                  <Anchor href={selectedQuestion?.media_Url} target="_blank">
                    <p className="text-xs mt-2">Open Audio media link</p>
                  </Anchor>
                </div>
              ) : (
                <></>
              )} */}
            </Grid.Col>
            <Grid.Col span={4}>
              {/* <TextInput
                label="No. of marks"
                maxLength={2}
                error={
                  selectedQuestion && isNaN(selectedQuestion?.marks)
                    ? "Value is not a number"
                    : ""
                }
                value={
                  selectedQuestion?.marks ? selectedQuestion?.marks : undefined
                }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      marks: +e?.target?.value,
                    });
                  }
                }}
              /> */}
            </Grid.Col>
          </Grid>

          {/* Options */}
          {/* {selectedQuestion?.questionsOptions?.map((each, index) => (
            <Grid>
              <Grid.Col span={10}>
                <p className="text-sm text-slate-500">Option {index + 1}</p>
                {selectedQuestion?.option_Media_Type === "Text" ||
                selectedQuestion?.option_Media_Type == "Text/Image" ? (
                  <TextInput
                    value={each?.option_Text}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion((prevDetails: any) => {
                          const updatedOptions = [
                            ...prevDetails.questionsOptions,
                          ];
                          updatedOptions[index] = {
                            ...updatedOptions[index],
                            option_Text: e?.target?.value,
                          };
                          return {
                            ...prevDetails,
                            questionsOptions: updatedOptions,
                          };
                        });
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
                {selectedQuestion?.option_Media_Type === "Image" ||
                selectedQuestion?.option_Media_Type == "Text/Image" ? (
                  <div className="flex items-center justify-around">
                    <Image
                      height={200}
                      width={200}
                      className="mt-2"
                      fit="contain"
                      src={each?.base64OptionImage}
                      alt="option"
                    />

                    <TbReplace size={32} />
                    <div className="bg-slate-100 w-[120px] h-[130px] p-10 items-center justify-center flex rounded">
                      <ESelectFileForQuestionBank
                        maxSizeInKB={2000000}
                        onClear={() => {}}
                        onSelect={(file) => {
                          if (selectedQuestion) {
                            setSelectedQuestion((prevDetails: any) => {
                              const updatedOptions = [
                                ...prevDetails.questionsOptions,
                              ];
                              updatedOptions[index] = {
                                ...updatedOptions[index],
                                base64OptionImage: `${BASE64_INITIALS}${file}`,
                              };
                              return {
                                ...prevDetails,
                                questionsOptions: updatedOptions,
                              };
                            });
                          }
                        }}
                        dimensionsToMatch={{ width: 500, height: 500 }}
                        onSelectPreview={(preview) => {}}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {each?.option_Media_Url && !each?.base64OptionImage ? (
                  <>
                   
                    <Anchor
                      href={each?.option_Media_Url}
                      target="_blank"
                      className="text-xs"
                    >
                      {" "}
                      Tap to view media
                    </Anchor>
                  </>
                ) : (
                  <></>
                )}
              </Grid.Col>
              <Grid.Col span={2} className="flex items-center mt-3">
                <Checkbox
                  checked={each?.is_Correct}
                  onChange={(e) => {
                    if (selectedQuestion) {
                      setSelectedQuestion((prevDetails: any) => {
                        const updatedOptions = [
                          ...prevDetails.questionsOptions,
                        ];
                        updatedOptions[index] = {
                          ...updatedOptions[index],
                          is_Correct: !updatedOptions[index]?.is_Correct,
                        };
                        return {
                          ...prevDetails,
                          questionsOptions: updatedOptions,
                        };
                      });
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
          ))} */}

          {/* </ScrollArea> */}
          {/* Footer */}
          <div className="border-t mt-5 pt-2 flex gap-3 justify-end">
            <EButton
              className="btn-light"
              onClick={() => {
                setIsOpenEditModal(false);
              }}
            >
              Cancel
            </EButton>
            <EButton
              className="btn-red"
              loading={isBtnLoading}
              onClick={() => {
                handleEditQuestion();
              }}
            >
              Update
            </EButton>
          </div>
        </div>
      </Modal>
    </PageLayout>
  );
};

export default SpotAssessementGroup;
