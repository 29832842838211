import React, { useState } from 'react'
import PageLayout from '../../../../PageLayout';
import { EPageTitle } from '../../../../libs/ui';
import { Tabs } from '@mantine/core';
import SchoolWiseClassroomHindiObservation from './Sections/SchoolWiseClassroomHindiObservation';
import SchoolWiseClassroomMathObservation from './Sections/SchoolWiseClassroomMathObservation';
import MonthlyHindiObservationReport from './Sections/MonthlyHindiObservationReport';
import MonthlyMathObservationReport from './Sections/MonthlyMathObservationReport';
import MonthlyHindiObservationReportNewQuestions from './Sections/MonthlyHindiObservationReportNewQuestions';
import MonthlyMathObservationReportNewQuestions from './Sections/MonthlyMathObservationReportNewQuestions';

export default function SchoolWiseClassroomObservation() {

    const [activeTab, setActiveTab] = useState<string | null>('first');

    return (
        <PageLayout>
            <EPageTitle
                title="School wise Classroom Observation"
            />

            <Tabs orientation="vertical" value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="first">Hindi observation Report</Tabs.Tab>
                    <Tabs.Tab value="second">Math observation Report</Tabs.Tab>
                    <Tabs.Tab value="three">Monthly Hindi observation Report</Tabs.Tab>
                    <Tabs.Tab value="four">Monthly Math observation Report</Tabs.Tab>
                    <Tabs.Tab value="five">Monthly Hindi observation Report &#40;New&#41;</Tabs.Tab>
                    <Tabs.Tab value="six">Monthly Math observation Report &#40;New&#41;</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="first" px="xs">
                    <SchoolWiseClassroomHindiObservation />
                </Tabs.Panel>
                <Tabs.Panel value="second" px="xs">
                    <SchoolWiseClassroomMathObservation />
                </Tabs.Panel>
                <Tabs.Panel value="three" px="xs">
                    <MonthlyHindiObservationReport />
                </Tabs.Panel>
                <Tabs.Panel value="four" px="xs">
                    <MonthlyMathObservationReport />
                </Tabs.Panel>
                <Tabs.Panel value="five" px="xs">
                    <MonthlyHindiObservationReportNewQuestions />
                </Tabs.Panel>
                <Tabs.Panel value="six" px="xs">
                    <MonthlyMathObservationReportNewQuestions />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

