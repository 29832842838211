import React from 'react'
import PageLayout from '../../../PageLayout';
import { EPageTitle } from '../../../libs/ui';
import { Tabs } from '@mantine/core';
import UploadQuestionForm from '../../QuestionBank/Components/Uploader/UploadQuestionForm';
import UploadGroupQuestionSpot from './uploader/UploadGroupQuestionSpot';

const SpotAssessementAddGroup = () => {

  return (
    <PageLayout>
      <EPageTitle
        title="Upload Group"
        // end={<>
        //     <EButton
        //         className="btn-light mr-2"
        //         rightIcon={<FaFileDownload />}
        //         onClick={() => console.log("Download File")}
        //     >
        //         Download CSV Template
        //     </EButton>
        //     <EButton
        //         className="btn-light"
        //         rightIcon={<FaArrowLeft />}
        //         onClick={() => navigate(-1)}
        //     >
        //         Go Back
        //     </EButton>
        // </>}
      />

      <Tabs defaultValue="add_manually" keepMounted={false}>
        {/* ADD QUESTIONS MANUALLY */}
        <Tabs.Panel value="add_manually" pt="xs">
          <UploadGroupQuestionSpot />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}

export default SpotAssessementAddGroup