import { Grid, Modal, ScrollArea } from '@mantine/core'
import React, { useState } from 'react'
import { EButton, EDrawer, EInfoPanel, ELabelView, EModal, ESectionDivider, ETable } from '../../../libs/ui'
import { INewSurveyQuestion, INewSurveyQuestionGroup } from '../../../types'
import InfoLabel from '../../../libs/ui/src/InfoLabel/InfoLabel'
import { FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
type IProp = {
    questionGroup : INewSurveyQuestionGroup
}
export default function QuestionGroup({questionGroup}:IProp) {

    const [isOpenDrawer,setIsOpenDrawer]=useState<boolean>(false)
    const [isOpenDeleteModal,setIsOpenDeleteModal]=useState<boolean>(false)
    const [selectedQuestion ,setSelectedQuestion] =useState<INewSurveyQuestion | undefined>(undefined)

  return (
    <div>
        <ETable>
          <thead>
            <tr>
                <th>Question no.</th>
                <th>Question title</th>
                <th>Question description</th>
                <th>Survey Character Limit</th>
                <th></th>
            </tr>
          </thead>
          <tbody>{
            questionGroup?.questions?.map((each,index)=>
            <tr>
                <td>{index+1}.</td>
                <td>{each?.survey_Question_Title}</td>
                <td>{each?.survey_Question_Description}</td>
                <td>{each?.survey_Character_Limit}</td>
                <td><p className='text-blue-400 cursor-pointer'
                onClick={()=>{
                    setSelectedQuestion(each)
                    setIsOpenDrawer(true)
                }}
                >...view details</p></td>
            </tr>)}
          </tbody>
        </ETable>
    <EDrawer 
    opened={isOpenDrawer} 
    position="right"
    title=' Question Details'
    onClose={()=>{
        setSelectedQuestion(undefined)
        setIsOpenDrawer(false)}} 
    drawerContent={
        <ScrollArea type="never" className='h-[92%]'>
         <div className='bg-slate-50 p-2 rounded flex flex-col gap-2'>  
         <div className='flex justify-end px-2'>  
        <InfoLabel
          textSize="sm"
          label='Survey Character Limit'
          value={selectedQuestion?.survey_Character_Limit ?? "-"}
        />
            </div> 
         <ELabelView
          label='Question title'
          value={selectedQuestion?.survey_Question_Title ?? "-"}
        />
        <ELabelView
          label='Question Instruction'
          value={selectedQuestion?.survey_Question_Instructions ?? '-'}
        />
        <ELabelView
          label='Question Description'
          value={selectedQuestion?.survey_Question_Description ?? "-"}
        />
        
        <div className='flex justify-between pr-6'>
        <ELabelView
          label='Survey Question Type Id'
          value={selectedQuestion?.survey_Question_Type_Id.toString() ?? "-"}
        />
        <ELabelView
          label='Survey Option Type Id'
          value={selectedQuestion?.survey_Sub_Question_Type_Id.toString() ?? "-"}
        />
        </div>
        </div>
         
         <ESectionDivider title='Options' classes='pb-0'/>
          {
            selectedQuestion?.survey_Options_List?.map((each ,index)=>(
                <div className='bg-slate-100 rounded-sm mt-2 p-2'>
                    <p>{index+1 +". "+ each?.survey_Options}</p>
                </div>
            ))
          } 
          <div className='flex gap-2 justify-end mt-4 mb-10'>
            <EButton
            className="btn-red"
            onClick={()=>setIsOpenDeleteModal(true)}
            >
                Delete <MdDelete size={22} className='ml-2'/>
            </EButton>
            {/* <EButton
            className="btn-green"
            >
            Edit <FiEdit size={22} className='ml-2'/>
            </EButton> */}
          </div>
        </ScrollArea>
    }
        />

        {/* Delete Modal */}
        <Modal
        opened={isOpenDeleteModal}
        onClose={() => { }}
        withCloseButton={false}
        title="Are you sure you want to delete ?">
        <div className='flex gap-3 justify-end'>
          <EButton
            className="btn-light"
            onClick={() => {
            //   setSelectedDeleteItemIndex(undefined)
              setIsOpenDeleteModal(false)
            }}
          >Cancel</EButton>
          <EButton
            className="btn-red"
            // onClick={() => handleDeleteTestFromList()}
          >Delete</EButton>
        </div>
       </Modal>
   </div>
  )
}
