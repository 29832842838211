import React, { useState } from 'react'
import PageLayout from '../../../../PageLayout';
import { EPageTitle } from '../../../../libs/ui';
import { Tabs } from '@mantine/core';
import ClassroomHindiObservation from './Sections/ClassroomHindiObservation';
import ClassroomMathObservation from './Sections/ClassroomMathObservation';
import MentorWiseClassroomObservation from './Sections/MentorWiseClassroomObservation';

export default function ClassroomObservation()  {

    const [activeTab, setActiveTab] = useState<string | null>('first');

    return (
        <PageLayout>
            <EPageTitle
                title="Classroom Observation"
            />

            <Tabs value={activeTab} onTabChange={setActiveTab}>
                <Tabs.List>
                    <Tabs.Tab value="first"> Hindi observation Report</Tabs.Tab>
                    <Tabs.Tab value="second">Math observation Report</Tabs.Tab>
                    {/* <Tabs.Tab value="third">Mentor wise classroom observation</Tabs.Tab> */}
                </Tabs.List>

                <Tabs.Panel value="first" pt="xs">
                    <ClassroomHindiObservation/>
                </Tabs.Panel>
                <Tabs.Panel value="second" pt="xs">
                    <ClassroomMathObservation/>
                    </Tabs.Panel>
                {/* <Tabs.Panel value="third" pt="xs">
                    <MentorWiseClassroomObservation/>
                    </Tabs.Panel> */}
            </Tabs>
        </PageLayout>
    )
}
