import { useState } from "react";

import { calculateCurrentMonth, calculateYearForDashboard, handleDownload, showToast } from "../../../../../utils/utility";
import { ISchoolWiseClassroomHindiObservationReport } from "../../../../../libs/data-access/types";
import moment from "moment";

export default function useMonthlyMathObservationReportNewQuestions() {

    const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
    const [selectedSession, setSelectedSession] = useState<string | undefined>(
      parseInt(moment().format("MM"), 10) > 4
        ? moment().add(1, "year").format("YYYY")
        : moment().format("YYYY")
    );
    const [selectStartDate, setSelectedStartDate] = useState<string | undefined>(undefined)
    const [selectEndDate, setSelectedEndDate] = useState<string | undefined>(undefined)
    const [schoolWiseClassroomHindiObservationReport, setSchoolWiseClassroomHindiObservationReport] = useState<ISchoolWiseClassroomHindiObservationReport[]>([]);
    // data for CSV download
    const [schoolWiseClassroomHindiObservationCSV, setSchoolWiseClassroomHindiObservationCSV] = useState<{ headers: any[], content: any[] }>();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleSelectMonth = (value: string) => {
        setSelectedMonth(value);
        setSchoolWiseClassroomHindiObservationReport([]);
    };

    /* API CALLS */
    const fetchSchoolWiseClassroomHindiObservation = async () => {

        setIsLoading(true);
        const response = await handleDownload(
          `https://report.nipunharyana.in/api/report/dashboard/observation/GetObravtionReportWithSelectedQuestionMath?GetMonthYearFromDate=${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}`,
          `School__wise_Classroom_Math_Observation_Report_New_for_${moment(
            selectedMonth
          ).format("MMM")}-${moment().format("YYYY")}.csv`
        ).catch((error) => {
          console.log("Error while downloading report: ", error);
        });
        if (response) {
        } else {
            showToast({
                type: "error",
                title: "Error while fetching report",
                message: "Something went wrong!"
            });

        };
        setIsLoading(false);

    };

    return {
      isLoading,
      selectedMonth,
      selectStartDate,
      setSelectedStartDate,
      selectEndDate,
      setSelectedEndDate,
      handleSelectMonth,
      setSelectedMonth,
      schoolWiseClassroomHindiObservationReport,
      schoolWiseClassroomHindiObservationCSV,
      fetchSchoolWiseClassroomHindiObservation,
      selectedSession,
      setSelectedSession,
    };

}
