import React, { useContext, useEffect, useState } from 'react'
import { calculateCurrentMonth, showToast } from '../../../../../utils/utility';
import useMasterData from '../../../../Hooks/useMasterData';
import { AppContext } from '../../../../../AppContext';
import { ROLES } from '../../../../../Routes/routes';
import { useTeacherTraining } from '../../../../../libs/data-access';
import { ITrainingData } from '../../../../../libs/data-access/types';
import * as XLSX from "xlsx";

export default function useTeacherTrainingReport() {
  const { user, role } = useContext(AppContext);

  const { fetchTeacherTrainingReportFast, fetchTeacherTrainingData } =
    useTeacherTraining();

  const {
    getDistrictByStateId,
    districts,
    getBlocksByDistrictId,
    blocks,
    getClusterByBlockId,
    clusterSchools,
  } = useMasterData();

  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [selectedMonth, setSelectedMonth] = useState<string | undefined>(calculateCurrentMonth());
  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(
    undefined
  );
  const [selectedBlock, setSelectedBlock] = useState<string | undefined>(
    undefined
  );
  const [selectedCluster, setSelectedCluster] = useState<string | undefined>(
    undefined
  );
  const [trainingData, setTrainingData] = useState<any[]>([]);
  const [reportLevel, setReportLevel] = useState<
    "District" | "Block" | "Cluster" | "School"
  >("District");

  const [trainingTitle, setTrainingTitle] = useState<any>([]);
  const [selectedTitle, setSelectedTitle] = useState<any>(undefined);
  const [isLoadingSchedule, setIsLoadingSchedule] = useState<any>(false);
  const [isLoadingPre, setIsLoadingPre] = useState<any>(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentItems, setCurrentItems] = useState<any>([]); // Specify the type here
  const [searchQuery, setSearchQuery] = useState("");

  const fetchTrainingTitle = async () => {
    setIsLoading(true);
    const res = await fetchTeacherTrainingData(
      `/GetTeacherTrainingScheduleTitle`
    );
    if (res.isSuccess) {
      setTrainingTitle(res.data);
    } else {
      setTrainingTitle([]);
      showToast({
        type: "error",
        message: res.message || "Fail to get teacher training Title",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      fetchTrainingTitle();
      getDistrictByStateId(1);
      if (role?.includes(ROLES.BLOCK)) {
        setSelectedDistrict(
          String(user?.details?.blockUserDetails?.district_Id)
        );
        setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id));
      }
      if (role?.includes(ROLES.DISTRICT)) {
        setSelectedDistrict(
          String(user?.details?.districtUserDetails?.district_Id)
        );
      }
    }
  }, [isFirst]);

  useEffect(() => {
    if (selectedDistrict) {
      getBlocksByDistrictId(+selectedDistrict);
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedBlock) {
      getClusterByBlockId(+selectedBlock);
    }
  }, [selectedBlock]);

  const handleClear = () => {
    setSelectedDistrict(undefined);
    setSelectedBlock(undefined);
    setSelectedCluster(undefined);
  };
  const fetchTrainingData = async () => {
    setIsLoading(true);
    // setReportLevel(
    //   selectedCluster
    //     ? "School"
    //     : selectedBlock
    //     ? "Cluster"
    //     : selectedDistrict
    //     ? "Block"
    //     : "District"
    // );

    setReportLevel(
        selectedBlock
        ? "Block"
        : selectedDistrict
        ? "District"
        : "District"
    );

    // const res = await fetchTeacherTrainingReportFast(`TrainingReport?DistrictId=${selectedDistrict ? +selectedDistrict : 0}&BlockId=${ selectedBlock ? +selectedBlock :0}&ClusterId=${selectedCluster ? +selectedCluster : 0}`)
    const res = await fetchTeacherTrainingData(
      `/GetOverAllTeacherTraningReport?districtId=${
        selectedDistrict ? +selectedDistrict : 0
      }&blockId=${
        selectedBlock ? +selectedBlock : 0
      }&Training_Schedule_Header_Id=${
        selectedTitle ? +selectedTitle : 0
      }&OFFSET=${currentPage * 10}&PAGESIZE=${itemsPerPage}`
    );
    if (res.isSuccess) {
      setTotalRecords(res?.data[0]?.total);
      setTrainingData(res.data);
    } else {
      setTrainingData([]);
      showToast({
        type: "error",
        message: res.message || "Fail to get teacher training report",
      });
    }
    setIsLoading(false);
  };

  const getTeacherTrainingScheduleReport = async () => {
    setIsLoadingSchedule(true);

    // Create an array to hold the query parameters
    let queryParams = [];

    // Add parameters to the array only if they have valid values
    if (selectedDistrict) {
      queryParams.push(`districtId=${+selectedDistrict}`);
    }
    if (selectedBlock) {
      queryParams.push(`blockId=${+selectedBlock}`);
    }
    if (selectedTitle) {
      queryParams.push(`Training_Schedule_Header_Id=${+selectedTitle}`);
    }

    const response = await fetchTeacherTrainingData(
      `/GetTeacherTranningSchedule?${queryParams.join("&")}`
    );

    // const response = await fetchTeacherTrainingData(
    //   `/GetTeacherTranningSchedule?districtId=${
    //     selectedDistrict ? +selectedDistrict : null
    //   }&blockId=${
    //     selectedBlock ? +selectedBlock : null
    //   }&Training_Schedule_Header_Id=${selectedTitle ? +selectedTitle : null}`
    // );
    if (response.isSuccess) {
      const resData = response?.data;
      let filterKey = [
        "created_On",
        "training_Schedule_Header_Id",
        "teacher_Training_Schedule_Id",
      ];
      const data = resData?.map((item: any) => {
        const filteredItem = {} as any;
        for (const key in item) {
          if (!filterKey.includes(key)) {
            filteredItem[key] = item[key];
          }
        }
        return filteredItem;
      });

      setIsLoadingSchedule(false);
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "training_Schedule");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsLoadingPre(false);
    } else {
      setIsLoadingSchedule(false);
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
    }
  };

  const getTeacherTrainingTestReport = async () => {
    setIsLoadingPre(true);

    // Create an array to hold the query parameters
    let queryParams = [];

    // Add parameters to the array only if they have valid values
    if (selectedDistrict) {
      queryParams.push(`districtId=${+selectedDistrict}`);
    }
    if (selectedBlock) {
      queryParams.push(`blockId=${+selectedBlock}`);
    }
    if (selectedTitle) {
      queryParams.push(`Training_Schedule_Header_Id=${+selectedTitle}`);
    }

    const response = await fetchTeacherTrainingData(
      `/GetTeacherWisePreAndPostTest?${queryParams.join("&")}`
    );

    //   const response = await fetchTeacherTrainingData(
    //     `/GetTeacherWisePreAndPostTest?districtId=${
    //       selectedDistrict ? +selectedDistrict : null
    //     }&blockId=${
    //       selectedBlock ? +selectedBlock : null
    //     }&Training_Schedule_Header_Id=${selectedTitle ? +selectedTitle : null}`
    //   );
    if (response.isSuccess) {
      const resData = response?.data;
      let filterKey = [
        "created_On",
        "training_Schedule_Header_Id",
        "teacher_Training_Schedule_Id",
      ];
      const data = resData?.map((item: any) => {
        const filteredItem = {} as any;
        for (const key in item) {
          if (!filterKey.includes(key)) {
            filteredItem[key] = item[key];
          }
        }
        return filteredItem;
      });

      setIsLoadingPre(false);
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "training_Status");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsLoadingPre(false);
    } else {
      setIsLoadingPre(false);
      showToast({
        type: "error",
        message: "Something went wrong!",
      });
    }
  };

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    //   const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredElements = trainingData?.filter((item: any) =>
      item?.training_Title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    // const currentItems = filteredElements.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(filteredElements); // Update currentItems state with filtered data
  }, [searchQuery, trainingData]);

  return {
    isLoading,
    districts,
    blocks,
    selectedBlock,
    selectedDistrict,
    selectedCluster,
    clusterSchools,
    setSelectedBlock,
    setSelectedCluster,
    setSelectedDistrict,
    handleClear,
    fetchTrainingData,
    trainingData,
    reportLevel,
    trainingTitle,
    selectedTitle,
    setSelectedTitle,
    isLoadingSchedule,
    isLoadingPre,
    getTeacherTrainingScheduleReport,
    getTeacherTrainingTestReport,

    currentPage,
    setCurrentPage,
    itemsPerPage,
    nextPage,
    prevPage,
    totalRecords,
    setSearchQuery,
    searchQuery,
    currentItems,
  };
}
