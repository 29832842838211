import { Textarea, Tooltip, Image, Alert } from '@mantine/core';
import React from 'react'
import { MdOutlineHideImage } from 'react-icons/md';
import { ESelectFileForQuestionBank } from '../../../../libs/ui';
import { TiTickOutline } from 'react-icons/ti';
import { GiCancel } from 'react-icons/gi';
import { BASE64_INITIALS } from '../../../../initialStates';
import { BsInfoCircle } from 'react-icons/bs';
import { questionOptions } from '../../../../libs/data-access/types';

interface IProps {
    questionFilters: any;
    optionDetails: questionOptions | undefined;
    handleChangeOptionDetails: (text: string) => void;
    optionList: questionOptions[];
    handleAddNewOption: (option: any) => void;
    removeOption: () => void;
};

export default function AddNewOptionFrame({
    questionFilters,
    optionDetails,
    handleChangeOptionDetails,
    optionList,
    handleAddNewOption,
    removeOption
}: IProps) {
    return (
        <div className="input-item">
            <div className="radio-btn">
                {/* <Radio
                size="md"
                color="green"
                checked={each.isCorrectOption}
                value={String(each.id)}
                name={questionFilters == 2 ? "single" : ""}
                //   onChange={() => handleSetCorrectOption(Number(each.id))}
                onClick={() => handleSetCorrectOption(Number(each?.id))}
              /> */}
            </div>


            <div className="action-icons">
                {optionDetails?.option_Text
                    ?
                    <Tooltip
                        withArrow
                        label="Save option"
                    >
                        <a>
                            <TiTickOutline
                                size={20}
                                className="cursor-pointer"
                                onClick={() => optionDetails.option_Text
                                    ?
                                    handleAddNewOption(optionDetails)
                                    :
                                    console.log("Option text or file not provided")}
                            />
                        </a>
                    </Tooltip>
                    :
                    <></>}
            </div>

            <Textarea
                size="lg"
                variant="unstyled"
                autoFocus
                className={`w-full ${questionFilters != 3 ? "ml-2" : ""}`}
                placeholder="Enter option here..."
                value={optionDetails?.option_Text || ""}
                onChange={(e) =>
                    handleChangeOptionDetails(e.target.value)
                }
            />
        </div>
    )
}