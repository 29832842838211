import React, { useContext, useEffect, useState } from 'react';
import { MultiSelect, Select } from '@mantine/core';

import { ROLES } from '../../../../../Routes/routes';
import { AppContext } from '../../../../../AppContext';
import useMasterData from '../../../../Hooks/useMasterData';
import { useSchoolData } from '../../../../../libs/data-access';
import { IClusterSchool, ISchool } from '../../../../../libs/data-access/types';
import { PeriodicAssessmentFilterContext } from '../Hooks/usePeriodicAssessment';
import { EButton } from '../../../../../libs/ui';
import { MdClear, MdSearch } from 'react-icons/md';
import { showToast } from '../../../../../utils/utility';

interface IProps {
  handleResetFilters: () => void;
};

export default function PeriodicAssessmentFilter({
  handleResetFilters,
}: IProps) {

  const {
    user,
    role
  } = useContext(AppContext);
  const {
    periodicAssessmentID,
    district,
    block,
    cluster,
    school,
    grade,
    subject,
    month,
    handleChangeFilters,
    handleIsSearching,
    isSearching,
    session
  } = useContext(PeriodicAssessmentFilterContext);


  const {
    fetchSchoolData
  } = useSchoolData();
  const {
    assessmentTypes,
    getAssessmentTypes,
    districts,
    getDistrictByStateId,
    blocks,
    getBlocksByDistrictId,
    classes,
    getClasses,
    getSubject,
    subjectList,
    getSession,
    sessions
  } = useMasterData();

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [clusterSchools, setClusterSchools] = useState<IClusterSchool[]>([]);
  const [schools, setSchools] = useState<ISchool[]>([]);


  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
    } else {
      getAssessmentTypes();
      getDistrictByStateId(1);
      getClasses();
      getSession(false);
      if (role?.includes(ROLES.BLOCK)) {
        handleChangeFilters("district", user?.details?.blockUserDetails?.district_Id);
        handleChangeFilters("block", user?.details?.blockUserDetails?.block_Id);
      };
      if (role?.includes(ROLES.SCHOOL)) {
        handleChangeFilters("district", user?.details?.schoolUserDetails?.district_Id);
        handleChangeFilters("block", user?.details?.schoolUserDetails?.block_Id);
        handleChangeFilters("cluster", user?.details?.schoolUserDetails?.cluster_School_Id);
        handleChangeFilters("school", user?.details?.schoolUserDetails?.school_Id);
      };
      if (role?.includes(ROLES.DISTRICT)) {
        handleChangeFilters("district", user?.details?.districtUserDetails?.district_Id);
    }
    };
  }, [isFirstLoad]);

  useEffect(() => {
    district && getBlocksByDistrictId(district);
  }, [district]);

  useEffect(() => {
    block && fetchClusterSchools(block);
  }, [block]);

  useEffect(() => {
    cluster && fetchSchools(cluster);
  }, [cluster]);

  useEffect(() => {
    grade && getSubject();
  }, [grade]);

  // useEffect(()=>{
  //   if(isSearching){
  //     setTimeout(()=>{
  //     handleIsSearching(false)
  //     showToast({
  //       type:"error",
  //       message:"Data not found"
  //     })}
  //     ,1200)
  //   }
  // },[isSearching])

  useEffect(() => {
    handleChangeFilters("subject",subjectList?.map(e=>String(e?.id)))
  }, [subjectList]);


  // HANDLER FUNCTIONS
  const handleEnableCloseButton = () => {
    if (
      true ||
      district
    ) return false
    else return true;
  };


  // API CALLS
  const fetchClusterSchools = async (block: number) => {
    setClusterSchools([]);
    const response = await fetchSchoolData(`/GetClusterSchoolListByBlockId/${block}`);
    if (response.isSuccess) {
      // console.log("Response for fetch cluster schools: ", response.data);
      setClusterSchools(response.data);
    } else {
      console.error("Error while fetching cluster schools: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch cluster schools."
      });
    };
  };

  const fetchSchools = async (clusterSchool: number) => {
    setSchools([]);
    const response = await fetchSchoolData(`/GetSchoolListByClusterSchoolId/${clusterSchool}`);
    if (response.isSuccess) {
      // console.log("Response for fetch schools: ", response.data);
      setSchools(response.data);
    } else {
      console.error("Error while fetching schools: ", response.error);
      showToast({
        type: "error",
        title: "Something Went Wrong!",
        message: response.message || "Could not fetch schools."
      });
    };
  };



  return (
    <div className="filter-container">
      <div className='flex flex-col'>
        <div className="flex flex-wrap gap-2 items-end">
          <Select
            clearable
            // required={!district ? true : false}
            size="md"
            label="District"
            className="w-1/5 grow"
            disabled={role?.includes(ROLES.DISTRICT) || role?.includes(ROLES.BLOCK) || role?.includes(ROLES.SCHOOL) ? true : false}
            placeholder="Select"
            value={district ? String(district) : null}
            data={districts?.map(each => ({
              value: String(each.id),
              label: each.name
            }))}
            onChange={e => {
              handleChangeFilters("district", e ? Number(e) : null);
              handleChangeFilters("block", null);
              handleChangeFilters("cluster", null);
              handleChangeFilters("school", null);
            }}
          />

          <Select
            clearable
            size="md"
            label="Block"
            className="w-1/5 grow"
            placeholder="Select"
            disabled={!district || role?.includes(ROLES.BLOCK) || role?.includes(ROLES.SCHOOL) ? true : false}
            value={block ? String(block) : null}
            data={blocks?.map(e => {
              return {
                value: String(e.id),
                label: e.name
              }
            })}
            onChange={e => {
              handleChangeFilters("block", e ? Number(e) : null)
              handleChangeFilters("cluster", null);
              handleChangeFilters("school", null);
            }}
          />

          <Select
            clearable
            size="md"
            label="Cluster"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={!block || role?.includes(ROLES.SCHOOL) ? true : false}
            value={cluster ? String(cluster) : null}
            data={clusterSchools?.map(e => {
              return {
                value: String(e.cluster_School_Id),
                label: e.cluster_School_Name
              }
            })}
            onChange={e => {
              handleChangeFilters("cluster", e ? Number(e) : null);
              handleChangeFilters("school", null);
            }}
          />

          {/* <Select
            clearable
            size="md"
            label="School"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={!cluster || role?.includes(ROLES.SCHOOL) ? true : false}
            value={school ? String(school) : null}
            data={schools?.map(e => {
              return {
                value: String(e.school_Id),
                label: e.school_Name
              }
            })}
            onChange={e => {

              handleChangeFilters("school", e ? Number(e) : null)
            }}
          /> */}


          <Select
            size="md"
            label="Session"
            required
            placeholder="Select"
            className=' w-1/5 grow'
            // disabled={ false}
            value={session ? String(session) : null}
            data={sessions?.map(e => {
              return {
                value: String(e?.session_Id),
                label: e?.session_Name
              }
            })}
            onChange={e => {
              // handleChangeFilters("subject", []);
              handleChangeFilters("session", e ? String(e) : null);
            }}
          />


          <Select
            searchable={assessmentTypes?.length ? true : false}
            size='md'
            placeholder="Select"
            label="Select Assessment"
            data={assessmentTypes?.map(each => ({
              value: String(each.id), label: each.name
            }))}
            value={periodicAssessmentID ? String(periodicAssessmentID) : undefined}
            onChange={e => {
              handleChangeFilters("periodicAssessmentID", Number(e));
            }}
          />



          {/* <Select
            size="md"
            required
            label="Month"
            placeholder="Select"
            className="w-1/5 grow"
            disabled={false}
            data={
              [
                { label: "January", value: "01" },
                { label: "February", value: "02" },
                { label: "March", value: "03" },
                { label: "April", value: "04" },
                { label: "May", value: "05" },
                { label: "June", value: "06" },
                { label: "July", value: "07" },
                { label: "August", value: "08" },
                { label: "September", value: "09" },
                { label: "October", value: "10" },
                { label: "November", value: "11" },
                { label: "December", value: "12" }
              ]
            }
            value={month ? String(month) : null}
            onChange={e => {
              handleChangeFilters("month", e ? e : null);
            }}
          /> */}

          



          <Select
            size="md"
            label="Grade"
            required
            placeholder="Select"
            className=' w-1/5 grow'
            // disabled={ false}
            value={grade ? String(grade) : null}
            data={classes?.map(e => {
              return {
                value: String(e?.id),
                label: e?.name
              }
            })}
            onChange={e => {
              handleChangeFilters("subject", []);
              handleChangeFilters("grade", e ? Number(e) : null);
            }}
          />

          <MultiSelect
            label="Subject"
            size='md'
            required
            placeholder="Select"
            className="w-1/5 grow"
            value={subject?.map((e) => String(e))}
            data={subjectList?.map(e => {
              return {
                value: String(e?.id),
                label: e?.name
              }
            })}
            disabled={!grade ? true : false}
            onChange={e => {
              handleChangeFilters("subject", e ? e?.map((e) => +e) : null);
            }}
          />
        </div>

        <div className="flex justify-end gap-3 mt-4">
          {!handleEnableCloseButton()
            ?
            <EButton
              size="md"
              className="btn-light"
              rightIcon={<MdClear />}
              disabled={isSearching || role?.includes(ROLES.DISTRICT) ||role?.includes(ROLES.BLOCK) ||role?.includes(ROLES.SCHOOL) ? true : false}
              onClick={handleResetFilters}
            >Clear</EButton>
            :
            <></>}
          <EButton
            size="md"
            className="btn-blue"
            rightIcon={<MdSearch />}
            // disabled={handleEnableSearchButton() ? true : false}
            onClick={() => handleIsSearching(!isSearching)}
            loading={isSearching}
          >Search</EButton>
        </div>
      </div>
    </div>
  )
}
