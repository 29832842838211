import { Dispatch, SetStateAction, createContext, useContext } from "react";
import { calculateCurrentMonthForDashboard } from "../../utils/utility";
import { IMasterData } from "../../libs/data-access/types";
import moment from "moment";
import { AppContext } from "../../AppContext";
import { ROLES } from "../../Routes/routes";


export interface IFilterContext {
  selectedMonth: string | undefined;
  selectedDistrict: string | undefined;
  selectedBlock: string | undefined;
  selectedCluster: string | undefined;
  selectedSession: string | undefined;
  districts: IMasterData[] | undefined;
  blocks: IMasterData[] | undefined;
  setIsLoadingSchoolVisit: Dispatch<SetStateAction<boolean>>;
  isSearching: boolean;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
};

export const DashboardFilterContext = createContext<IFilterContext>({
    selectedMonth: calculateCurrentMonthForDashboard(),
    selectedDistrict: undefined,
    selectedBlock: undefined,
    selectedCluster: undefined,
    selectedSession: undefined,
    districts: undefined,
    blocks: undefined,
    setIsLoadingSchoolVisit: () => {},
    isSearching: false,
    setIsSearching: () => {}
});