import { showToast } from "./utility";
import {
  IEditQuestionDetails,
  ILoginParams,
  INewNotification,
  INewSchedule,
  INewSurvey,
  INewSurveyQuestion,
  INewTarget,
  IPeriodicAssessmentScheduleNewPayload,
  IPeriodicAssessmentSchedulePayload,
  IQuestionFilters,
  IQuestionUploadPayload,
  IScorecard,
  ISelectedQuestionDetails,
  ITeacherToClassAllocation,
  ITeacherToClassAllocationUpdate,
  IUserManagement,
} from "../types";
import {
  INewTestQuestionOption,
  IPutScheduleDetails,
  IScheduleList,
  ISlotTestQuestionListQuestion,
  ITrainingSlotList,
  testQuestions,
} from "../libs/data-access/types";
import moment from "moment";

export const handleValidateLoginParams = (loginParams: ILoginParams) => {
  let isValidated = false;
  if (!loginParams.username) {
    showToast({
      type: "warning",
      message: "Please provide username",
    });
    return isValidated;
  }

  if (!loginParams.password) {
    showToast({
      type: "warning",
      message: "Please provide password",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleValidateOrf = (orfParam: any) => {
  let isValidated = false;
  if (!orfParam.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide grade",
    });
    return isValidated;
  }

  if (!orfParam.subject_Id) {
    showToast({
      type: "warning",
      message: "Please provide subject",
    });
    return isValidated;
  }

  if (orfParam.orF_Question_Text?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide question text",
    });
    return isValidated;
  }

  if (orfParam.max_Seconds_To_Read?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide maximum seconds to read",
    });
    return isValidated;
  }

  if (orfParam.min_Word_Read_Per_Minute?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide minimum word read per minute",
    });
    return isValidated;
  }

  if (!orfParam.week_Start_Date) {
    showToast({
      type: "warning",
      message: "Please provide week start date",
    });
    return isValidated;
  }

  if (!orfParam.week_End_Date) {
    showToast({
      type: "warning",
      message: "Please provide week end date",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleValidateParams = (
  periodicScheduleParams: IPeriodicAssessmentScheduleNewPayload
) => {
  let isValidated = false;
  if (!periodicScheduleParams.created_By) {
    showToast({
      type: "warning",
      message: "Created by could not be found",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.periodic_Assessment_Id) {
    showToast({
      type: "warning",
      message: "Please provide assessment",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.start_Date) {
    showToast({
      type: "warning",
      message: "Please provide start date",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.end_Date) {
    showToast({
      type: "warning",
      message: "Please provide end date",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide grade",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.subject_Id) {
    showToast({
      type: "warning",
      message: "Please provide subject",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.total_Number_Of_Questions) {
    showToast({
      type: "warning",
      message: "Please provide number of questions",
    });
    return isValidated;
  }

  if (!periodicScheduleParams.scheduleCompetancy.length) {
    showToast({
      type: "warning",
      message: "Please provide competencies",
    });
    return isValidated;
  }

  if (periodicScheduleParams.scheduleCompetancy.length) {
    if (
      periodicScheduleParams?.scheduleCompetancy.filter((e) =>
        isNaN(e?.number_Of_Question)
      ).length
    ) {
      showToast({
        type: "warning",
        message: "All no. of questions value must be a numeric input",
      });
      return isValidated;
    }
  }

  if (periodicScheduleParams.subject_Id == 3) {
    if (periodicScheduleParams?.scheduleORFQuestion?.length) {
      showToast({
        type: "warning",
        title: "Please refresh your page",
        message: "Math subject should not contain any orf",
      });
      return isValidated;
    }
  }

  if (periodicScheduleParams.scheduleORFQuestion) {
    periodicScheduleParams.scheduleORFQuestion?.forEach((each, index) => {
      if (!each?.orF_Question_Text.trim()) {
        showToast({
          type: "warning",
          title: `Question no. ${index + 1} has a mistake 🤥`,
          message: `Please Provide valid question text`,
        });
        return isValidated;
      }
      if (
        !String(each?.min_Word_Read_Per_Minute).trim() ||
        isNaN(each?.min_Word_Read_Per_Minute)
      ) {
        showToast({
          type: "warning",
          title: `Question no. ${index + 1} error 🤥`,
          message: `Please Provide valid min word read per minute`,
        });
        return isValidated;
      }
      if (
        !each?.max_Seconds_To_Read.trim() ||
        isNaN(+each?.max_Seconds_To_Read)
      ) {
        showToast({
          type: "warning",
          title: `Question no. ${index + 1} error 🤥`,
          message: `Please Provide valid max seconds to read`,
        });
        return isValidated;
      }
    });
  }

  isValidated = true;
  return isValidated;
};

export const handleValidateQuestionParams = (
  questionPayload: IQuestionUploadPayload,
  questionFilters: IQuestionFilters
) => {
  let isValidated = false;
  const CorrectAns: any = [];

  questionPayload?.base64ImageQuestionOption.forEach((e) => {
    if (e.is_Correct) {
      CorrectAns.push(1);
    }
  });

  if (!questionPayload.assessment_Type_Id) {
    showToast({
      type: "warning",
      message: "Please provide question bank",
    });
    return isValidated;
  }

  if (!questionPayload.question_Type_Id) {
    showToast({
      type: "warning",
      message: "Please provide question type",
    });
    return isValidated;
  }

  if (!questionPayload.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide class / Grade",
    });
    return isValidated;
  }

  if (!questionPayload.subject_Id) {
    showToast({
      type: "warning",
      message: "Please provide subject",
    });
    return isValidated;
  }

  if (!questionPayload.competancy_Id) {
    showToast({
      type: "warning",
      message: "Please provide competency",
    });
    return isValidated;
  }

  if (!questionPayload.media_Type_Id) {
    showToast({
      type: "warning",
      message: "Please provide media type for question",
    });
    return isValidated;
  }

  if (!questionPayload.option_Media_Type_Id) {
    showToast({
      type: "warning",
      message: "Please provide media type for options",
    });
    return isValidated;
  }

  /* VALIDATION FOR MEDIA TYPES STARTS */

  if (questionFilters.media_Type_Id == 1) {
    if (
      questionPayload.base64QuestionImage ||
      questionPayload.question_Media_Url
    ) {
      showToast({
        type: "warning",
        title: "Media Type is Text Only",
        message: "You can not add Image/Video URL",
      });
      return isValidated;
    }
  }

  if (questionFilters.media_Type_Id == 4) {
    if (!questionPayload.question_Media_Url) {
      showToast({
        type: "warning",
        message: "Please provide a video link for question",
      });
      return isValidated;
    }
    if (!questionPayload.question_Text) {
      showToast({
        type: "warning",
        message: "Please provide the question text",
      });
      return isValidated;
    }
  }
  if (questionFilters.media_Type_Id == 6) {
    if (!questionPayload.question_Media_Url) {
      showToast({
        type: "warning",
        message: "Please provide a audio link for question",
      });
      return isValidated;
    }
    if (!questionPayload.question_Text) {
      showToast({
        type: "warning",
        message: "Please provide the question text",
      });
      return isValidated;
    }
  }

  if (questionFilters.media_Type_Id == 5) {
    if (!questionPayload?.base64QuestionImage) {
      showToast({
        type: "warning",
        message: "Please provide an image for question",
      });
      return isValidated;
    }
  }

  if (questionFilters.media_Type_Id == 3) {
    if (!questionPayload?.base64QuestionImage) {
      showToast({
        type: "warning",
        message: "Please provide an image for question",
      });
      return isValidated;
    }
  }

  if (questionFilters.option_Media_Type_Id == 1) {
    let result = questionPayload.base64ImageQuestionOption.map((e, index) => {
      if (e.base64OptionImage) {
        showToast({
          type: "warning",
          message: `Please provide only text to option ${index + 1}`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (questionFilters.option_Media_Type_Id == 3) {
    let result = questionPayload.base64ImageQuestionOption.map((e, index) => {
      if (!e.base64OptionImage) {
        showToast({
          type: "warning",
          message: `Please provide image to option ${index + 1}`,
        });
        return false;
      }
      if (!!e.option_Text) {
        showToast({
          type: "warning",
          message: `Please provide only image to option ${index + 1}`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (questionFilters.option_Media_Type_Id == 5) {
    let result = questionPayload.base64ImageQuestionOption.map((e, index) => {
      if (!e.base64OptionImage || !e.option_Text) {
        showToast({
          type: "warning",
          message: `Please provide image and text properly to option ${
            index + 1
          }`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (questionPayload?.marks == 0) {
    showToast({
      type: "warning",
      message: "Please provide no. of marks for question",
    });
    return isValidated;
  }
  if (isNaN(questionPayload?.marks)) {
    showToast({
      type: "warning",
      message: "Only numeric values are accepted as question marks.",
    });
    return isValidated;
  }
  /* VALIDATION FOR MEDIA TYPES ENDS */

  if (!questionPayload.question_Text) {
    showToast({
      type: "warning",
      message: "Please provide question text",
    });
    return isValidated;
  }

  if (questionPayload.base64ImageQuestionOption.length < 2) {
    showToast({
      type: "warning",
      message: "Please provide at least 2 options for the question",
    });
    return isValidated;
  }

  if (questionPayload.question_Type_Id == 2 && CorrectAns.length <= 1) {
    showToast({
      type: "warning",
      message:
        "Select at least two correct option for multiple answer question",
    });
    return isValidated;
  }

  if (questionPayload.question_Type_Id == 1 && CorrectAns.length !== 1) {
    showToast({
      type: "warning",
      message: "Please select single answer as correct option",
    });
    return isValidated;
  }

  if (questionPayload.assessment_Type_Id == 4 && !questionPayload.week_Id) {
    showToast({
      type: "warning",
      message: "Please provide week ",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

////////spot assesement question validator/////////////

export const handleValidateQuestionSpot = (payload: any, main: any) => {
  let isValidated = true; // Set to true by default

  payload.forEach((item: any, index: number) => {
    // Use forEach instead of map
    const CorrectAns: any = [];

    item?.spotQuestionOptions.forEach((e: any) => {
      if (e.is_Correct) {
        CorrectAns.push(1);
      }
    });

    if (!item.question_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide question type to question ${index + 1}`,
      });
      isValidated = false; // Set to false when validation fails
      return; // Exit the loop early
    }

    if (!item.media_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide media type for question to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (!item.option_Media_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide media type for options to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Marks == 0) {
      showToast({
        type: "warning",
        message: `Please provide question marks more than 0 to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    /* VALIDATION FOR MEDIA TYPES STARTS */

    if (item.media_Type_Id == 1) {
      if (item.base64QuestionImage || item.question_Media_Url) {
        showToast({
          type: "warning",
          title: "Media Type is Text Only",
          message: `You can not add Image/Video URL to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 4) {
      if (!item.question_Media_Url) {
        showToast({
          type: "warning",
          message: `Please provide a video link to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
      if (!item.question_Text) {
        showToast({
          type: "warning",
          message: `Please provide the question text to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 6) {
      if (!item.question_Media_Url) {
        showToast({
          type: "warning",
          message: `Please provide a audio link to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
      if (!item.question_Text) {
        showToast({
          type: "warning",
          message: `Please provide the question text to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 5) {
      if (!item?.base64QuestionImage) {
        showToast({
          type: "warning",
          message: `Please provide an image to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 3) {
      if (!item?.base64QuestionImage) {
        showToast({
          type: "warning",
          message: `Please provide an image to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 1) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (e.base64OptionImage) {
            showToast({
              type: "warning",
              message: `Please provide only text to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 3) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (!e.base64OptionImage) {
            showToast({
              type: "warning",
              message: `Please provide image to question ${index + 1} option ${
                childIndex + 1
              }`,
            });
            return false;
          }
          if (!!e.option_Text) {
            showToast({
              type: "warning",
              message: `Please provide only image to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 5) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (!e.base64OptionImage || !e.option_Text) {
            showToast({
              type: "warning",
              message: `Please provide image and text properly to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (isNaN(item?.question_Marks)) {
      showToast({
        type: "warning",
        message: `Only numeric values are accepted as question marks to question ${
          index + 1
        }.`,
      });
      isValidated = false;
      return;
    }
    /* VALIDATION FOR MEDIA TYPES ENDS */

    if (item.spotQuestionOptions.length < 2) {
      showToast({
        type: "warning",
        message: `Please provide at least 2 options to question ${index + 1}`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Type_Id == 2 && CorrectAns.length <= 1) {
      showToast({
        type: "warning",
        message: `Select at least two correct option for multiple answer question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Type_Id == 1 && CorrectAns.length !== 1) {
      showToast({
        type: "warning",
        message: `Please select single answer as correct option to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    //   if (payload.assessment_Type_Id == 4 && !questionPayload.week_Id) {
    //     showToast({
    //       type: "warning",
    //       message: "Please provide week ",
    //     });
    //     isValidated = false;
    //     return;
    //   }
  });

  if (!main.question_Group_Instruction_Id) {
    showToast({
      type: "warning",
      message: "Please provide Question Group ",
    });
    isValidated = false;
  }

  if (!main.login_User_Id) {
    showToast({
      type: "warning",
      message: "Please provide User Id ",
    });
    isValidated = false;
  }

  return isValidated; // Return the final validation result
};

export const handleValidateGroupQuestionSpot = (payload: any, main: any) => {
  let isValidated = true; // Set to true by default

  payload.forEach((item: any, index: number) => {
    // Use forEach instead of map
    const CorrectAns: any = [];

    item?.spotQuestionOptions.forEach((e: any) => {
      if (e.is_Correct) {
        CorrectAns.push(1);
      }
    });

    if (!item.question_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide question type to question ${index + 1}`,
      });
      isValidated = false; // Set to false when validation fails
      return; // Exit the loop early
    }

    if (!item.media_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide media type for question to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (!item.option_Media_Type_Id) {
      showToast({
        type: "warning",
        message: `Please provide media type for options to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Marks == 0) {
      showToast({
        type: "warning",
        message: `Please provide question marks more than 0 to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    /* VALIDATION FOR MEDIA TYPES STARTS */

    if (item.media_Type_Id == 1) {
      if (item.base64QuestionImage || item.question_Media_Url) {
        showToast({
          type: "warning",
          title: "Media Type is Text Only",
          message: `You can not add Image/Video URL to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 4) {
      if (!item.question_Media_Url) {
        showToast({
          type: "warning",
          message: `Please provide a video link to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
      if (!item.question_Text) {
        showToast({
          type: "warning",
          message: `Please provide the question text to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 6) {
      if (!item.question_Media_Url) {
        showToast({
          type: "warning",
          message: `Please provide a audio link to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
      if (!item.question_Text) {
        showToast({
          type: "warning",
          message: `Please provide the question text to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 5) {
      if (!item?.base64QuestionImage) {
        showToast({
          type: "warning",
          message: `Please provide an image to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.media_Type_Id == 3) {
      if (!item?.base64QuestionImage) {
        showToast({
          type: "warning",
          message: `Please provide an image to question ${index + 1}`,
        });
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 1) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (e.base64OptionImage) {
            showToast({
              type: "warning",
              message: `Please provide only text to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 3) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (!e.base64OptionImage) {
            showToast({
              type: "warning",
              message: `Please provide image to question ${index + 1} option ${
                childIndex + 1
              }`,
            });
            return false;
          }
          if (!!e.option_Text) {
            showToast({
              type: "warning",
              message: `Please provide only image to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (item.option_Media_Type_Id == 5) {
      let result = item.spotQuestionOptions.map(
        (e: any, childIndex: number) => {
          if (!e.base64OptionImage || !e.option_Text) {
            showToast({
              type: "warning",
              message: `Please provide image and text properly to question ${
                index + 1
              } option ${childIndex + 1}`,
            });
            return false;
          }
        }
      );
      if (result.includes(false)) {
        isValidated = false;
        return;
      }
    }

    if (isNaN(item?.question_Marks)) {
      showToast({
        type: "warning",
        message: `Only numeric values are accepted as question marks to question ${
          index + 1
        }.`,
      });
      isValidated = false;
      return;
    }
    /* VALIDATION FOR MEDIA TYPES ENDS */

    if (item.spotQuestionOptions.length < 2) {
      showToast({
        type: "warning",
        message: `Please provide at least 2 options to question ${index + 1}`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Type_Id == 2 && CorrectAns.length <= 1) {
      showToast({
        type: "warning",
        message: `Select at least two correct option for multiple answer question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    if (item.question_Type_Id == 1 && CorrectAns.length !== 1) {
      showToast({
        type: "warning",
        message: `Please select single answer as correct option to question ${
          index + 1
        }`,
      });
      isValidated = false;
      return;
    }

    //   if (payload.assessment_Type_Id == 4 && !questionPayload.week_Id) {
    //     showToast({
    //       type: "warning",
    //       message: "Please provide week ",
    //     });
    //     isValidated = false;
    //     return;
    //   }
  });

  if (main.question_Group_Instruction.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Question Group Instruction",
    });
    isValidated = false;
  }

  // if (!main.min_Attempt_Question) {
  //   showToast({
  //     type: "warning",
  //     message: "Please provide Minimum Attempt Question",
  //   });
  //   isValidated = false;
  // }

  if (main.week_Start_Date.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Week Start Date",
    });
    isValidated = false;
  }

  if (main.week_End_Date.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Week End Date",
    });
    isValidated = false;
  }

  if (!main.competency_Id) {
    showToast({
      type: "warning",
      message: "Please provide Week End Date",
    });
    isValidated = false;
  }
  if (!main.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide Class",
    });
    isValidated = false;
  }

  if (!main.subject_Id) {
    showToast({
      type: "warning",
      message: "Please provide Subject",
    });
    isValidated = false;
  }

  if (!main.login_User_Id) {
    showToast({
      type: "warning",
      message: "Please provide User Id ",
    });
    isValidated = false;
  }

  return isValidated; // Return the final validation result
};

export const handleValidateGroup = (payload: any) => {
  let isValidated = false;

  if (payload?.question_Group_Instruction?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Group Instruction",
    });
    return isValidated;
  }

  // if (!payload?.min_Attempt_Question) {
  //   showToast({
  //     type: "warning",
  //     message: "Please provide Group Minimum Attempt Question",
  //   });
  //   return isValidated;
  // }

  if (!payload?.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide Group Grade",
    });
    return isValidated;
  }

  if (!payload?.subject_Id) {
    showToast({
      type: "warning",
      message: "Please provide Group Subject",
    });
    return isValidated;
  }

  if (payload?.week_Start_Date?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Group Week Start Date",
    });
    return isValidated;
  }

  if (payload?.week_End_Date?.length <= 0) {
    showToast({
      type: "warning",
      message: "Please provide Group Week End Date",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

///////spot assessement question validator////////////

export const handleValidateEditQuestionParams = (
  questionPayload: IEditQuestionDetails,
  selectedQuestion: ISelectedQuestionDetails
) => {
  let isValidated = false;
  const CorrectAns: any = [];

  questionPayload?.questionOptions.forEach((e) => {
    if (e.is_Correct) {
      CorrectAns.push(1);
    }
  });

  /* VALIDATION FOR MEDIA TYPES STARTS */

  if (selectedQuestion.questions_Media_Type_Id == 1) {
    if (
      questionPayload.base64QuestionImage ||
      questionPayload.question_Media_Url
    ) {
      showToast({
        type: "warning",
        title: "Media Type is Text Only",
        message: "You can not add Image/Video URL",
      });
      return isValidated;
    }
  }

  if (selectedQuestion.questions_Media_Type_Id == 4) {
    if (!questionPayload.question_Media_Url) {
      showToast({
        type: "warning",
        message: "Please provide a video link for question",
      });
      return isValidated;
    }
    if (!questionPayload.question_Text) {
      showToast({
        type: "warning",
        message: "Please provide the question text",
      });
      return isValidated;
    }
  }
  if (selectedQuestion.questions_Media_Type_Id == 6) {
    if (!questionPayload.question_Media_Url) {
      showToast({
        type: "warning",
        message: "Please provide a audio link for question",
      });
      return isValidated;
    }
    if (!questionPayload.question_Text) {
      showToast({
        type: "warning",
        message: "Please provide the question text",
      });
      return isValidated;
    }
  }

  if (selectedQuestion.questions_Media_Type_Id == 5) {
    if (!questionPayload?.base64QuestionImage) {
      showToast({
        type: "warning",
        message: "Please provide an image for question",
      });
      return isValidated;
    }
  }

  if (selectedQuestion.questions_Media_Type_Id == 3) {
    if (!questionPayload?.base64QuestionImage) {
      showToast({
        type: "warning",
        message: "Please provide an image for question",
      });
      return isValidated;
    }
  }

  if (selectedQuestion.options_Media_Type_Id == 1) {
    let result = questionPayload.questionOptions?.map((e, index) => {
      if (e.base64OptionImage) {
        showToast({
          type: "warning",
          message: `Please provide only text to option ${index + 1}`,
        });
        return false;
      }
      if (!e?.option_Text) {
        showToast({
          type: "warning",
          message: `Please provide only text to option ${index + 1}`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (selectedQuestion.options_Media_Type_Id == 3) {
    let result = questionPayload.questionOptions.map((e, index) => {
      if (!e.base64OptionImage) {
        showToast({
          type: "warning",
          message: `Please provide image to option ${index + 1}`,
        });
        return false;
      }
      if (!!e.option_Text) {
        showToast({
          type: "warning",
          message: `Please provide only image to option ${index + 1}`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (selectedQuestion.options_Media_Type_Id == 5) {
    let result = questionPayload.questionOptions.map((e, index) => {
      if (!e.base64OptionImage || !e.option_Text) {
        showToast({
          type: "warning",
          message: `Please provide image and text properly to option ${
            index + 1
          }`,
        });
        return false;
      }
    });
    if (result.includes(false)) {
      return isValidated;
    }
  }

  if (questionPayload?.marks == 0) {
    showToast({
      type: "warning",
      message: "Please provide no. of marks for question",
    });
    return isValidated;
  }
  if (isNaN(questionPayload?.marks)) {
    showToast({
      type: "warning",
      message: "Only numeric values are accepted as question marks.",
    });
    return isValidated;
  }
  /* VALIDATION FOR MEDIA TYPES ENDS */

  if (!questionPayload.question_Text) {
    showToast({
      type: "warning",
      message: "Please provide question text",
    });
    return isValidated;
  }

  if (questionPayload.questionOptions.length < 2) {
    showToast({
      type: "warning",
      message: "Please provide at least 2 options for the question",
    });
    return isValidated;
  }

  if (selectedQuestion.question_Type_Id == 2 && CorrectAns.length <= 1) {
    showToast({
      type: "warning",
      message:
        "Select at least two correct option for multiple answer question",
    });
    return isValidated;
  }

  if (selectedQuestion.question_Type_Id == 1 && CorrectAns.length !== 1) {
    showToast({
      type: "warning",
      message: "Please select single answer as correct option",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleEditTeacherAllocation = (
  editTeacherAllocationPayload: ITeacherToClassAllocationUpdate
) => {
  let isValidated = false;
  if (!editTeacherAllocationPayload?.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide class",
    });
    return isValidated;
  }

  if (!editTeacherAllocationPayload?.section_Id) {
    showToast({
      type: "warning",
      message: "Please provide section",
    });
    return isValidated;
  }
  isValidated = true;
  return isValidated;
};

export const handleTeacherAllocation = (
  teacherAllocation: ITeacherToClassAllocation
) => {
  let isValidated = false;
  if (!teacherAllocation?.class_Id) {
    showToast({
      type: "warning",
      message: "Please provide class",
    });
    return isValidated;
  }
  if (!teacherAllocation?.section_Id) {
    showToast({
      type: "warning",
      message: "Please provide section",
    });
    return isValidated;
  }
  teacherAllocation?.allocatedClass?.map((each) => {
    if (each.class_Id == teacherAllocation?.class_Id) {
      if (each?.section_Id == teacherAllocation?.section_Id) {
        showToast({
          type: "warning",
          message: "Selected Class-Section already allocated to this teacher",
        });
        return isValidated;
      }
    }
  });
  isValidated = true;
  return isValidated;
};

export const handleValidNewSchedule = (
  newSchedule: INewSchedule,
  trainingSlot: ITrainingSlotList[]
) => {
  let isValidated = false;

  if (!newSchedule?.training_Description) {
    showToast({
      type: "warning",
      message: " Please provide description!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Place) {
    showToast({
      type: "warning",
      message: " Please provide place!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Start_Date) {
    showToast({
      type: "warning",
      message: " Please provide start date !",
    });
    return isValidated;
  }
  if (!newSchedule?.training_End_Date) {
    showToast({
      type: "warning",
      message: " Please provide end date!",
    });
    return isValidated;
  }

  if (newSchedule?.training_Start_Date && newSchedule?.training_End_Date) {
    if (
      moment(newSchedule?.training_End_Date).diff(
        moment(newSchedule?.training_Start_Date),
        "days"
      ) <
      trainingSlot[0]?.no_Of_Days - 1
    ) {
      showToast({
        type: "warning",
        title: `Minimum training duration : ${trainingSlot[0]?.no_Of_Days} ${
          trainingSlot[0]?.no_Of_Days > 1 ? "days" : "day"
        }`,
        message: " Please select date range accordingly!",
      });
      return isValidated;
    }
  }
  if (!newSchedule?.training_Start_Time) {
    showToast({
      type: "warning",
      message: " Please provide schedule start time!",
    });
    return isValidated;
  }
  if (!newSchedule?.teacherTest.length) {
    showToast({
      type: "warning",
      message: " Please provide teacher test!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Title) {
    showToast({
      type: "warning",
      message: " Please provide title!",
    });
    return isValidated;
  }
  if (!newSchedule?.block_Admin_User_Id) {
    showToast({
      type: "warning",
      message: "Could not get user id !",
    });
    return isValidated;
  }

  if (!newSchedule?.block_Id) {
    showToast({
      type: "warning",
      message: "Could not get block id !",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Schedule_Header_Id) {
    showToast({
      type: "warning",
      message: "Could not get training slot  id !",
    });
    return isValidated;
  }
  isValidated = true;
  return isValidated;
};
export const handleValidEditSchedule = (
  newSchedule: IPutScheduleDetails,
  trainingSlot: ITrainingSlotList | undefined
) => {
  let isValidated = false;
  if (!newSchedule?.training_Title) {
    showToast({
      type: "warning",
      message: " Please provide title!",
    });
    return isValidated;
  }
  if (!trainingSlot) {
    showToast({
      type: "warning",
      message: "Please refresh as you might have missed some slot details.",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Description) {
    showToast({
      type: "warning",
      message: " Please provide description!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Place) {
    showToast({
      type: "warning",
      message: " Please provide place!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Start_Date) {
    showToast({
      type: "warning",
      message: " Please provide start date !",
    });
    return isValidated;
  }
  if (!newSchedule?.training_End_Date) {
    showToast({
      type: "warning",
      message: " Please provide end date!",
    });
    return isValidated;
  }
  if (!newSchedule?.training_Start_Time) {
    showToast({
      type: "warning",
      message: " Please provide schedule start time!",
    });
    return isValidated;
  }
  if (!newSchedule?.teacher_Training_Schedule_Id) {
    showToast({
      type: "warning",
      message: "Please provide teacher training schedule id !",
    });
    return isValidated;
  }
  if (
    newSchedule?.training_Start_Date &&
    newSchedule?.training_End_Date &&
    trainingSlot
  ) {
    if (
      moment(newSchedule?.training_End_Date).diff(
        moment(newSchedule?.training_Start_Date),
        "days"
      ) <
      trainingSlot?.no_Of_Days - 1
    ) {
      showToast({
        type: "warning",
        title: `Minimum training duration : ${trainingSlot?.no_Of_Days} ${
          trainingSlot?.no_Of_Days > 1 ? "days" : "day"
        }`,
        message: " Please select date range accordingly!",
      });
      return isValidated;
    }
  }
  isValidated = true;
  return isValidated;
};

export const handleValidSlotTestNewQuestion = (question: testQuestions) => {
  let isValidated = false;
  if (!question?.question_Text) {
    showToast({
      type: "warning",
      message: " Please provider question text!",
    });
    return isValidated;
  }
  if (question?.questionOptions.length < 2) {
    showToast({
      type: "warning",
      message: " Please select at least two option for question !",
    });
    return isValidated;
  }
  if (!question?.questionOptions.filter((e) => e?.is_Correct).length) {
    showToast({
      type: "warning",
      message: " Please select correct option for question !",
    });
    return isValidated;
  }
  if (question?.question_Type_Id == 1) {
    if (question?.questionOptions.filter((e) => e?.is_Correct).length > 1) {
      showToast({
        type: "warning",
        message:
          "Please select only one correct option for single-answer type question !",
      });
      return isValidated;
    }
  }
  if (question?.question_Type_Id == 2) {
    if (question?.questionOptions.filter((e) => e?.is_Correct).length < 2) {
      showToast({
        type: "warning",
        message:
          " Please select at least two correct option for multiple-answer type question !",
      });
      return isValidated;
    }
  }

  isValidated = true;
  return isValidated;
};

export const handleValidTestQuestionList = (
  question: INewTestQuestionOption
) => {
  let isValidated = false;
  if (!question?.schedule_Header_Test_Id) {
    showToast({
      type: "warning",
      message: " Please select slot test !",
    });
    return isValidated;
  }
  if (!question?.question_Group_Name.trim()) {
    showToast({
      type: "warning",
      message: " Please provide slot question group name !",
    });
    return isValidated;
  }
  if (!question?.state_Admin_User_Id) {
    showToast({
      type: "error",
      message: "Could not found user id plz try to login again !",
    });
    return isValidated;
  }
  if (!question?.testQuestions.length) {
    showToast({
      type: "warning",
      title: "Empty question list",
      message: "No any question to upload in question list !",
    });
    return isValidated;
  }
  isValidated = true;
  return isValidated;
};

export const handleValidEditTestQuestionList = (
  question: ISlotTestQuestionListQuestion
) => {
  let isValidated = false;
  if (!question?.question_Text.trim()) {
    showToast({
      type: "warning",
      message: "Slot question text cannot be empty !",
    });
    return isValidated;
  }
  if (!question?.questionsOptions?.length) {
    showToast({
      type: "warning",
      message: "Slot question options cannot be empty !",
    });
    return isValidated;
  }

  if (!question?.questionsOptions.filter((e) => e?.is_Correct).length) {
    showToast({
      type: "warning",
      message: " Please select correct option for question !",
    });
    return isValidated;
  }
  if (question?.question_Type == "Single Answer") {
    if (question?.questionsOptions.filter((e) => e?.is_Correct).length > 1) {
      showToast({
        type: "warning",
        message:
          "Please select only one correct option for single-answer type question !",
      });
      return isValidated;
    }
  }
  if (question?.question_Type == "Multiple Choice Type") {
    if (question?.questionsOptions.filter((e) => e?.is_Correct).length < 2) {
      showToast({
        type: "warning",
        message:
          " Please select at least two correct option for multiple-answer type question !",
      });
      return isValidated;
    }
  }

  isValidated = true;
  return isValidated;
};
export const handleValidNewTarget = (target: INewTarget) => {
  let isValidated = false;
  if (!target?.getMonthYear) {
    showToast({
      type: "warning",
      message: "Please select month for target !",
    });
    return isValidated;
  }
  if (!target?.role_Id) {
    showToast({
      type: "warning",
      message: "Please select role !",
    });
    return isValidated;
  }
  if (!target?.designation_Id) {
    showToast({
      type: "warning",
      message: "Please select designation !",
    });
    return isValidated;
  }
  if (!target?.target_Unique_School_Visit) {
    showToast({
      type: "warning",
      message: "Unique school visit target cannot be empty !",
    });
    return isValidated;
  }
  if (!target?.target_Classroom_Observation) {
    showToast({
      type: "warning",
      message: "Classroom observation target cannot be empty !",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

// Survey Validator

export const handleValidNewQuestion = (newQuestion: INewSurveyQuestion) => {
  let isValidated = false;
  if (isNaN(+newQuestion?.survey_Character_Limit)) {
    showToast({
      type: "warning",
      message: "Please provide valid number for character limit !",
    });
    return isValidated;
  }
  if (!newQuestion?.survey_Question_Type_Id) {
    showToast({
      type: "warning",
      message: "Please provide survey question type id !",
    });
    return isValidated;
  }
  // if(!newQuestion?.survey_Sub_Question_Type_Id) {
  //     showToast({
  //         type: "warning",
  //         message: "Please provide survey option type id !"
  //     });
  //     return isValidated;
  // }

  isValidated = true;
  return isValidated;
};

export const handleValidNewSurvey = (Survey: INewSurvey) => {
  let isValidated = false;

  if (!Survey?.role_Id?.length) {
    showToast({
      type: "warning",
      message: "Please select any role !",
    });
    return isValidated;
  }
  if (!Survey?.district_Id?.length) {
    showToast({
      type: "warning",
      message: "Please select any district !",
    });
    return isValidated;
  }
  if (!Survey?.class_Id?.length && Survey?.role_Id.includes("10")) {
    showToast({
      type: "warning",
      message: "Please select any class for student !",
    });
    return isValidated;
  }

  if (!Survey?.survey_Title) {
    showToast({
      type: "warning",
      message: "Please provide survey title !",
    });
    return isValidated;
  }

  if (!Survey?.survey_Start_Date) {
    showToast({
      type: "warning",
      message: "Please provide survey start date !",
    });
    return isValidated;
  }

  if (!Survey?.survey_End_Date) {
    showToast({
      type: "warning",
      message: "Please provide survey end date !",
    });
    return isValidated;
  }

  // if (!Survey?.district_Id?.length) {
  //     showToast({
  //         type: "warning",
  //         message: "Kindly choose the districts for the survey !"
  //     });
  //     return isValidated;
  // }

  if (!Survey?.question_Groups?.length) {
    showToast({
      type: "warning",
      message: "Please provide at least one set of questions for the survey !",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleValidNewNotification = (payload: INewNotification) => {
  let isValidated = false;
  if (!payload?.heading_Text) {
    showToast({
      type: "warning",
      message: "Please provide notification heading !",
    });
    return isValidated;
  }
  if (!payload?.details[0]?.role_Id?.length) {
    showToast({
      type: "warning",
      title: "Role can't be empty",
      message: "Please provide role and designation !",
    });
    return isValidated;
  }
  if (!payload?.details[0]?.district_Id?.length) {
    showToast({
      type: "warning",
      title: "District can't be empty",
      message: "Please provide district and blocks !",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleValidScoreCard = (payload: IScorecard) => {
  let isValidated = false;
  if (!payload?.SessionId) {
    showToast({
      type: "warning",
      message: "Please provide Session !",
    });
    return isValidated;
  }
  if (!payload?.MonthId) {
    showToast({
      type: "warning",
      message: "Please provide Month !",
    });
    return isValidated;
  }
  if (!payload?.file) {
    showToast({
      type: "warning",
      message: "Please provide File !",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};

export const handleValidUserManagement = (
  payload: IUserManagement,
  district: any,
  block: any,
  cluster: any,
  errorMsg: any,
  accessLevelDesignation:any,
) => {
  let isValidated = false;
  if (!payload?.role) {
    showToast({
      type: "warning",
      message: "Please provide Role !",
    });
    return isValidated;
  }
  if (!payload?.designation) {
    showToast({
      type: "warning",
      message: "Please provide Designation !",
    });
    return isValidated;
  }
  if (!payload?.name) {
    showToast({
      type: "warning",
      message: "Please provide Name !",
    });
    return isValidated;
  }
  if (!payload?.mobile) {
    showToast({
      type: "warning",
      message: "Please provide Mobile Number !",
    });
    return isValidated;
  }

  if (!payload?.Username) {
    showToast({
      type: "warning",
      message: "Please provide Username !",
    });
    return isValidated;
  }

  if (!payload?.dateOfBirth) {
    showToast({
      type: "warning",
      message: "Please provide Date Of Birth !",
    });
    return isValidated;
  }
  if (!payload?.password) {
    showToast({
      type: "warning",
      message: "Please provide password !",
    });
    return isValidated;
  }
  if (accessLevelDesignation != "State"){
    if (!district) {
      showToast({
        type: "warning",
        message: "Please provide District !",
      });
      return isValidated;
    }
  }

if (accessLevelDesignation != "State" && accessLevelDesignation != "District") {
  if (!block) {
    showToast({
      type: "warning",
      message: "Please provide Block !",
    });
    return isValidated;
  }
}

if (
  accessLevelDesignation != "State" &&
  accessLevelDesignation != "District" &&
  accessLevelDesignation != "Block"
) {
  if (!cluster) {
    showToast({
      type: "warning",
      message: "Please provide Cluster !",
    });
    return isValidated;
  }
}


  if (errorMsg?.length > 0) {
    showToast({
      type: "warning",
      message: "User Name Already Exist!",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};


export const handleValidUserManagementUpdate = (
  payload: IUserManagement,
  errorMsg: any
) => {
  let isValidated = false;

  if (!payload?.name) {
    showToast({
      type: "warning",
      message: "Please provide Name !",
    });
    return isValidated;
  }
  if (!payload?.mobile) {
    showToast({
      type: "warning",
      message: "Please provide Mobile Number !",
    });
    return isValidated;
  }



  if (!payload?.dateOfBirth) {
    showToast({
      type: "warning",
      message: "Please provide Date Of Birth !",
    });
    return isValidated;
  }
  if (!payload?.password) {
    showToast({
      type: "warning",
      message: "Please provide password !",
    });
    return isValidated;
  }



  if (errorMsg?.length>0) {
    showToast({
      type: "warning",
      message: "User Name Already Exist!",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};



export const handleValidPeriodicAssessement = (payload: any) => {
  let isValidated = false;

  if (!payload?.district) {
    showToast({
      type: "warning",
      message: "Please provide District !",
    });
    return isValidated;
  }
  if (!payload?.session) {
    showToast({
      type: "warning",
      message: "Please provide Session !",
    });
    return isValidated;
  }
  if (!payload?.periodicAssessmentID) {
    showToast({
      type: "warning",
      message: "Please provide Assessement !",
    });
    return isValidated;
  }
  if (!payload?.grade) {
    showToast({
      type: "warning",
      message: "Please provide Class !",
    });
    return isValidated;
  }
  if (!payload?.subject) {
    showToast({
      type: "warning",
      message: "Please provide Subject !",
    });
    return isValidated;
  }

  isValidated = true;
  return isValidated;
};
