import React, { useState } from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EEmpty, EPageTitle } from '../../libs/ui'
import { ECard } from '../../libs/ui/src/Card/ECard';
import { DatePicker } from '@mantine/dates';
import { Image, Modal, Select, Skeleton, Text, TextInput, Title } from '@mantine/core';
import { BiDownload } from 'react-icons/bi';
import useDownloadAssessement from './hook/useDownloadAssessement';
import { FaRegEye } from "react-icons/fa";
import { BASE64_INITIALS } from '../../initialStates';
import moment from 'moment';
import { ROLES } from '../../Routes/routes';

const DownloadAssessement = () => {
    const {
      handleDownload,
      currentPage,
      itemsPerPage,
      totalRecords,
      nextPage,
      prevPage,
      setCurrentPage,

      mentorImages,
      filterData,
      filterHandleChange,
      loading,
      onSearchFunc,
      user,
      role,
      districts,
      selectedDistrict,
      setSelectedDistrict,
    } = useDownloadAssessement();

const [opened, setOpened] = useState(false);
const [activeindex,setActiveIndex] = useState<any>(null)


// export const BASE64_INITIALS = "data:image/jpeg;base64,";
      
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  // const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  let startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2 / 2));
  let endPage = Math.min(totalPages, startPage + itemsPerPage / 2 - 1);

  if (endPage - startPage + 1 < itemsPerPage / 2) {
    if (currentPage <= Math.ceil(itemsPerPage / 2 / 2)) {
      endPage = Math.min(itemsPerPage / 2, totalPages);
    } else if (currentPage > totalPages - Math.floor(itemsPerPage / 2 / 2)) {
      startPage = Math.max(1, totalPages - itemsPerPage / 2 + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2 / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(itemsPerPage / 2 / 2)
      );
    }
  }

  return (
    <PageLayout>
      <EPageTitle title="Mentor/Monitor Image" />
      <div className="filter-container">
        {/* <h4>Filters</h4> */}
        <div className="flex justify-between w-full items-end">
          <div className="flex justify-between gap-3">
            <div className="flex gap-3">
              <Select
                label="Select District"
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedDistrict(e);
                }}
              />

              <TextInput
                placeholder="Unique Code"
                label="Mentor/Monitor Username"
                value={filterData?.search}
                onChange={(e: any) =>
                  filterHandleChange({
                    name: "search",
                    value: e.currentTarget.value,
                  })
                }
              />

              <DatePicker
                label="Select From Date"
                placeholder="MM/DD/YYYY"
                value={filterData?.fromDate}
                onChange={(e: any) =>
                  filterHandleChange({ name: "fromDate", value: e })
                }
                maxDate={
                  filterData?.toDate ? new Date(filterData.toDate) : new Date()
                }
              />
              <DatePicker
                label="Select To Date"
                placeholder="MM/DD/YYYY"
                value={filterData?.toDate}
                onChange={(e: any) =>
                  filterHandleChange({ name: "toDate", value: e })
                }
                minDate={
                  filterData?.fromDate
                    ? new Date(filterData.fromDate)
                    : undefined
                }
                maxDate={new Date()}
              />
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-blue"
                // disabled={!selectedMonth}
                // // disabled={true}
                loading={loading}
                onClick={() => {
                  onSearchFunc();
                }}
              >
                Search
              </EButton>
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-blue "
                loading={loading}
                onClick={() => handleDownload()}
              >
                Download All Images
                <BiDownload className="ml-2" size={22} />
              </EButton>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ECard noHeader title="" customClass="overflow-auto">
          {loading ? (
            <Skeleton radius="md" height={250} />
          ) : mentorImages.length > 0 ? (
            mentorImages?.map((item: any, index: number) => {
              return (
                <div className="flex justify-between mt-3 mb-3 border-b-2 pb-3 min-h-14">
                  <div className="flex">
                    <div className="relative inline-block cursor-pointer group">
                      <Image
                        height={150}
                        width={150}
                        src={BASE64_INITIALS + item?.mentor_Pic_Data}
                        alt=""
                      />
                      <div
                        className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        onClick={() => {
                          setOpened(true);
                          setActiveIndex(index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <FaRegEye size={28} color="white" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="flex items-center">
                        <Title className="opacity-60" order={6}>
                          Unique Code :
                        </Title>
                        <p className="ml-3 text-sm">{item?.unique_Code}</p>
                      </div>

                      <div className="flex items-center mt-3">
                        <Title className="opacity-60" order={6}>
                          Date :
                        </Title>
                        <span className="ml-3 text-sm">
                          {moment(item?.created_On)?.format(
                            "YYYY-MM-DD : HH:mm"
                          )}
                        </span>
                      </div>

                      <div className="flex items-center mt-3">
                        <Title className="opacity-60" order={6}>
                          District :
                        </Title>
                        <p className="ml-3 text-sm">{item?.district_Name}</p>
                      </div>

                      <div className="flex items-center mt-3">
                        <Title className="opacity-60" order={6}>
                          Block :
                        </Title>
                        <p className="ml-3 text-sm">{item?.block_Name}</p>
                      </div>

                      <div className="flex items-center mt-3">
                        <Title className="opacity-60" order={6}>
                          School :
                        </Title>
                        <p className="ml-3 text-sm">
                          {item?.school_Name}({item?.school_Code})
                        </p>
                      </div>

                      <div className="flex items-center mt-3">
                        <Title className="opacity-60" order={6}>
                          Location :
                        </Title>
                        <a
                          className="ml-3 text-sm text-blue-500 hover:underline"
                          href={`https://www.google.com/maps?q=${item?.latitude},${item?.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View on Map
                        </a>
                      </div>
                    </div>

                    <Modal
                      opened={opened && activeindex == index}
                      onClose={() => {
                        setOpened(false);
                        setActiveIndex(null);
                      }}
                      title="Image Viewer"
                      size="lg" // Adjust the size to fit the image or use "lg" for a large modal
                      centered // Centers the modal on the screen
                      // styles={{
                      //   modal: {
                      //     width: "100vw",
                      //     maxWidth: "none",
                      //     height: "100vh",
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     alignItems: "center",
                      //   },
                      // }}
                    >
                      <Image
                        src={BASE64_INITIALS + item?.mentor_Pic_Data}
                        alt="Full view"
                        // style={{
                        //   width: "100%",
                        //   height: "100%",
                        //   objectFit: "contain",
                        // }}
                      />
                    </Modal>
                  </div>
                  <div>
                    <EButton className="btn-blue">
                      <a
                        href={BASE64_INITIALS + item?.mentor_Pic_Data}
                        download="image.png" // Filename for the downloaded file
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Download <BiDownload className="ml-2" size={22} />
                      </a>
                    </EButton>
                    {/* <Text fz="md">Default text</Text> */}
                  </div>
                </div>
              );
            })
          ) : (
            <EEmpty
              title="No Data Found"
              description="There are no Data available."
            />
          )}

          <div className="text-end mt-2">
            {/* Pagination controls */}
            <EButton
              className="btn-blue"
              onClick={prevPage}
              disabled={currentPage === 0}
            >
              Previous
            </EButton>

            {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

            {/* Render first page */}
            {startPage > 1 && (
              <>
                <EButton
                  onClick={() => setCurrentPage(0)}
                  className="btn-light ml-2"
                >
                  1
                </EButton>
                {startPage > 2 && <span className="ml-2">...</span>}
              </>
            )}

            {/* Render page numbers */}
            {Array.from(
              { length: endPage - startPage + 1 },
              (_, index) => startPage + index
            ).map((page) => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page - 1)}
                className={
                  currentPage === page - 1 ? "btn-blue ml-2" : "btn-light ml-2"
                }
              >
                {page}
              </EButton>
            ))}

            {/* Render last page */}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && <span className="ml-2">...</span>}
                <EButton
                  onClick={() => setCurrentPage(totalPages - 1)}
                  className={
                    currentPage === totalPages - 1
                      ? "btn-blue ml-2"
                      : "btn-light ml-2"
                  }
                >
                  {totalPages}
                </EButton>
              </>
            )}

            <EButton
              className="btn-blue ml-2"
              onClick={nextPage}
              // disabled={currentItems.length < itemsPerPage || currentItems.length === 0}
              disabled={
                mentorImages?.length < itemsPerPage ||
                currentPage === totalPages - 1
              }
            >
              Next
            </EButton>
          </div>
        </ECard>
      </div>
    </PageLayout>
  );
}

export default DownloadAssessement