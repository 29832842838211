import {
  Select,
  Checkbox,
  TextInput,
  Skeleton,
  Avatar,
} from "@mantine/core";

import {
  EButton,
  EModal,
  EPageTitle
} from "../../libs/ui";
import PageLayout from "../../PageLayout";
import TeacherAllocationOverviewBySchool from "./Components/TeacherAllocationOverviewBySchool";

import { defaultSelectedTeacher } from "../../initialStates";
import useTeacherAllocationBySchool from "./Hooks/useTeacherAllocationBySchool";

export default function TeacherAllocationBySchoolIndex() {
  const {
    schoolCodeToBeSearched,
    setSchoolCodeToBeSearched,

    classesBasedOnSchool,
    sectionBasedOnSchoolAndClass,

    getAllTeacherListBySchoolID,
    allTeacherListBySchoolId,
    isChecked,
    setIsChecked,
    handleDisableSelectedClass,
    handleDisableSelectedSection,
    isLoading,
    setIsLoading,
    isOpenUpdateAllocationModal,
    setIsOpenUpdateAllocationModal,
    selectedTableIndex,
    setSelectedTableIndex,
    selectedTeacher,
    setSelectedTeacher,
    postClassAllocationToTeacher,
    // putClassAllocationToTeacher
  } = useTeacherAllocationBySchool();

  return (
    <PageLayout>
      <EPageTitle
        title="Teachers"
        description={`There are a total of ${allTeacherListBySchoolId ? allTeacherListBySchoolId.length : "--"
          } teachers`}
        // end={<div className="flex items-end gap-2">
        //   <TextInput
        //     size="md"
        //     label="Enter dedicated school ID"
        //     value={schoolCodeToBeSearched}
        //     onChange={e => setSchoolCodeToBeSearched(+e.target.value)}
        //   />
        //   <EButton
        //     className="btn-blue"
        //     loading={isLoading}
        //     onClick={getAllTeacherListBySchoolID}
        //   >Search</EButton>
        // </div>}
      />

      {isLoading
        ?
        <Skeleton radius="md" height={150} />
        :
        <TeacherAllocationOverviewBySchool
          overviewData={allTeacherListBySchoolId ? allTeacherListBySchoolId : []}
          handleSelectedIndexChange={(index) => setSelectedTableIndex(index)}
          getAllTeacherListBySchoolID={getAllTeacherListBySchoolID}
        />}

      {/* SELECTED TEACHER INFO */}
      {selectedTableIndex !== -1 ? (
        <EModal
          transition="fade"
          title="Teacher Allocation"
          closeOnClickOutside={false}
          isOpen={selectedTableIndex !== -1 ? true : false}
          setIsOpen={() => (selectedTableIndex !== -1 ? true : false)}
          onClose={() => {
            // setIsOpenAddAllocationModal(false);
            setSelectedTableIndex(-1);
            setSelectedTeacher(defaultSelectedTeacher);
          }}
          children={
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-2">
                <Avatar color="cyan" radius="xl">
                  {selectedTeacher?.employee_Name ? selectedTeacher?.employee_Name[0].toUpperCase() : ""}
                </Avatar>
                <div>
                  <span className="font-semibold capitalize">{selectedTeacher?.employee_Name ? selectedTeacher?.employee_Name?.toLowerCase() : ""}</span>
                  <p className="mb-0 pb-0 text-slate-500">{selectedTeacher?.employee_Code ? selectedTeacher?.employee_Code : ""}</p>
                </div>
              </div>

              <Select
                size="md"
                label="Select Class"
                placeholder="Select"
                data={classesBasedOnSchool.map((e) => ({
                  value: String(e?.id),
                  label: e?.name,
                  // disabled: handleDisableSelectedClass(e.id)
                }))}
                value={String(selectedTeacher?.currentAllocation?.selectedClass_id)}
                onChange={(e) =>
                  setSelectedTeacher({
                    ...selectedTeacher,
                    currentAllocation: { selectedClass_id: Number(e), selectedSection_id: (0) }
                  })
                }
              />

              <Select
                size="md"
                label="Select section"
                placeholder="Select"
                data={sectionBasedOnSchoolAndClass.map((e) => ({
                  value: String(e?.id),
                  label: e?.name,
                  disabled: handleDisableSelectedSection(e.id)
                }))}
                value={String(selectedTeacher?.currentAllocation?.selectedSection_id)}
                onChange={(e) =>
                  setSelectedTeacher({
                    ...selectedTeacher,
                    currentAllocation: {
                      selectedClass_id:
                        selectedTeacher?.currentAllocation?.selectedClass_id
                          ?
                          selectedTeacher?.currentAllocation?.selectedClass_id
                          :
                          0,
                      selectedSection_id: Number(e)
                    }
                  })
                }
              />

              <div className="flex justify-end gap-3">
                <EButton
                  className="btn-grey"
                  loading={isLoading}
                  onClick={() => setSelectedTableIndex(-1)}
                >
                  Cancel
                </EButton>

                <EButton
                  className="btn-blue"
                  loading={isLoading}
                  onClick={() => postClassAllocationToTeacher()}
                >
                  Allocate
                </EButton>
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}

      {/* SELECTED TEACHER INFO UPDATE CLASS AND SECTION */}
      {selectedTableIndex !== -1 ? (
        <EModal
          title="Update Allocation Details"
          transition="fade"
          isOpen={isOpenUpdateAllocationModal}
          setIsOpen={() => (isOpenUpdateAllocationModal ? true : false)}
          onClose={() => setIsOpenUpdateAllocationModal(false)}
          children={
            <div className="flex flex-col gap-5">
              <Select
                label="Select Class"
                placeholder="Select"
                data={classesBasedOnSchool.map((e) => ({
                  value: String(e?.id),
                  label: e?.name,
                }))}
                value={String(selectedTeacher?.allocation?.map(e => e.class_Id))}
                onChange={(e) => ""
                  // setSelectedTeacher({
                  //   ...selectedTeacher,
                  //   class_Id: Number(e),
                  // })
                }
              />

              <Select
                label="Select section"
                placeholder="Select"
                data={sectionBasedOnSchoolAndClass.map((e) => ({
                  value: String(e?.id),
                  label: e?.name,
                }))}
                value={String(selectedTeacher?.allocation?.map(e => e.section_Id))}
                onChange={(e) => ""
                  // setSelectedTeacher({
                  //   ...selectedTeacher,
                  //   section_Id: Number(e),
                  // })
                }
              />

              <div className="flex justify-between items-center">
                <Checkbox
                  label={
                    <span className="text-slate-400">
                      I am sure I want to update this allocation
                    </span>
                  }
                  checked={isChecked}
                  onChange={(e) => setIsChecked(!isChecked)}
                />
                <EButton
                  loading={isLoading}
                  disabled={!isChecked}
                  className="btn-blue"
                // onClick={() => putClassAllocationToTeacher()}
                >
                  Save
                </EButton>
              </div>
            </div>
          }
        />
      ) : (
        <></>
      )}
    </PageLayout>
  );
}
