import React, { useContext, useState } from "react";
import { HiChevronDown, HiChevronRight } from "react-icons/hi2";

import "./ENavItem.scss";

import { AppContext } from "../../../../../../AppContext";
import { IRoute } from "../../../../../../Routes/routes";
import { Tooltip } from "@mantine/core";

type Props = {
  parentIndex: number;
  label: string | JSX.Element;
  icon?: JSX.Element;
  isActive?: boolean;
  handleClick?: () => void;
  setHoverIndex: (e: number[]) => void;
  hasSubMenu?: boolean;
  // handleExpand?: () => void;
  // subMenu?: IRoute[];
};

export function ENavItem({
  parentIndex,
  label,
  isActive,
  icon,
  handleClick,
  hasSubMenu,
  setHoverIndex,
}: //  handleExpand,
//  subMenu,
Props) {
  const { isSidebarCollapsed } = useContext(AppContext);
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <div
      className={"nav-item  " + (isActive ? " active" : "")}
      onClick={() =>
        hasSubMenu ? setHoverIndex([parentIndex]) : handleClick && handleClick()
      }
    >
      {icon ? (
        <Tooltip label={label}>
          {icon && <div className="icon">{icon}</div>}
        </Tooltip>
      ) : null}
      {!isSidebarCollapsed ? <div className="flex flex-1 ml-2">{label}</div> : <></>}
      {hasSubMenu && (
        <div className="dropdown-icon">
          {isHover ? <HiChevronDown strokeWidth={1.5} size={16} /> : <HiChevronRight strokeWidth={1.5} size={16} />}
        </div>
      )}
    </div>
  );
}

export default ENavItem;

// hasSubMenu ?
//         <>
//           <div className={'nav-item ' + (isActive ? ' active' : '')}
//           onClick={() => {
//             setHoverIndex([parentIndex])
//           }}
//             >
//             {icon ? <div className="icon">{icon}</div> : null}
//             {!isSidebarCollapsed ? label : <></>}
//             {hasSubMenu && <div className="icon ml-auto">{isHover ? <HiChevronDown /> : <HiChevronRight />}</div>}
//           </div>
//         </>
//         :
