import { createContext, Dispatch, SetStateAction } from "react";

import { IUser, IUserDetails } from "./types";
import { initialUser } from "./initialStates";
import { IBlockLoginDetails, IDepartmentUserDetails, ILoginDetails, ISchoolLoginDetails } from "./libs/data-access/types";
import { IRoute } from "./Routes/routes";

export interface IAuthContext {
    user: IUser;
    handleUser: (value: Omit<IUser, "details">) => void;
    handleUserDetails: (key: keyof IUserDetails, value: IDepartmentUserDetails | IBlockLoginDetails | ISchoolLoginDetails | undefined) => void;
    isSidebarCollapsed: boolean | undefined,
    setIsSidebarCollapsed: Dispatch<SetStateAction<boolean | undefined>>;
    isLoggedIn: boolean | undefined;
    setIsLoggedIn: Dispatch<SetStateAction<boolean | undefined>>;
    token: string | undefined;
    setToken: Dispatch<SetStateAction<undefined | string>>;
    role: string[] | undefined;
    setRole: Dispatch<SetStateAction<string[] | undefined>>;
    handleLogout: () => void;
    currentRotes: IRoute[] | undefined;
    setCurrentRoutes: Dispatch<SetStateAction<IRoute[]>>;
    targetRef: React.MutableRefObject<HTMLDivElement | null>;
    scrollTriger:boolean;
    setScrollTriger: Dispatch<SetStateAction<boolean>>;
};

export const AppContext = createContext<IAuthContext>({
    user: initialUser,
    handleUser: () => { },
    handleUserDetails: () => { },
    isSidebarCollapsed: true,
    setIsSidebarCollapsed: () => { },
    isLoggedIn: true,
    setIsLoggedIn: () => { },
    token: undefined,
    setToken: () => { },
    role: undefined,
    setRole: () => { },
    handleLogout: () => { },
    currentRotes : undefined,
    setCurrentRoutes : ()=> { },
    targetRef: { current: null },
    scrollTriger: false,
    setScrollTriger: () => { },
});