import React, { useEffect, useState } from 'react'
import { useMasterFetch, useSurveyData } from '../../../../libs/data-access'
import { showToast } from '../../../../utils/utility'
import { useNavigate, useParams } from 'react-router-dom'
import { ISurveyDetailsByID, ISurveyList } from '../../../../libs/data-access/types'
import useMasterData from '../../../Hooks/useMasterData'

export default function useSurveyDetails() {

    const param = useParams()

    const navigate =useNavigate()
    const {
        fetchSurveyData
    }=useSurveyData()

    const {
    districts,
    getDistrictByStateId
    } =useMasterData()

    const [isFirst, setIsFirst]=useState<boolean>(true)
    const [isLoading, setIsLoading]=useState<boolean>(false)
    const [surveyDetails,setSurveyDetails]= useState<ISurveyDetailsByID |undefined>(undefined)

    useEffect(()=>{
         if(isFirst){
            setIsFirst(false)
         }
         else{
            getSurveyDetailsById()
            getDistrictByStateId(1)
         }
    },[isFirst])

    // APi function
    const getSurveyDetailsById = async()=>{
        setIsLoading(true)
        const res = await fetchSurveyData(`GetSurevyById?surevy_Id=${param?.surveyId}`)
        if(res.isSuccess){
            setSurveyDetails(res?.data[0])
        }
        else{
            showToast({
                type:"error",
                message: res?.message ?? "Couldn't fetch selected survey details"
            })
            navigate(`/survey`)
        }
        setIsLoading(false)
    }
  return {
     isLoading,
     districts,
     surveyDetails
  }
}
