import React, { useContext, useEffect, useState } from 'react'
import { useDashboardDate } from '../../../../../libs/data-access';
import { DashboardFilterContext } from '../../../DashboardFilterContext';
import { IClassroomAndSchoolCompliance, IObservationCompliance, IReportLevel } from '../../../../../libs/data-access/types';
import moment from 'moment';
import { calculateYearForDashboard, groupByKey } from '../../../../../utils/utility';

export default function useLineGraphOverall() {
  const {
    fetchDashboardData
  } = useDashboardDate()

  const {
    selectedMonth,
    selectedDistrict,
    selectedBlock,
    selectedCluster,
    districts,
    blocks,
    isSearching,
    setIsLoadingSchoolVisit,
    setIsSearching,
    selectedSession,
  } = useContext(DashboardFilterContext);

  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [reportLevel, setReportLevel] = useState<IReportLevel>({ levelNames: "state", districtName: "haryana",districtId:0, blockName: "" });
  const [activeButtonValue, setActiveButtonValue] = useState<"hindi" | "math" | undefined> (undefined);
  const [isLoadingClassroomAndSchoolCompliance, setIsLoadingClassroomAndSchoolCompliance] = useState<boolean>(false);
  const [isLoadingObservationCompliance, setIsLoadingObservationCompliance] = useState<boolean>(false);


  const [classroomAndSchoolCompliance, setClassroomAndSchoolCompliance] = useState<IClassroomAndSchoolCompliance[]>([]);
  const [observationCompliance, setObservationCompliance] = useState<IObservationCompliance[]>([]);

  const chartOption = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: {
      categories: ['Aug', 'Sep', 'Oct', 'Nov'],
    },
    legend: {
      show: true,
      position: "top"
    }
  };

  useEffect(() => {
    if (isSearching) {
      getClassroomAndSchoolCompliance();
      setActiveButtonValue("hindi")
    }
  }, [isSearching]);

  useEffect(() => {
    if (activeButtonValue) {
      getObservationCompliance();
    }
  }, [activeButtonValue, isSearching]);

  // Handler function


  // API function
  const getClassroomAndSchoolCompliance = async () => {

    setIsLoadingClassroomAndSchoolCompliance(true);
    const res = await fetchDashboardData(
      `trendOverTime?DistrictId=${selectedDistrict ?? 0}&BlockId=${
        selectedBlock ?? 0
      }&ClusterId=${selectedCluster ?? 0}&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }-${selectedMonth}-01`}`
    );
    if (res.isSuccess) {
      const data = res?.data.sort((a: any, b: any) => {
        // First, compare the years
        if (a.Year_Name !== b.Year_Name) {
          return a.Year_Name - b.Year_Name;
        }
        // If years are equal, compare the months
        return a.MonthD - b.MonthD;
      });
      // console.log("Trend over time: ", data);
      setClassroomAndSchoolCompliance(data)
      setReportLevel({
        levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
        districtName: districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
        districtId: selectedDistrict ? +selectedDistrict : 0 ,
        blockName: blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : ""
      });
    } else {
      setClassroomAndSchoolCompliance([])
      // showToast({
      //     type: "error",
      //     message: res?.message || "Fail to get learning indicator"
      // })
    };
    setIsLoadingClassroomAndSchoolCompliance(false);

  };

  const getObservationCompliance = async () => {

    setIsLoadingObservationCompliance(true);
    const res = await fetchDashboardData(
      `ObservationForTrendOverTime?SubjectId=${
        activeButtonValue === "hindi" ? 2 : 3
      }&DistrictId=${selectedDistrict ?? 0}&BlockId=${
        selectedBlock ?? 0
      }&ClusterId=${selectedCluster ?? 0}&GetMonthYearFromDate=${`${
        selectedMonth
          ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
          : moment().format("YYYY")
      }-${selectedMonth}-01`}`
    );
    if (res.isSuccess) {
      // const data = res?.data.sort((a: any, b: any) => a.MonthD < b.MonthD);
      const data = res?.data.sort((a: any, b: any) => {
        // First, compare the years
        if (a.Year_Name !== b.Year_Name) {
          return a.Year_Name - b.Year_Name;
        }
        // If years are equal, compare the months
        return a.MonthD - b.MonthD;
      });
      // const groupedData = groupByKey(data, "Month_Name");
      // console.log("Grouped observation compliance data: ", data);
      setObservationCompliance(data);
      setReportLevel({
        levelNames: selectedCluster ? "cluster" : selectedBlock ? "block" : selectedDistrict ? "district" : "state",
        districtName: districts && selectedDistrict ? districts.filter((e) => e?.id == +selectedDistrict)[0]?.name : "Haryana",
        districtId : selectedDistrict ? +selectedDistrict : 0 ,
        blockName: blocks && selectedBlock ? blocks.filter((e) => e?.id == +selectedBlock)[0]?.name : ""
      });
    } else {
      setObservationCompliance([]);
      // showToast({
      //     type: "error",
      //     message: res?.message || "Fail to get learning indicator"
      // })
    };
    setIsLoadingObservationCompliance(false);

  };

  return {
    isLoadingClassroomAndSchoolCompliance,
    activeButtonValue,
    setActiveButtonValue,
    classroomAndSchoolCompliance,
    chartOption,

    isLoadingObservationCompliance,
    observationCompliance
  };

}