import { Space, Tabs } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { FcQuestions } from "react-icons/fc";
import { GrDocumentUpload } from "react-icons/gr";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";

import PageLayout from "../../../PageLayout";
import { EButton, EPageTitle } from "../../../libs/ui";
import UploadQuestionForm from "../Components/Uploader/UploadQuestionForm";
import UploadQuestionFromCSV from "../Components/Uploader/UploadQuestionFromCSV";

export default function QuestionBank() {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <EPageTitle
        title="Upload Questions"
        // end={<>
        //     <EButton
        //         className="btn-light mr-2"
        //         rightIcon={<FaFileDownload />}
        //         onClick={() => console.log("Download File")}
        //     >
        //         Download CSV Template
        //     </EButton>
        //     <EButton
        //         className="btn-light"
        //         rightIcon={<FaArrowLeft />}
        //         onClick={() => navigate(-1)}
        //     >
        //         Go Back
        //     </EButton>
        // </>}
      />

      <Tabs defaultValue="add_manually" keepMounted={false}>
        {/* <Tabs.List>
          <Tabs.Tab value="add_manually" 
        //   icon={<FcQuestions />}
          >
            Add Manually
          </Tabs.Tab>
          <Tabs.Tab
            disabled
            value="select_csv_file"
            // icon={<GrDocumentUpload />}
          >
            Upload CSV File
          </Tabs.Tab>
        </Tabs.List>

        <Space h={10} /> */}
        {/* ADD QUESTIONS MANUALLY */}
        <Tabs.Panel value="add_manually" pt="xs">
          <UploadQuestionForm />
        </Tabs.Panel>

        {/* UPLOAD QUESTION FILE */}
        <Tabs.Panel value="select_csv_file" pt="xs">
          <UploadQuestionFromCSV />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}
