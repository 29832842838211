import { AxiosResponse } from 'axios';

import { IResponse, res } from './types';

/*
* format return type for API success (Status Code: 200) response
*/
export const handleFormatResponse = (
    response: AxiosResponse<any, any>
) => {
    const data: IResponse<any> = response.data ?? res;
    let r = res;
    // console.log("Response: ", data);
    // if (data.status) {
      if (data.status || (data.status == false && data?.response?.length == 0)) {
        return (r = {
          ...r,
          isSuccess: true,
          data: data.response,
          message: data.message ? String(data.message) : "success",
        });
      } else {
        return (r = {
          isSuccess: false,
          data: undefined,
          error: data.response,
          message: String(data.message) || "",
        });
      };
};

/*
* format return type for API request fails
*/
export const handleFormatResponseForError = (
    error: any
) => {
    let r = res;
    return r = {
        isSuccess: false,
        data: undefined,
        error: error,
        message: error?.response?.data?.message || error?.message || "Request could not get processed!"
    };
};

export const camelCaseToTitleCase =(input: string): string => {
    // Split the string by uppercase letters
    const words = input.split(/(?=[A-Z])/);
  
    // Capitalize the first letter of each word and join them back together
    const titleCase = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  
    return titleCase;
  }

  export default function CommaSeparator(value: number) {
    if(!isNaN(value)){
        const formattedNumber = new Intl.NumberFormat('en-IN').format(value);
        return formattedNumber;
    }
    else{
        return 0
    }

  }  