import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from "../../utility";

export function useMasterFetch() {

    const fetchMasterData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Masters/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        fetchMasterData
    };
}
