import { HiPlus } from "react-icons/hi2";
import { Avatar, Checkbox, Text, TextInput } from "@mantine/core";

import { EButton, EEmpty, EModal, ETable } from "../../../libs/ui";
import AllocatedClassSectionBadge from "./AllocatedClassSectionBadge";

import {
  IAllTeacherListBySchoolId
} from "../../../libs/data-access/types";
import useTeacherAllocation from "../Hooks/useTeacherAllocation";

interface IProps {
  overviewData: IAllTeacherListBySchoolId[];
  handleSelectedIndexChange: (index: number) => void;
  getAllTeacherListBySchoolID: () => void;
};

export default function TeacherAllocationOverviewBySchool({
  overviewData,
  handleSelectedIndexChange,
  getAllTeacherListBySchoolID
}: IProps) {

  const {
    state,
    dispatch,
    handleSelectTeacherClassSection,
    isLoading,
    deleteClassSectionAllocationForSpecificTeacher
  } = useTeacherAllocation({ getAllTeacherListBySchoolID });

  return (
    <>
      {overviewData.length
        ?
        <ETable>
          <thead>
            <tr >
              <th>Teacher</th>
              <th>Class - Section</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {overviewData.map((each, index) => (
              <tr key={index + "ov"}>
                <td>
                  <div className="flex items-center gap-2">
                    <Avatar color="cyan" radius="xl">
                      {each.employee_Name[0].toUpperCase()}
                    </Avatar>
                    <div>
                      <span className="font-semibold capitalize">{each.employee_Name.toLowerCase()}</span>
                      <p className="mb-0 pb-0 text-slate-500">{each.employee_Code}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="flex gap-3">
                    {each?.allocation?.map((c, i) => (
                      <AllocatedClassSectionBadge
                        key={i + "allocation"}
                        allocatedClassSection={c}
                        handleClick={() => handleSelectTeacherClassSection(each, i)}
                      />
                    ))}
                  </div>
                </td>
                <td>
                  <div className="flex justify-end">
                    {/* {each.class_Id && each.section_Id ? (
                      <HiPencilSquare
                        className="cursor-pointer text-green-400 hover:text-green-500"
                        onClick={() => {
                          handleUpdateTeacherAllocationChange();
                          handleSelectedIndexChange(index);
                        }}
                      />
                    ) : ( */}
                    <Text
                      className="cursor-pointer flex gap-2 items-center text-blue-500 hover:text-blue-600"
                      onClick={() => handleSelectedIndexChange(index)}
                    >
                      <HiPlus />
                      <span>Allocate Class & Section</span>
                    </Text>
                    {/* )} */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </ETable>
        :
        <EEmpty
          title="No teacher available for this school"
        />}

      {state.isDeletingAllocation
        ?
        <EModal
          size="lg"
          title="Are you sure?"
          closeOnClickOutside={false}
          isOpen={state.isDeletingAllocation}
          setIsOpen={() => dispatch({ type: "cancel" })}
        >
          <div className="flex flex-col gap-5">

            <img
              width={70}
              src={require("../../../assets/cancelDocument.svg").default}
            />

            <p>
              <span className="font-semibold">{state.teacher?.employee_Name} ({state.teacher?.employee_Code})</span>
              &nbsp;will be removed from <span className="font-semibold">
                {state.selectedClassSectionToBeDeleted?.class_Name} - {state.selectedClassSectionToBeDeleted?.section_Name}
              </span>
            </p>

            <div className="flex justify-end gap-3">
              {isLoading
                ?
                <></>
                :
                <EButton
                  className="btn-light"
                  loading={isLoading}
                  onClick={() => dispatch({ type: "cancel" })}
                >
                  Cancel
                </EButton>}

              <EButton
                className="btn-outline-red"
                loading={isLoading}
                onClick={() => deleteClassSectionAllocationForSpecificTeacher()}
              >
                Yes, Remove
              </EButton>
            </div>
          </div>
        </EModal>
        :
        <></>}
    </>
  );
}
