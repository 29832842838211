import React, { useContext, useEffect, useState } from 'react'
import { calculateCurrentMonthForDashboard, calculateDefaultSession, showToast } from '../../../utils/utility';
import { IScorecard } from '../../../types';
import { handleValidScoreCard } from '../../../utils/validators';
import useMasterData from '../../Hooks/useMasterData';
import { BASE_URL } from '../../../libs/data-access/constant';
import { useDashboardDate } from '../../../libs/data-access';
import { AppContext } from '../../../AppContext';
import moment from 'moment';

const useScorecard = () => {

  const {
    getSession,
    sessions
  } = useMasterData();

  const {
    saveDashboardFormData,
    fetchDashboardDataStaging,
    deleteDashboardDataStaging
  } = useDashboardDate()

  const {
    role,
    user,
  } = useContext(AppContext);



 const monthOptions = [
   { label: "April", value: "04" },
   { label: "May", value: "05" },
   { label: "June", value: "06" },
   { label: "July", value: "07" },
   { label: "August", value: "08" },
   { label: "September", value: "09" },
   { label: "October", value: "10" },
   { label: "November", value: "11" },
   { label: "December", value: "12" },
   { label: "January", value: "01" },
   { label: "February", value: "02" },
   { label: "March", value: "03" },
 ];

  console.log("session",sessions)


  const [scoreCardValue, setScoreCardValue] = useState<IScorecard>({
    SessionId: "",
    MonthId: "",
    file: null,
    SessionName: "",
    MonthName: "",
    CreatedBy: `${user.role_Id}`,
  });

  const [scoreCardSearchValue, setScoreCardSearchValue] = useState<IScorecard>({
    SessionId: "",
    MonthId: "",
  });

  const [allScoreCardData,setAllScoreCardData] = useState([])

  const[open,setOpen] = useState(false)

  const [deleteId,setDeleteId] = useState(null)
const [openConfirm,setConfirm] = useState(false)
 const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(11);

    const [totalRecords,setTotalRecords] = useState(11)

  const [searchQuery, setSearchQuery] = useState('');
  const [currentItems, setCurrentItems] = useState([]); // Specify the type here
  const [initial,setInitial] = useState(true)

  const [loading,setLoading] = useState(false)


  console.log("allScoreCardData", allScoreCardData);
  console.log("scoreCardSearchValue",scoreCardSearchValue)

  const handleChangeScoreCard = ({name,value}: { name: string, value: any }) => {
    setScoreCardValue((prev)=>{
      return {
        ...prev,
        [name]:value
      }
    })
  }

  const handleChangeScoreCardSearch = ({name,value}: { name: string, value: any }) => {
    setScoreCardSearchValue((prev)=>{
      return {
        ...prev,
        [name]:value
      }
    })
  }

const saveScorecard = async() => {
const valid = handleValidScoreCard(scoreCardValue)
if (valid) {
  const fileSizeInBytes = scoreCardValue?.file?.size;
  const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB in bytes
if(fileSizeInBytes !== undefined && fileSizeInBytes>maxSizeInBytes){
  showToast({
    type:"warning",
    message : "please select file size upto 2mb"
})
}
else{
console.log("api hit")
const formData = new FormData();
// Object.keys(scoreCardValue).forEach((item: string)=>{
//   formData.append(`${item}`, String(scoreCardValue[item as keyof IScorecard]));
// })
formData.append('SessionId', scoreCardValue.SessionId);
formData.append('MonthId', scoreCardValue.MonthId);
formData.append('SessionName', scoreCardValue.SessionName || ''); // Use empty string as default value if undefined
formData.append('MonthName', scoreCardValue.MonthName || ''); // Use empty string as default value if undefined
formData.append('CreatedBy', String(scoreCardValue.CreatedBy) || '');

if (scoreCardValue.file) {
  formData.append('file', scoreCardValue.file);
}
// formData.append('SessionId', scoreCardValue.SessionId);
// formData.append('MonthId', scoreCardValue.MonthId);
// formData.append('file', scoreCardValue.file);
// formData.append('SessionName', scoreCardValue.SessionName);
// formData.append('MonthName', scoreCardValue.MonthName);
// formData.append('CreatedBy', scoreCardValue.CreatedBy);
const res = await saveDashboardFormData(`${BASE_URL}PeriodicAssessment/PeriodicAssessment/UploadPeriodicAssessmentScoreCard`,formData)
console.log("")
      if (res.isSuccess) {
        showToast({
          type:"success",
          message : res.message
      })
      setScoreCardValue({
        SessionId:"",
        MonthId:'',
        file:null,
        SessionName:"",
        MonthName:"",
        CreatedBy:`${user.role_Id}`,
      })

      getScoreCardApi()
      } else {
        showToast({
          type:"error",
          message : res.message
      })
      setScoreCardValue({
        SessionId:"",
        MonthId:'',
        file:null,
        SessionName:"",
        MonthName:"",
        CreatedBy:`${user.role_Id}`,
      })
      };

      setOpen(false)
}
}
}

const getScoreCardApi = async(current?:any) => {
  setLoading(true)
  console.log("searchValueinapi",scoreCardSearchValue.SessionId)
  const res = await fetchDashboardDataStaging(
    `${BASE_URL}PeriodicAssessment/PeriodicAssessment/GetPeriodicAssessmentScoreCard?Session_Id=${
      scoreCardSearchValue?.SessionId?.length > 0
        ? scoreCardSearchValue?.SessionId
        : 0
    }&Month_Id=${
      scoreCardSearchValue?.MonthId?.length > 0
        ? scoreCardSearchValue?.MonthId
        : 0
    }&OFFSET=${
      current ? 0 : currentPage * itemsPerPage
    }&PAGESIZE=${itemsPerPage}`
  );
      if (res.isSuccess) {
        setAllScoreCardData(res?.data)
        setTotalRecords(res?.data[0]?.total);
      } else {
        setAllScoreCardData([])
        showToast({
          type:"error",
          message : res.message || "Something went wrong"
      })
      };

      setLoading(false);
}



const deleteScoreCard = async(id:any) => {
  const res = await deleteDashboardDataStaging(`${BASE_URL}PeriodicAssessment/PeriodicAssessment/DeletePeriodicAssessmentScoreCard/${id}/${user.role_Id}`)
      if (res.isSuccess) {
        showToast({
          type:"success",
          message : res.message
      })
      getScoreCardApi()
      } else {
        showToast({
          type:"error",
          message : res.message
      })
      };
}

const deleteConfirmFunc = (id:any) => {
  setDeleteId(id)
  setConfirm(true)
}

const confirmAcceptFunc = () => {
  deleteScoreCard(deleteId)
  setDeleteId(null)
  setConfirm(false)
}

const confirmRejectFunc = () => {
  setConfirm(false)
  setDeleteId(null)
}


useEffect(() => {
  setScoreCardValue((prev:any) => ({
    ...prev,
    SessionName: prev.SessionId?.length > 0 ? sessions?.find(option => String(option?.session_Id) === prev.SessionId)?.session_Name : ""
  }));
}, [sessions,scoreCardValue.SessionId]); // Assuming sessions is a dependency

useEffect(() => {
  if (sessions?.length>0){
setScoreCardSearchValue((prev: any) => ({
  ...prev,
  SessionId: String(
    sessions?.find(
      (option) =>
        option?.session_Name ==
        calculateDefaultSession(parseInt(moment().format("M")))
    )?.session_Id
  ),
}));
setInitial(false)
  }
    
}, [sessions]);


  useEffect(() => {
    setScoreCardValue((prev) => ({
      ...prev,
      MonthName:
        prev.MonthId?.length > 0
          ? monthOptions?.find((option) => option?.value === prev.MonthId)
              ?.label
          : "",
    }));
  }, [scoreCardValue.MonthId]); 

 const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);


    useEffect(() => {
  //   const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const filteredElements = allScoreCardData?.filter((item: any) =>
      item.fileName?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
    // const currentItems = filteredElements.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(filteredElements); // Update currentItems state with filtered data
  }, [searchQuery,allScoreCardData]);

useEffect(() => {
  getSession(false);
}, [])

useEffect(() => {
  if(scoreCardSearchValue?.SessionId?.length>0){
getScoreCardApi();
  }
}, [currentPage, initial]);



  return {
    scoreCardValue,
    handleChangeScoreCard,
    saveScorecard,
    handleChangeScoreCardSearch,
    scoreCardSearchValue,
    sessions,
    monthOptions,
    open,
    setOpen,
    allScoreCardData,
    getScoreCardApi,
    deleteScoreCard,
    deleteId,
    openConfirm,
    deleteConfirmFunc,
    confirmAcceptFunc,
    confirmRejectFunc,

    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    totalRecords,
    loading
  }
}


export default useScorecard