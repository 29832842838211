import axios from "axios";
import { BASE_URL } from "../../constant";

import {
  handleFormatResponse,
  handleFormatResponseForError,
} from "../../utility";

export function useUserManagementFetch() {

  const fetchUserManagementData = async (endpoint: string) => {
    try {
      let response = await axios.get(`${BASE_URL}UserManagement/${endpoint}`);
      // console.log("Response: ", response?.data);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      // console.log("Error: ", error);
      const formattedError = handleFormatResponseForError(error);
      return formattedError;
    }
  };

  const postUserManagementData = async (endpoint: string, payload: any) => {
    try {
      let response = await axios.post(
        `${BASE_URL}UserManagement/${endpoint}`,
        payload
      );
      // console.log("Response: ", response?.data);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      // console.log("Error: ", error);
      const formattedError = handleFormatResponseForError(error);
      return formattedError;
    }
  };

  const putUserManagementData = async (endpoint: string, payload: any) => {
    try {
      let response = await axios.put(
        `${BASE_URL}UserManagement/${endpoint}`,
        payload
      );
      // console.log("Response: ", response?.data);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      // console.log("Error: ", error);
      const formattedError = handleFormatResponseForError(error);
      return formattedError;
    }
  };

  const deleteUserManagementData = async (endpoint: string) => {
    try {
      let response = await axios.delete(`${BASE_URL}UserManagement/${endpoint}`);
      // console.log("Response: ", response?.data);
      const formattedResponse = handleFormatResponse(response);
      return formattedResponse;
    } catch (error: any) {
      // console.log("Error: ", error);
      const formattedError = handleFormatResponseForError(error);
      return formattedError;
    }
  };

  return {
    fetchUserManagementData,
    postUserManagementData,
    putUserManagementData,
    deleteUserManagementData,
  };
}
