import React, { useContext, useState } from 'react'
import { useMentorReports } from '../../../../../libs/data-access';
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import { IDateWiseMentorVisitComplianceReportByDate } from '../../../../../libs/data-access/types';
import moment from 'moment';
import useDashboardVisitComplianceReports from './useDashboardVisitComplianceReports';
import { AppContext } from '../../../../../AppContext';

export default function useDateWiseMentorVisitCompliance() {

  const {
    user,
    role
} =useContext(AppContext)

  const {
      fetchMentorReport,
      fetchMentorFastApiReport
  } = useMentorReports();

  const {
    selectedBlock,
    selectedDistrict,
    districts,
    blocks,
    setSelectedBlock,
    setSelectedDistrict,
    selectedMonth,
    setSelectedMonth,
    handelClearFilter,
    selectedSession,
    setSelectedSession,
  } = useDashboardVisitComplianceReports();

//   const [selectedMonth, setSelectedMonth] = useState<string>(calculateCurrentMonth());
  const [dateWiseDistrictComplianceReport, setDateWiseDistrictComplianceReport] = useState<IDateWiseMentorVisitComplianceReportByDate[]>([]);
  // data for CSV download
  const [dateWiseDistrictComplianceCSV, setDateWiseDistrictComplianceCSV] = useState<{ headers: any[], content: any[] }>();

  // loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* HANDLER FUNCTIONS */
  const handleSelectMonth = (value: string) => {
      setSelectedMonth(value);
      setDateWiseDistrictComplianceReport([]);
  };

  const formatVisitComplianceByDistrictReport = (visitCompliance: IDateWiseMentorVisitComplianceReportByDate[]) =>{

    const headers = [
        { label: "District", key: "district" },
        { label: "Block", key: "block" },
        { label: "Cluster ( if applicable)", key: "cluster" },
        { label: "School Name", key: "school_name" },
        { label: "Mentor ID", key: "mentor_id" },
        { label: "Date of visit", key: "date_of_visit" },
        { label: "Total Classroom Observations", key: "total_classroom_observations" },
        // { label: "Total Unique School Visits", key: "total_unique_school_visits" },
        // { label: "Total school visits", key: "total_school_visits" },
        { label: "Total Number of Students Spot Tested", key: "total_number_of_students_spot_tested" },
    ];

    const data = visitCompliance?.map(each => Object.assign({
        district: each.District_Name || "",
        block:each?.Block_Name || "",
        cluster:each?.Cluster_School_Name || "",
        school_name: each.School_Name ?? "",
        mentor_id: each.Unique_Code ?? "",
        date_of_visit: each?.Visit_Date ?  each?.Visit_Date == "Never Visited!" ? "Never Visited!" : moment(each?.Visit_Date).format("DD-MM-YYYY") : "",
        // date_of_visit: each?.visit_Month ?  each?.visit_Month : "",
        total_classroom_observations: each.Total_Classroom_Observations ?? "",
        // total_unique_school_visits: each.total_Unique_School_Visit ?? "",
        // total_school_visits: each.total_School_Visit ?? "",
        total_number_of_students_spot_tested: each.Total_Student_Spot_Tested ?? ""
    }));

    setDateWiseDistrictComplianceCSV({
        headers: headers,
        content: data
    });

};

  /* API CALLS */
  const fetchDateWiseDistrictVisitCompliance = async () => {
    setDateWiseDistrictComplianceCSV({ headers:[], content: [] })
    setDateWiseDistrictComplianceReport([])
      setIsLoading(true);
      const response = await fetchMentorFastApiReport(
        `VisitCompliance/GetMentorWiseVisitSummaryByFilter?GetMonthYearFromDate=${`${
          selectedMonth
            ? calculateYearForDashboard(+selectedMonth, Number(selectedSession))
            : moment().format("YYYY")
        }-${selectedMonth}-01`}&DistrictId=${selectedDistrict ?? 0}&BlockId=${
          selectedBlock ?? 0
        }`
      );

      if (response.isSuccess) {

          // console.log("Response for report: ", response.data);
          setDateWiseDistrictComplianceReport(response.data);
          formatVisitComplianceByDistrictReport(response.data);

      } else {

          console.log("Error while fetching report: ", response.error);
          showToast({
              type: "error",
              title: "Error while fetching report",
              message: response.message || "Something went wrong!"
          });

      };
      setIsLoading(false);

  };

  return {
    isLoading,
    handleSelectMonth,
    dateWiseDistrictComplianceReport,
    dateWiseDistrictComplianceCSV,
    fetchDateWiseDistrictVisitCompliance,
    selectedBlock,
    selectedDistrict,
    districts,
    blocks,
    setSelectedBlock,
    setSelectedDistrict,
    selectedMonth,
    setSelectedMonth,
    handelClearFilter,
    selectedSession,
    setSelectedSession,
  };
}
