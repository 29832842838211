type Props = {
  title: string;
  description?: string;
  end?: JSX.Element;
};

export function EPageTitle({ title, description, end }: Props) {
  return (
    <div className="md:flex md:justify-between mb-6">
      <div className="flex-1 mb-4 md:mb-0">
        <div className="text-4xl font-extrabold tracking-tight text-blue-950">
          {title}
        </div>
        <p className="text-base text-slate-500 font-medium pt-1">{description}</p>
      </div>
      {end ? <div className="right">{end}</div> : null}
    </div>
  );
}
