import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsCheck2Square, BsUpload } from "react-icons/bs";

import PageLayout from "../../../PageLayout";
import {
  EButton,
  EDrawer,
  EEmpty,
  EModal,
  EPageTitle,
  ESectionDivider,
  ESelectFileForQuestionBank,
  ETable,
} from "../../../libs/ui";
import { DEPARTMENT_PATH } from "../../../Routes/routes";
import {
  Anchor,
  Badge,
  Card,
  Checkbox,
  Grid,
  Image,
  Modal,
  ScrollArea,
  Select,
  Skeleton,
  TextInput,
  Textarea,
  Tooltip,
} from "@mantine/core";
// import useQuestionBankIndex from "../Hooks/useQuestionBankIndex";
// import useQuestionBankFilter from "../Hooks/useQuestionBankFilter";
// import { useUploadQuestionForm } from "../Hooks/useUploadQuestionForm";
import { IQuestionFilters } from "../../../types";
import { FiDelete, FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import InfoLabel from "../../../libs/ui/src/InfoLabel/InfoLabel";
import { GrList } from "react-icons/gr";
import { TiTick } from "react-icons/ti";
import { TbReplace } from "react-icons/tb";
import { BASE64_INITIALS } from "../../../initialStates";
import { API_URL } from "../../../libs/data-access/constant";
import useQuestionBankFilter from "../../QuestionBank/Hooks/useQuestionBankFilter";
import useQuestionBankIndex from "../../QuestionBank/Hooks/useQuestionBankIndex";
import useSpotQuestionFilter from "./hook/useSpotQuestionFilter";
import { DatePicker } from "@mantine/dates";

const SpotAssessementQuestion = () => {
const navigate = useNavigate();



const {
  subjectList,
  classes,
  questionFilters,
  handleChangeQuestionFilterParams,

  isLoading,
  isBtnLoading,
  questionList,
  selectedQuestion,
  setSelectedQuestion,
  handleSearch,
  handleDeleteQuestionFromQuestionBank,
  isOpenDeleteModal,
  setIsOpenDeleteModal,



  setSelectedGroup,
  selectedGroup,
  allGroups,
} = useSpotQuestionFilter();
// const {
//   isLoading,
//   isBtnLoading,
//   isOpenDeleteModal,
//   setIsOpenDeleteModal,
//   isOpenEditModal,
//   setIsOpenEditModal,
//   questionList,
//   handleSearch,
//   handleEditQuestion,
//   selectedQuestion,
//   setSelectedQuestion,
//   handleDeleteQuestionFromQuestionBank,
//   enlargedText,
//   setEnlargedText,

//   setSelectedGroup,
//   selectedGroup,
// } = useSpotQuestionBank(questionFilters);

// const dummyGroup = [
//   { label: "group1", value: "1" },
//   { label: "group2", value: "2" },
//   { label: "group3", value: "3" },
// ];
console.log("selected",selectedQuestion)
  return (
    <PageLayout>
      <EPageTitle
        title="Questions"
        end={
          <EButton
            className="btn-light"
            rightIcon={<BsUpload />}
            onClick={() => navigate("/upload-spot-question-bank")}
          >
            Upload Questions
          </EButton>
        }
      />

      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between items-end gap-4 w-full">
          <div className="flex gap-5">
            <Select
              required
              label="Select Grade"
              placeholder="Select"
              size="md"
              data={classes?.map((each) => ({
                value: String(each.id),
                label: each.name,
              }))}
              value={String(questionFilters.selectedGrade) || null}
              onChange={(e) =>
                handleChangeQuestionFilterParams("selectedGrade", String(e))
              }
            />
            <Select
              required
              label="Subject"
              placeholder="Select"
              size="md"
              data={subjectList?.map((item) => ({
                value: String(item.id),
                label: item.name,
              }))}
              value={String(questionFilters.selectedSubject) || null}
              onChange={(e) =>
                handleChangeQuestionFilterParams("selectedSubject", String(e))
              }
            />
            <DatePicker
              label="Select Start Date"
              placeholder="MM/DD/YYYY"
              value={questionFilters?.WeekStartDate}
              onChange={(e: any) =>
                handleChangeQuestionFilterParams("WeekStartDate", e)
              }
              maxDate={
                questionFilters?.WeekEndDate
                  ? new Date(questionFilters.WeekEndDate)
                  : undefined
              }
            />
            <DatePicker
              label="Select End Date"
              placeholder="MM/DD/YYYY"
              value={questionFilters?.WeekEndDate}
              onChange={(e: any) =>
                handleChangeQuestionFilterParams("WeekEndDate", e)
              }
              minDate={
                questionFilters?.WeekStartDate
                  ? new Date(questionFilters.WeekStartDate)
                  : undefined
              }
            />
            <Select
              required
              // className="w-[33%]"
              label="Question Group"
              placeholder="Select"
              size="md"
              data={allGroups?.map((each: any) => ({
                value: String(each.question_Group_Instruction_Id),
                label: each.competency,
              }))}
              value={String(selectedGroup) || null}
              onChange={(e) => {
                setSelectedGroup(String(e));
              }}
            />
            {/* <Select
              label="Competency Level"
              placeholder="Select"
              clearable
              size="md"
              nothingFound="No Competency found in selected Subject"
              data={competencyByClassSubject?.map((each) => ({
                value: String(each.competancy_Id),
                label: each.competancy,
              }))}
              value={String(questionFilters.selectedCompetencyLevel) || null}
              onChange={(e) =>
                e
                  ? handleChangeQuestionFilterParams(
                      "selectedCompetencyLevel",
                      String(e)
                    )
                  : handleChangeQuestionFilterParams(
                      "selectedCompetencyLevel",
                      undefined
                    )
              }
            /> */}
          </div>
          <div className="flex gap-3">
            <EButton
              className="btn-blue"
              disabled={selectedGroup ? false : true}
              loading={isLoading}
              // disabled={true}
              onClick={handleSearch}
            >
              Search
            </EButton>
          </div>
        </div>
      </div>

      {/* TAble list */}
      <div className="mt-10">
        {isLoading ? (
          <Skeleton height={280} />
        ) : (
          <div>
            {!questionList?.length ? (
              // <></>
              <EEmpty title={"Select required filters and tap to search"} />
            ) : (
              <ETable>
                <thead>
                  <tr>
                    {/* <th>Question Image</th> */}
                    <th>Question</th>
                    <th>Question Type</th>
                    <th>Question Media Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {questionList?.map((item) =>
                    item?.questions?.map((e: any) => (
                      <tr>
                        {/* {e?.base64QuestionImage ? (
                          <td>
                            <Image
                              height={100}
                              width={110}
                              src={e?.base64QuestionImage}
                              fit="contain"
                            />
                          </td>
                        ) : (
                          <td>--</td>
                        )} */}

                        <td>{e?.question_Text}</td>
                        <td>{e?.question_Type}</td>
                        <td>{e?.question_Media_Type ?? "-"}</td>
                        <td>
                          <p
                            className="text-green-500 flex cursor-pointer"
                            onClick={() => {
                              setSelectedQuestion(e);
                            }}
                          >
                            View details...
                          </p>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </ETable>
            )}
          </div>
        )}
      </div>

      {/* SELECTED QUESTION INFORMATION */}
      <EDrawer
        opened={!!selectedQuestion}
        position="right"
        onClose={() => setSelectedQuestion(undefined)}
        title="Question Details"
        drawerContent={
          <ScrollArea className="h-full pb-10 pr-2 flex flex-col justify-between">
            <div>
              <Card className="bg-slate-50">
                <InfoLabel
                  label="Question"
                  value={selectedQuestion?.question_Text ?? "-"}
                />

                <InfoLabel
                  textSize="md"
                  label="Question Media Type"
                  value={selectedQuestion?.question_Media_Type ?? "-"}
                />
                <InfoLabel
                  label="Question type"
                  value={
                    selectedQuestion?.question_Type_Id == 1
                      ? "Single Answer"
                      : selectedQuestion?.question_Type_Id == 2
                      ? "Multiple Answer"
                      : "-"
                  }
                />
                <InfoLabel
                  label="Marks of question"
                  value={selectedQuestion?.question_Marks ?? "-"}
                />

                {selectedQuestion?.base64QuestionImage ? (
                  <Image
                    width={240}
                    height={240}
                    src={selectedQuestion?.base64QuestionImage}
                    fit="contain"
                  />
                ) : (
                  <></>
                )}
                {/* {
                                    selectedQuestion?.base64QuestionImage ?
                                        <Anchor
                                            // href={selectedQuestion?.base64QuestionImage}
                                            target="_blank"
                                            className='text-xs'
                                            onClick={() => window.open(selectedQuestion?.base64QuestionImage, "_blank")}
                                        >
                                            Tap to view question media
                                        </Anchor>
                                        : <></>
                                } */}
                {/* <InfoLabel label='Description' value={selectedQuestion?.question_Instruction ?? "-"} /> */}
              </Card>
              <Card className="bg-slate-50 mt-2">
                <div className="flex items-center gap-2 text-slate-600">
                  <GrList />
                  <p className="text-lg font-semibold ">Options List</p>
                </div>
                {
                  // selectedSchedule?.tests.filter((e) => e?.test_Type.toLowerCase() === "pre") &&
                  selectedQuestion?.questionsOptions?.map(
                    (each: any, index: number) => (
                      <Card className="bg-slate-100 p-3 my-2" withBorder>
                        <div className="flex justify-end items-center ">
                          {each?.is_Correct ? (
                            <Badge color="green">{"Correct Answer"}</Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                        {each?.option_Text ? (
                          <InfoLabel
                            textSize="sm"
                            label="Text"
                            value={each?.option_Text}
                          />
                        ) : (
                          <></>
                        )}
                        {each?.base64OptionImage ? (
                          <>
                            {/* <InfoLabel textSize="sm" label='Image' value={" "} /> */}
                            <Image
                              height={200}
                              width={200}
                              fit="contain"
                              src={each?.base64OptionImage}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                        {/* {
                                                each?.option_Media_Url && !each?.base64OptionImage ? <>
                                                    <Anchor
                                                        href={each?.option_Media_Url}
                                                        target="_blank"
                                                        className='text-xs'
                                                    >
                                                        Tap to view media
                                                    </Anchor>
                                                </>
                                                    : <></>
                                            } */}
                      </Card>
                    )
                  )
                }
              </Card>
            </div>

            <div className="flex justify-end mt-3 gap-5 px-2">
              <EButton
                size="md"
                className="btn-red"
                onClick={() => setIsOpenDeleteModal(true)}
              >
                <MdDelete /> <span className="pl-2">Delete</span>
              </EButton>
              {selectedQuestion?.options_Media_Type_Id == 3 ||
              selectedQuestion?.options_Media_Type_Id == 5 ? (
                <></>
              ) : (
                <EButton
                  className="btn-green"
                  onClick={() => {
                    navigate("/upload-spot-question-bank", {
                      state: {
                        filters: { ...questionFilters, group: selectedGroup },
                        questionDetail: selectedQuestion,
                      },
                    });
                    // setIsOpenEditModal(true);
                  }}
                >
                  <FiEdit /> <span className="pl-2">Edit Question</span>
                </EButton>
              )}
            </div>
          </ScrollArea>
        }
      />

      {/* Delete question modal */}
      <Modal
        opened={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        withCloseButton={false}
        title="Are you sure you want to delete ?"
      >
        <div className="flex gap-3 justify-end">
          <EButton
            className="btn-light"
            onClick={() => {
              setIsOpenDeleteModal(false);
            }}
          >
            Cancel
          </EButton>
          <EButton
            className="btn-red"
            loading={isBtnLoading}
            onClick={() => handleDeleteQuestionFromQuestionBank()}
          >
            Delete
          </EButton>
        </div>
      </Modal>

      {/* Edit Question Modal */}
      {/* <Modal
        opened={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        size="xl"
        withCloseButton={false}
      >
        <div className="flex flex-col gap-4 justify-between">
          
          <Grid>
            <Grid.Col span={6}>
              <InfoLabel
                label="Assessment"
                value={selectedQuestion?.assessment_Type}
              />
              <InfoLabel
                label="Media type"
                value={selectedQuestion?.media_Type}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <InfoLabel
                label="Question type"
                value={
                  selectedQuestion?.question_Type_Id == 1
                    ? "Single Answer"
                    : selectedQuestion?.question_Type_Id == 2
                    ? "Multiple Answer"
                    : "-"
                }
              />
              <InfoLabel
                label="Option media type"
                value={selectedQuestion?.option_Media_Type}
              />
            </Grid.Col>
          </Grid>
          
          <Grid className="border">
            <Grid.Col span={8}>
              <Textarea
                label="Question"
                value={selectedQuestion?.question_Text.split("\n")[0]}
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      question_Text: e?.target?.value.replaceAll("\n", " "),
                    });
                  }
                }}
              />
              <Textarea
                label="Enlarged Text"
                value={enlargedText}
                onChange={(e) => {
                  setEnlargedText(e?.target?.value.replaceAll("\n", " "));
                }}
              />
              {selectedQuestion?.media_Type == "Text/Image" ? (
                <>
                  <p className="text-xs text-slate-400 mt-3">Question Image</p>
                  <div className="flex justify-between items-center">
                    <Image
                      style={{ objectFit: "contain" }}
                      className=""
                      fit="contain"
                      height={240}
                      width={250}
                      src={selectedQuestion?.base64QuestionImage}
                      alt="ques.. ?"
                    />
                    <TbReplace size={32} />
                    <div className="bg-slate-100 w-[120px] h-[130px] p-10 items-center justify-center flex rounded">
                      <ESelectFileForQuestionBank
                        maxSizeInKB={2000000}
                        onClear={() => {}}
                        onSelect={(file) => {
                          if (selectedQuestion) {
                            setSelectedQuestion({
                              ...selectedQuestion,
                              base64QuestionImage: `${BASE64_INITIALS}${file}`,
                            });
                          }
                        }}
                        dimensionsToMatch={{ width: 500, height: 500 }}
                        onSelectPreview={(preview) => {}}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {selectedQuestion?.media_Type == "Text/Video" ? (
                <div>
                  <TextInput
                    label="Media URL"
                    value={selectedQuestion?.media_Url}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion({
                          ...selectedQuestion,
                          media_Url: e?.target?.value,
                        });
                      }
                    }}
                  />
                  <Anchor href={selectedQuestion?.media_Url} target="_blank">
                    <p className="text-xs mt-2">Open media link</p>
                  </Anchor>
                </div>
              ) : (
                <></>
              )}
              {selectedQuestion?.media_Type == "Text/Audio" ? (
                <div>
                  <TextInput
                    label="Media URL"
                    value={selectedQuestion?.media_Url}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion({
                          ...selectedQuestion,
                          media_Url: e?.target?.value,
                        });
                      }
                    }}
                  />
                  <Anchor href={selectedQuestion?.media_Url} target="_blank">
                    <p className="text-xs mt-2">Open Audio media link</p>
                  </Anchor>
                </div>
              ) : (
                <></>
              )}
            </Grid.Col>
            <Grid.Col span={4}>
              <TextInput
                label="No. of marks"
                maxLength={2}
                error={
                  selectedQuestion && isNaN(selectedQuestion?.marks)
                    ? "Value is not a number"
                    : ""
                }
                value={
                  selectedQuestion?.marks ? selectedQuestion?.marks : undefined
                }
                onChange={(e) => {
                  if (selectedQuestion) {
                    setSelectedQuestion({
                      ...selectedQuestion,
                      marks: +e?.target?.value,
                    });
                  }
                }}
              />
            </Grid.Col>
          </Grid>

          
          {selectedQuestion?.questionsOptions?.map((each:any, index:number) => (
            <Grid>
              <Grid.Col span={10}>
                <p className="text-sm text-slate-500">Option {index + 1}</p>
                {selectedQuestion?.option_Media_Type === "Text" ||
                selectedQuestion?.option_Media_Type == "Text/Image" ? (
                  <TextInput
                    value={each?.option_Text}
                    onChange={(e) => {
                      if (selectedQuestion) {
                        setSelectedQuestion((prevDetails: any) => {
                          const updatedOptions = [
                            ...prevDetails.questionsOptions,
                          ];
                          updatedOptions[index] = {
                            ...updatedOptions[index],
                            option_Text: e?.target?.value,
                          };
                          return {
                            ...prevDetails,
                            questionsOptions: updatedOptions,
                          };
                        });
                      }
                    }}
                  />
                ) : (
                  <></>
                )}
                {selectedQuestion?.option_Media_Type === "Image" ||
                selectedQuestion?.option_Media_Type == "Text/Image" ? (
                  <div className="flex items-center justify-around">
                    <Image
                      height={200}
                      width={200}
                      className="mt-2"
                      fit="contain"
                      src={each?.base64OptionImage}
                      alt="option"
                    />

                    <TbReplace size={32} />
                    <div className="bg-slate-100 w-[120px] h-[130px] p-10 items-center justify-center flex rounded">
                      <ESelectFileForQuestionBank
                        maxSizeInKB={2000000}
                        onClear={() => {}}
                        onSelect={(file) => {
                          if (selectedQuestion) {
                            setSelectedQuestion((prevDetails: any) => {
                              const updatedOptions = [
                                ...prevDetails.questionsOptions,
                              ];
                              updatedOptions[index] = {
                                ...updatedOptions[index],
                                base64OptionImage: `${BASE64_INITIALS}${file}`,
                              };
                              return {
                                ...prevDetails,
                                questionsOptions: updatedOptions,
                              };
                            });
                          }
                        }}
                        dimensionsToMatch={{ width: 500, height: 500 }}
                        onSelectPreview={(preview) => {}}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {each?.option_Media_Url && !each?.base64OptionImage ? (
                  <>
                    
                    <Anchor
                      href={each?.option_Media_Url}
                      target="_blank"
                      className="text-xs"
                    >
                      {" "}
                      Tap to view media
                    </Anchor>
                  </>
                ) : (
                  <></>
                )}
              </Grid.Col>
              <Grid.Col span={2} className="flex items-center mt-3">
                <Checkbox
                  checked={each?.is_Correct}
                  onChange={(e) => {
                    if (selectedQuestion) {
                      setSelectedQuestion((prevDetails: any) => {
                        const updatedOptions = [
                          ...prevDetails.questionsOptions,
                        ];
                        updatedOptions[index] = {
                          ...updatedOptions[index],
                          is_Correct: !updatedOptions[index]?.is_Correct,
                        };
                        return {
                          ...prevDetails,
                          questionsOptions: updatedOptions,
                        };
                      });
                    }
                  }}
                />
              </Grid.Col>
            </Grid>
          ))}

          
          <div className="border-t mt-5 pt-2 flex gap-3 justify-end">
            <EButton
              className="btn-light"
              onClick={() => {
                setIsOpenEditModal(false);
              }}
            >
              Cancel
            </EButton>
            <EButton
              className="btn-red"
              loading={isBtnLoading}
              onClick={() => {
                handleEditQuestion();
              }}
            >
              Update
            </EButton>
          </div>
        </div>
      </Modal> */}
    </PageLayout>
  );
};

export default SpotAssessementQuestion;
