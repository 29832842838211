import React, { useEffect, useState } from 'react'
import { ITeacherTrainingSlotTestList, ITrainingSlotList } from '../../../../libs/data-access/types';
import { useTeacherTraining } from '../../../../libs/data-access';
import { showToast } from '../../../../utils/utility';
import { useNavigate } from 'react-router-dom';
import { TransferListData, TransferListItem } from '@mantine/core';

const defaultTrainingSlotList: ITrainingSlotList = {
    training_Schedule_Header_Id: 0,
    schedule_Header_Title: '',
    start_Date: '',
    end_Date: '',
    no_Of_Days: 0,
    training_Description:"",
    tests: [],
};

export default function useEditTrainingSlot(slotParamId:string) {

    const {
        fetchTeacherTrainingData
      } = useTeacherTraining()
    const navigate = useNavigate()
    
    const [isFirst,setIsFirst]=useState<boolean>(true);
    const [isAnyChangeDone,setIsAndChangeDone]=useState<boolean>(false);
    const [isLoading,setIsLoading]=useState<boolean>(false);
    const [isLoadingTeacherList,setIsLoadingTeacherList]=useState<boolean>(false);
    const [isLoadingBtn,setIsLoadingBtn]=useState<boolean>(false);
    const [transferList,setTransferList] =useState<TransferListData>([[],[]])
    const [teacherTrainingSlot,setTeacherTrainingSlot]=useState<ITrainingSlotList >(defaultTrainingSlotList);
    const [teacherTrainingSlotTestList,setTeacherTrainingSlotTestList]=useState<ITeacherTrainingSlotTestList[]>([]);

    useEffect(()=>{
        if(isFirst){
        setIsFirst(false)
    }
    else{
     getTeacherTrainingSlotByID()
     getTeacherTrainingSlotTestList()
    }
    },[isFirst])

    useEffect(()=>{
        let selectedTestsArray :any = [] ;
        let allTestsArray :any = [] ;
        if(teacherTrainingSlot) {
            selectedTestsArray = teacherTrainingSlot?.tests?.map((e) => ({
                value: `${e?.schedule_Header_Test_Id}`,
                label: `${e?.test_Name}`,
                description: `${e?.subject_Name}`
              }))
            }
        if(teacherTrainingSlotTestList) {
            allTestsArray = 
            teacherTrainingSlotTestList.filter((e)=>( !teacherTrainingSlot?.tests.map(e=>e?.schedule_Header_Test_Id).includes(e?.teacher_Training_Test_Id))).
            map((e) => ({
                value: `${e?.teacher_Training_Test_Id}`,
                label: `${e?.test_Name}`,
                description: `${e?.subject_Name}`,
              }))
            }
     setTransferList([allTestsArray,selectedTestsArray])
    },[teacherTrainingSlot ,teacherTrainingSlotTestList])

    // Handler function

     const handleSubmit=()=>{
       let Payload :any ={
        ...teacherTrainingSlot,tests:transferList[1].map((e)=>({teacher_Training_Test_Id: +e?.value}))
       }

       console.log("Payload for Update SLOT" ,Payload)
     }
    // API function

    const getTeacherTrainingSlotByID = async () => {
        setIsLoading(true)
        const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetail?Is_Current_Header=true`)
        if (res?.isSuccess) {
            setTeacherTrainingSlot(res?.data[0])
            
        }
        else {
            setTeacherTrainingSlot(defaultTrainingSlotList)
          showToast({
            type: "error",
            message: "Error while getting schedule details"
        });
         navigate(`/state/training-slot`)
        }
        setIsLoading(false)
      }
     
    const getTeacherTrainingSlotTestList = async()=>{
        setIsLoadingTeacherList(true)
        const res = await fetchTeacherTrainingData(`/StateAdmin/GetTeacherTrainingHeaderTestList`)
        if(res?.isSuccess){
            setTeacherTrainingSlotTestList(res?.data)
        }
        else{
            showToast({
                type:"error",
                message: res?.message ?? "Could not fetch tests list"
            })
            setTeacherTrainingSlotTestList([])
        }
        setIsLoadingTeacherList(false)
    }

    return {
        isLoading,
        isLoadingTeacherList,
        isLoadingBtn,
        setIsLoadingBtn,
        transferList,
        setTransferList,
        teacherTrainingSlot,
        setTeacherTrainingSlot,
        isAnyChangeDone,
        setIsAndChangeDone,
        handleSubmit
  }
}
