import React, { useState, CSSProperties, Dispatch, SetStateAction, useRef } from 'react';

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';

interface IProps {
  handleCSVData: (CSVData: any) => void;
  setJSONdataFromCSV: Dispatch<SetStateAction<any[]>>;
};

// const GREY = '#CCC';
// const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
// const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
// const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
//   DEFAULT_REMOVE_HOVER_COLOR,
//   40
// );
// const GREY_DIM = '#686868';

// const styles = {
//   zone: {
//     alignItems: 'center',
//     border: `2px dashed ${GREY}`,
//     borderRadius: 20,
//     display: 'flex',
//     flexDirection: 'column',
//     height: '100%',
//     justifyContent: 'center',
//     padding: 40,
//     cursor: "pointer"
//   } as CSSProperties,
//   file: {
//     background: 'linear-gradient(to bottom, #EEE, #DDD)',
//     borderRadius: 20,
//     display: 'flex',
//     height: "20 rem",
//     width: "fit-content",
//     position: 'relative',
//     zIndex: 10,
//     flexDirection: 'column',
//     justifyContent: 'center',
//   } as CSSProperties,
//   info: {
//     alignItems: 'center',
//     display: 'flex',
//     flexDirection: 'column',
//     paddingLeft: 10,
//     paddingRight: 10,
//   } as CSSProperties,
//   size: {
//     backgroundColor: GREY_LIGHT,
//     borderRadius: 3,
//     marginBottom: '0.5em',
//     justifyContent: 'center',
//     display: 'flex',
//   } as CSSProperties,
//   name: {
//     backgroundColor: GREY_LIGHT,
//     borderRadius: 3,
//     fontSize: 12,
//     marginBottom: '0.5em',
//   } as CSSProperties,
//   progressBar: {
//     bottom: 14,
//     position: 'absolute',
//     width: '100%',
//     paddingLeft: 10,
//     paddingRight: 10,
//   } as CSSProperties,
//   zoneHover: {
//     borderColor: GREY_DIM,
//   } as CSSProperties,
//   default: {
//     borderColor: GREY,
//   } as CSSProperties,
//   remove: {
//     height: 23,
//     position: 'absolute',
//     right: 6,
//     top: 6,
//     width: 23,
//   } as CSSProperties,
// };


// export default function CSVReader({
//   handleCSVData,
//   setJSONdataFromCSV
// }: IProps) {

//   const { CSVReader } = useCSVReader();
//   const [zoneHover, setZoneHover] = useState<boolean>(false);
//   const [removeHoverColor, setRemoveHoverColor] = useState(
//     DEFAULT_REMOVE_HOVER_COLOR
//   );

//   return (
//     <CSVReader
//       onUploadAccepted={(results: { data: any[], errors: any[], meta: any[] }) => {
//         // console.log('---------------------------');
//         console.log(results.data);
//         // console.log('---------------------------');
//         handleCSVData(results.data);
//         setZoneHover(false);
//       }}
//       onDragOver={(event: DragEvent) => {
//         event.preventDefault();
//         setZoneHover(true);
//       }}
//       onDragLeave={(event: DragEvent) => {
//         event.preventDefault();
//         setZoneHover(false);
//       }}
//       noDrag
//     >
//       {({
//         getRootProps,
//         acceptedFile,
//         ProgressBar,
//         getRemoveFileProps,
//         Remove,
//       }: any) => (
//         <>
//           <div
//             {...getRootProps()}
//             style={Object.assign(
//               {},
//               styles.zone,
//               zoneHover && styles.zoneHover
//             )}
//           >
//             {acceptedFile ? (
//               <>
//                 <div style={styles.file}>
//                   <div style={styles.info}>
//                     <span style={styles.size}>
//                       {formatFileSize(acceptedFile.size)}
//                     </span>
//                     <span style={styles.name}>{acceptedFile.name}</span>
//                   </div>
//                   <div style={styles.progressBar}>
//                     <ProgressBar />
//                   </div>
//                   <div
//                     {...getRemoveFileProps()}
//                     style={styles.remove}
//                     onMouseOver={(event: Event) => {
//                       event.preventDefault();
//                       setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
//                     }}
//                     onMouseOut={(event: Event) => {
//                       event.preventDefault();
//                       setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
//                     }}
//                   // onClick={(e) => {e.preventDefault(); setJSONdataFromCSV([]); console.log("Remove CLicked")}}
//                   >
//                     <Remove color={removeHoverColor} />
//                   </div>
//                 </div>
//               </>
//             ) : (
//               "Select CSV File"
//             )}
//           </div>
//         </>
//       )}
//     </CSVReader>
//   );
// }




const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  } as CSSProperties,
  browseFile: {
    width: '20%',
  } as CSSProperties,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } as CSSProperties,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } as CSSProperties,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } as CSSProperties,
};

export default function CSVReader({
  handleCSVData,
  setJSONdataFromCSV
}: IProps) {
  const { CSVReader } = useCSVReader();
  const btnRef = useRef<HTMLButtonElement>(null);

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        // console.log('---------------------------');
        // console.log('---------------------------');
        setJSONdataFromCSV(results.data)
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }: any) => (
        <>
          <div className="flex rounded-md">
            <button
              type='button'
              className="w-[20%] rounded-l-md bg-blue-500 hover:text-blue:600 text-white"
              {...getRootProps()}
            >
              Browse file
            </button>
            <div style={styles.acceptedFile}>
              {acceptedFile ? acceptedFile.name : ""}
            </div>
            {acceptedFile
              ?
              <div
                className="w-[20%] cursor-pointer rounded-r-md bg-red-500 hover:text-red:600 text-white flex justify-center items-center"
                onClick={() => { handleCSVData([]); console.log("Removed"); btnRef.current?.click(); }}>
                Remove
              </div>
              :
              <></>}
          </div>

          <button
            ref={btnRef}
            className="hidden"
            {...getRemoveFileProps()}
          >
            Remove
          </button>
          {/* <button
                ref={btnRef}
                  className="w-[20%] rounded-r-md bg-red-500 hover:text-red:600 text-white"
                  {...getRemoveFileProps()}
                >
                  Remove
                </button> */}
          <ProgressBar
            className="my-2 rounded-sm bg-green-400"
          />
        </>
      )}
    </CSVReader>
  );
}