import React, { useEffect } from 'react';
import { FiVideoOff } from 'react-icons/fi';
import { Textarea, TextInput, Tooltip, Image, NumberInput } from '@mantine/core';
import { MdOutlineHideImage } from 'react-icons/md';
import { BsFillCameraVideoFill } from 'react-icons/bs';

import { ESelectFileForQuestionBank } from '../../../../libs/ui';

import { BASE64_INITIALS } from '../../../../initialStates';
import { IQuestionDetails, IQuestionFilters } from '../../../../types';
import { AiFillAudio, AiOutlineAudioMuted } from 'react-icons/ai';

interface IProps {
  questionFilters: IQuestionFilters;
  questionDetails: IQuestionDetails;
  handleChangeQuestionDetails: (
    key: keyof IQuestionDetails,
    value: string | File | undefined
  ) => void;
};

export default function AddQuestionFrame({
    questionFilters,
    questionDetails,
    handleChangeQuestionDetails
}: IProps)

 {
    return (
        <div>
            <div className="w-full justify-between flex ">
                <p className='text-xl font-bold'>Question</p>           
                </div>
            {/* <p className="text-sm opacity-50">
                Enter text, image or video question below
            </p> */}
            <p className="text-sm opacity-50">
                {questionFilters.media_Type_Id == 1
                    ?
                    "Question can only contain Text"
                    :
                    questionFilters.media_Type_Id == 4
                        ?
                        "Question needs to contain Text and Video"
                        :
                        questionFilters.media_Type_Id == 5
                            ?
                            "Question needs to contain Text and Image"
                            :
                        questionFilters.media_Type_Id == 6
                            ?
                            "Question needs to contain Text and Audio"
                            :
                            ""}
            </p>
             
           
            <div className="options-container">
           
                <div className="input-item mt-2">
                    <div className="action-icons">
                        {/* SELECT IMAGE */}
                        {questionFilters.media_Type_Id == 5 // INFO: if media type is Text/Image
                            ?
                            questionDetails.file
                                ?
                                <Tooltip
                                    withArrow
                                    color="red"
                                    label="Remove selected image"
                                >
                                    <a>
                                        <MdOutlineHideImage
                                            onClick={() => {
                                                handleChangeQuestionDetails("typeOfURL", undefined);
                                                handleChangeQuestionDetails("file", undefined);
                                                handleChangeQuestionDetails("url", undefined);
                                            }}
                                        />
                                    </a>
                                </Tooltip>
                                :
                                <Tooltip
                                    withArrow
                                    label="Select image"
                                >
                                    <a>
                                        <ESelectFileForQuestionBank
                                            maxSizeInKB={3000000}
                                            onClear={() => { }}
                                            onSelect={file => {
                                                handleChangeQuestionDetails("typeOfURL", "Image");
                                                handleChangeQuestionDetails("file", file);
                                            }}
                                            onSelectPreview={preview => handleChangeQuestionDetails("url", preview)}
                                        />
                                    </a>
                                </Tooltip>
                            :
                            <></>}

                        {/* SELECT VIDEO */}
                        {questionFilters.media_Type_Id == 4 // INFO: if media type is Text/Video
                            ?
                            questionDetails.typeOfURL === "Video"
                                ?
                                <Tooltip
                                    withArrow
                                    color="red"
                                    label="Remove Video URL"
                                >
                                    <a>
                                        <FiVideoOff
                                            onClick={() => {
                                                handleChangeQuestionDetails("typeOfURL", undefined);
                                                handleChangeQuestionDetails("file", undefined);
                                                handleChangeQuestionDetails("url", undefined);
                                            }}
                                        />
                                    </a>
                                </Tooltip>
                                :
                                <Tooltip
                                    withArrow
                                    label="Video URL"
                                >
                                    <a>
                                        <BsFillCameraVideoFill
                                            onClick={() => handleChangeQuestionDetails("typeOfURL", "Video")}
                                        />
                                    </a>
                                </Tooltip>
                            :
                            <></>}
                        {questionFilters.media_Type_Id == 6 // INFO: if media type is Text/ Audio
                            ?
                            questionDetails.typeOfURL === "Audio"
                                ?
                                <Tooltip
                                    withArrow
                                    color="red"
                                    label="Remove Audio URL"
                                >
                                    <a>
                                        <AiOutlineAudioMuted
                                            onClick={() => {
                                                handleChangeQuestionDetails("typeOfURL", undefined);
                                                handleChangeQuestionDetails("file", undefined);
                                                handleChangeQuestionDetails("url", undefined);
                                            }}
                                        />
                                    </a>
                                </Tooltip>
                                :
                                <Tooltip
                                    withArrow
                                    label="Audio URL"
                                >
                                    <a>
                                        <AiFillAudio
                                            onClick={() => handleChangeQuestionDetails("typeOfURL", "Audio")}
                                        />
                                    </a>
                                </Tooltip>
                            :
                            <></>}

                    </div>

                    <Textarea
                        required
                        size="lg"
                        variant="unstyled"
                        className="w-full"
                        placeholder="Enter question here"
                        value={questionDetails.text}
                        onChange={(e) =>{
                            handleChangeQuestionDetails("text", e.target.value.replaceAll("\n"," "))
                        }}
                    />
                </div>
                <div className="input-item mt-2">

                    <Textarea
                        required
                        size="lg"
                        variant="unstyled"
                        className="w-full"
                        placeholder="Enter Enlarged text of the question here"
                        value={questionDetails.enlargedText}
                        onChange={(e) =>
                            handleChangeQuestionDetails("enlargedText", e.target.value.replaceAll("\n"," "))
                        }
                    />
                </div>

                {questionDetails.typeOfURL === "Image"
                    &&
                    questionDetails.file
                    ?
                    <Image
                        radius="md"
                        height={256}
                        src={`${BASE64_INITIALS}${questionDetails.file}`}
                        alt="Option Image"
                        className="mt-2"
                    />
                    :
                    questionDetails.typeOfURL === "Video"
                        ?
                        <div className="input-item mt-2">
                            <TextInput
                                type="url"
                                size="lg"
                                variant="unstyled"
                                className="w-full mr-2"
                                placeholder="Enter YouTube embedded URL here"
                                value={questionDetails.url}
                                onChange={(e) => {
                                    handleChangeQuestionDetails("url", e.target.value);
                                }}
                            />
                        </div>
                    :
                    questionDetails.typeOfURL === "Audio"
                        ?
                        <div className="input-item mt-2">
                            <TextInput
                                type="url"
                                size="lg"
                                variant="unstyled"
                                className="w-full mr-2"
                                placeholder="Enter Audio URL here"
                                value={questionDetails.url}
                                onChange={(e) => {
                                    handleChangeQuestionDetails("url", e.target.value);
                                }}
                            />
                        </div>
                        :
                        <></>}
            </div>
            <TextInput
                    maxLength={2}
                    size='md'
                    className='w-1/2 pr-2 mt-3'
                    placeholder='Enter no. of marks for question'
                    value={questionDetails.mark}
                    error={isNaN(questionDetails?.mark) ? "Value is not a number *" :""}
                    onChange={(e) => {
                        handleChangeQuestionDetails("mark", e.target.value);
                    }}
                    /> 
        </div>
    )
}
