import { Badge, Button, Card, Grid } from '@mantine/core'
import React from 'react'
import InfoLabel from '../InfoLabel/InfoLabel'
import "./DetailsCard.scss"
type IProps ={
    isLoading: boolean;    
    isActive: boolean;
    cardId: number ;
    name: string;
    subject: string;
    type: string;
    handleClick :(e:number)=>void
  }

export default function DetailsCard(value: IProps) {
  return (
    <div className='news-listing-container'>
      <div className='news-list-item'> 
      <div className='overlay'>
        <Button 
         className='btn-light'
         disabled={value?.isLoading}
         loading={value?.isLoading}
         onClick={()=>value?.handleClick(value?.cardId)}
         >
          Change Status
        </Button>
        </div> 
      <Card className='image' withBorder >
                <div className='flex justify-end items-center'>
                  {
                    value?.isActive ?
                      <Badge color='green'>{"active"}</Badge>
                      :
                      <Badge color='blue'>{"upcoming"}</Badge>
                  }
                </div>
                <InfoLabel textSize="sm" label='Name' value={value?.name} />
                <Grid>
                  <Grid.Col span={6}>
                    <InfoLabel textSize="sm" label='Subject' value={value?.subject} />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <InfoLabel textSize="sm" label='Test type' value={value?.type} />
                  </Grid.Col>
                </Grid>
                {/* <InfoLabel textSize="sm" label='Test name' value={value?.subject_Name}/> */}
              </Card>
              </div>
              </div>

  )
}
