import React from 'react'
import useTargetIndex from './hooks/useTargetIndex'
import PageLayout from '../../PageLayout'
import { EButton, EDrawer, EEmpty, EInfoPanel, ELabelView, EPageTitle, ETable } from '../../libs/ui'
import { Badge, Card, Grid, ScrollArea, Select, Skeleton, TextInput } from '@mantine/core'
import moment from 'moment'
import { BsPlus } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { FiEdit } from 'react-icons/fi'
import InfoLabel from '../../libs/ui/src/InfoLabel/InfoLabel'
export default function TargetIndex() {

  const {
    isLoading,
    isLoadingBtn,
    isOpen,
    setIsOpen,
    allRole,
    selectedRole,
    setSelectedRole,
    designationByRoleId,
    selectedDesignation,
    setSelectedDesignation,
    targetList,
    selectedTarget,
    setSelectedTarget,
    defaultSelectedTarget,
    handleUpdateTarget,
    getTargetList
  } = useTargetIndex()

  const navigate = useNavigate()

  return (
    <PageLayout>
      <EPageTitle
        title='Targets'
        end={
          <EButton
            className="btn-blue"
            onClick={() => navigate('/new-target')}
          >
            <BsPlus size={22} /> New Target
          </EButton>}
      />
      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between items-end w-full">
          <div className="flex gap-5">
            {/* <Select
              label="Select month"
              placeholder="Select month"
              data={
                [
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" }
                ]
              }
              value={selectedMonth}
              onChange={e => setSelectedMonth(String(e))}
            /> */}
            <Select
              label="Role"
              placeholder='Select Role'
              value={selectedRole ? String(selectedRole) : null}
              data={
                allRole.map(e => ({ label: e?.name, value: String(e?.id) }))}
              onChange={(e) => {
                setSelectedRole(e ? +e : undefined)
                setSelectedDesignation(undefined)
              }}
            />

            {/* <Select
              label="Designation"
              placeholder='Select'
              clearable
              data={
                designationByRoleId?.map(e=>({
                  label: e?.name , 
                  value :String(e?.id)
                }))
              }
              nothingFound="No any designation for this role"
              value={selectedDesignation ? String(selectedDesignation) : null}
              onChange={(e)=> setSelectedDesignation(e ? +e : undefined)} 
               /> */}
          </div>
          <div className='flex gap-3'>
            {/* <EButton
                className="btn-light"
                disabled={!selectedRole}
                onClick={() => {
                  setSelectedRole(undefined)
                  setSelectedDesignation(undefined)
                 }}
              >Clear</EButton> */}
            <EButton
              className="btn-blue"
              loading={isLoading}
              disabled={!selectedRole}
              onClick={
                () => {
                  getTargetList()
                }}
            >Search</EButton>
          </div>
        </div>
      </div>
      <div className='mt-6'>
      {
        isLoading ?
          <Skeleton height={180} />
          :
          !selectedRole ?
            <><EEmpty title='Select role and search target' /> </> :
            <ETable>
              <thead>
                <tr>
                  <th>Role</th>
                  <th>Designation</th>
                  <th>Month</th>
                  <th>Unique School Visits</th>
                  <th>Classroom Observation</th>
                  <th>Target Spot Assessment</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  targetList.map(e =>
                    <tr>
                      <td>{e?.role_Name ?? "-"}</td>
                      <td>{e?.designation ?? "-"}</td>
                      <td>{e?.target_Month ? moment(e?.target_Month).format("MMM") + "-" + e?.target_Year : "-"}</td>
                      <td>{e?.target_Unique_School_Visit ?? "-"}</td>
                      <td>{e?.target_Classroom_Observation ?? "-"}</td>
                      <td>{e?.target_Spot_Assessment ?? "-"}</td>
                      <td>
                        <p
                          className='flex items-center text-green-500 gap-2 pr-2 cursor-pointer'
                          onClick={() => {
                            setIsOpen(true)
                            setSelectedTarget(e)
                          }}>
                          <FiEdit />Edit
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>

            </ETable>
      }
      </div>

      <EDrawer
        opened={isOpen}
        onClose={() => {
          setIsOpen(false)
          setSelectedTarget(defaultSelectedTarget)
        }}
        title='Edit Target '

        position="right"
        drawerContent={
          <div>
            {/* <Card className='bg-slate-50 p-4'>
              <div className='flex justify-end '>
                <Badge className='p-2'>
                  {selectedTarget?.month}
                </Badge>
              </div>
              <Grid>
                <Grid.Col span={6}>
                  <ELabelView label='Role' value={selectedTarget?.role} />
                </Grid.Col>
                <Grid.Col span={6}>
                  <ELabelView label='Designation' value={selectedTarget?.designation} />
                </Grid.Col>
              </Grid>
            </Card> */}
            <ScrollArea h={500} type="never">
              <div className='flex flex-col gap-5 mt-4 pb-20'>
                <Grid className='mb-3'>
                  <Grid.Col span={6} className="text-gray-700 font-medium">
                    <p>Month</p>
                    <p>Role</p>
                    <p>Designation</p>
                  </Grid.Col>
                  <Grid.Col span={6} className='text-slate-500 opacity-90'>
                    <p>{selectedTarget?.target_Month ? moment(selectedTarget?.target_Month).format("MMM") + "-" + selectedTarget?.target_Year : "-"}</p>
                    <p>{selectedTarget?.role_Name ?? "-"}</p>
                    <p>{selectedTarget?.designation ?? "-"}</p>
                  </Grid.Col>
                </Grid>
                <EInfoPanel
                  text={"text-xs text-slate-500 font-light"}
                  children={
                    <TextInput
                      label="Unique School visit"
                      placeholder="Enter no. of target "
                      size='sm'
                      type='number'
                      value={selectedTarget?.target_Unique_School_Visit || undefined}
                      onChange={((e) => {
                        setSelectedTarget({ ...selectedTarget, target_Unique_School_Visit: +e?.target?.value })
                      })
                      }
                    />
                  }
                  title={'The target is to achieve a specific number of unique school visits, where each school is counted only once, regardless of how many times it has been visited.'}
                />
                <EInfoPanel
                  text={"text-xs text-slate-500 font-light"}
                  children={
                    <TextInput
                      label="Classroom Observation"
                      placeholder="Enter no. of target "
                      size='sm'
                      type='number'
                      value={selectedTarget?.target_Classroom_Observation || undefined}
                      onChange={((e) => {
                        setSelectedTarget({ ...selectedTarget, target_Classroom_Observation: +e?.target?.value })
                      })
                      }
                    />
                  }
                  title={"Classroom observation involves a mentor observing a teacher's interaction with students and how they deliver instruction."}
                />
              </div>
            </ScrollArea>
            <div className='absolute bottom-0 p-2 bg-opacity-10 bg-black w-full right-0 flex justify-end gap-3'>
              <EButton
                className="btn-light"
                onClick={() => {
                  setIsOpen(false)
                  setSelectedTarget(defaultSelectedTarget)
                }}
              >
                Cancel
              </EButton>
              <EButton
                className="btn-green"
                loading={isLoadingBtn}
                disabled={selectedTarget?.target_Unique_School_Visit && selectedTarget?.target_Classroom_Observation ? false : true}
                onClick={() => handleUpdateTarget()}
              >
                Update
              </EButton>
            </div>
          </div>
        }
      />

    </PageLayout>
  )
}
