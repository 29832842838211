import axios from 'axios';
import React from 'react'
import { FastAPI } from '../../constant';
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useDashboardDate() {

const fetchDashboardData = async (endpoint: string) => {
    try {

        let response = await axios.get(`${FastAPI}home/${endpoint}`);
        // console.log("Response: ", response?.data);
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {

        // console.log("Error: ", error);
        const formattedError = handleFormatResponseForError(error);
        return formattedError;

    };
}; 

const fetchDashboardDataStaging = async (endpoint: string) => {
    try {

        let response = await axios.get(`${endpoint}`);
        // console.log("Response: ", response?.data);
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {

        // console.log("Error: ", error);
        const formattedError = handleFormatResponseForError(error);
        return formattedError;

    };
}; 

const saveDashboardFormData= async (endpoint: string,data:any) => {
    try {

        let response = await axios.post(`${endpoint}`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        // console.log("Response: ", response?.data);
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {

        // console.log("Error: ", error);
        const formattedError = handleFormatResponseForError(error);
        return formattedError;

    };
};

const deleteDashboardDataStaging = async (endpoint: string) => {
    try {

        let response = await axios.delete(`${endpoint}`);
        // console.log("Response: ", response?.data);
        const formattedResponse = handleFormatResponse(response);
        return formattedResponse;

    } catch (error: any) {

        // console.log("Error: ", error);
        const formattedError = handleFormatResponseForError(error);
        return formattedError;

    };
};


return{
    fetchDashboardData,
    fetchDashboardDataStaging,
    saveDashboardFormData,
    deleteDashboardDataStaging
}
}
