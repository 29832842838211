import React, { useContext, useEffect } from "react";
import MapData from "../../../../../libs/maps/MapData";
import { EButton, EInfoPanel, ESectionDivider, ETable, EVerticalFormSection } from "../../../../../libs/ui";
import useSpotAssessmentResult from "../Hooks/useSpotAssessmentResult";
import { Loader, Skeleton, Text, Accordion } from "@mantine/core";
import { CSVLink } from "react-csv";
import moment from "moment";
import { EPlaceholder } from "../../../../../libs/ui/src/Placeholder/EPlaceholder";
import { ECard } from "../../../../../libs/ui/src/Card/ECard";
import { BiDownload } from "react-icons/bi";
import ColorBaseMap from "../../../../../libs/maps/ColourBaseMap";
import { AssessmentFilterContext } from "../../Components/Hooks/useAssessmentFilter";
export default function SpotAssessmentResult() {
  const {
    isLoading,
    reportLevel,
    isLoadingDownloadReport,
    spotAssessmentResultData,
    spotAssessmentResultData2,
    spotAssessmentResultCSV,
    keysForTableHeader,
    keysForCompetency,
    keysForOrf,
    isORFDataLoading,
    ORFReportData,
    ORFResultResultCSV,
    grade,

    downloadCSVData,
    totalOfData,
  } = useSpotAssessmentResult();

  // console.log(spotAssessmentResultData,"and", spotAssessmentResultData2,"spotAssessmentResultData + spotAssessmentResultData2");
  // console.log("keysForCompetency", keysForCompetency);
  // console.log("keysForOrf", keysForOrf);

  // let objectKeyforTableBody = spotAssessmentResultData ? Object.keys(spotAssessmentResultData) : spotAssessmentResultData2 ?  Object?.keys(spotAssessmentResultData2) : [];
  // const overallAllCompetency =
  //   spotAssessmentResultData && Object.keys(spotAssessmentResultData).map(
  //     (each) =>
  //       Math.round(spotAssessmentResultData?.[each]?.filter((e:any) => e?.type !== "orf").
  //         reduce((sum:any, each:any) => {
  //           const value = each?.Mastery_Status_Percentage;
  //           if (!isNaN(value)) {
  //             return sum + value;
  //           }
  //           return sum;
  //         }, 0) / spotAssessmentResultData?.[each]?.filter((e:any) => e?.type !== "orf")?.length)
  //   );

  // let overallAllCompetencyOfAllDistrict = overallAllCompetency && Math.round(overallAllCompetency.reduce((all:any, eachSum:any) => {
  //   if (!isNaN(eachSum)) {
  //     return all + eachSum;
  //   }
  //   return all;
  // }, 0) / overallAllCompetency?.length);

  let overallAllCompetencyOfAllDistrict =
    spotAssessmentResultData &&
    Math.round(
      spotAssessmentResultData
        .map((item: any) => {
          return item?.OverAllAvg;
        })
        .reduce((all: any, eachSum: any) => {
          if (!isNaN(eachSum)) {
            return all + eachSum;
          }
          return all;
        }, 0) /
        spotAssessmentResultData.map((item: any) => {
          return item?.OverAllAvg;
        })?.length
    );

  const isOrf = spotAssessmentResultData?.some((item: any) =>
    item?.subjects?.some((subject: any) =>
      subject?.data?.some((insideChild: any) => insideChild?.type === "orf")
    )
  );

    // console.log("orf",isOrf)

  // console.log("spotAssessmentResultData", spotAssessmentResultData);
  // console.log("keysForCompetency", keysForCompetency);
  // console.log(
  //   "overallAllCompetencyOfAllDistrict",
  //   overallAllCompetencyOfAllDistrict
  // );
  return (
    <div className="flex flex-col gap-7">
      {/* Map  */}
      {isLoading ? (
        <Skeleton radius={"md"} height={450} />
      ) : spotAssessmentResultData ? (
        <div className="w- h-full flex justify-center">
          <div className="w-1/2">
            <ColorBaseMap
              mapData={
                // spotAssessmentResultData
                //   ? Object.keys(spotAssessmentResultData).map((each) => {
                //       const competencyCount = spotAssessmentResultData?.[
                //         each
                //       ]?.filter((e: any) => e?.type !== "orf")?.length;
                //       return {
                //         name:
                //           spotAssessmentResultData?.[each][0]?.District_Name ??
                //           spotAssessmentResultData?.[each][0]?.Block_Name ??
                //           reportLevel?.blockName,
                //         value:
                //           Math.round(
                //             spotAssessmentResultData?.[each]
                //               ?.filter((e: any) => e?.type !== "orf")
                //               .reduce((sum: any, e: any) => {
                //                 const value = e?.Mastery_Status_Percentage;
                //                 if (!isNaN(value)) {
                //                   return sum + value;
                //                 }
                //                 return sum;
                //               }, 0) / competencyCount
                //           ) || 0,
                //       };
                //     })
                //   : []
                spotAssessmentResultData
                  ? spotAssessmentResultData.map((item: any) => {
                      return {
                        name: item?.name,
                        value: item?.OverAllAvg,
                      };
                    })
                  : []
              }
              selectedDistrict={reportLevel?.districtName || ""}
              reportLevel={reportLevel}
              overAllStats={{
                label: "",
                value: overallAllCompetencyOfAllDistrict ?? 100,
                // value: 100,
              }}
              uniqueId={"teacherObservation"}
              hideChart
            />
          </div>
        </div>
      ) : (
        // <MapData
        //   title="District-level choropleth map"
        //   data={spotAssessmentMapData ? Object.keys(spotAssessmentMapData).map(
        //     (each) => ({
        //       district: spotAssessmentMapData?.[each][0]?.District_Name ?? "",
        //       average: spotAssessmentMapData?.[each][0]?.Mastery_Status_Percentage
        //     })) : []}
        //   isLoading={isMapLoading}
        //   setSelectedDistrict={() => { }}
        // />
        <EPlaceholder
          iconSrc={
            require("../../../../../assets/icons/icon-placeholder.svg").default
          }
          title="Create Report"
          description="Please select a month and other filters and press search to generate a report."
        />
      )}
      <div></div>
      {/* Table to displayed */}
      <div>
        {isLoading ? (
          <Skeleton radius={"md"} height={250} />
        ) : spotAssessmentResultData || spotAssessmentResultData2 ? (
          <ECard
            title="Filter-wise % students"
            customClass="overflow-auto"
            header={
              <CSVLink
                style={{}}
                className="text-blue-600 text-sm hover:underline"
                // data={[]}
                data={downloadCSVData ?? []}
                // headers={[]}
                // headers={spotAssessmentResultCSV?.headers ?? []}
                filename={`Spot_Assessment_Result_${moment().format(
                  "MMM"
                )}-${moment().format("YYYY")}.csv`}
              >
                <EButton className="btn-blue">
                  Download Report <BiDownload className="ml-2" size={22} />
                </EButton>
              </CSVLink>
            }
          >
            {/* <ESectionDivider title="Filter-wise % students" /> */}
            <ETable>
              {/* <thead>
                <tr>
                  <th style={{ minWidth: "160px" }}>
                    {spotAssessmentResultData[0]?.level_Name
                      ? spotAssessmentResultData[0]?.level_Name
                      : "District"}
                  </th>
                  <th style={{ minWidth: "160px" }}>OverAll Average</th>
                  <th style={{ minWidth: "160px" }}></th>
                </tr>
              </thead> */}

              <thead>
                <tr>
                  <th style={{ minWidth: "160px" }} rowSpan={2}>
                    District
                  </th>
                  <th style={{ minWidth: "160px" }} rowSpan={2}>
                    Overall Avg (%)
                  </th>
                  {spotAssessmentResultData[0]?.subjects?.map(
                    (subject: any, sIndex: any) => (
                      <th
                        style={{ minWidth: "260px" }}
                        // colSpan={subject?.data?.length * 3}
                        colSpan={subject?.data?.length}
                      >
                        {subject.subject}
                      </th>
                    )
                  )}
                </tr>
                <tr>
                  {spotAssessmentResultData[0]?.subjects?.map(
                    (subject: any, sIndex: any) =>
                      subject?.data?.map((child: any, dIndex: any) => (
                        // <th style={{ minWidth: "160px" }} colSpan={3}>
                        <th style={{ minWidth: "160px" }}>
                          {child?.type == "compentancy"
                            ? child?.Text
                            : `${child?.Text}(ORF)`}
                        </th>
                      ))
                  )}
                </tr>

                {/* <tr>
                  {spotAssessmentResultData[0]?.subjects?.map(
                    (subject: any, sIndex: any) =>
                      subject?.data?.map((child: any, dIndex: any) => (
                        <>
                          <th style={{ minWidth: "160px" }}>
                            Average Percentage
                          </th>
                          <th style={{ minWidth: "160px" }}>Tested</th>
                          <th style={{ minWidth: "160px" }}>Master Student</th>
                        </>
                      ))
                  )}
                </tr> */}

                {/* <tr>
                  {spotAssessmentResultData[0]?.subjects?.map(
                    (subject: any, sIndex: any) =>
                      subject?.data?.map((child: any, dIndex: any) => (
                        <th key={`${sIndex}-${dIndex}`}>Tested</th>
                      ))
                  )}
                </tr> */}
                {/* <tr>
                  {spotAssessmentResultData[0]?.subjects?.map(
                    (subject: any, sIndex: any) =>
                      subject?.data?.map((child: any, dIndex: any) => (
                        <th key={`${sIndex}-${dIndex}`}>Master Student</th>
                      ))
                  )}
                </tr> */}
              </thead>
              <tbody>
                {spotAssessmentResultData?.map(
                  (district: any, districtIndex: any) => (
                    <tr>
                      <td style={{ minWidth: "160px" }}>{district?.name}</td>
                      <td style={{ minWidth: "160px" }}>
                        {`${district?.OverAllAvg}%`}
                      </td>
                      {district?.subjects?.map((subject: any, sIndex: any) =>
                        subject?.data?.map((detail: any, dIndex: any) => (
                          <>
                            <td style={{ minWidth: "260px" }}>
                              {/* <Text>{detail.Month_Name}</Text> */}
                              {detail?.AvgPercentage != null
                                ? detail?.AvgPercentage
                                : "--"}
                            </td>
                            {/* <td style={{ minWidth: "160px" }}>
                              
                              {detail?.Tested ? detail?.Tested : "--"}
                            </td>
                            <td style={{ minWidth: "160px" }}>
                            
                              {detail?.Master_Student
                                ? detail?.Master_Student
                                : "--"}
                            </td> */}
                          </>
                        ))
                      )}
                    </tr>
                  )
                )}
                <tr>
                  <td style={{ minWidth: "160px" }}>{totalOfData?.name}</td>
                  <td style={{ minWidth: "160px" }}>
                    {`${totalOfData?.OverAllAvg}%`}
                  </td>
                  {/* {totalOfData?.subjects?.map((item:any)=>
                    item?.data?.map((child:any)=>(
                       <td>{child}</td>
                    ))
                    
                  )} */}
                </tr>
              </tbody>

              {/* <thead className="border">
                  <tr>
                    <th></th>
                    { spotAssessmentResultData && 
                    <th></th>}
                    {keysForCompetency?.subject.map((eachSubject, index) =>
                      <th colSpan={eachSubject?.count}><p className="text-center">{eachSubject?.label}</p></th>
                    )}
                    {keysForOrf?.subject.map((eachSubject, index) =>
                      <th colSpan={eachSubject?.count}><p className="text-center">{eachSubject?.label}</p></th>
                    )}
                  </tr>
                  <tr>
                    <th>{
                      keysForTableHeader ?
                        keysForTableHeader.includes("District_Name") ?
                          "District Name" :
                          keysForTableHeader.includes("Block_Name") ?
                            "Block Name" :
                            keysForTableHeader.includes("Cluster_Name") ?
                              "Cluster Name" :
                              keysForTableHeader.includes("School_Name") ?
                                "School Name" : "Name"
                        :
                        "Name"
                    }</th>
                   {
                    spotAssessmentResultData && 
                   <th>Overall Competency Avg.</th> }
                    {keysForCompetency?.header.map(eachCompetency => <th>{eachCompetency}</th>)}
                    {keysForOrf?.header.map(eachCompetency => <th>{eachCompetency}</th>)}
                  </tr>
                </thead>
                <tbody className="border">{ 
                 objectKeyforTableBody.map(
                    (eachDistrict) => (
                      <tr key={eachDistrict + "spot"}>
                        <td>
                          {
                            keysForTableHeader.includes("District_Name") ? spotAssessmentResultData ? spotAssessmentResultData?.[eachDistrict][0]?.District_Name  : spotAssessmentResultData2 ? spotAssessmentResultData2[eachDistrict][0]?.District_Name : "":
                              keysForTableHeader.includes("Block_Name") ? spotAssessmentResultData ? spotAssessmentResultData?.[eachDistrict][0]?.Block_Name  : spotAssessmentResultData2 ? spotAssessmentResultData2[eachDistrict][0]?.Block_Name : "":
                                keysForTableHeader.includes("Cluster_Name") ? spotAssessmentResultData ? spotAssessmentResultData?.[eachDistrict][0]?.Cluster_Name  : spotAssessmentResultData2 ? spotAssessmentResultData2[eachDistrict][0]?.Cluster_Name : "":
                                  keysForTableHeader.includes("School_Name") ? spotAssessmentResultData ? spotAssessmentResultData?.[eachDistrict][0]?.School_Name  : spotAssessmentResultData2 ? spotAssessmentResultData2[eachDistrict][0]?.School_Name :  "":
                                    "-"}
                        </td>
                        {
                        spotAssessmentResultData && 
                        <td>
                          {  
                           spotAssessmentResultData &&
                            Math.round(spotAssessmentResultData?.[eachDistrict]?.filter(e => e?.type !== "orf").reduce((sum, each) => {
                              const value = each?.Mastery_Status_Percentage;
                              if (!isNaN(value)) {
                                return sum + value;
                              }
                              return sum;
                            }, 0) / spotAssessmentResultData?.[eachDistrict]?.filter(e => e?.type !== "orf")?.length || 0)
                          }
                        </td>}
                        {keysForCompetency?.header.map((eachCompetency) =>
                          <td>
                            {
                              eachCompetency?.split("-")[0]?.trim() === "ORF" ?
                                // spotAssessmentResultData?.[eachDistrict].filter((e) =>
                                //   `ORF` == eachCompetency
                                // )[0]?.percentage
                                spotAssessmentResultData?.[eachDistrict]?.filter(e => e?.type === "orf" && `ORF - ${e?.Subject_Name}` === eachCompetency)[0]?.percentage ?? 0
                                :
                                spotAssessmentResultData?.[eachDistrict].filter((e) =>
                                  e?.Competency == eachCompetency
                                )[0]?.Mastery_Status_Percentage ?? "--"
                            }
                          </td>)
                        }
                        {keysForOrf?.header.map(each =>
                          <td>
                            {
                              each?.split("-")[0]?.trim() === "ORF" ?
                                // spotAssessmentResultData?.[eachDistrict].filter((e) =>
                                //   `ORF` == each
                                // )[0]?.percentage
                                spotAssessmentResultData2?.[eachDistrict]?.filter(e => e?.type === "orf" && `ORF - ${e?.Subject_Name}` === each)[0]?.percentage ?? 0
                                :
                                spotAssessmentResultData2?.[eachDistrict].filter((e) =>
                                  e?.Competency == each
                                )[0]?.Mastery_Status_Percentage ?? "--"
                            }
                          </td>)
                        }
                      </tr>
                    )
                  )}
                </tbody> */}
            </ETable>
          </ECard>
        ) : (
          <></>
        )}
        {/* { spotAssessmentResultData?.[eachDistrict].filter((e) =>  
        e?.Competency == eachCompetency
       )[0]?.type== "orf" ? 
      spotAssessmentResultData?.[eachDistrict].filter((e) =>
       e?.Competency == eachCompetency)[0]?.percentage 
       :  
       spotAssessmentResultData?.[eachDistrict].filter((e) =>
        e?.Competency == eachCompetency)[0]?.Mastery_Status_Percentage 
         } */}
      </div>

      {/* DATA for excel */}
      {/* <div className="mt-5">
        {isLoadingDownloadReport
          ?
          <Skeleton radius={"md"} height={250} />
          :
          spotAssessmentResultCSV?.content
            ?
            <EVerticalFormSection
              title=""
              rightPanel={<>

              </>}
            >
              <div>


                <EInfoPanel
                  title=" Your report is ready to download, Please click on Download File to download report"
                >
                  <CSVLink
                    style={
                      {

                      }
                    }
                    className="text-blue-600 text-sm hover:underline"
                    // data={[]}
                    data={spotAssessmentResultCSV?.content ?? []}
                    // headers={[]}
                    headers={spotAssessmentResultCSV?.headers ?? []}
                    filename={`Spot_Assessment_Result_${moment().format("MMM")}-${moment().format("YYYY")}.csv`}
                  >Download File</CSVLink>
                </EInfoPanel>
              </div>
            </EVerticalFormSection>
            :
            <></>}
      </div> */}

      {/* ORF REPORT */}
      {isORFDataLoading ? (
        <Skeleton radius="md" height={150} />
      ) : grade != 1 && isOrf == true ? (
        <EInfoPanel
          title="ORF Report"
          text="This report is generate for all grades and does not rely on the selected grade"
        >
          <CSVLink
            className="text-blue-600 text-sm hover:underline"
            data={ORFResultResultCSV?.content ?? []}
            headers={ORFResultResultCSV?.headers ?? []}
            filename={`ORF_Result_${moment().format("MMM")}-${moment().format(
              "YYYY"
            )}.csv`}
          >
            {ORFReportData.length ? (
              <EButton className="btn-blue">
                Download Report <BiDownload className="ml-2" size={22} />
              </EButton>
            ) : (
              <Text>
                Please select a month and press search to generate a report!
              </Text>
            )}
          </CSVLink>
        </EInfoPanel>
      ) : null}
    </div>
  );
};

