import React from "react";
import "./EPlaceholder.scss";

interface IProps {
  iconSrc?: string;
  title: string;
  description?: string;
}

export function EPlaceholder(props: any) {
  return (
    <div className="max-w-lg mx-auto flex flex-col items-center text-center rounded-xl p-6">
      <div className="icon mb-4 bg-amber-100 p-4 rounded-3xl">
        <img className="w-14 h-14" src={props?.iconSrc ? props.iconSrc : require("../../../../assets/icons/icon-placeholder.svg").default} alt="" />
      </div>
      <div className="text-group">
        <div className="title text-xl font-bold text-slate-900">
          {props.title}
        </div>
        <div className="description text-slate-500 mt-1">
          {props.description}
        </div>
      </div>
    </div>
  );
}
