import { IAllTeacherListBySchoolId, IGrievanceTicket, IPeriodicAssessmentSchedules } from "./libs/data-access/types";
import { INewTicket, IPayloadForSessionWorkingDays, IPeriodicScheduleEditPayload, IQuestionDetails } from "./types";

export const BASE64_INITIALS = "data:image/jpeg;base64,";

export const initialUser = {
    user_Id: 0,
    role_Id: 0,
    role_Name: "",
    grievance_For_Role_Id:0,
    details: {
        departmentUserDetails: null,
        districtUserDetails: null,
        schoolUserDetails: null,
        helpDeskUserDetails: null,
        blockUserDetails: null
    }
};

export const questionFiltersParams = {
    typeOfQuestionBank: undefined,
    assessmentSeq: undefined,
    questionType: undefined,
    // selectedMonth: undefined,
    week: undefined,
    marks: undefined,
    selectedGrade: undefined,
    selectedSubject: undefined,
    selectedTopic: undefined,
    selectedCompetencyLevel: undefined,
    media_Type_Id: undefined,
    option_Media_Type_Id: undefined
};

export const periodicScheduleParams: IPeriodicAssessmentSchedules = {
    periodic_Assessment_Schedule_Id: 0,
    periodic_Assessment_Id: 0,
    class_Id: 0,
    subject_Id: 0,
    class_Name: "",
    subject_Name: "",
    number_Of_Questions: 25,
    assessment: "",
    startDate: "",
    endDate: "",
    scheduleCompetancy: [],
    scheduleORFQuestion: []
};

export const editPeriodicScheduleParams: IPeriodicScheduleEditPayload = {
    periodic_Assessment_Id: 0,
    class_Id: 0,
    subject_Id: 0,
    number_Of_Questions: 0,
    start_Date: "",
    end_Date: "",
    modified_By: 0,
    scheduleCompetancy: [],
    orfQuestions: []
};

export const questionInfo: IQuestionDetails = {
    text: "",
    url: undefined,
    mark: 0,
    enlargedText: "",
    typeOfURL: undefined
}

export const defaultSelectedTeacher: IAllTeacherListBySchoolId = {
    school_Teacher_Id: 0,
    teacher_Id: 0,
    employee_Code: "",
    employee_Name: "",
    designation: "",
    allocation: []
}

export const DefaultNewTicket: INewTicket = {
    grievance_Category_Id: 0,
    user_Id: 0,
    date_Of_Issue: "",
    grievance_Query: "",
    contact_Number: ""
}

export const DefaultGrievanceTicket: IGrievanceTicket = {
    "ticket_Id": 0,
    "ticket_Number": "",
    "grievance_Category_Id": 0,
    "grievanceForRole": "",
    "category_Name": "",
    "grievance_Query": "",
    "grievance_Reply": "",
    "date_Of_Issue": "",
    "date_Of_Solved": "",
    "ticket_Status": ""
};

export const sessionWorkingDaysPayloadInitialValue: IPayloadForSessionWorkingDays = {
    Session_Id: 0,
    NumberOfWorkingDay: 0
};