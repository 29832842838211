import { BsInfoCircle } from 'react-icons/bs';
import './EEmpty.scss';

type Props = {
  title: string;
  description?: string;
  button?: JSX.Element;
};

export function EEmpty({ title, description, button }: Props) {
  return (
    <div className="empty">
      <h5 className="mb-1 flex items-center gap-2">
        <BsInfoCircle className="text-teal-600" />
        <span>{title}</span>
      </h5>
      <p className="opacity-60">{description}</p>
      {button ? <div className="mt-6">{button}</div> : null}
    </div>
  );
}
