import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { BsInfoCircle } from "react-icons/bs";
import { Alert, Select, Space } from "@mantine/core";

import { EButton, EVerticalFormSection } from "../../../libs/ui";

import { IQuestionFilters } from "../../../types";
import useQuestionBankFilter from "../Hooks/useQuestionBankFilter";

interface IProps {
  handleGetSelectedParameters: (params: IQuestionFilters) => void;
}

function QuestionBankFilter({
  questionFilters,
  setQuestionFilters,
  assessmentTypes,
  subjectList,
  classes,
  competencyList,
  competencyByClassSubject,
  questionType,
  spotAssessmentData,
  mediaTypeForQuestion,
  mediaTypeForOptions,
  handleChangeQuestionFilterParams,
  handleClearQuestionFilters,
}:any) {

  // const {
  //   assessmentTypes,
  //   subjectList,
  //   classes,
  //   competencyByClassSubject,
  //   questionType,
  //   spotAssessmentData,
  //   mediaTypeForQuestion,
  //   mediaTypeForOptions,

  //   questionFilters,
  //   handleChangeQuestionFilterParams,
  //   handleClearQuestionFilters
  // } = useQuestionBankFilter({
  //   handleGetSelectedParameters
  // });

  console.log("questionFilters",questionFilters)

  return (
    <div className="max-w-5xl ">
      <EVerticalFormSection
        title="Parameters / Filters"
        subtitle="Please select below filters to set questions to continue. These are required."
        // rightPanel={
        //   Object.values(questionFilters).filter((e) => e !== undefined)
        //     ?.length ? (
        //     <EButton
        //       className="bg-primary-50 text-primary-600 hover:bg-primary-100"
        //       leftIcon={<MdOutlineCancel />}
        //       onClick={handleClearQuestionFilters}
        //     >
        //       Clear Selection
        //     </EButton>
        //   ) : (
        //     <></>
        //   )  3339 || 2667
        // }
      >
        <div className="grid grid-cols-3 gap-3">
          <Select
            required
            // className="w-[33%]"
            label="Question Bank"
            placeholder="Select"
            size="md"
            data={assessmentTypes?.map((each:any) => ({
              value: String(each.id),
              label: each.name,
            }))}
            value={questionFilters.typeOfQuestionBank}
            onChange={(e) => {
              handleChangeQuestionFilterParams("typeOfQuestionBank", String(e));
              // console.log("question bank", questionFilters.typeOfQuestionBank);
            }}
          />
          <Select
            required
            label="Question Type"
            placeholder="Select"
            size="md"
            data={questionType?.map((each:any) => ({
              value: String(each.id),
              label: each.text,
            }))}
            // value={questionFilters?.questionType ? String(questionFilters?.questionType) : String(questionType[0]?.id)}
            value={String(questionFilters?.questionType)}
            onChange={(e) => {
              handleChangeQuestionFilterParams("questionType", String(e));
              // console.log("Select Question type", String(e));
            }}
          />
          {
            questionFilters.typeOfQuestionBank == 4 ? (
              <Select
                required
                searchable
                // className="w-[33%]"
                label="Week"
                placeholder="Select"
                size="md"
                data={spotAssessmentData?.map((each:any) => ({
                  value: String(each.id),
                  label: each.name,
                }))}
                value={String(questionFilters.week)}
                onChange={(e) => {
                  handleChangeQuestionFilterParams("week", String(e));
                  handleChangeQuestionFilterParams("assessmentSeq", undefined);
                }}
              />
            ) : (
              <></>
            )
          }
          <Select
            required
            label="Select Grade"
            placeholder="Select"
            size="md"
            data={classes?.map((each:any) => ({
              value: String(each.id),
              label: each.name,
            }))}
            value={String(questionFilters.selectedGrade)}
            onChange={(e) =>
              handleChangeQuestionFilterParams("selectedGrade", String(e))
            }
          />
          <Select
            required
            label="Subject"
            placeholder="Select"
            size="md"
            data={subjectList?.map((item:any) => ({
              value: String(item.id),
              label: item.name,
            }))}
            value={String(questionFilters.selectedSubject)}
            onChange={(e) =>
              handleChangeQuestionFilterParams("selectedSubject", String(e))
            }
          />
          <Select
            required
            label="Competency Level"
            placeholder="Select"
            size="md"
            data={competencyByClassSubject?.map((each:any) => ({
              value: String(each.competancy_Id),
              label: each.competancy,
            }))}
            value={String(questionFilters.selectedCompetencyLevel)}
            onChange={(e) =>
              handleChangeQuestionFilterParams(
                "selectedCompetencyLevel",
                String(e)
              )
            }
          />

          <Select
            required
            label="Media Type for Question"
            placeholder="Select"
            size="md"
            data={mediaTypeForQuestion?.map((each:any) => ({
              value: String(each.media_Type_Id),
              label: each.media_Type,
            }))}
            value={String(questionFilters.media_Type_Id)}
            onChange={(e) =>
              handleChangeQuestionFilterParams(
                "media_Type_Id",
                String(e)
              )
            }
          />
          <Select
            required
            label="Media Type for Options"
            placeholder="Select"
            size="md"
            data={mediaTypeForOptions?.map((each:any) => ({
              value: String(each.media_Type_Id),
              label: each.media_Type,
            }))}
            value={String(questionFilters.option_Media_Type_Id)}
            onChange={(e) =>
              handleChangeQuestionFilterParams(
                "option_Media_Type_Id",
                String(e)
              )
            }
          />
        </div>

        {!questionFilters.media_Type_Id || !questionFilters.option_Media_Type_Id
          ?
          <>
            <Space h={34} />
            <Alert
              icon={<BsInfoCircle />}
              title="Missing Media Type!"
              color="orange"
            >
              You need to select media type for question and options
            </Alert>
          </>
          :
          <></>}
      </EVerticalFormSection>
    </div>
  );
}

export default React.memo(QuestionBankFilter);
