import React from 'react'
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';
import axios from 'axios';
import { BASE_URL, FastAPI } from '../../constant';
import { INewSchedule } from '../../../../types';

export function useTeacherTraining() {
    

    const fetchTeacherTrainingData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}TeachersTraining${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };

    const fetchTeacherTrainingReportFast = async (endpoint: string) => {
        try {

            let response = await axios.get(`${FastAPI}Training/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };  
    const PostTeacherTrainingData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}TeachersTraining${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const PutTeacherTrainingData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}TeachersTraining${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const DeleteTeacherTrainingData = async(
        endpoint:string
    )=>{

        try {

            let response = await axios.delete(`${BASE_URL}TeachersTraining${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };
    
    const PostTeacherTrainingSlotTest = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}StateAdmin/Question/TeachersTraining/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };


    const PutTeacherTrainingSlotTest = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}StateAdmin/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };


    return {
        fetchTeacherTrainingData,
        fetchTeacherTrainingReportFast,
        PostTeacherTrainingData,
        PutTeacherTrainingData,
        DeleteTeacherTrainingData,
        PutTeacherTrainingSlotTest,
        PostTeacherTrainingSlotTest
    };
}
