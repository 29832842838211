import React, { useContext, useEffect, useState } from 'react'
import useMasterData from '../../Hooks/useMasterData';
import { calculateCurrentMonthForDashboard } from '../../../utils/utility';
import { IReportLevel } from '../../../libs/data-access/types';
import { AppContext } from '../../../AppContext';
import { ROLES } from '../../../Routes/routes';
import moment from 'moment';

export default function useDetailsKpiIndex() {

    const {
        getDistrictByStateId,
        districts,
        getBlocksByDistrictId,
        blocks,
        clusterSchools,
        getClusterByBlockId
    } = useMasterData();

    const {
        user,
        role
    } =useContext(AppContext)

    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(calculateCurrentMonthForDashboard());
    const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(undefined);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>(undefined);
    const [selectedCluster, setSelectedCluster] = useState<string | undefined>(undefined);
    const [reportLevel, setReportLevel] = useState< IReportLevel>({ levelNames: "state", districtName: "haryana", districtId:0 ,blockName:"" });

    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isLoadingSchoolVisit, setIsLoadingSchoolVisit] = useState<boolean>(false)
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const [isLoadingLearningIndicator, setIsLoadingLearningIndicator] = useState<boolean>(false)
    const [isLoadingHaryanaOverAllStats, setIsLoadingHaryanaOverAllStats] = useState<boolean>(false)

        const [selectedSession, setSelectedSession] = useState<
          string | undefined
        >(
          parseInt(moment().format("MM"), 10) > 4
            ? moment().add(1, "year").format("YYYY")
            : moment().format("YYYY")
        );

    useEffect(() => {
        if (isFirst) {
            setIsFirst(false)
        }
        else {
            getDistrictByStateId(1)
            if (role?.includes(ROLES.BLOCK)) {
                setSelectedDistrict(String(user?.details?.blockUserDetails?.district_Id))
                setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id))
            }
            else if (role?.includes(ROLES.DISTRICT)) {
                setSelectedDistrict(String(user?.details?.districtUserDetails?.district_Id))
            }
            else{
                handleSearch()
            }
        }}, [isFirst])

    useEffect(() => {
        if (selectedDistrict) {
            if (role?.includes(ROLES.DISTRICT)) {
                handleSearch()
            }
            getBlocksByDistrictId(+selectedDistrict)
        }
        else {
            
        }
    }, [selectedDistrict])

    useEffect(() => {
        if (selectedBlock) {
            if (role?.includes(ROLES.BLOCK)) {
                handleSearch()
            }
            getClusterByBlockId(+selectedBlock)
        }
    }, [selectedBlock])

    const handleSearch = () => {
        setIsSearching(true)
    }

    return {
      reportLevel,
      isLoadingSchoolVisit,
      setIsLoadingSchoolVisit,
      isSearching,
      setIsSearching,
      isLoadingLearningIndicator,
      districts,
      blocks,
      clusterSchools,
      selectedDistrict,
      setSelectedDistrict,
      selectedBlock,
      setSelectedBlock,
      selectedCluster,
      setSelectedCluster,
      selectedMonth,
      setSelectedMonth,
      isLoadingHaryanaOverAllStats,
      handleSearch,
      selectedSession,
      setSelectedSession,
    };
}
