interface IProps {
  label: string;
  value: string;
}

export function ELabelView({ label, value }: IProps) {
  return (
    <div>
      <div className="text-slate-400 font-medium text-sm my-2">{label}</div>
      <div className="text-slate-600 font-normal text-base">{value}</div>
    </div>
  );
}
