import { useState, useEffect, useContext } from "react";
import { IEditQuestionDetails, IQuestionFilters, ISelectedQuestionDetails, ISpotQuestionBankQuestionList, TQuestionFilters } from "../../../../types";
import { useDashboardDate, useStateAdmin } from "../../../../libs/data-access";
import useMasterData from "../../../Hooks/useMasterData";
import { IMediaType } from "../../../../libs/data-access/types";
import { questionFiltersParams } from "../../../../initialStates";
import { showToast } from "../../../../utils/utility";
import { AppContext } from "../../../../AppContext";
import { handleValidateEditQuestionParams } from "../../../../utils/validators";
import { BASE_URL } from "../../../../libs/data-access/constant";
import moment from "moment";

// import { showToast } from "../../../utils/utility";
// import useMasterData from "../../Hooks/useMasterData";
// import { useStateAdmin } from "../../../libs/data-access";
// import { IMediaType } from "../../../libs/data-access/types";
// import { questionFiltersParams } from "../../../initialStates";
// import { IQuestionFilters, TQuestionFilters } from "../../../types";

interface IProps {
    handleGetSelectedParameters: (params: IQuestionFilters) => void;
};

const useSpotQuestionFilter = () => {
    
   const MEDIA_TYPE_QUESTION = 0;
   const MEDIA_TYPE_OPTION = 1;

   const { fetchStateAdmin } = useStateAdmin();
   const {

     subjectList,
     getSubject,
     classes,
     getClasses,
     competencyList,

   } = useMasterData();

   const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  
   const [questionFilters, setQuestionFilters] = useState<any>({
     selectedGrade: "",
     selectedSubject: "",
     WeekStartDate: undefined,
     WeekEndDate: undefined,
   });

   

   console.log("question filter in useBankfilter", questionFilters);

   useEffect(() => {
     if (isFirstLoad) {
       setIsFirstLoad(false);
     } else {
       getSubject();
       getClasses();

     }
   }, [isFirstLoad]);



//    useEffect(() => {
//      if (questionType.length) {
//        const singleOptionID = questionType.find(
//          (each) => String(each.id).trim() === "2"
//        )?.id;
//        handleChangeQuestionFilterParams(
//          "questionType",
//          singleOptionID ? String(singleOptionID) : undefined
//        );
//      }
//    }, [questionType]);

   useEffect(() => {
     if (
       questionFilters.selectedSubject &&
       questionFilters?.selectedGrade &&
       questionFilters?.WeekStartDate &&
       questionFilters?.WeekEndDate
     ) {
       GetSpotQuestionGroup(
         questionFilters.selectedSubject,
         questionFilters?.selectedGrade,
         questionFilters?.WeekStartDate,
         questionFilters?.WeekEndDate
       );
     }
   }, [
     questionFilters.selectedSubject,
     questionFilters?.selectedGrade,
     questionFilters?.WeekStartDate,
     questionFilters?.WeekEndDate,
   ]);
  

  //  useEffect(() => {
  //    // console.log("Question FIlters: ", questionFilters);
  //    handleGetSelectedParameters(questionFilters);
  //  }, [questionFilters]);

   // for question filter management
   const handleChangeQuestionFilterParams = (
     key: any,
     value: string | undefined
   ) => {
     setQuestionFilters((prev: any) => ({ ...prev, [key]: value }));
   };



   /* API CALLS */
  //  const handleFetchMediaTypeForQuestion = async (mediaType: number) => {
  //    const response = await fetchStateAdmin(
  //      `Question/GetMediaTypeForQuestionOrOption/${mediaType}`
  //    );
  //    if (response.isSuccess) {
  //      // console.log("Response for media type: ", response.data);
  //      const data: IMediaType[] | undefined = response.data;
  //      if (data) {
  //        mediaType === 0
  //          ? setMediaTypeForQuestion(data)
  //          : setMediaTypeForOptions(data);
  //      }
  //    } else {
  //      showToast({
  //        type: "error",
  //        title: "Error Ocurred!",
  //        message: "Could not fetch media types",
  //      });
  //    }
  //  };


   ///////index//////////////

   const { deleteStateAdmin, putStateAdmin } = useStateAdmin();

   const { user } = useContext(AppContext);


   const [isLoading, setIsLoading] = useState<boolean>(false);
   const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
   const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
   const [selectedQuestion, setSelectedQuestion] = useState<any>(undefined);
   const [questionList, setQuestionList] = useState<
     ISpotQuestionBankQuestionList[]
   >([]);
  

   const [selectedGroup, setSelectedGroup] = useState("");
   const [allGroups,setAllGroups] = useState([])



   const handleSearch = () => {
     GetSpotQuestionsByQuestionGroupId();
   };

  //  const handleEditQuestion = () => {
  //    if (!user.user_Id) {
  //      showToast({
  //        type: "warning",
  //        title: "Please login again",
  //        message: "User could not be found",
  //      });
  //      return;
  //    }
  //    if (selectedQuestion) {
  //      let payload: IEditQuestionDetails = {
  //        question_Id: selectedQuestion?.question_Id,
  //        base64QuestionImage: selectedQuestion?.base64QuestionImage?.replace(
  //          "data:image/jpeg;base64,",
  //          ""
  //        ),
  //        question_Instruction: selectedQuestion?.question_Instruction,
  //        question_Text: `${selectedQuestion?.question_Text
  //          .split("\n")[0]
  //          .replace("/n", "")}${enlargedText ? `\n${enlargedText}` : ``}`,
  //        media_Type_Id: selectedQuestion?.questions_Media_Type_Id,
  //        option_Media_Type_Id: selectedQuestion?.options_Media_Type_Id,
  //        question_Media_Url: selectedQuestion?.media_Url,
  //        is_Active: true,
  //        marks: selectedQuestion?.marks,
  //        created_By: user.user_Id,
  //        questionOptions: selectedQuestion?.questionsOptions?.map((e) => ({
  //          question_Option_Id: e?.question_Option_Id,
  //          base64OptionImage: e?.base64OptionImage?.replace(
  //            "data:image/jpeg;base64,",
  //            ""
  //          ),
  //          option_Text: e?.option_Text,
  //          is_Correct: e?.is_Correct,
  //          option_Media_Url: "",
  //        })),
  //      };
  //      const isValidated = handleValidateEditQuestionParams(
  //        payload,
  //        selectedQuestion
  //      );
  //      if (isValidated) {
  //        console.log("update question payload", payload);
  //        updateQuestionDetails(payload);
  //      }
  //    }
  //  };

   const handleDeleteQuestionFromQuestionBank = () => {
     if (selectedQuestion) {
       deleteQuestionById();
     }
   };

   const GetSpotQuestionsByQuestionGroupId = async () => {
     setIsLoading(true);
     const res = await fetchStateAdmin(
       `Question/GetSpotAssessmentQuestionsByGroupId/${selectedGroup}`
     );
     if (res.isSuccess) {
       setQuestionList(res?.data);
     } else {
       setQuestionList([]);
       showToast({
         type: "error",
         message: res?.message || "Fail to get question list",
       });
     }
     setIsLoading(false);
   };


      const GetSpotQuestionGroup = async (subject:String,grade:String,weekStart:any,weekEnd:any) => {
        // let newWeekStart:any = new Date(weekStart);
        // newWeekStart.setDate(newWeekStart.getDate() + 1);




        //  let newWeekEnd: any = new Date(weekEnd);
        //  newWeekEnd.setDate(newWeekEnd.getDate() + 1);


        setIsLoading(true);
        const res = await fetchStateAdmin(
          `Question/GetSpotAssessmentQuestionGroups/${grade}/${subject}/${String(
            moment(weekStart).format("YYYY-MM-DD")
          )}/${String(moment(weekEnd).format("YYYY-MM-DD"))}`
        );
        if (res.isSuccess) {
          setAllGroups(res?.data);
        } else {
          setAllGroups([]);
          showToast({
            type: "error",
            message: res?.message || "Fail to get question group",
          });
        }
        setIsLoading(false);
      };

   const deleteQuestionById = async () => {
     setIsBtnLoading(true);
     const res = await deleteStateAdmin(
       `Question/DeleteSpotAssessmentQuestion/${selectedQuestion?.question_Id}`
     );
     if (res.isSuccess) {
       showToast({
         type: "success",
         message: "Question Deleted Successfully 🗑",
       });
       setIsOpenDeleteModal(false);
       setSelectedQuestion(undefined);
       GetSpotQuestionsByQuestionGroupId();
     } else {
       showToast({
         type: "error",
         title: "Try again to delete",
         message: res?.message || "Fail to delete Question from bank",
       });
     }
     setIsBtnLoading(false);
   };






   ////////index////////////

   return {
     subjectList,
     classes,
     competencyList,
  

    


     questionFilters,
     handleChangeQuestionFilterParams,


     isLoading,
     isBtnLoading,
     questionList,
     selectedQuestion,
     setSelectedQuestion,
     handleSearch,
     handleDeleteQuestionFromQuestionBank,
     isOpenDeleteModal,
     setIsOpenDeleteModal,



     setSelectedGroup,
     selectedGroup,
     allGroups
   };
};

export default useSpotQuestionFilter;
