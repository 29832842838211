import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from "../../utility";

export function usePeriodicAssessment() {

    const fetchPeriodicAssessment = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}PeriodicAssessment/PeriodicAssessment${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const postPeriodicAssessment = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}PeriodicAssessment/PeriodicAssessment${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const putPeriodicAssessment = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}PeriodicAssessment/PeriodicAssessment${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const deletePeriodicAssessment = async (endpoint: string) => {
        try {

            let response = await axios.delete(`${BASE_URL}PeriodicAssessment/PeriodicAssessment${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        fetchPeriodicAssessment,
        postPeriodicAssessment,
        putPeriodicAssessment,
        deletePeriodicAssessment
    };
}
