import { useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Anchor, Breadcrumbs, TextInput, Image, Avatar, Button, Modal } from "@mantine/core";
import { HiBars3BottomLeft, HiListBullet } from "react-icons/hi2";

import { AppContext } from "../../../../AppContext";
import { MdOutlineLogout } from "react-icons/md";
import { ROLES } from "../../../../Routes/routes";
import { EButton } from "../Button/EButton";

export function ETopbar() {
  const { 
    isSidebarCollapsed, 
    setIsSidebarCollapsed,
    role,
    user,
    handleLogout
  } = useContext(AppContext);
  const location = useLocation();
  const [logOutModalOpened, setLogOutModalOpened] = useState<boolean>(false);

  // const navigate = useNavigate();
  return (
    <div className="flex w-full items-center">
      <HiBars3BottomLeft
        size={24}
        className="cursor-pointer"
        onClick={() => setIsSidebarCollapsed((prev) => !prev)}
      />
      {/* {location.pathname == "/" ? ( */}
        <div className="flex w-full items-center">
          <div className="">
            {/* <Image
                    maw={50}
                    mx="auto"
                    radius="md"
                    src={require("../../../../assets/icons/NIPUN_Haryana logo_high_res.png")}
                    alt="NIPUN_logo"
                /> */}
          </div>

          <div className="flex flex-1 items-center flex-row gap-1 ml-6">
            <Image
              maw={36}
              mx="auto"
              src={require("../../../../assets/icons/Emblem_of_Haryana.svg.png")}
              alt="NIPUN_logo"
            />
            <div className="grow pl-2">
              <div className="m-0 text-xl font-extrabold text-slate-900">
                NIPUN Haryana Monitoring Dashboard
              </div>
              <p className="mt-0 text-sm text-slate-500">
                Haryana School Shiksha Pariyojna Parishad, Panchkula, Haryana
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            {/* <Avatar size={32}/> */}
            {/* {
              !isSidebarCollapsed ? <></> :
              <div className="border-r border-slate-200 pr-4">
              <div className="text-xs text-slate-500">You are logged in as,</div>
              <div className="text-base text-slate-700 font-semibold">
              {role?.includes(ROLES.DEPARTMENT)
                    ? ROLES.DEPARTMENT
                    : role?.includes(ROLES.SCHOOL)
                    ? user.details.schoolUserDetails?.school_Name
                      ? `School - ${user.details.schoolUserDetails.school_Name.trim()}`
                      : "School"
                    : role?.includes(ROLES.BLOCK)
                    ? user.details.blockUserDetails?.block_Name
                      ? `Block - ${user.details.blockUserDetails.block_Name.trim()}`
                      : "Block"
                    : role?.includes(ROLES.HELPDESK)
                    ? ROLES.HELPDESK
                    : "Administration"}
              </div>
            </div>} */}
                <div className="ml-3">
                  <Button 
                  size="sm" 
                  variant="outline"
                  onClick={()=>setLogOutModalOpened(true)}
                  >Logout</Button>
                {/* <MdOutlineLogout size={24}/> Logout */}
                </div>
          </div>
        </div>
      {/* ) : (
        <></>
      )} */}

      {/* <div className="breadcrumbs font-medium">
                <Breadcrumbs separator={<ChevronRightIcon className="w-3" />}>
                    {
                        breadCrumbData.map((path, index) => (
                            index !== 0 ? <Anchor
                                key={index}
                                onClick={() => index !== breadCrumbData.length - 1 && navigate(breadCrumbData.slice(0, index + 1).join('/').replace(" ", "-"))}
                            >
                                <span
                                    className={`capitalize ${index === breadCrumbData.length - 1 && "text-slate-400"}`}
                                >
                                    {path.replace("-", " ")}
                                </span>
                            </Anchor> : <></>
                        ))
                    }
                </Breadcrumbs>
            </div> */}

      {/* <div className="flex gap-6">
                <div className="icon-link">
                    <InboxIcon />
                </div>
                <div className="icon-link">
                    <BellIcon />
                </div>
            </div> */}
            
            <Modal
        opened={logOutModalOpened}
        onClose={() => setLogOutModalOpened(false)}
        title="Are you sure you want to logout?"
      >
        <div className="flex justify-end">
          <EButton
            size="sm"
            className="btn-light"
            onClick={() => {
              handleLogout();
              setLogOutModalOpened(false);
            }}
          >
            Yes, Logout
          </EButton>
        </div>
      </Modal>
    </div>
  );
}
