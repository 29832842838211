import React, { useState, useEffect } from 'react';
import { ECard } from '../../../../libs/ui/src/Card/ECard';
import { Table, Input, Skeleton } from '@mantine/core';
import { EButton, ETable } from '../../../../libs/ui';
import { CiSearch } from "react-icons/ci";
import useSchoolLevelMentoring from './hook/useSchoolLevelMentoring';
import { schoolLevelMentoring } from '../../../../types';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { BiDownload } from "react-icons/bi";



const SchoolLevelMentoring = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(10);

  // const elements: Element[] = [
  //   { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
  //   { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
  //   { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
  //   { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
  //   { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },

  //   { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
  //   { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
  //   { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
  //   { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
  //   { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },

  //   { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
  //   { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
  //   { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
  //   { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
  //   { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },
  //   // Add more elements as needed
  // ];

  

  const {
    scoolLevelMentoring,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    loader,
    totalRecords,
    recordsXL,
  } = useSchoolLevelMentoring();



  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  // const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  let startPage = Math.max(1, currentPage - Math.floor(itemsPerPage/2 / 2));
let endPage = Math.min(totalPages, startPage + itemsPerPage/2 - 1);

// If there are too few pages to fill itemsPerPage, adjust startPage and endPage
// if (endPage - startPage + 1 < itemsPerPage) {
//     startPage = Math.max(1, endPage - itemsPerPage + 1);
// }
// if (endPage - startPage + 1 < itemsPerPage/2) {
//   if (currentPage <= Math.ceil(itemsPerPage/2 / 2)) {
//       endPage = itemsPerPage/2;
//   } else if (currentPage > totalPages - Math.floor(itemsPerPage/2 / 2)) {
//       startPage = totalPages - itemsPerPage/2 + 1;
//   } else {
//       startPage = currentPage - Math.floor(itemsPerPage/2 / 2);
//       endPage = currentPage + Math.floor(itemsPerPage/2 / 2);
//   }
// }
  if (endPage - startPage + 1 < itemsPerPage / 2) {
    if (currentPage <= Math.ceil(itemsPerPage / 2 / 2)) {
      endPage = Math.min(itemsPerPage / 2, totalPages);
    } else if (currentPage > totalPages - Math.floor(itemsPerPage / 2 / 2)) {
      startPage = Math.max(1, totalPages - itemsPerPage / 2 + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2 / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(itemsPerPage / 2 / 2)
      );
    }
  }


  return (
    <div>
      <ECard
        title="Mentoring - School level Status"
        header={
          <div
            className={
              recordsXL?.length <= 0 ? "pointer-events-none opacity-50" : ""
            }
          >
            <CSVLink
              style={{}}
              className="text-blue-600 text-sm hover:underline"
              data={recordsXL}
              filename={`Mentoring_School_level_${moment().format(
                "MMM"
              )}-${moment().format("YYYY")}.csv`}
            >
              <EButton className="btn-blue">
                Download Report <BiDownload className="ml-2" size={22} />
              </EButton>
            </CSVLink>
          </div>
        }
      >
        <div className="grid gap-6">
          {loader ? (
            <Skeleton height={220} radius="md" />
          ) : (
            <>
              <Input
                className="w-1/4 ml-auto hidden"
                icon={<CiSearch />}
                placeholder="Search"
                value={searchQuery}
                onChange={(e: any) => setSearchQuery(e.target.value)}
              />

              <ETable>
                <thead>
                  <tr>
                    {/* {currentItems[0]?.districtName &&
                    currentItems[0]?.districtName?.length > 0 ? ( */}
                    <th>District</th>
                    {/* ) : (
                      <th>--</th>
                    )} */}
                    {/* {currentItems[0]?.blockName &&
                    currentItems[0]?.blockName?.length > 0 ? ( */}
                    <th>Block</th>
                    {/* ) : (
                      <th>--</th>
                    )} */}
                    {/* {currentItems[0]?.clusterName &&
                    currentItems[0]?.clusterName?.length > 0 ? ( */}
                    <th>Cluster</th>
                    {/* ) : (
                      <th>--</th>
                    )} */}
                    <th>School Code</th>
                    <th>School Name</th>
                    <th>Last Visit Date</th>
                    <th>Days Since Last Visit</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((element: any, index) => {console.log("element",element); return (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: `${
                          element?.daysSinceLastVisit > 89 ||
                          element?.lastVisit == null
                            ? "#ea9999"
                            : element?.daysSinceLastVisit > 29 &&
                              element?.daysSinceLastVisit <= 89
                            ? "#FFFF80"
                            : "#d9ead3"
                        }`,
                      }}
                    >
                      {element?.districtName?.length > 0 ? (
                        <td>{element?.districtName}</td>
                      ) : (
                        <td>--</td>
                      )}
                      {element?.blockName?.length > 0 ? (
                        <td>{element?.blockName}</td>
                      ) : (
                        <td>--</td>
                      )}
                      {element?.clusterName?.length > 0 ? (
                        <td>{element?.clusterName}</td>
                      ) : (
                        <td>--</td>
                      )}
                      <td>{element?.schoolCode}</td>
                      <td>{element?.schoolName}</td>
                      <td>
                        {element
                          ?.lastVisit!=null ? moment(element?.lastVisit)
                          .format("YYYY-MM-DD"):"--"}
                      </td>
                      <td>{element?.daysSinceLastVisit}</td>
                    </tr>
                  );})}
                </tbody>
              </ETable>

              <div className="text-end mt-2">
                {/* Pagination controls */}
                <EButton
                  className="btn-blue"
                  onClick={prevPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </EButton>

                {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

                {/* Render first page */}
                {startPage > 1 && (
                  <>
                    <EButton
                      onClick={() => setCurrentPage(0)}
                      className="btn-light ml-2"
                    >
                      1
                    </EButton>
                    {startPage > 2 && <span className="ml-2">...</span>}
                  </>
                )}

                {/* Render page numbers */}
                {Array.from(
                  { length: endPage - startPage + 1 },
                  (_, index) => startPage + index
                ).map((page) => (
                  <EButton
                    key={page}
                    onClick={() => setCurrentPage(page - 1)}
                    className={
                      currentPage === page - 1
                        ? "btn-blue ml-2"
                        : "btn-light ml-2"
                    }
                  >
                    {page}
                  </EButton>
                ))}

                {/* Render last page */}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <span className="ml-2">...</span>
                    )}
                    <EButton
                      onClick={() => setCurrentPage(totalPages - 1)}
                      className={
                        currentPage === totalPages - 1
                          ? "btn-blue ml-2"
                          : "btn-light ml-2"
                      }
                    >
                      {totalPages}
                    </EButton>
                  </>
                )}

                <EButton
                  className="btn-blue ml-2"
                  onClick={nextPage}
                  // disabled={currentItems.length < itemsPerPage || currentItems.length === 0}
                  disabled={
                    currentItems.length < itemsPerPage ||
                    currentPage === totalPages - 1
                  }
                >
                  Next
                </EButton>
              </div>
            </>
          )}
        </div>
      </ECard>
    </div>
  );
};

export default SchoolLevelMentoring;