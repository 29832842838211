import React from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EPageTitle, ETable } from '../../libs/ui'
import { FaPlus } from 'react-icons/fa'
import { BsPlus } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { SurveyFilterContext } from './SurveyFilterContext'
import SurveyFilter from './Component/SurveyFilter'
import useSurveyList from './hooks/useSurveyList'
import moment from 'moment'
import { FiEdit } from 'react-icons/fi'
import { CiViewTable } from 'react-icons/ci'
import { Badge, Skeleton } from '@mantine/core'
import { handleCheckDateRange, handleCheckEndDateRange, handleSortByOrderDate } from '../../utils/utility'

export default function SurveyList() {

  const navigate = useNavigate()
  const searchButton = true;

  const {
    isLoading,
    isSearching,
    setIsSearching,
    selectedDistrict,
    setSelectedDistrict,
    selectedRole,
    setSelectedRole,
    surveyList,
    classForStudent,
    setClassForStudent
  } = useSurveyList()

  return (
    <SurveyFilterContext.Provider
      value={{
        selectedDistrict,
        setSelectedDistrict,
        selectedRole,
        setSelectedRole,
        searchButton,
        isSearching,
        setIsSearching,
        classForStudent,
        setClassForStudent
      }}
    >
      <PageLayout>

        <EPageTitle title='Survey'
          end={
            <EButton
              className={"btn-blue"}
              onClick={() => navigate('/new-survey')}
            >
              <BsPlus size={22} /> New Survey
            </EButton>
          } />
        {/* Filter */}
        {/* <SurveyFilter /> */}
        {/* Table */}
        <div className='mt-7'>
          {
            isLoading ? <Skeleton height={300}>loading</Skeleton> :
              <ETable >
                <thead>
                  <tr>
                    <th>Survey Title</th>
                    <th>Description</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    handleSortByOrderDate(
                      {
                        appointments: surveyList,
                        orderByDateKey: "survey_Start_Date"
                      }).
                      map(each =>
                        <tr>
                          <td>{each?.survey_Title ?? "-"}</td>
                          <td>{each?.survey_Description ? each?.survey_Description : "-"}</td>
                          <td>{each?.survey_Start_Date ? moment(each?.survey_Start_Date).format("DD-MM-YYYY") : "--/--/----"}</td>
                          <td>{each?.survey_End_Date ? moment(each?.survey_End_Date).format("DD-MM-YYYY") : "--/--/----"}</td>
                          {
                          handleCheckDateRange(each?.survey_Start_Date) ?
                            <td>
                              {/* incoming */}
                                <Badge color='blue'>Upcoming</Badge>
                            </td>
                            :
                            handleCheckEndDateRange(each?.survey_End_Date) ?
                              <td>

                                {/* Expire */}
                                <Badge color='red'>Expired</Badge>
                              </td>
                              :
                              <td>
                                {/* ONgoing */}
                                  <Badge color='orange'>On going</Badge>
                              </td>
                          }
                          <td>
                            <div
                              className='flex mt-2 ml-1 cursor-pointer items-center gap-1'
                              onClick={() => navigate(`/survey-details/${each?.survey_Id}`)}
                            >
                              <CiViewTable size={14} />
                              <p className=' text-blue-400 text-sm'>
                              Details
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}
                </tbody>
              </ETable>}
        </div>
      </PageLayout>
    </SurveyFilterContext.Provider>
  )
}