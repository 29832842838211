import React from 'react'
import PageLayout from '../../../PageLayout';
import { EButton, EPageTitle, ESectionDivider, EVerticalFormSection } from '../../../libs/ui';
import { Checkbox, Select, Space, Tabs, TextInput, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import UseOrfQuestion from './UseOrfQuestion';

const OrfQuestion = () => {
  const {
    classes,
    subjectList,
    orfData,
    handleChangeORF,
    confirmSave,
    setConfirmSave,
    isSaving,
    SaveOrf,
    isLoading,
  } = UseOrfQuestion();
  return (
    <PageLayout>
      <EPageTitle title="Upload ORF Question" />

      <Tabs defaultValue="add_manually" keepMounted={false}>
        {/* ADD QUESTIONS MANUALLY */}
        <Tabs.Panel value="add_manually" pt="xs">
          <div className="">
            <div className="grid grid-cols-12 grid-rows-1 gap-3">
              <div className="col-span-full">
                <div className="max-w-5xl ">
                  <EVerticalFormSection
                    title="Parameters / Filters"
                    subtitle="Please select below filters to set questions to continue. These are required."
                  >
                    <div className="grid grid-cols-3 gap-3">
                      <Select
                        required
                        label="Select Grade"
                        placeholder="Select"
                        size="md"
                        data={classes?.map((each: any) => ({
                          value: String(each.id),
                          label: each.name,
                        }))}
                        value={String(orfData.class_Id) || null}
                        onChange={(e) => handleChangeORF("class_Id", String(e))}
                      />
                      <Select
                        required
                        label="Subject"
                        placeholder="Select"
                        size="md"
                        data={subjectList?.map((item: any) => ({
                          value: String(item.id),
                          label: item.name,
                        }))}
                        value={String(orfData.subject_Id) || null}
                        onChange={(e) =>
                          handleChangeORF("subject_Id", String(e))
                        }
                      />
                      <DatePicker
                        label="Select Start Date"
                        placeholder="MM/DD/YYYY"
                        value={orfData?.week_Start_Date}
                        onChange={(e: any) =>
                          handleChangeORF("week_Start_Date", e)
                        }
                        minDate={new Date()}
                        maxDate={
                          orfData?.week_End_Date
                            ? new Date(orfData.week_End_Date)
                            : undefined
                        }
                      />
                      <DatePicker
                        label="Select End Date"
                        placeholder="MM/DD/YYYY"
                        value={orfData?.week_End_Date}
                        onChange={(e: any) =>
                          handleChangeORF("week_End_Date", e)
                        }
                        minDate={
                          orfData?.week_Start_Date
                            ? new Date(orfData.week_Start_Date)
                            : new Date()
                        }
                      />
                    </div>
                  </EVerticalFormSection>
                </div>

                <Space h={24} />
                <ESectionDivider title={"ORF Question"} />
                <div className="grid auto-rows-max max-h-screen">
                  <Textarea
                    placeholder="Enter question"
                    size="md"
                    minRows={3}
                    value={orfData?.orF_Question_Text}
                    onChange={(e: any) =>
                      handleChangeORF("orF_Question_Text", e?.target?.value)
                    }
                  />
                  <div className="flex gap-10 mt-4">
                    <TextInput
                      size="md"
                      className="grow"
                      placeholder="Enter min word read per minutes"
                      maxLength={2}
                      error={
                        isNaN(+orfData?.min_Word_Read_Per_Minute)
                          ? "Value is not a number"
                          : ""
                      }
                      value={orfData?.min_Word_Read_Per_Minute}
                      onChange={(e: any) =>
                        handleChangeORF(
                          "min_Word_Read_Per_Minute",
                          e?.target?.value
                        )
                      }
                    />
                    <TextInput
                      size="md"
                      className="grow"
                      error={
                        isNaN(+orfData?.max_Seconds_To_Read)
                          ? "Value is not a number"
                          : ""
                      }
                      maxLength={3}
                      placeholder="Enter max seconds to read"
                      value={orfData?.max_Seconds_To_Read}
                      onChange={(e: any) =>
                        handleChangeORF("max_Seconds_To_Read", e?.target?.value)
                      }
                    />
                  </div>

                  <div className="mt-10 flex w-full justify-between">
                    <Checkbox
                      label={"Are you sure you want to save?"}
                      checked={confirmSave}
                      onClick={() => setConfirmSave(!confirmSave)}
                    />
                    <EButton
                      className="btn-blue"
                      loading={isLoading}
                      disabled={!confirmSave}
                      onClick={() => SaveOrf()}
                    >
                      Save
                    </EButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}

export default OrfQuestion