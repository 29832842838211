import React, { useContext, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Select, Skeleton } from '@mantine/core';

import { EButton, EVerticalFormSection, EInfoPanel, ETable, StatCard } from '../../../../../libs/ui';

import useClassroomObservation from '../Hooks/useClassroomObservation';
import { AppContext } from '../../../../../AppContext';
import { ROLES } from '../../../../../Routes/routes';

export default function UniqueSchoolVisit() {

    const {
        user,
        role
    } =useContext(AppContext)
    
    const {
      isLoading,
      isLoadingThreshHoldTable,
      isFirstLoad,
      setIsFirstLoad,
      selectedMonth,
      setSelectedMonth,
      districts,
      selectedDistrict,
      setSelectedDistrict,
      getDistrictByStateId,
      blocks,
      selectedBlock,
      setSelectedBlock,
      handelClearFilter,
      getBlocksByDistrictId,
      uniqueSchoolVisits,
      fetchThresholdForUniqueSchoolVisits,
      selectedSession,
      setSelectedSession,
    } = useClassroomObservation();

    return (
      <div>
        {/* FILTERS */}
        <div className="filter-container">
          <div className="flex justify-between items-end w-full">
            <div className="flex gap-5">
              <Select
                label="Select Session"
                placeholder="Select month"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              {/* <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              /> */}

              <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />

              <Select
                label="Select District"
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedDistrict(e);
                }}
              />
              <Select
                label="Select Block"
                placeholder="Select block"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => setSelectedBlock(e)}
              />
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-light"
                disabled={
                  !selectedDistrict ||
                  role?.includes(ROLES.BLOCK) ||
                  role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                onClick={handelClearFilter}
              >
                Clear
              </EButton>
              <EButton
                className="btn-blue"
                disabled={!selectedMonth}
                loading={isLoading}
                onClick={() => {
                  fetchThresholdForUniqueSchoolVisits();
                }}
              >
                Search
              </EButton>
            </div>
          </div>
        </div>

        {/* DATA */}
        <div className="mt-5">
          {isLoading ? (
            <Skeleton radius={"md"} height={200} />
          ) : uniqueSchoolVisits?.totalSchool ? (
            <EVerticalFormSection title="" rightPanel={<></>}>
              <div>
                <EInfoPanel title="">
                  <div className="flex gap-2 justify-between py-5">
                    <StatCard
                      stat={
                        uniqueSchoolVisits?.totalSchool
                          ? `${uniqueSchoolVisits?.totalSchool}`
                          : "-"
                      }
                      label={"Total School"}
                    />
                    <StatCard
                      stat={
                        uniqueSchoolVisits?.totalUniqSchool
                          ? `${uniqueSchoolVisits?.totalUniqSchool}`
                          : "-"
                      }
                      label={"Total Unique School Visit"}
                    />
                    <StatCard
                      stat={
                        uniqueSchoolVisits?.total_percentage
                          ? `${uniqueSchoolVisits?.total_percentage}`
                          : "-"
                      }
                      label={"Unique School Visit Percentage"}
                    />
                  </div>
                </EInfoPanel>
                <div className="mt-5">
                  {isLoadingThreshHoldTable ? (
                    <Skeleton radius={"md"} height={250} />
                  ) : (
                    <ETable>
                      <thead>
                        <tr>
                          <th>District / Block</th>
                          <th>Total School</th>
                          <th>Unique School Visit</th>
                          <th>Unique School Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueSchoolVisits?.report?.map((each, index) => (
                          <tr key={index + "visit_com"}>
                            <td>{each.Name.trim() || ""}</td>
                            <td>{each.Total_School ?? "-"}</td>
                            <td>{each.Unique_School_Visit ?? "-"}</td>
                            <td>{each.Percentage ?? "-"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </ETable>
                  )}
                </div>
              </div>
            </EVerticalFormSection>
          ) : (
            <EInfoPanel title="Please select a month, district and block and press search to generate report" />
          )}
        </div>
      </div>
    );
}
