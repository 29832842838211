import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import PageLayout from "../../PageLayout";
import { EButton, EModal, EPageTitle, ETable } from "../../libs/ui";
import {
  Alert,
  Button,
  Group,
  NumberInput,
  PasswordInput,
  Select,
  Skeleton,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import useMasterData from "../Hooks/useMasterData";
import { BsUpload } from "react-icons/bs";
import { FileInput } from "@mantine/core";
import { ECard } from "../../libs/ui/src/Card/ECard";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { API_URL, BASE_URL } from "../../libs/data-access/constant";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Notification } from "@mantine/core";
import { AppContext } from "../../AppContext";
import useUserManagement from "./hook/useUserManagement";
import { DatePicker } from "@mantine/dates";
import { ROLES } from "../../Routes/routes";
import moment from "moment";

const UserManagement = () => {
  const {
    addUserValue,
    handleChangeUserManagement,
    saveUserManagement,
    handleChangeUserManagementSearch,
    userManagementSearchValue,
    open,
    setOpen,
    allUserManagement,
    getUserManagementApi,
    deleteUserManagement,
    deleteId,
    openConfirm,
    deleteConfirmFunc,
    confirmAcceptFunc,
    confirmRejectFunc,

    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    totalRecords,
    loading,

    districts,
    blocks,
    clusterSchools,
    selectedDistrict,
    setSelectedDistrict,
    selectedBlock,
    setSelectedBlock,
    selectedCluster,
    setSelectedCluster,
    userManageRoles,
    userManageDesignation,
    setItemsPerPage,
    userManageAddDesignation,
    setPrefix,
    prefix,
    debouncedHandleChangeUserManagement,
    errorMsg,
    editUSer,
    onCloseClickModelFunc,
    updateUserManagementApi,
    isEdit,
    enableRemark,
    setEnableRemark,
    remarkValue,
    setRemarkValue,
    setAccessLevelDesignation,
    debouncedHandleChangeSearchQuery,
    parseDMY,
  } = useUserManagement();

  const { role, user } = useContext(AppContext);

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const maxPageNumbers = 5; // Adjust this to 5 or 10 based on your requirement

  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  // Ensure that the displayed pages are within the correct range
  if (endPage - startPage + 1 < maxPageNumbers) {
    if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
      endPage = Math.min(maxPageNumbers, totalPages);
    } else if (currentPage > totalPages - Math.floor(maxPageNumbers / 2)) {
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(maxPageNumbers / 2)
      );
    }
  }

  const customPrefixes: any = {
    "Humana field team": "hpp",
    "Principal DIET": "dietp",
    "Dy. DEO": "dydeo",
    "DIET Lecturer": "dietl",
  };

  return (
    <PageLayout>
      <EPageTitle title="User Management" />
      <div className="filter-container">
        {/* <h4>Filters</h4> */}
        <div className="flex justify-between w-full items-end">
          <div className="flex justify-between gap-3">
            <div className="flex gap-5">
              <Select
                label="Role"
                placeholder="Select Role"
                className=" w-1/5 grow"
                // disabled={ false}
                value={userManagementSearchValue?.role}
                data={userManageRoles?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                onChange={(e: any) => {
                  handleChangeUserManagementSearch({ name: "role", value: e });
                  handleChangeUserManagementSearch({
                    name: "designation",
                    value: "",
                  });
                }}
              />

              <Select
                label="Designation"
                placeholder="Select Designation"
                data={userManageDesignation?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={userManagementSearchValue?.designation}
                onChange={(e: any) =>
                  handleChangeUserManagementSearch({
                    name: "designation",
                    value: e,
                  })
                }
              />
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-blue"
                // disabled={!selectedMonth}
                // // disabled={true}
                // loading={isSearching}
                onClick={() => {
                  getUserManagementApi(true);
                  setCurrentPage(0);
                  setItemsPerPage(10);
                  setSearchQuery("");
                }}
              >
                Search
              </EButton>
            </div>
          </div>
          <div>
            <EButton
              className="btn-blue"
              // rightIcon={<BsUpload />}
              // disabled={!selectedMonth}
              // // disabled={true}
              // loading={isSearching}
              onClick={() => setOpen(true)}
            >
              Add User
            </EButton>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <ECard noHeader title="" customClass="overflow-auto">
          {/* {element?.length > 0 ? ( */}
          {!loading ? (
            <>
              {allUserManagement?.length > 0 || searchQuery?.length>0 ? (
                <div className="flex justify-end items-center">
                  <TextInput
                    size="md"
                    label=""
                    placeholder="Search User Id"
                    className="w-1/3 mb-4"
                    value={searchQuery}
                    onChange={(e: any) =>{
                      debouncedHandleChangeSearchQuery(e?.target?.value);
                      setSearchQuery(e?.target?.value);
                      // setCurrentPage(0);
                      // setItemsPerPage(10);
                    }}
                  />
                </div>
              ) : null}
              {allUserManagement?.length > 0 ? (
                <>
                  <div className="max-h-screen overflow-scroll">
                    <ETable>
                      <thead>
                        <tr>
                          <th>District</th>
                          <th>Block</th>
                          <th>Cluster School</th>
                          <th>User Id</th>
                          <th>Mentor Name</th>
                          <th>Date Of Birth</th>
                          <th style={{ minWidth: "9rem" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {element.map((item: any) => { */}
                        {allUserManagement.map((item: any) => {
                          return (
                            <tr>
                              <td>
                                {item?.district_Name?.length > 0
                                  ? item?.district_Name
                                  : "--"}
                              </td>
                              <td>
                                {item?.block_Name?.length > 0
                                  ? item?.block_Name
                                  : "--"}
                              </td>
                              <td>
                                {item?.cluster_School_Name?.length > 0
                                  ? item?.cluster_School_Name
                                  : "--"}
                              </td>
                              <td>
                                {item?.userId?.length > 0 ? item?.userId : "--"}
                              </td>
                              <td>
                                {item?.mentor_Name?.length > 0
                                  ? item?.mentor_Name
                                  : "--"}
                              </td>
                              <td>
                                {item?.date_Of_Birth?.length > 0
                                  ? parseDMY(item?.date_Of_Birth)
                                    ? moment(
                                        parseDMY(
                                          item?.date_Of_Birth
                                        ).toLocaleDateString()
                                      ).format("DD/MM/YYYY") // Convert Date to string
                                    : "--"
                                  : "--"}
                              </td>
                              <td style={{ minWidth: "9rem" }}>
                                <div className="flex items-center">
                                  <EButton
                                    className="mr-2 cursor-pointer btn-blue"
                                    onClick={() => editUSer(item)}
                                  >
                                    Edit
                                  </EButton>

                                  <EButton
                                    className="cursor-pointer btn-red"
                                    onClick={() =>
                                      deleteConfirmFunc(item?.mentor_Id)
                                    }
                                  >
                                    Deactivate
                                  </EButton>
                                  {/* <MdEdit
                                    onClick={() => editUSer(item)}
                                    className="mr-2 cursor-pointer"
                                  />

                                  <MdDelete
                                    className="m-auto cursor-pointer text-red-500"
                                    onClick={() => deleteConfirmFunc(item?.id)}
                                  /> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ETable>
                  </div>

                  <div className="flex justify-end mt-2">
                    {/* Pagination controls */}
                    <div className="flex items-center mr-2">
                      <span className="mr-2 ">
                        <Text fz="sm">Records Per Page</Text>
                      </span>
                      <div className="w-20">
                        <Select
                          size="sm"
                          label=""
                          placeholder=""
                          data={[
                            {
                              label: "10",
                              value: "10",
                            },
                            {
                              label: "20",
                              value: "20",
                            },
                            {
                              label: "30",
                              value: "30",
                            },
                            {
                              label: "40",
                              value: "40",
                            },
                            {
                              label: "50",
                              value: "50",
                            },
                            {
                              label: "100",
                              value: "100",
                            },
                          ]}
                          value={String(itemsPerPage)}
                          onChange={(e: any) => setItemsPerPage(+e)}
                        />
                      </div>
                    </div>
                    <EButton
                      className="btn-blue"
                      onClick={prevPage}
                      disabled={currentPage === 0}
                    >
                      Previous
                    </EButton>

                    {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

                    {/* Render first page */}
                    {startPage > 1 && (
                      <>
                        <EButton
                          onClick={() => setCurrentPage(0)}
                          className="btn-light ml-2"
                        >
                          1
                        </EButton>
                        {startPage > 2 && <span className="ml-2">...</span>}
                      </>
                    )}

                    {/* Render page numbers */}
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => startPage + index
                    ).map((page) => (
                      <EButton
                        key={page}
                        onClick={() => setCurrentPage(page - 1)}
                        className={
                          currentPage === page - 1
                            ? "btn-blue ml-2"
                            : "btn-light ml-2"
                        }
                      >
                        {page}
                      </EButton>
                    ))}

                    {/* Render last page */}
                    {endPage < totalPages && (
                      <>
                        {endPage < totalPages - 1 && (
                          <span className="ml-2">...</span>
                        )}
                        <EButton
                          onClick={() => setCurrentPage(totalPages - 1)}
                          className={
                            currentPage === totalPages - 1
                              ? "btn-blue ml-2"
                              : "btn-light ml-2"
                          }
                        >
                          {totalPages}
                        </EButton>
                      </>
                    )}

                    <EButton
                      className="btn-blue ml-2"
                      onClick={nextPage}
                      // disabled={allUserManagement.length < itemsPerPage || allUserManagement.length === 0}
                      disabled={
                        currentPage >= totalPages - 1 || // Disable if on the last page
                        (allUserManagement?.length < itemsPerPage &&
                          currentPage === totalPages - 1) // Disable if fewer items on the last page
                      }
                    >
                      Next
                    </EButton>
                  </div>
                </>
              ) : allUserManagement?.length == 0 && searchQuery?.length==0 ? null : (
                <div className="text-blue-600 text-sm mt-2 mb-2 text-center">
                  <Text fz="md">No record Found!</Text>
                </div>
              )}
            </>
          ) : (
            <Skeleton height={220} radius="md" />
          )}
        </ECard>
      </div>

      <EModal
        size="lg"
        title={isEdit == true ? "Edit User" : "Add User"}
        isOpen={open}
        closeOnClickOutside={false}
        onClose={() => onCloseClickModelFunc()}
        setIsOpen={() => onCloseClickModelFunc()}
      >
        {isEdit == true ? null : (
          <div className="flex justify-between w-full gap-3 mb-4">
            <Select
              size="md"
              label="Role"
              required
              placeholder="Select"
              className=" w-1/2 "
              name="role"
              // disabled={ false}
              value={addUserValue?.role}
              data={userManageRoles?.map((each: any) => {
                return {
                  label: each.name,
                  value: String(each.id),
                };
              })}
              onChange={(e: any) => {
                handleChangeUserManagement({ name: "role", value: e });
                handleChangeUserManagement({ name: "designation", value: "" });
              }}
            />

            <Select
              size="md"
              required
              className=" w-1/2 "
              label="Designation"
              placeholder="Select Designation"
              data={userManageAddDesignation?.map((each: any) => {
                return {
                  label: each.designation,
                  value: String(each.designation_Id),
                };
              })}
              name="designation"
              value={addUserValue.designation}
              onChange={(e: any) => {
                handleChangeUserManagement({ name: "designation", value: e });
                const designationPrefix = userManageAddDesignation
                  .filter((item: any) => item?.designation_Id == e)
                  .map((item: any) => {
                    const designation = item?.designation;
                    if (customPrefixes[designation]) {
                      return customPrefixes[designation];
                    }
                    return designation.split(" ")[0].toLowerCase();
                  });
                // .map((item: any) =>
                //   item.designation.split(" ")[0].toLowerCase()
                // );
                setPrefix(`${designationPrefix}_`);

                const designationAccess = userManageAddDesignation
                  .filter((item: any) => item?.designation_Id == e)
                  ?.map((item: any) => item.access_Level)[0];
                setAccessLevelDesignation(designationAccess);
              }}
            />
          </div>
        )}
        <div className="flex justify-between w-full gap-3 mb-4">
          <TextInput
            size="md"
            label="Name"
            placeholder="Enter Name"
            className=" w-1/2 "
            value={addUserValue.name}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "name",
                value: e.target.value,
              })
            }
          />

          <NumberInput
            size="md"
            label="Mobile No."
            placeholder="Enter Mobile No."
            className=" w-1/2 "
            hideControls
            value={addUserValue.mobile}
            parser={(value) => {
              // Remove any non-numeric characters and limit the input to 11 digits
              const parsedValue = value?.replace(/\D/g, "");
              return parsedValue?.slice(0, 11);
            }}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "mobile",
                value: e,
              })
            }
          />
        </div>
        <div className="flex justify-between w-full gap-3">
          {isEdit == true ? null : (
            <Group spacing={0} className=" w-1/2 ">
              <TextInput
                value={prefix}
                readOnly
                label="prefix"
                size="md"
                // className="text-gray-500"
                classNames={{
                  input: "text-gray-500", // Add a class to the input element
                  root: "text-gray-500", // Add a class to the root element
                  label: "text-gray-500", // Add a class to the label element
                }}
                styles={{
                  input: {
                    textAlign: "center", // Center the icon text
                    cursor: "default",
                    borderRight: "none",
                    padding: 0,
                    width: "100%",
                  },
                  root: {
                    width: "30%", // Set the width of the Username TextInput to 70%
                  },
                }}
              />
              <TextInput
                size="md"
                label="Username"
                placeholder="Enter Username"
                value={addUserValue.Username}
                onChange={(e: any) => {
                  handleChangeUserManagement({
                    name: "Username",
                    value: e.target.value,
                  });
                  debouncedHandleChangeUserManagement(
                    "Username",
                    prefix + e.target.value
                  );
                }}
                styles={{
                  root: {
                    width: "70%", // Set the width of the Username TextInput to 70%
                  },
                }}
              />
            </Group>
          )}
          <DatePicker
            size="md"
            label="Date Of Birth"
            placeholder="Select Date"
            inputFormat="DD/MM/YYYY"
            // labelFormat="DD/MM/YYYY"
            className=" w-1/2 "
            value={addUserValue?.dateOfBirth}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "dateOfBirth",
                value: e,
              })
            }
            maxDate={new Date()}
          />
        </div>
        <div className="mb-4">
          {isEdit == true ? null : (
            <Text className="text-danger">{errorMsg}</Text>
          )}
        </div>
        <div className="flex justify-between w-full gap-3 mb-4">
          <PasswordInput
            size="md"
            label="Password"
            placeholder="Enter Password"
            className=" w-1/2 "
            value={addUserValue.password}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "password",
                value: e.target.value,
              })
            }
          />

          <TextInput
            size="md"
            label="E-Mail Id"
            placeholder="Enter E-Mail Id"
            className=" w-1/2 "
            value={addUserValue.emailId}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "emailId",
                value: e.target.value,
              })
            }
          />
        </div>
        {isEdit == true ? null : (
          <div className="flex justify-between w-full gap-3 mb-4">
            {userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "State" ? null : (
              <Select
                label="Select District"
                placeholder="Select district"
                className=" w-1/2"
                size="md"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedCluster(undefined);
                  setSelectedDistrict(e);
                }}
              />
            )}

            {userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "State" ||
            userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "District" ? null : (
              <Select
                label="Select Block"
                placeholder="Select block"
                className=" w-1/2 "
                size="md"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => {
                  setSelectedCluster(undefined);
                  setSelectedBlock(e);
                }}
              />
            )}
          </div>
        )}
        {isEdit == true ? null : (
          <div className="flex justify-between w-full gap-3 mb-4">
            {userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "State" ||
            userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "District" ||
            userManageAddDesignation?.find(
              (item: any) => item?.designation_Id == addUserValue.designation
            )?.access_Level == "Block" ? null : (
              <Select
                label="Select Cluster"
                placeholder="Select cluster"
                className=" w-full"
                size="md"
                clearable
                searchable
                nothingFound="No options"
                disabled={!selectedBlock}
                data={clusterSchools?.map((each) => {
                  return {
                    label: `${each.udise_Code} (${each.cluster_School_Name})`,
                    value: String(each.cluster_School_Id),
                  };
                })}
                value={selectedCluster ?? null}
                onChange={(e: any) => setSelectedCluster(e)}
              />
            )}
          </div>
        )}
        <div className="flex justify-between w-full gap-3 ">
          <Textarea
            size="md"
            className="w-full"
            placeholder="Enter Address"
            label="Address"
            minRows={2}
            value={addUserValue.address}
            onChange={(e: any) =>
              handleChangeUserManagement({
                name: "address",
                value: e.target.value,
              })
            }
          />
        </div>

        <div className="flex justify-end w-full mt-6">
          <EButton
            className="btn-blue"
            onClick={() =>
              isEdit == true ? updateUserManagementApi() : saveUserManagement()
            }
          >
            Submit
          </EButton>
        </div>
      </EModal>

      {openConfirm ? (
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
          style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 50 }}
        >
          <Alert
            className="w-1/3 text-lg"
            withCloseButton
            closeButtonLabel="Close alert"
            onClose={confirmRejectFunc}
            icon={<IoIosInformationCircleOutline size={16} />}
            title={enableRemark == true ? "Remark" : "Confirmation"}
            color="red"
          >
            <>
              {enableRemark == true ? (
                <div>
                  <Textarea
                    size="md"
                    className="w-full"
                    placeholder="Enter Remark"
                    label=""
                    minRows={2}
                    value={remarkValue}
                    onChange={(e: any) => setRemarkValue(e.target.value)}
                  />
                </div>
              ) : (
                <Text>Are sure you want to Deactivate this User?</Text>
              )}
            </>
            {enableRemark == true ? (
              <div className="flex justify-end mt-5">
                <EButton className="btn-blue" onClick={confirmAcceptFunc}>
                  Deactivate
                </EButton>
              </div>
            ) : (
              <Group className="flex justify-end mt-5">
                <EButton className="btn-light" onClick={confirmRejectFunc}>
                  No
                </EButton>
                <EButton
                  className="btn-blue"
                  onClick={() => setEnableRemark(true)}
                >
                  Yes
                </EButton>
              </Group>
            )}
          </Alert>
        </div>
      ) : null}
    </PageLayout>
  );
};

export default UserManagement;
