import React, { useContext, useEffect, useState } from 'react'
import { useTeacherTraining } from '../../../../../../libs/data-access';
import { AppContext } from '../../../../../../AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TransferListData, TransferListItem } from '@mantine/core';
import { IAllocatedTeacher, INewSchedule } from '../../../../../../types';
import { IAllocatedTeacherList, IPutScheduleDetails, IScheduleList, ISelectedTeacherTrainingScheduleDetails, ITeacherAllocationPayload, ITeacherTrainingScheduleDetails, ITrainingSlotList } from '../../../../../../libs/data-access/types';
import { showToast } from '../../../../../../utils/utility';
import { handleValidEditSchedule, handleValidNewSchedule } from '../../../../../../utils/validators';

const DefaultNewSchedule = {
  training_Schedule_Header_Id: 0,
  block_Admin_User_Id: 0,
  block_Id: 0,
  training_Title: "",
  training_Start_Date: "",
  training_End_Date: "",
  training_Place: "",
  training_Start_Time: "",
  training_End_Time: "",
  training_Description: "",
  teacherTest: [
    {
      schedule_Header_Test_Id: 0
    }
  ]
}

const DefaultScheduleById: ISelectedTeacherTrainingScheduleDetails = {
  teacher_Training_Schedule_Id: 0,
  training_Title: "",
  training_Start_Date: "",
  training_End_Date: "",
  training_Start_Time: "",
  training_End_Time: "",
  is_Training_End: false,
  // is_Attendance_Done: false,
  block_Name: "",
  training_Description: "",
  training_Place: "",
  tests: []
};

export default function useEditSchedule() {
  const paramId = useParams()

  const {
    fetchTeacherTrainingData,
    PostTeacherTrainingData,
    PutTeacherTrainingData,
    DeleteTeacherTrainingData
  } = useTeacherTraining()

  const {
    user
  } = useContext(AppContext);

  const navigate = useNavigate()


  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAllocatedTeacher, setIsLoadingAllocatedTeacher] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isChangingActiveStatus, setIsChangingActiveStatus] = useState<boolean>(false);

  const [teacherTransferList, setTeacherTransferList] = useState<TransferListData>([[], []]);
  const [allTeacherList, setAllTeacherList] = useState<TransferListItem[]>([]);
  const [allocatedTeacherList, setAllocatedTeacherList] = useState<TransferListItem[]>([])
  const [allAllocatedTeachers, setAllAllocatedTeachers] = useState<IAllocatedTeacher[]>([])

  const [newSchedule, setNewSchedule] = useState<INewSchedule>(DefaultNewSchedule)
  const [selectedSlotTest, setSelectedSlotTest] = useState<string[]>([])
  const [trainingSlot, setTrainingSlot] = useState<ITrainingSlotList>()
  const [scheduleByID, setScheduleById] = useState<ISelectedTeacherTrainingScheduleDetails>(DefaultScheduleById)


  useEffect(() => {
    if (isFirst) {
      setIsFirst(false)
    }
    else {
      getScheduleById()
      getTrainingSlot()
      getAllocatedTeacherList()
    }
  }, [isFirst])

  // useEffect(() => {
  //   let testList = selectedSlotTest?.map((each) => (
  //     trainingSlot?.tests.filter((e) => e?.subject_Name === each).map((item) => (item))
  //   )).flat()
  //   // setScheduleById(
  //   //   { ...scheduleByID, tests: testList })
  // }, [selectedSlotTest])

  useEffect(() => {
    setTeacherTransferList([allTeacherList, allocatedTeacherList])
  }, [allocatedTeacherList, allTeacherList])

  const handelSubmit = () => {
    if (selectedSlotTest?.length) {
      putScheduleDetails()
    }
    else {
      showToast({
        type: "warning",
        message: "Please provide at least one test"
      })
    }
  }

  const handelSubmitTeacher = () => {
    const teacherToUpload = teacherTransferList[1].filter((e) => !allocatedTeacherList.map(item => item?.value).includes(e?.value))
    teacherToUpload?.length && postTeacherAllocateToSchedule(teacherToUpload);

    const teacherToDelete = allocatedTeacherList.filter((e) => !teacherTransferList[1].map((item) => item?.value).includes(e?.value))
    teacherToDelete?.length && teacherToDelete?.forEach(e =>{
      const TeacherScheduleID = allAllocatedTeachers?.find(each=>each?.teacher_Id == +e?.value)?.training_Schedule_Teacher_Id 
      TeacherScheduleID && deleteAllocatedTeacherFromSchedule(TeacherScheduleID)})

  }

  const handleUpdateActiveStatus = (test: any) => {
    UpdateTestActiveStatus(test?.teacher_Training_Schedule_Test_Id, test?.is_Active)
  }
  //  API function

  const getTrainingSlot = async () => {
    setIsLoading(true)
    setTrainingSlot(undefined)
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTrainingScheduleHeaderDetailByScheduleHeaderId/${paramId?.slotId}`)
    if (res?.isSuccess) {
      setTrainingSlot(res?.data[0])
      setNewSchedule({ ...newSchedule, training_Schedule_Header_Id: res?.data[0]?.training_Schedule_Header_Id })
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting slot"
      });
    }
    setIsLoading(false)
  };

  const getScheduleById = async () => {
    setIsLoading(true)
    setScheduleById(DefaultScheduleById)
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTeacherTrainingScheduleByScheduleId/${paramId?.scheduleId}/${user?.user_Id}`);
    if (res?.isSuccess) {
      setScheduleById(res?.data[0])
      setSelectedSlotTest(Array.from(new Set(res?.data[0]?.tests.map(((e: any) => e?.subject_Name)))))
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule"
      });
      navigate(`/block/schedule-list`)
    }
    setIsLoading(false)
  };

  const getAllocatedTeacherList = async () => {
    setIsLoading(true)
    setAllocatedTeacherList([])
    setAllAllocatedTeachers([])
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetTeachersSelectedForTeacherTraining/${user?.user_Id}/${paramId?.scheduleId}`)
    if (res?.isSuccess) {
      let allocatedTeacherArray = res?.data.map((e: any) => ({
        value: `${e?.teacher_Id}`,
        label: `${e?.employee_Name} - ${e?.employee_Code}`,
        description: `${e?.designation}`
      }))
      setAllocatedTeacherList(allocatedTeacherArray)
      setAllAllocatedTeachers(res?.data)
      getAllTeacherListByBlock(allocatedTeacherArray)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting schedule"
      });
    }
    setIsLoading(false)
  };

  const getAllTeacherListByBlock = async (allocatedTeacherArray: TransferListItem[]) => {
    setIsLoadingAllocatedTeacher(true)
    setAllTeacherList([])
    const res = await fetchTeacherTrainingData(`/BlockAdmin/GetBlockTeacherListForTraining/${user?.details?.blockUserDetails?.block_Id}`)
    if (res?.isSuccess) {
      const teachersArray: TransferListItem[] = res?.data.map((e: any) => ({
        value: `${e?.teacher_Id}`,
        label: `${e?.employee_Name} - ${e?.employee_Code}`,
        description: `${e?.designation}`
      }))

      const finalList = teachersArray?.filter((e) => (!allocatedTeacherArray.map(item => item?.value).includes(e?.value)))
      setAllTeacherList(finalList)
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while getting all teacher list"
      });
    }
    setIsLoadingAllocatedTeacher(false)
  };

  const postTeacherAllocateToSchedule = async (teacherToUpload: TransferListItem[]) => {

    let Payload: ITeacherAllocationPayload = {
      teacher_Training_Schedule_Id: + scheduleByID?.teacher_Training_Schedule_Id,
      block_Admin_User_Id: user?.user_Id,
      teacherSelection:
        teacherToUpload.map((each) => ({
          teacher_Id: +each?.value
        }))
    }
    const res = await PostTeacherTrainingData(`/BlockAdmin/AddTeachersForScheduledTraining`, Payload)
    if (res?.isSuccess) {
      showToast({
        type: "success",
        message: "Schedule created Successfully"
      });
      getScheduleById()
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while posting schedule"
      });
    }
    setIsBtnLoading(false)
  };

  const deleteAllocatedTeacherFromSchedule = async (teachersId: number) => {
    const res = await DeleteTeacherTrainingData(`/BlockAdmin/DeleteTeacherFromScheduledTraining/${teachersId}/${user?.user_Id}`)
    if (res.isSuccess) {
      showToast({
        type: 'success',
        message: "Successfully Teacher List updated"
      })
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while removing allocated teacher"
      })
    }
  };

  const putScheduleDetails = async () => {
    
    const Payload: IPutScheduleDetails = {
      teacher_Training_Schedule_Id: +scheduleByID?.teacher_Training_Schedule_Id,
      block_Admin_User_Id: user?.user_Id,
      training_Title: scheduleByID?.training_Title,
      training_Place: scheduleByID?.training_Place,
      training_Description: scheduleByID?.training_Description,
      training_Start_Date: scheduleByID?.training_Start_Date,
      training_End_Date: scheduleByID?.training_End_Date,
      training_Start_Time: scheduleByID?.training_Start_Time,
      training_End_Time: scheduleByID?.training_End_Time,
    };

    const testList = selectedSlotTest?.map((each) => (
      trainingSlot!.tests.filter((e) => e?.subject_Name === each).map((item) => (item))
    )).flat();

    const testToDelete = scheduleByID?.tests.filter(each => !testList.map(e => e?.subject_Id).includes(each?.subject_Id));
    const testToUpload = testList.filter(each => !scheduleByID?.tests.map(e => e?.subject_Id).includes(each?.subject_Id));

    // TODO: need to implement APIs and logic for the below two requests

    if (testToDelete.length) {
      for (const each of testToDelete) {
        const res = await DeleteTeacherTrainingData(`/BlockAdmin/DeleteTeacherFromScheduledTraining/${each?.teacher_Training_Schedule_Test_Id}/${user?.user_Id}`)
        if (res.isSuccess) {
          setScheduleById({ ...scheduleByID, tests: scheduleByID?.tests.filter(e => e?.teacher_Training_Schedule_Test_Id !== each?.teacher_Training_Schedule_Test_Id) })
        }
        else {
          showToast({
            type: "error",
            message: res?.message ?? "Error while updating schedule test"
          })
          break;
        }
      }
    }
    if (testToUpload.length) {
      for (const each of testToUpload) {
        console.log("TODO: Need to add functionality")
        // const res = await PutTeacherTrainingData(``, "")
        // if (res.isSuccess) {

        // }
        // else {
        //   showToast({
        //     type: "error",
        //     message: res?.message ?? "Error while updating schedule test"
        //   })
        //   break;
        // }
      }
    }

    let valid = handleValidEditSchedule(Payload , trainingSlot)
    if (valid) {
      const res = await PutTeacherTrainingData(`/BlockAdmin/UpdateTeachersTrainingSchedule`, Payload)
      if (res.isSuccess) {
        showToast({
          type: "success",
          title: "successfully",
          message: "Schedule Details Updated"
        })
        getScheduleById()
      }
      else {
        showToast({
          type: "error",
          message: res?.message ?? "error while updating Training schedule"
        })
      }
    }

  };

  const UpdateTestActiveStatus = async (testId: number, status: boolean) => {
    setIsChangingActiveStatus(true)
    const res = await PutTeacherTrainingData(`/BlockAdmin/ChangeTeacherTrainingTestStatus/${testId}/${!status}`, "")
    if (res.isSuccess) {
      showToast({
        type: "success",
        title: "successfully updated",
        message: "Test active status updated"
      })
      getScheduleById()
    }
    else {
      showToast({
        type: "error",
        message: res?.message ?? "Error while changing active status"
      })
      getScheduleById()
    }
    setIsChangingActiveStatus(false)
  };

  return {
    isLoading,
    isBtnLoading,
    handelSubmit,
    handelSubmitTeacher,
    trainingSlot,
    newSchedule,
    setNewSchedule,
    teacherTransferList,
    setTeacherTransferList,
    setSelectedSlotTest,
    selectedSlotTest,
    scheduleByID,
    setScheduleById,
    handleUpdateActiveStatus,
    isChangingActiveStatus,
    // teacherAllocateToSchedule
  }
}
