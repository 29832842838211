import axios from 'axios';
import React from 'react'
import { BASE_URL } from '../../constant';
import { handleFormatResponse, handleFormatResponseForError } from '../../utility';

export function useTargetDate() {
   
    const fetchTargetData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}ManageTargets/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        };
    };  
    const postTargetData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}ManageTargets/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const PutTargetData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}ManageTargets/${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const DeleteTargetData = async(
        endpoint:string
    )=>{

        try {

            let response = await axios.delete(`${BASE_URL}ManageTargets/${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };
  
    return {
        fetchTargetData,
        postTargetData,
        PutTargetData
  } 
}
