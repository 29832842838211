import { TextInput, Image, PasswordInput } from '@mantine/core';

import './Login.scss';

import { EButton } from '../../../../libs/ui';

import useAuthentication from '../../Hooks/useAuthentication';

export default function Login() {

  const {
    loginParams,
    isLoggingIn,
    handleLoginPayloadChange,
    handleUserLogin
  } = useAuthentication();

  return (
    <div className="page-login">
      <div className="inner">
        <div className="left">
          <div className='flex justify-around w-full items-center'>
            <Image
              maw={130}
              mx="auto"
              radius="md"
              src={require("../../../../assets/icons/Emblem_of_Haryana.svg.png")}
              alt="Haryana_logo"
            />
            <Image
              maw={130}
              mx="auto"
              radius="md"
              src={require("../../../../assets/icons/NIPUN_Haryana logo_high_res.png")}
              alt="NIPUN_logo"
            />
          </div>
          <h4 className="font-medium opacity-60">
            Welcome to NIPUN Haryana portal
          </h4>
          <h2>Department of School Education, Haryana</h2>
        </div>

        <div className="right">
          <h3>Sign in to your account</h3>
          <p className="text-sm opacity-50">
            Please enter your user credentials below to login
          </p>

          <p className="text-sm opacity-50 my-8">
            School Login: कृपया 10 अंकों का UDISE कोड दर्ज करें (शुरुआत में शून्य न जोड़े )
          </p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="signin-form mt-3"
          >
            <TextInput
              required
              size="md"
              placeholder={'Enter Username'}
              value={loginParams.username}
              onChange={(e) => {
                const { value } = e.target;
                handleLoginPayloadChange("username", value);
              }}
            />

            <PasswordInput
              required
              size="md"
              placeholder={'Enter Password (########)'}
              value={loginParams.password}
              onChange={(e) => {
                const { value } = e.target;
                handleLoginPayloadChange("password", value);
              }}
            />

            {/* <PatternFormat
              title="DOB"
              placeholder="Date of Birth (DD-MM-YYYY)"
              className="p-2.5 rounded-md border border-slate-300 focus:outline-none focus:border focus:border-blue-400"
              format="##/##/####"
              value={loginParams.password}
              onChange={e => {
                const { value } = e.target;
                handleLoginPayloadChange("password", value);
              }}
            /> */}

            <EButton
              fullWidth
              type="submit"
              className="btn-blue"
              loading={isLoggingIn}
              onClick={() => {
                handleUserLogin();
              }}
            >
              {'Sign in'}
            </EButton>
          </form>

        </div>
      </div>
    </div>
  )
}
