import React, { forwardRef, useState } from 'react'
import PageLayout from '../../../../../PageLayout'
import { EButton, EInfoPanel, ELabelView, EPageTitle, ESectionDivider, ReviewInput } from '../../../../../libs/ui'
import useCreateNewSchedule from './hooks/useCreateNewSchedule'
import { Card, Grid, Group, TextInput, Textarea, Text, MultiSelect, Avatar, TransferListItemComponent, Checkbox, TransferListItemComponentProps, TransferList, TransferListData, Button } from '@mantine/core'
import { DatePicker, TimeInput } from '@mantine/dates'
import moment from 'moment'
import DotsSpinner from '../../../../../libs/maps/Loader/DotsSpinner/DotsSpinner'
import { useNavigate, useParams } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'


//   interface for Multi Test drop-down 
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  description: string;
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* <Avatar src={image} /> */}
        <FaUser size={22} />

        <div>
          <Text>{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

//  interface for teacher selection transfer list
const ItemComponent: TransferListItemComponent = ({
  data,
  selected,
}: TransferListItemComponentProps) => (
  <Group noWrap>
    {/* <Avatar src={data.image} radius="xl" size="lg" /> */}
    <div style={{ flex: 1 }}>
      <Text size="sm" weight={500}>
        {data?.label}
      </Text>
      <Text size="xs" color="dimmed" weight={400}>
        {data?.description}
      </Text>
    </div>
    <Checkbox
      checked={selected}
      onChange={() => { }}
      tabIndex={-1}
      sx={{ pointerEvents: 'none' }} />
  </Group>
);

export default function CreateNewSchedule() {

  const { slotID }: { slotID: string } = useParams() as { slotID: string };

  const navigate = useNavigate();

  const {
    trainingSlotList,
    isLoading,
    isBtnLoading,
    handelSubmit,
    newSchedule,
    setNewSchedule,
    teacherList,
    setTeacherList,
    selectedTeacher,
    setSelectedTeacher,
    setSelectedSlotTest,
    selectedSlotTest,
    setSelectedPostTest,
    setSelectedPreTest,
  } = useCreateNewSchedule(slotID)

   console.log(newSchedule)
  return (
    <>
      <PageLayout>
        <EPageTitle
          title='New Training Schedule'
          end={<Button
            size='md'
            className="btn-blue"
            onClick={() => navigate(`/block/schedule-list`)}
          >
            <p className='flex gap-1 items-center'>
              <BsArrowLeft size={18} /> {"  "} <span>Back</span>
            </p>
          </Button>} />

        {/* SLOT Details */}
        {trainingSlotList?.length ?
          <Card className='bg-slate-50 pb-10'>
            <ESectionDivider title={`Teacher Training Slot Details`}
              classes='pt-0 pb-0'
              titleStyle='text-xl font-semibold pr-2 text-slate-500'
            />
            <Grid className=''>
              <Grid.Col span={4}>
                <p className='text-slate-400 font-bold'>
                  Slot Title :
                  <span className='text-dark text-sm '>
                    {" "} {trainingSlotList[0]?.schedule_Header_Title}
                  </span>
                </p>
              </Grid.Col>
              <Grid.Col span={4}>
                <p className='text-slate-400 font-bold'>
                  Start Date :
                  <span className=' text-dark text-sm'>
                    {" "} {trainingSlotList[0]?.start_Date ? moment(trainingSlotList[0]?.start_Date).format('DD-MM-YYYY') : ""}
                  </span></p>
              </Grid.Col>
              <Grid.Col span={4}>
                <p className='text-slate-400 font-bold'>
                  End Date :
                  <span className=' text-dark text-sm'>
                    {" "} {trainingSlotList[0]?.end_Date ? moment(trainingSlotList[0]?.end_Date).format('DD-MM-YYYY') : ""}
                  </span></p>
              </Grid.Col>
            </Grid>
            <p className='text-slate-400 mt-2 font-bold'>
            Minimum Training Duration :
                  <span className=' text-dark text-sm'>
                    {" "} {trainingSlotList[0]?.no_Of_Days + `${trainingSlotList[0]?.no_Of_Days > 1 ?" days" : " day"}`}
                  </span></p>
          </Card>
          :
          <EInfoPanel title={'Teacher training slot is not available'} />}

        {/* SCHEDULE DETAILS */}
        {trainingSlotList?.length ?
          <Card className='pt-2 pb-10 px-4 mt-4 bg-slate-50 overflow-visible'>
            <ESectionDivider title={`Create Schedule`} classes='pt-0 ' titleStyle='text-xl font-semibold pr-2 text-slate-500' />
            <div className='flex flex-col gap-4'>
              <TextInput
                required
                size="md"
                description='Training Title'
                maxLength={60}
                placeholder='Enter title'
                readOnly
                value={trainingSlotList[0]?.schedule_Header_Title}
                // onChange={(e) => setNewSchedule(prev => ({ ...prev, training_Title: e.target.value }))}
              />
              <Grid>
                <Grid.Col span={6}>
                  <Textarea
                    size="md"
                    description='Training Description'
                    placeholder='Enter description'
                    value={newSchedule?.training_Description ?? ""}
                    onChange={(e) => setNewSchedule(prev => ({ ...prev, training_Description: e.target.value }))}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Textarea
                    size="md"
                    description='Training Place'
                    placeholder='Enter training place'
                    value={newSchedule?.training_Place ?? ""}
                    onChange={(e) => setNewSchedule({ ...newSchedule, training_Place: e.target.value })}
                  />
                </Grid.Col>
              </Grid>
              {/* Start Date - End Date */}
              <Grid>
                <Grid.Col span={6}>
                  <DatePicker
                    size="md"
                    placeholder='Select date'
                    description='Start Date'
                    minDate={new Date(moment(trainingSlotList[0]?.start_Date).format('YYYY,MM,DD'))}
                    maxDate={new Date(moment(trainingSlotList[0]?.end_Date).format('YYYY,MM,DD'))}
                    value={newSchedule?.training_Start_Date ? new Date(newSchedule?.training_Start_Date) : null}
                    onChange={(e: Date) =>{
                      console.log("Start Date :" ,e)
                      setNewSchedule({ ...newSchedule, training_Start_Date: e, training_End_Date: "" })
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DatePicker
                    size="md"
                    placeholder='Select date'
                    description='End Date'
                    value={newSchedule?.training_End_Date ? new Date(newSchedule?.training_End_Date) : null}
                    minDate={
                      newSchedule?.training_Start_Date
                        ?
                        new Date(moment(newSchedule?.training_Start_Date).format('YYYY,MM,DD'))
                        :
                        new Date(moment(trainingSlotList[0]?.start_Date).add(1, "day").format('YYYY,MM,DD'))
                    }
                    maxDate={new Date(moment(trainingSlotList[0]?.end_Date).format('YYYY,MM,DD'))}
                    onChange={(e: Date) => {
                      console.log("End Date :" ,e)
                      setNewSchedule({ ...newSchedule, training_End_Date: e })}}
                  />
                </Grid.Col>
              </Grid>
              {/* Start Time - End Time */}
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    description="Start Time"
                    size='md'
                    type="time"
                    value={newSchedule?.training_Start_Time ?? null}
                    onChange={(e) => setNewSchedule({ ...newSchedule, training_Start_Time: e?.target?.value })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    description="End Time"
                    size='md'
                    type="time"
                    value={newSchedule?.training_End_Time ?? null}
                    onChange={(e) => setNewSchedule({ ...newSchedule, training_End_Time: e?.target?.value })}
                  />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={6}>
                  <MultiSelect
                    size='md'
                    description="Subject"
                    placeholder='Select'
                    value={selectedSlotTest}
                    data={
                      trainingSlotList[0]?.tests.filter(e => e?.subject_Name !== null).length ?
                        Array.from(new Set(trainingSlotList[0]?.tests?.map((e => e?.subject_Name))))
                        : []
                    }
                    onChange={(e) => setSelectedSlotTest(e)}
                  />
                </Grid.Col>
              </Grid>

              {/* <Grid>
              <Grid.Col span={6}>
                <MultiSelect
                  description="Choose pre teacher test"
                  placeholder='Select'
                  size='md'
                  itemComponent={SelectItem}
                  data={trainingSlotList[0]?.tests.filter((e) => e?.test_Type.toLowerCase() === "pre").map((e) => ({
                    label: `${e?.test_Name}`,
                    value: `${e?.schedule_Header_Test_Id}`,
                    description: `${e?.subject_Name}`,
                  }))}
                  onChange={((e) =>
                     setSelectedPreTest( e?.map((e) =>
                     ({ schedule_Header_Test_Id: +e })
                   ))
                  )}
                  // onChange={((e) =>
                  //   setNewSchedule({
                  //     ...newSchedule, teacherTest: e?.map((e) =>
                  //       ({ schedule_Header_Test_Id: +e })
                  //     )
                  //   })
                  // )}
                  searchable
                  nothingFound="No any pre test available"
                  maxDropdownHeight={400}
                  filter={(value, selected, item) =>
                    !selected &&
                    (item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                      item.description.toLowerCase().includes(value.toLowerCase().trim()))
                  }
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <MultiSelect
                  description="Choose post teacher test"
                  size='md'
                  placeholder='Select'
                  itemComponent={SelectItem}
                  data={trainingSlotList[0]?.tests.filter((e) => e?.test_Type.toLowerCase() === "post").map((e) => ({
                    label: `${e?.test_Name}`,
                    value: `${e?.schedule_Header_Test_Id}`,
                    description: `${e?.subject_Name}`,
                  }))}
                  onChange={((e) =>
                    setSelectedPostTest( e?.map((e) =>
                    ({ schedule_Header_Test_Id: +e })
                  ))
                 )}
                  searchable
                  nothingFound="No any post test available"
                  maxDropdownHeight={400}
                  filter={(value, selected, item) =>
                    !selected &&
                    (item.label && item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                      item.description.toLowerCase().includes(value.toLowerCase().trim()))
                  }
                />
              </Grid.Col>
            </Grid> */}
            </div>
          </Card>
          : <></>}

        {/* ADD TEACHERS TO SCHEDULE */}
        {trainingSlotList?.length ?
          <div className='pt-2 pb-10 px-4 rounded-b-md mt-0 bg-slate-50'>
            <ESectionDivider
              title={`Select Teachers For Training Schedule`}
              classes='pt-0 '
              titleStyle='text-xl font-semibold pr-2 text-slate-500'
            />
            <MultiSelect
              searchable
              itemComponent={SelectItem}
              placeholder='Search teacher...'
              label="Teacher list for training"
              value={selectedTeacher}
              onChange={((e) =>{
                e[0] == "-1" ? 
                setSelectedTeacher(teacherList[0]?.map(each=>each?.value)) 
                :
                setSelectedTeacher(e)})}
              data={[{label:"All",value:"-1",disabled: selectedTeacher.length ?  true : false}].concat(teacherList[0]?.map(each=>({label:each?.label,value:each?.value ,disabled:false})))}
            />
            {/* <TransferList
           value={teacherList}
           onChange={setTeacherList}
           searchPlaceholder="Search teacher..."
           nothingFound="No one here"
           titles={['All teachers list ', 'Teachers list for Training']}
           listHeight={300}
           breakpoint="md"
           itemComponent={ItemComponent}
           filter={(query, item) =>
             item?.label.toLowerCase().includes(query.toLowerCase().trim()) ||
             item?.description.toLowerCase().includes(query.toLowerCase().trim())
           }
         /> */}
            <div className='flex mt-5 justify-end'>
              <EButton
                type="submit"
                className="btn-blue"
                loading={isBtnLoading}
                onClick={() => handelSubmit()}
              >
                {'Submit Schedule'}
              </EButton>
            </div>
          </div>
          :
          <div className="" style={{ height: "300px" }}>
            <DotsSpinner />
          </div>}

      </PageLayout>
    </>
  )
}
