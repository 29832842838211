import React from "react";

interface IProps {
  title: string;
  classes?: string;
  titleStyle?: string;
  end?: React.ReactNode;
}

export function ESectionDivider({ title, classes, titleStyle, end }: IProps) {
  return (
    <div
      className={` flex items-center justify-between mb-4 ${
        classes && classes
      }`}
    >
      <div
        className={` capitalize ${
          titleStyle ? titleStyle : "text-lg font-bold mr-4"
        }`}
      >
        {title}
      </div>
      <div className="flex-1 h-[1px] bg-slate-200"></div>
      {end && <div className="pl-3">{end}</div>}
    </div>
  );
}
