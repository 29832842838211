import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EModal, EPageTitle, ETable } from '../../libs/ui'
import { Alert, Button, Group, Select, Skeleton } from '@mantine/core'
import useMasterData from '../Hooks/useMasterData'
import { BsUpload } from "react-icons/bs";
import { FileInput } from '@mantine/core';
import { ECard } from '../../libs/ui/src/Card/ECard'
import { FaFilePdf } from "react-icons/fa";
import useScorecard from './hook/useScorecard'
import { MdDelete } from "react-icons/md";
import { API_URL, BASE_URL } from '../../libs/data-access/constant'
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Notification } from '@mantine/core';
import { AppContext } from '../../AppContext'

const Scorecard = () => {
  const {
    scoreCardValue,
    handleChangeScoreCard,
    saveScorecard,
    handleChangeScoreCardSearch,
    scoreCardSearchValue,
    sessions,
    monthOptions,
    open,
    setOpen,
    allScoreCardData,
    getScoreCardApi,
    deleteScoreCard,
    deleteId,
    openConfirm,
    deleteConfirmFunc,
    confirmAcceptFunc,
    confirmRejectFunc,

    currentPage,
    setCurrentPage,
    itemsPerPage,
    setSearchQuery,
    nextPage,
    prevPage,
    searchQuery,
    currentItems,
    totalRecords,
    loading,
  } = useScorecard();

  const { role, user } = useContext(AppContext);
  

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const maxPageNumbers = 5; 

  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  // Ensure that the displayed pages are within the correct range
  if (endPage - startPage + 1 < maxPageNumbers) {
    if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
      endPage = Math.min(maxPageNumbers, totalPages);
    } else if (currentPage > totalPages - Math.floor(maxPageNumbers / 2)) {
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(maxPageNumbers / 2)
      );
    }
  }

  //////demo data////

  // const element = [
  //   {
  //     session_Name: "2023-2024",
  //     month_Name: "february",
  //     fileURL:
  //       "https://dsel.education.gov.in/sites/default/files/2023-04/A%26N_2324.pdf",
  //     fileName: "Minutes (174.48 KB) - (Friday, April 28, 2023)",
  //   },
  // ];

  //////demo data////

  return (
    <PageLayout>
      <EPageTitle title="District Scorecard" />
      <div className="filter-container">
        {/* <h4>Filters</h4> */}
        <div className="flex justify-between w-full items-end">
          <div className="flex justify-between gap-3">
            <div className="flex gap-5">
              <Select
                label="Session"
                placeholder="Select"
                className=" w-1/5 grow"
                // disabled={ false}
                value={scoreCardSearchValue?.SessionId}
                data={[
                  { value: "8", label: "Session 2022 - 2023" },
                  ...sessions?.map((e) => {
                    return {
                      value: String(e?.session_Id),
                      label: e?.session_Name,
                    };
                  }),
                ]}
                onChange={(e: any) =>
                  handleChangeScoreCardSearch({ name: "SessionId", value: e })
                }
                // onChange={e => {
                //   // handleChangeFilters("subject", []);
                //   handleChangeFilters("session", e ? String(e) : null);
                // }}
              />

              <Select
                label="Select month"
                placeholder="Select month"
                data={monthOptions}
                value={scoreCardSearchValue?.MonthId}
                onChange={(e: any) =>
                  handleChangeScoreCardSearch({ name: "MonthId", value: e })
                }
              />
            </div>
            <div className="flex gap-3 items-end">
              <EButton
                className="btn-blue"
                // disabled={!selectedMonth}
                // // disabled={true}
                // loading={isSearching}
                onClick={() => {
                  getScoreCardApi(true);
                  setCurrentPage(0)
                }}
              >
                Search
              </EButton>
            </div>
          </div>
          {user.role_Id == 6 ? (
            <div>
              <EButton
                className="btn-blue"
                rightIcon={<BsUpload />}
                // disabled={!selectedMonth}
                // // disabled={true}
                // loading={isSearching}
                onClick={() => setOpen(true)}
              >
                Upload Scorecard
              </EButton>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-4">
        <ECard noHeader title="" customClass="overflow-auto">
          {/* {element?.length > 0 ? ( */}
          {!loading ? (
            <>
              {currentItems?.length > 0 ? (
                <>
                  <ETable>
                    <thead>
                      <tr>
                        <th>Session</th>
                        <th>Month</th>
                        <th>Document</th>
                        {user.role_Id == 6 ? <th>Action</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {element.map((item: any) => { */}
                      {currentItems.map((item: any) => {
                        return (
                          <tr>
                            <td>{item.session_Name}</td>
                            <td>{item.month_Name}</td>
                            <td>
                              <a
                                className="flex justify-center"
                                href={API_URL + item.fileURL}
                                // href={item.fileURL}
                                target="_blank"
                              >
                                <FaFilePdf />
                                {item?.fileName}
                              </a>
                            </td>
                            {user.role_Id == 6 ? (
                              <td>
                                <MdDelete
                                  className="m-auto cursor-pointer text-red-500"
                                  onClick={() => deleteConfirmFunc(item?.id)}
                                />
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </ETable>

                  <div className="text-end mt-2">
                    {/* Pagination controls */}
                    <EButton
                      className="btn-blue"
                      onClick={prevPage}
                      disabled={currentPage === 0}
                    >
                      Previous
                    </EButton>

                    {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

                    {/* Render first page */}
                    {startPage > 1 && (
                      <>
                        <EButton
                          onClick={() => setCurrentPage(0)}
                          className="btn-light ml-2"
                        >
                          1
                        </EButton>
                        {startPage > 2 && <span className="ml-2">...</span>}
                      </>
                    )}

                    {/* Render page numbers */}
                    {Array.from(
                      { length: endPage - startPage + 1 },
                      (_, index) => startPage + index
                    ).map((page) => (
                      <EButton
                        key={page}
                        onClick={() => setCurrentPage(page - 1)}
                        className={
                          currentPage === page - 1
                            ? "btn-blue ml-2"
                            : "btn-light ml-2"
                        }
                      >
                        {page}
                      </EButton>
                    ))}

                    {/* Render last page */}
                    {endPage < totalPages && (
                      <>
                        {endPage < totalPages - 1 && (
                          <span className="ml-2">...</span>
                        )}
                        <EButton
                          onClick={() => setCurrentPage(totalPages - 1)}
                          className={
                            currentPage === totalPages - 1
                              ? "btn-blue ml-2"
                              : "btn-light ml-2"
                          }
                        >
                          {totalPages}
                        </EButton>
                      </>
                    )}

                    <EButton
                      className="btn-blue ml-2"
                      onClick={nextPage}
                      // disabled={
                      //   currentItems?.length < itemsPerPage ||
                      //   currentPage === totalPages - 1
                      // }
                      disabled={
                        currentPage >= totalPages - 1 || // Disable if on the last page
                        (currentItems?.length < itemsPerPage &&
                          currentPage === totalPages - 1) // Disable if fewer items on the last page
                      }
                    >
                      Next
                    </EButton>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Skeleton height={220} radius="md" />
          )}
        </ECard>
      </div>

      <EModal
        size="lg"
        title="Upload Scorecard"
        isOpen={open}
        onClose={() => setOpen(false)}
        setIsOpen={() => setOpen(false)}
      >
        <div className="flex justify-between w-full gap-3 ">
          <Select
            size="md"
            label="Session"
            required
            placeholder="Select"
            className=" w-1/3"
            name="session"
            // disabled={ false}
            value={scoreCardValue.SessionId}
            data={[
              { value: "8", label: "Session 2022 - 2023" },
              ...sessions?.map((e) => {
                return {
                  value: String(e?.session_Id),
                  label: e?.session_Name,
                };
              }),
            ]}
            onChange={(e: any) =>
              handleChangeScoreCard({ name: "SessionId", value: e })
            }
            // onChange={e => {
            //   // handleChangeFilters("subject", []);
            //   handleChangeFilters("session", e ? String(e) : null);
            // }}
          />

          <Select
            size="md"
            required
            className=" w-1/3"
            label="Select month"
            placeholder="Select month"
            data={[
              { label: "January", value: "01" },
              { label: "February", value: "02" },
              { label: "March", value: "03" },
              { label: "April", value: "04" },
              { label: "May", value: "05" },
              { label: "June", value: "06" },
              { label: "July", value: "07" },
              { label: "August", value: "08" },
              { label: "September", value: "09" },
              { label: "October", value: "10" },
              { label: "November", value: "11" },
              { label: "December", value: "12" },
            ]}
            name="month"
            value={scoreCardValue.MonthId}
            onChange={(e: any) =>
              handleChangeScoreCard({ name: "MonthId", value: e })
            }
          />

          <FileInput
            accept="application/pdf"
            classNames={{
              root: "w-1/3",
              input: "text-ellipsis overflow-hidden whitespace-nowrap",
            }}
            size="md"
            label="Select files"
            placeholder="Upload files"
            value={scoreCardValue.file}
            name="file"
            onChange={(e: any) =>
              handleChangeScoreCard({ name: "file", value: e })
            }
          />
        </div>
        <div className="flex justify-end w-full mt-6">
          <EButton className="btn-blue" onClick={saveScorecard}>
            Upload
          </EButton>
        </div>
      </EModal>

      {openConfirm ? (
        //   <Notification icon={<IoIosInformationCircleOutline size={18} />} color="teal" title="Teal notification">
        //   This is teal notification with icon
        //   <Group>
        //     <Button onClick={confirmAcceptFunc}>Confirm</Button>
        //     <Button onClick={confirmRejectFunc}>Cancel</Button>
        //     </Group>
        // </Notification>
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <Alert
            className="w-1/3 text-lg"
            withCloseButton
            closeButtonLabel="Close alert"
            onClose={confirmRejectFunc}
            icon={<IoIosInformationCircleOutline size={16} />}
            title="Confirmation"
            color="red"
          >
            Are sure you want to proceed?
            <Group className="flex justify-end mt-5">
              <EButton className="btn-light" onClick={confirmRejectFunc}>
                No
              </EButton>
              <EButton className="btn-blue" onClick={confirmAcceptFunc}>
                Yes
              </EButton>
            </Group>
          </Alert>
        </div>
      ) : null}
    </PageLayout>
  );
}

export default Scorecard