import { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../AppContext";
import { showToast } from "../../../utils/utility";
import useMasterData from "../../Hooks/useMasterData";
import { useStudentData, useTeacherData } from "../../../libs/data-access";
import axios from "axios";

export default function useMISDataSync() {

    const { user } = useContext(AppContext);

    const {
        classes,
        getClasses,
    } = useMasterData();
    const {
        postStudentData
    } = useStudentData();
    const {
        postTeacherData
    } = useTeacherData();

    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [activeTab, setActiveTab] = useState<"student" | "teacher">("student");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [studentParams, setStudentParams] = useState<{
        selectedGrade: string | null;
        UDISE: number | null;
    }>({
        selectedGrade: null,
        UDISE: null
    });
    const [teacherParams, setTeacherParams] = useState<{
        UDISE: string | null;
    }>({
        UDISE: null
    });



    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
        } else {
            getClasses();
        };
    }, [firstLoad]);



    // HANDLER FUNCTIONS
    const handleStudentParamsChange = (key: "selectedGrade" | "UDISE", value: string | null) => {

        setStudentParams(prev => ({ ...prev, [key]: value }));

    };

    const handleTeacherParamsChange = (key: "UDISE", value: string | null) => {

        setTeacherParams(prev => ({ ...prev, [key]: value }));

    };

    // API CALLS
    const syncStudentDataFromMIS = async () => {

        if (!user.role_Id) {
            showToast({
                type: "warning",
                title: "Oops!",
                message: "Logged in user's role is missing!"
            });
            return;
        };
        if (!studentParams.UDISE
            ||
            !studentParams.selectedGrade) {
            showToast({
                type: "warning",
                title: "Oops!",
                message: "School UDISE code or Grade is missing!"
            });
            return;
        };
        if (String(studentParams.UDISE)?.length !== 10) {
            showToast({
                type: "warning",
                title: "Oops!",
                message: "School UDISE length needs to be of 10 digits!"
            });
            return;
        };

        setIsLoading(true);

        const response = await postStudentData(`StudentSyncWithMIS/${user.role_Id}/${studentParams.UDISE}/${studentParams.selectedGrade}`);
        if (response.isSuccess) {

            showToast({
                type: "success",
                title: "Success!",
                message: response.message || "Data synced successfully!"
            });
            setStudentParams({ UDISE: null, selectedGrade: null });

        } else {

            console.log("Error while student MIS data sync: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not sync student data!"
            });

        };

        // const response = await axios.post(`https://tokanapi.nipunharyana.in/api/Student/StudentSyncWithMIS/${3}/${studentParams.UDISE}/${studentParams.selectedGrade}`, {}, {
        //     headers: {
        //         Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImFicmNfY2hoYXByYSIsImp0aSI6ImY2MWY0ZThkLTNlYTEtNDNjOC04MTgyLTNjYmU2ODQ2MDY5MSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6Ik1lbnRvciIsImV4cCI6MTcwNDg2MzY0NCwiaXNzIjoiaHR0cHM6Ly90b2thbmFwaS5uaXB1bmhhcnlhbmEuaW4iLCJhdWQiOiJodHRwczogLy9mbG5zdGFnaW5nYXBpLndlZXhjZWxkZW1vLmNvbSJ9.h7l_SVDvU4i7OHu7QOZO7tGlWTNEwFcZQYaMIbaxCSw",
        //         "Content-Type": "application/json",
        //     }
        // }).catch(
        //     error => {
        //         console.log("Error while student MIS data sync: ", error?.response);
        //         showToast({
        //             type: "error",
        //             title: "Oops!",
        //             message: "Could not sync student data!"
        //         });
        //     }
        // );

        setIsLoading(false);

    };

    const syncTeacherDataFromMIS = async () => {

        if (!user.role_Id) {
            showToast({
                type: "warning",
                title: "Oops!",
                message: "Role ios missing!"
            });
            return;
        };
        if (String(teacherParams.UDISE)?.length !== 10) {
            showToast({
                type: "warning",
                title: "Oops!",
                message: "School UDISE length needs to be of 10 digits!"
            });
            return;
        };

        setIsLoading(true);

        // const response = await axios.post(`https://tokanapi.nipunharyana.in/api/Teacher/TeacherSyncWithMIS/${3}/${teacherParams.UDISE}`, {}, {
        //     headers: {
        //         Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImFicmNfY2hoYXByYSIsImp0aSI6ImY2MWY0ZThkLTNlYTEtNDNjOC04MTgyLTNjYmU2ODQ2MDY5MSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6Ik1lbnRvciIsImV4cCI6MTcwNDg2MzY0NCwiaXNzIjoiaHR0cHM6Ly90b2thbmFwaS5uaXB1bmhhcnlhbmEuaW4iLCJhdWQiOiJodHRwczogLy9mbG5zdGFnaW5nYXBpLndlZXhjZWxkZW1vLmNvbSJ9.h7l_SVDvU4i7OHu7QOZO7tGlWTNEwFcZQYaMIbaxCSw",
        //         "Content-Type": "application/json",
        //     }
        // }).catch(
        //     error => {
        //         console.log("Error while teacher MIS data sync: ", error?.response);
        //         showToast({
        //             type: "error",
        //             title: "Oops!",
        //             message: "Could not sync teacher data!"
        //         });
        //     }
        // );

        // if (response?.status === 200) {
        //     showToast({
        //         type: "success",
        //         title: "Success!",
        //         message: "Data synced successfully!"
        //     });
        //     setTeacherParams({ UDISE: null });
        // };

        const response = await postTeacherData(`/TeacherSyncWithMIS/${user.role_Id}/${teacherParams.UDISE}`);

        if (response.isSuccess) {

            // console.log("Response for teacher MIS data sync: ", response.data);
            showToast({
                type: "success",
                title: "Success!",
                message: response.message || "Data synced successfully!"
            });
            setTeacherParams({ UDISE: null });

        } else {

            console.log("Error while teacher MIS data sync: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not sync teacher data!"
            });

        };

        setIsLoading(false);

    };


    return {
        activeTab,
        setActiveTab,
        isLoading,
        classes,
        studentParams,
        handleStudentParamsChange,
        syncStudentDataFromMIS,
        teacherParams,
        handleTeacherParamsChange,
        syncTeacherDataFromMIS
    };

};
