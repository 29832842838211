import React, { useState } from 'react'
import { Avatar, Badge, Select, Skeleton, Tabs } from '@mantine/core';
import { BsSearch } from 'react-icons/bs';

import PageLayout from '../../PageLayout';
import SideDrawer from './component/SideDrawer';
import { EButton, EDrawer, EEmpty, EPageTitle, ETable } from '../../libs/ui';

import useGrievance from './hooks/useGrievance';
import moment from 'moment';
import { DatePicker } from "@mantine/dates";

export default function Grievance() {

  const {
    activeTab,
    setActiveTab,
    tickets,
    isLoading,
    handleFetchTickets,
    handleReplyInput,
    closeSpecificTicket,

    isOpenDrawer,
    setIsOpenDrawer,
    selectedTicket,
    setSelectedTicket,
    selectedIndex,
    setSelectedIndex,

    filterData,
    filterHandleChange,
    users,
    category,
    currentPage,
    itemsPerPage,
    totalRecords,
    nextPage,
    prevPage,
    setCurrentPage,
    onSearchFunc,
    handleCloseDateInput,
  } = useGrievance();


  // const totalPages = Math.ceil(totalRecords / itemsPerPage);

  // let startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2 / 2));
  // let endPage = Math.min(totalPages, startPage + itemsPerPage / 2 - 1);


  // if (endPage - startPage + 1 < itemsPerPage / 2) {
  //   if (currentPage <= Math.ceil(itemsPerPage / 2 / 2)) {
  //     endPage = Math.min(itemsPerPage / 2, totalPages);
  //   } else if (currentPage > totalPages - Math.floor(itemsPerPage / 2 / 2)) {
  //     startPage = Math.max(1, totalPages - itemsPerPage / 2 + 1);
  //   } else {
  //     startPage = Math.max(1, currentPage - Math.floor(itemsPerPage / 2 / 2));
  //     endPage = Math.min(
  //       totalPages,
  //       currentPage + Math.floor(itemsPerPage / 2 / 2)
  //     );
  //   }
  // }

  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const maxPageNumbers = 5; // Adjust this to 5 or 10 based on your requirement

  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  // Ensure that the displayed pages are within the correct range
  if (endPage - startPage + 1 < maxPageNumbers) {
    if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
      endPage = Math.min(maxPageNumbers, totalPages);
    } else if (currentPage > totalPages - Math.floor(maxPageNumbers / 2)) {
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(maxPageNumbers / 2)
      );
    }
  }

  return (
    <PageLayout>
      <EPageTitle title="Grievances" />

      {/* FILTERS */}
      <div className="filter-container">
        {/* <h4>Filters</h4> */}
        <div className="flex justify-between gap-3">
          <div className="flex gap-5">
            <Select
              clearable
              label="Select User"
              placeholder="Select User"
              data={users?.map((each: any) => {
                return {
                  label: each.userName,
                  value: String(each.role_Id),
                };
              })}
              value={filterData?.user}
              onChange={(e: any) =>
                filterHandleChange({ name: "user", value: e })
              }
            />
            <Select
              label="Select Category"
              placeholder="Select Category"
              clearable
              // disabled={
              //   role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
              //     ? true
              //     : false
              // }
              data={category?.map((each: any) => {
                return {
                  label: each.category_Name,
                  value: String(each.category_Id),
                };
              })}
              value={filterData?.category}
              onChange={(e: any) =>
                filterHandleChange({ name: "category", value: e })
              }
            />
            <Select
              label="Select Grivance status"
              placeholder="Select Grivance status"
              // disabled={
              //   !selectedDistrict || role?.includes(ROLES.BLOCK) ? true : false
              // }
              data={[
                { label: "All", value: "-1" },
                { label: "Open", value: "0" },
                { label: "Closed", value: "1" },
              ]}
              // data={blocks?.map((each) => {
              //   return {
              //     label: each.name,
              //     value: String(each.id),
              //   };
              // })}
              value={filterData?.grivanceStatus}
              onChange={(e: any) =>
                filterHandleChange({ name: "grivanceStatus", value: e })
              }
            />
            {/* <Select
              label="Select From Date"
              placeholder="Select From Date"
              clearable
              // disabled={!selectedBlock}
              data={[]}
              // value={selectedCluster ?? null}
              // onChange={(e: any) => setSelectedCluster(e)}
            /> */}
            <DatePicker
              label="Select From Date"
              placeholder="MM/DD/YYYY"
              value={filterData?.fromDate}
              onChange={(e: any) =>
                filterHandleChange({ name: "fromDate", value: e })
              }
              // maxDate={
              //   filterData?.toDate ? new Date(filterData.toDate) : undefined
              // }
              maxDate={
                filterData?.toDate ? new Date(filterData.toDate) : new Date()
              }
            />
            <DatePicker
              label="Select To Date"
              placeholder="MM/DD/YYYY"
              value={filterData?.toDate}
              onChange={(e: any) =>
                filterHandleChange({ name: "toDate", value: e })
              }
              minDate={
                filterData?.fromDate ? new Date(filterData.fromDate) : undefined
              }
              maxDate={new Date()}
            />

            {/* <Select
              label="Select To Date"
              placeholder="Select To Date"
              clearable
              // disabled={!selectedBlock}
              data={[]}
              // value={selectedCluster ?? null}
              // onChange={(e: any) => setSelectedCluster(e)}
            /> */}
          </div>
          <div className="flex gap-3 items-end">
            <EButton
              className="btn-blue"
              disabled={
                filterData?.user?.length <= 0 &&
                filterData?.category?.length <= 0 &&
                filterData?.grivanceStatus?.length <= 0 &&
                filterData?.fromDate?.length <= 0 &&
                filterData?.toDate?.length <= 0
              }
              loading={isLoading}
              onClick={() => {
                onSearchFunc();
              }}
            >
              Search
            </EButton>
          </div>
        </div>
      </div>

      {/* <Tabs
        defaultValue="open"
        value={activeTab}
        onTabChange={(value: "open" | "closed") => setActiveTab(value)}
      >
        <Tabs.List>
          <Tabs.Tab value="open">Open Tickets</Tabs.Tab>
          <Tabs.Tab value="closed">Closed</Tabs.Tab>
        </Tabs.List> */}

      {/* OPEN TICKETS */}
      {/* <Tabs.Panel value="open" pt="lg">
          {
            isLoading
              ?
              <Skeleton
                radius="md"
                height={250}
              />
              :
              tickets.length > 0
                ?
                <ETable>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Ticket #</th>
                      <th>User</th>
                      <th>Phone</th>
                      <th>District</th>
                      <th>Category</th>
                      <th>Remarks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((each, index) =>
                      <tr
                        key={index + "ticket"}
                        className="cursor-pointer"
                        onClick={() => {
                          setIsOpenDrawer(true);
                          setSelectedTicket({ ...each, reply: undefined });
                        }}>
                        <td>{each.date_Of_Issue ? moment(each.date_Of_Issue).format("DD/MM/YYYY") : "-"}</td>
                        <td className='text-blue-600'>{each.ticket_Number}</td>
                        <td>
                          <div className='flex gap-1 items-center'>
                            <Avatar className='rounded-full'>{each.name?.charAt(0)}</Avatar>
                            <span>{each.name}</span>
                          </div>
                        </td>
                        <td>{each.contact_Number}</td>
                        <td>{each.district_Name || " - "}</td>
                        <td>{each.category_Name}</td>
                        <td>
                          <p>{each.grievance_Query?.length > 25 ? each.grievance_Query.substring(0, 25) + "..." : each.grievance_Query}</p>
                        </td>
                        <td>
                          <Badge color="green">{each.ticket_Status}</Badge>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </ETable>
                :
                <EEmpty
                  title="No Ticket Found"
                  description="There are no open tickets available."
                />
          }
        </Tabs.Panel> */}

      {/* CLOSED TICKETS */}
      {/* <Tabs.Panel value="closed" pt="lg">
          {
            isLoading
              ?
              <Skeleton
                radius="md"
                height={250}
              />
              :
              tickets.length > 0
                ?
                <ETable>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Ticket #</th>
                      <th>User</th>
                      <th>Phone</th>
                      <th>District</th>
                      <th>Category</th>
                      <th>Remarks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((each, index) =>
                      <tr
                        key={index + "ticket"}
                        className="cursor-pointer"
                        onClick={() => {
                          setIsOpenDrawer(true);
                          setSelectedTicket({ ...each, reply: undefined });
                        }}>
                        <td>{each.date_Of_Issue ? moment(each.date_Of_Issue).format("DD/MM/YYYY") : "-"}</td>
                        <td className='text-blue-600'>{each.ticket_Number}</td>
                        <td>
                          <div className='flex gap-1 items-center'>
                            <Avatar className='rounded-full'>{each.name?.charAt(0)}</Avatar>
                            <span>{each.name}</span>
                          </div>
                        </td>
                        <td>{each.contact_Number}</td>
                        <td>{each.district_Name || " - "}</td>
                        <td>{each.category_Name}</td>
                        <td>
                          <p>{each.grievance_Query?.length > 25 ? each.grievance_Query.substring(0, 25) + "..." : each.grievance_Query}</p>
                        </td>
                        <td>
                          <Badge color="blue">{each.ticket_Status}</Badge>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </ETable>
                :
                <EEmpty
                  title="No Ticket Found"
                  description="There are no closed tickets available."
                />
          }
        </Tabs.Panel>
      </Tabs> */}
      {isLoading ? (
        <Skeleton radius="md" height={250} />
      ) : tickets.length > 0 ? (
        <>
          <ETable>
            <thead>
              <tr>
                <th>Date</th>
                <th>Ticket #</th>
                <th>User</th>
                <th>Phone</th>
                <th>District</th>
                <th>Category</th>
                <th>Remarks</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((each, index) => (
                <tr
                  key={index + "ticket"}
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpenDrawer(true);
                    setSelectedTicket({ ...each, reply: undefined });
                  }}
                >
                  <td>
                    {each.date_Of_Issue
                      ? moment(each.date_Of_Issue).format("DD/MM/YYYY")
                      : "-"}
                  </td>
                  <td className="text-blue-600">{each.ticket_Number}</td>
                  <td>
                    <div className="flex gap-1 items-center">
                      <Avatar className="rounded-full">
                        {each.name?.charAt(0)}
                      </Avatar>
                      <span>{each.name}</span>
                    </div>
                  </td>
                  <td>{each.contact_Number}</td>
                  <td>{each.district_Name || " - "}</td>
                  <td>{each.category_Name}</td>
                  <td>
                    <p>
                      {each.grievance_Query?.length > 25
                        ? each.grievance_Query.substring(0, 25) + "..."
                        : each.grievance_Query}
                    </p>
                  </td>
                  <td>
                    <Badge color="blue">{each.ticket_Status}</Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </ETable>

          <div className="text-end mt-2">
            {/* Pagination controls */}
            <EButton
              className="btn-blue"
              onClick={prevPage}
              disabled={currentPage === 0}
            >
              Previous
            </EButton>

            {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

            {/* Render first page */}
            {startPage > 1 && (
              <>
                <EButton
                  onClick={() => setCurrentPage(0)}
                  className="btn-light ml-2"
                >
                  1
                </EButton>
                {startPage > 2 && <span className="ml-2">...</span>}
              </>
            )}

            {/* Render page numbers */}
            {Array.from(
              { length: endPage - startPage + 1 },
              (_, index) => startPage + index
            ).map((page) => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page - 1)}
                className={
                  currentPage === page - 1 ? "btn-blue ml-2" : "btn-light ml-2"
                }
              >
                {page}
              </EButton>
            ))}

            {/* Render last page */}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && <span className="ml-2">...</span>}
                <EButton
                  onClick={() => setCurrentPage(totalPages - 1)}
                  className={
                    currentPage === totalPages - 1
                      ? "btn-blue ml-2"
                      : "btn-light ml-2"
                  }
                >
                  {totalPages}
                </EButton>
              </>
            )}

            <EButton
              className="btn-blue ml-2"
              onClick={nextPage}
              // disabled={
              //   tickets.length < itemsPerPage || currentPage === totalPages - 1
              // }
              disabled={
                currentPage >= totalPages - 1 || // Disable if on the last page
                (tickets?.length < itemsPerPage &&
                  currentPage === totalPages - 1) // Disable if fewer items on the last page
              }
            >
              Next
            </EButton>
          </div>
        </>
      ) : (
        <EEmpty
          title="No Ticket Found"
          description="There are no closed tickets available."
        />
      )}
      {selectedTicket ? (
        <EDrawer
          title="Ticket Details"
          opened={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
          drawerContent={
            <SideDrawer
              ticket={selectedTicket}
              closeSpecificTicket={closeSpecificTicket}
              handleReplyInput={handleReplyInput}
              isLoading={isLoading}
              handleCloseDateInput={handleCloseDateInput}
            />
          }
          position="right"
        />
      ) : (
        <></>
      )}
    </PageLayout>
  );
}
