
import { HiOutlineInformationCircle } from 'react-icons/hi2';

import './EInfoPanel.scss';

type Props = {
  title: string;
  children?: JSX.Element | JSX.Element[];
  direction?: any;
  text?: string;
};

export function EInfoPanel({ title, children, direction, text }: Props) {
  return (
    <div className="p-7 bg-primary-50 rounded-lg" data-direction={direction}>
      <HiOutlineInformationCircle
        size={20}
        className='mb-2text-cyan-500'
      />
      <div className={`font-semibold mb-3 opacity-90 text-lg`}>{title}</div>
      {text
        ?
        <p className="text-slate-400 text-sm mb-3">{text}</p>
        :
        <></>}
      <div className='opacity-70 text-base'>
        {children}
      </div>
    </div>
  );
}