import "./EFormSection.scss";

type Props = {
  title: string;
  subtitle?: string;
  rightPanel?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
};

export function EVerticalFormSection({
  title,
  subtitle,
  rightPanel,
  children,
  fullWidth,
}: Props) {
  return (
    <div>
      <div className="flex justify-between items-center">
        <span>
          <div className="text-xl font-bold">{title}</div>
          {subtitle ? (
            <p className="text-sm mt-0.5 text-slate-500">{subtitle}</p>
          ) : (
            <></>
          )}
        </span>
        {rightPanel ?? <></>}
      </div>

      <div>
        <div className="my-5">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
}
