import React from 'react'
import useMarkAttendance from '../hooks/useMarkAttendance';
import { Grid, Skeleton, Switch } from '@mantine/core';
import { FaUser } from 'react-icons/fa';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import { EButton } from '../../../../../../libs/ui';

type IProps ={
    isLoading:boolean
    teacherListForAttendance :any []
    handleAttendanceChange : (index :number ,event :any)=> void
    handleSubmitAttendance :()=>void
}
export default function MarkNewAttendance(
    {
        isLoading,
        teacherListForAttendance,
        handleAttendanceChange,
        handleSubmitAttendance
    }:IProps
) {
    return (
        <>
         
         { isLoading ? 
            <Skeleton height={190} radius="md" /> :  
            <>            {
                teacherListForAttendance?.map((each, index) =>(
                     <Grid className='flex'>
                        <Grid.Col span={3} className='flex gap-3 mt-3 '>
                            <div className="bg-slate-300 rounded-xl p-3 w-12 h-12 items-center justify-center">
                                <FaUser size={25} />
                            </div>
                            <div>
                                <p className="font-semibold text-md">{each?.employee_Name}</p>
                                <p className='text-sm'>{each?.employee_Code}</p>
                            </div>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <div>
                                <p className='mt-2 font-semibold'>Designation</p>
                                {each?.designation}
                            </div>
                        </Grid.Col>
                        <Grid.Col span={2}>
                            <Switch
                                checked={each?.is_Present}
                                onChange={(event) => handleAttendanceChange(index, event)}
                                color="teal"
                                size="md"
                                label={each?.is_Present ? "Presence" : "Absence"}
                                thumbIcon={each?.is_Present ? (
                                    <BsCheckCircle color='dark' size={"0.8"} />
                                ) : (
                                    <BsXCircle size="0.8rem" />
                                )} />
                        </Grid.Col>
                    </Grid>
                ))}
            <Grid>
                <Grid.Col span={4}>
                </Grid.Col>
                <Grid.Col span={1}>
                        <div className='flex gap-2'>
                            <EButton
                                onClick={() =>{}}
                                className="btn-light"
                            >
                                Cancel
                            </EButton>
                            <EButton
                                onClick={() => handleSubmitAttendance()}
                                className="btn-blue"
                            >
                                Submit Attendance
                            </EButton>
                        </div>
                </Grid.Col>
            </Grid>
            </>}
        </>
    )
}

