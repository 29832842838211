import {
  Badge,
  Button,
  Card,
  Checkbox,
  Grid,
  Group,
  MultiSelect,
  Text,
  TextInput,
  Textarea,
  TransferList,
  TransferListItemComponent,
  TransferListItemComponentProps,
  Tooltip,
} from "@mantine/core";
import React, { forwardRef } from 'react'
import PageLayout from '../../../../../PageLayout';
import { EButton, EInfoPanel, EPageTitle, ESectionDivider } from '../../../../../libs/ui';
import moment from 'moment';
import { DatePicker } from '@mantine/dates';
import DotsSpinner from '../../../../../libs/maps/Loader/DotsSpinner/DotsSpinner';
import useEditSchedule from './hooks/useEditSchedule';
import InfoLabel from '../../../../../libs/ui/src/InfoLabel/InfoLabel';
import DetailsCard from '../../../../../libs/ui/src/Cards/DetailsCard';
import { BsArrowLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BsChevronRight } from "react-icons/bs";
import { BsChevronLeft } from "react-icons/bs";
import { BsChevronDoubleRight } from "react-icons/bs";
import { BsChevronDoubleLeft } from "react-icons/bs";


export default function EditSchedule() {
  const {
    trainingSlot,
    isLoading,
    isBtnLoading,
    handelSubmit,
    handelSubmitTeacher,
    teacherTransferList,
    setTeacherTransferList,
    setSelectedSlotTest,
    selectedSlotTest,
    scheduleByID,
    setScheduleById,
    handleUpdateActiveStatus,
    isChangingActiveStatus
  } = useEditSchedule()

 const navigate =useNavigate()
  //   interface for Multi Test drop-down 
  interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ image, label, description, ...others }: ItemProps, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {/* <Avatar src={image} /> */}

          <div>
            <Text>{label}</Text>
            <Text size="xs" color="dimmed">
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  //  interface for teacher selection transfer list

  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      {/* <Avatar src={data.image} radius="xl" size="lg" /> */}
      <div style={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {data.label}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data.description}
        </Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => { }}
        tabIndex={-1}
        sx={{ pointerEvents: 'none' }} />
    </Group>
  );

  return (
    <>
      <PageLayout>
        <EPageTitle
          title="Edit Training Schedule"
          end={
            <EButton
              size="md"
              className="btn-blue"
              onClick={() => navigate(`/block/schedule-list`)}
            >
              <p className="flex gap-1 items-center">
                <BsArrowLeft size={18} /> {"  "} <span>Back</span>
              </p>
            </EButton>
          }
        />
        {
          //   trainingSlot ?
          //     <Card className='bg-slate-50 pb-10'>
          //       <ESectionDivider title={`Teacher Training Slot Details`}
          //         classes='pt-0 pb-0 '
          //         titleStyle='text-xl font-semibold pr-2 text-slate-500'
          //       />
          //       {/* <p className='text-slate-400 pb-1 font-bold text-lg '>
          //         Teacher training slot
          //       </p> */}
          //       <Grid className='text-center'>
          //         <Grid.Col span={4}>
          //           <p className='text-slate-400 font-bold text-md'>
          //             Slot Title :
          //             <span className=' text-dark text-sm '>
          //               {" "} {trainingSlot?.schedule_Header_Title}
          //             </span>
          //           </p>
          //         </Grid.Col>
          //         <Grid.Col span={4}>
          //           <p className='text-slate-400 font-bold text-md'>
          //             Start Date :
          //             <span className=' text-dark text-sm '>
          //               {" "} {trainingSlot?.start_Date ? moment(trainingSlot?.start_Date).format('DD-MM-YYYY') : ""}
          //             </span></p>
          //         </Grid.Col>
          //         <Grid.Col span={4}>
          //           <p className='text-slate-400 font-bold text-md'>
          //             End Date :
          //             <span className=' text-dark text-sm '>
          //               {" "} {trainingSlot?.end_Date ? moment(trainingSlot?.end_Date).format('DD-MM-YYYY') : ""}
          //             </span></p>
          //         </Grid.Col>
          //       </Grid>
          //     </Card>
          //     :
          //     <EInfoPanel title={'No any teacher training slot available'} />
        }
        {trainingSlot ? (
          <Card className="pt-2 pb-10 px-4 mt-4 bg-slate-50 overflow-visible">
            <ESectionDivider
              title={`Edit Schedule`}
              classes="pt-0 "
              titleStyle="text-xl font-semibold pr-2 text-slate-500"
            />
            <div className="flex flex-col gap-4">
              <TextInput
                required
                size="md"
                description="Training Title"
                readOnly
                maxLength={60}
                value={scheduleByID?.training_Title}
                onChange={(e) =>
                  setScheduleById({
                    ...scheduleByID,
                    training_Title: e.target.value,
                  })
                }
              />
              <Grid>
                <Grid.Col span={6}>
                  <Textarea
                    size="md"
                    description="Training Description"
                    value={scheduleByID?.training_Description ?? ""}
                    onChange={(e) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_Description: e.target.value,
                      })
                    }
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Textarea
                    size="md"
                    description="Training Place"
                    value={scheduleByID?.training_Place ?? ""}
                    onChange={(e) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_Place: e.target.value,
                      })
                    }
                  />
                </Grid.Col>
              </Grid>
              {/* Start Date - End Date */}
              <Grid>
                <Grid.Col span={6}>
                  <DatePicker
                    size="md"
                    placeholder="Select date"
                    description="Start Date"
                    minDate={
                      new Date(
                        moment(trainingSlot?.start_Date).format("YYYY,MM,DD")
                      )
                    }
                    maxDate={
                      new Date(
                        moment(trainingSlot?.end_Date).format("YYYY,MM,DD")
                      )
                    }
                    value={
                      scheduleByID?.training_Start_Date
                        ? new Date(scheduleByID?.training_Start_Date)
                        : null
                    }
                    onChange={(e: Date) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_Start_Date: moment(e).format("YYYY-MM-DD"),
                        training_End_Date: "",
                      })
                    }
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <DatePicker
                    size="md"
                    placeholder="Select date"
                    description="End Date"
                    value={
                      scheduleByID?.training_End_Date
                        ? new Date(scheduleByID?.training_End_Date)
                        : null
                    }
                    minDate={
                      new Date(
                        moment(scheduleByID?.training_Start_Date).format(
                          "YYYY,MM,DD"
                        )
                      )
                    }
                    maxDate={
                      new Date(
                        moment(trainingSlot?.end_Date).format("YYYY,MM,DD")
                      )
                    }
                    onChange={(e: Date) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_End_Date: moment(e).format("YYYY-MM-DD"),
                      })
                    }
                  />
                </Grid.Col>
              </Grid>
              {/* Start Time - End Time */}
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    description="Start Time"
                    size="md"
                    type="time"
                    value={scheduleByID?.training_Start_Time ?? null}
                    onChange={(e) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_Start_Time: e?.target?.value,
                      })
                    }
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    description="End Time"
                    size="md"
                    type="time"
                    value={scheduleByID?.training_End_Time ?? null}
                    onChange={(e) =>
                      setScheduleById({
                        ...scheduleByID,
                        training_End_Time: e?.target?.value,
                      })
                    }
                  />
                </Grid.Col>
              </Grid>

              {/* subject */}
              <Grid>
                <Grid.Col span={6}>
                  <MultiSelect
                    size="md"
                    description="Subject"
                    value={selectedSlotTest ?? null}
                    // value={Array.from(new Set(scheduleByID?.tests.map((e => e?.subject_Name))))}
                    data={Array.from(
                      new Set(
                        trainingSlot?.tests.map((e) => e?.subject_Name ?? "")
                      )
                    )}
                    onChange={(e) => setSelectedSlotTest(e)}
                  />
                </Grid.Col>
              </Grid>

              <div className="flex mt-5 justify-end">
                <EButton
                  type="submit"
                  className="btn-blue"
                  loading={isBtnLoading}
                  onClick={() => handelSubmit()}
                >
                  {"Update Schedule"}
                </EButton>
              </div>
            </div>
          </Card>
        ) : (
          <EInfoPanel title={"No any teacher training slot available"} />
        )}
        <Card className="pt-2 pb-10 px-4 mt-4 bg-slate-50">
          <ESectionDivider
            title={`Select Tests For Training Schedule`}
            classes="pt-0 "
            titleStyle="text-xl font-semibold pr-2 text-slate-500"
          />
          <Grid>
            {/* Pre Test */}
            <Grid.Col span={5} className="px-5">
              <ESectionDivider title="Pre Test" classes="pt-0" />
              {scheduleByID?.tests
                ?.filter((e) => e?.test_Type.toLowerCase() === "pre")
                .map((each, index) => (
                  <DetailsCard
                    isLoading={isChangingActiveStatus}
                    isActive={each?.is_Active}
                    name={each?.test_Name}
                    subject={each?.subject_Name}
                    cardId={each?.teacher_Training_Schedule_Test_Id}
                    type={each?.test_Type}
                    handleClick={() => {
                      handleUpdateActiveStatus(each);
                    }}
                  />
                ))}
            </Grid.Col>
            {/* Post test */}
            <Grid.Col span={5} className="px-5">
              <ESectionDivider title="Post Test" classes="pt-0" />
              {scheduleByID?.tests
                ?.filter((e) => e?.test_Type.toLowerCase() === "post")
                .map((each, index) => (
                  <DetailsCard
                    isLoading={isChangingActiveStatus}
                    isActive={each?.is_Active}
                    name={each?.test_Name}
                    cardId={each?.teacher_Training_Schedule_Test_Id}
                    subject={each?.subject_Name}
                    type={each?.test_Type}
                    handleClick={() => handleUpdateActiveStatus(each)}
                  />
                ))}
            </Grid.Col>
          </Grid>
        </Card>
        {!trainingSlot ? (
          <div className="" style={{ height: "300px" }}>
            <DotsSpinner />
          </div>
        ) : (
          <Card className="pt-2 pb-10 px-4 mt-4 bg-slate-50">
            <ESectionDivider
              title={`Select Teachers For Training Schedule`}
              classes="pt-0 "
              titleStyle="text-xl font-semibold pr-2 text-slate-500"
            />
            <TransferList
              transferIcon={({ reversed }) =>
                reversed ? (
                  <div>
                    <Tooltip
                      label="Remove selected teacher(s)"
                      zIndex={999999999999}
                    >
                      <p>
                        <BsChevronLeft />
                      </p>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Tooltip
                      label="Add selected teacher(s)"
                      zIndex={999999999999}
                    >
                      <p>
                        <BsChevronRight />
                      </p>
                    </Tooltip>
                  </div>
                )
              }
              transferAllIcon={({ reversed }) =>
                reversed ? (
                  <div>
                    <Tooltip label="Remove All Teachers" zIndex={999999999999}>
                      <p>
                        <BsChevronDoubleLeft />
                      </p>
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Tooltip label="Add All Teachers" zIndex={999999999999}>
                      <p>
                        <BsChevronDoubleRight />
                      </p>
                    </Tooltip>
                  </div>
                )
              }
              value={teacherTransferList}
              onChange={setTeacherTransferList}
              searchPlaceholder="Search teacher..."
              nothingFound="No one here"
              titles={["All teachers list ", "Teachers list for Training"]}
              listHeight={300}
              breakpoint="md"
              itemComponent={ItemComponent}
              filter={(query, item) =>
                item?.label
                  ?.toLowerCase()
                  .includes(query?.toLowerCase().trim()) ||
                item?.description
                  ?.toLowerCase()
                  .includes(query?.toLowerCase().trim())
              }
            />
            <div className="flex mt-5 justify-end">
              <EButton
                type="submit"
                className="btn-blue"
                loading={isBtnLoading}
                onClick={() => handelSubmitTeacher()}
              >
                {"Update Teacher"}
              </EButton>
            </div>
          </Card>
        )}
      </PageLayout>
    </>
  );
}

