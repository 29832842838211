import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from "../../utility";

export function useGrievanceData() {

    const fetchGrievanceData = async (endpoint: string) => {
        try {

            let response = await axios.get(`${BASE_URL}Grievance${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const postGrievanceData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}Grievance${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const putGrievanceData = async (
        endpoint: string,
        payload: any
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}Grievance${endpoint}`, payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    const putGrievanceDataWithoutPayload = async (
        endpoint: string
    ) => {
        try {

            let response = await axios.put(`${BASE_URL}Grievance${endpoint}`);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

    return {
        fetchGrievanceData,
        postGrievanceData,
        putGrievanceData,
        putGrievanceDataWithoutPayload
    };

}
