import { Tooltip, Textarea, Image, Alert, Radio } from "@mantine/core";
import { GiCancel } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineHideImage } from "react-icons/md";

import { ESelectFileForQuestionBank } from "../../../../libs/ui";

import { BASE64_INITIALS } from "../../../../initialStates";
import { IQuestionFilters, IQuestionOption } from "../../../../types";

interface IProps {
  questionFilters: any;
  optionDetails: any;
  handleChangeOptionDetails: (
    key: any,
    value: string | File | undefined,
    index: number,
    childIndex: number
  ) => void;
  optionList: any[];
  handleAddNewOption: (option: any) => void;
  removeOption: (index: number, childIndex: number) => void;
  index: number;
  childIndex: number;
  handleSetCorrectOption: (index: number, childIndex: number) => void;
  handleSetCorrectOptionMultiple: (index: number, childIndex: number) => void;
}

export default function AddOptionFrameSpot({
  questionFilters,
  optionDetails,
  handleChangeOptionDetails,
  optionList,
  handleAddNewOption,
  removeOption,
  index,
  childIndex,
  handleSetCorrectOption,
  handleSetCorrectOptionMultiple,
}: IProps) {
  return (
    <div className="input-item">
      {optionDetails?.option_Text?.length > 0 ||
      optionDetails?.base64OptionImage?.length > 0 ? (
        <div className="radio-btn">
          <Radio
            size="md"
            color="green"
            checked={optionDetails.is_Correct}
            value={String(optionDetails.is_Correct)}
            onClick={() => {
              questionFilters?.question_Type_Id == 1
                ? handleSetCorrectOption(index, childIndex)
                : handleSetCorrectOptionMultiple(index, childIndex);
            }}
          />
        </div>
      ) : null}

      {/* SELECT IMAGE */}
      <div className="action-icons">
        {
          // INFO: if media type is "Image" or "Text/Image"
          questionFilters.option_Media_Type_Id != 1 ? (
            optionDetails.base64OptionImage ? (
              <Tooltip withArrow color="red" label="Remove selected image">
                <a>
                  <MdOutlineHideImage
                    onClick={() => {
                      handleChangeOptionDetails(
                        "base64OptionImage",
                        "",
                        index,
                        childIndex
                      );
                    }}
                  />
                </a>
              </Tooltip>
            ) : (
              <Tooltip withArrow label="Select image">
                <a>
                  <ESelectFileForQuestionBank
                    maxSizeInKB={2000000}
                    onClear={() => {}}
                    onSelect={(file) =>
                      handleChangeOptionDetails(
                        "base64OptionImage",
                        file,
                        index,
                        childIndex
                      )
                    }
                    dimensionsToMatch={{ width: 500, height: 500 }}
                    onSelectPreview={(preview) => {
                      // handleChangeOptionDetails("url", preview); console.log(preview)
                    }}
                  />
                </a>
              </Tooltip>
            )
          ) : (
            <></>
          )
        }

        {/* {optionDetails.text || optionDetails.file ? (
          <Tooltip withArrow label="Save option">
            <a>
              <TiTickOutline
                size={20}
                className="cursor-pointer"
                onClick={() =>
                  optionDetails.text || optionDetails.file
                    ? handleAddNewOption(optionDetails)
                    : console.log("Option text or file not provided")
                }
              />
            </a>
          </Tooltip>
        ) : (
          <></>
        )} */}

        <Tooltip withArrow color="red" label="Remove this option">
          <a
            className="cursor-pointer"
            onClick={() => removeOption(index, childIndex)}
          >
            <GiCancel size={20} />
          </a>
        </Tooltip>
      </div>

      {optionDetails?.base64OptionImage ? (
        <Image
          radius="md"
          src={`${BASE64_INITIALS}${optionDetails?.base64OptionImage}`}
          alt="Option Image"
          className="mt-5"
        />
      ) : (
        <></>
      )}

      {(questionFilters.option_Media_Type_Id == 3 ||
        questionFilters.option_Media_Type_Id == 5) &&
      !optionDetails.base64OptionImage ? (
        <Alert icon={<BsInfoCircle />} title="Missing Image!" color="orange">
          You need to select an image for this options
        </Alert>
      ) : (
        <></>
      )}

      {questionFilters.option_Media_Type_Id != 3 ? (
        <Textarea
          size="lg"
          variant="unstyled"
          autoFocus
          className={`w-full ${
            questionFilters.option_Media_Type_Id != 3 ? "ml-2" : ""
          }`}
          placeholder="Enter option here..."
          value={optionDetails.option_Text || ""}
          onChange={(e) =>
            handleChangeOptionDetails(
              "option_Text",
              e.target.value,
              index,
              childIndex
            )
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
}
