import React from 'react'
import PageLayout from '../../PageLayout'
import { EButton, EPageTitle } from '../../libs/ui'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import SurveyFilter from '../Survey/Component/SurveyFilter'
import { SurveyFilterContext } from '../Survey/SurveyFilterContext'
import useNotification from './hook/useNotifcation'
import { Checkbox, Grid, TextInput, Textarea } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import moment from 'moment'

export default function NewNotification() {
  const navigate = useNavigate()
  const searchButton = false;
  const {
    isLoading,
    isConfirm,
    setIsConfirm,
    selectedDistrict,
    setSelectedDistrict,
    selectedRole,
    setSelectedRole,
    setIsSearching,
    isSearching,
    newNotification,
    setNewNotification,
    handleSave
  } = useNotification()

  //  TODO : (when a user is select district and unselect its all block ) handle it with validator
  return (
    <PageLayout>
      <SurveyFilterContext.Provider
        value={{
          selectedDistrict,
          setSelectedDistrict,
          selectedRole,
          setSelectedRole,
          searchButton,
          isSearching,
          setIsSearching
        }}
      >
        <EPageTitle
          title='New Notification'
          end={
            <EButton
              className="btn-blue"
              onClick={() => navigate('/notification')}
            > <BsArrowLeft className='mr-2' /> Back</EButton>
          } />
        <SurveyFilter withBlocks studentWithClass />
       <Grid className='mt-2'>
          <Grid.Col span={6}>
            <TextInput
              label="Heading"
              placeholder='Enter heading text'
              size='md'
              required   
              value={newNotification?.heading_Text ?? ""}
              onChange={(e)=>setNewNotification(prev=>({...prev,heading_Text:e?.target?.value}))}
            />
             <Textarea
          className='mt-2'
          label="Description"
          placeholder='Enter description here'
          size='md'
          value={newNotification?.description ?? ""}
          onChange={(e=>setNewNotification(prev=>({...prev,description: e?.target?.value})))}
        />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput 
              label="Media Url"
              placeholder='Enter url'
              size='md'
              value={newNotification?.media_URL ?? ""}
              onChange={e=>setNewNotification(prev=>({...prev,media_URL:e?.target?.value}))}
            />
          </Grid.Col>
        </Grid>
        <div className="mt-12 border-t p-4 flex justify-between">
          <Checkbox
            label={"Are you sure you want to save?"}
            checked={isConfirm}
            onClick={() => setIsConfirm(!isConfirm)}
          />
          <EButton
            className="btn-blue"
            loading={isLoading}
            disabled={!isConfirm}
            onClick={() => handleSave()}
          >
            Save
          </EButton>
        </div>
      </SurveyFilterContext.Provider>
    </PageLayout>
  )
}
