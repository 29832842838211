import React from 'react'
import PageLayout from '../../../PageLayout';
import { EButton, EEmpty, EPageTitle, ETable } from '../../../libs/ui';
import { BsUpload } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Select, Skeleton } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import UseOrfQuestionList from './UseOrfQuestionList';
import { MdDelete, MdEdit } from 'react-icons/md';
import moment from 'moment';

const OrfQuestionListing = () => {
    const navigate = useNavigate()

    const {
      classes,
      subjectList,
      orfFilter,
      handleChangeOrf,
      isLoading,
      handleSearch,
      orfData,
    } = UseOrfQuestionList();
  return (
    <PageLayout>
      <EPageTitle
        title="ORF Questions"
        end={
          <EButton
            className="btn-light"
            rightIcon={<BsUpload />}
            onClick={() => navigate("/upload-spot-orf-question")}
          >
            Upload ORF Questions
          </EButton>
        }
      />

      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between items-end gap-4 w-full">
          <div className="flex gap-5">
            <Select
              required
              label="Select Grade"
              placeholder="Select"
              size="md"
              data={classes?.map((each) => ({
                value: String(each.id),
                label: each.name,
              }))}
              value={String(orfFilter.classID) || null}
              onChange={(e) => handleChangeOrf("classID", String(e))}
            />
            <Select
              required
              label="Subject"
              placeholder="Select"
              size="md"
              data={subjectList?.map((item) => ({
                value: String(item.id),
                label: item.name,
              }))}
              value={String(orfFilter.subject) || null}
              onChange={(e) => handleChangeOrf("subject", String(e))}
            />
            <DatePicker
              label="Select Start Date"
              placeholder="MM/DD/YYYY"
              value={orfFilter?.startDate}
              onChange={(e: any) => handleChangeOrf("startDate", e)}
              maxDate={
                orfFilter?.endDate ? new Date(orfFilter.endDate) : undefined
              }
            />
            <DatePicker
              label="Select End Date"
              placeholder="MM/DD/YYYY"
              value={orfFilter?.endDate}
              onChange={(e: any) => handleChangeOrf("endDate", e)}
              minDate={
                orfFilter?.startDate ? new Date(orfFilter.startDate) : undefined
              }
            />
          </div>
          <div className="flex gap-3">
            <EButton
              className="btn-blue"
              disabled={
                orfFilter?.classID &&
                orfFilter?.subject &&
                orfFilter?.startDate &&
                orfFilter?.endDate
                  ? false
                  : true
              }
              loading={isLoading}
              onClick={() => handleSearch()}
            >
              Search
            </EButton>
          </div>
        </div>
      </div>

      {/* TAble list */}
      <div className="mt-10">
        {isLoading ? (
          <Skeleton height={280} />
        ) : (
          <div>
            {!orfData?.length ? (
              // <></>
              <EEmpty title={"Select required filters and tap to search"} />
            ) : (
              <ETable>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Minimum Word Read Per Minute</th>
                    <th>Maximum Seconds To Read</th>
                    <th>Week Start Date</th>
                    <th>Week End Date</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {orfData?.map((e: any) => (
                    <tr>
                      <td>{e?.orF_Question_Text}</td>
                      <td>{e?.min_Word_Read_Per_Minute}</td>
                      <td>{e?.max_Seconds_To_Read}</td>
                      <td>{moment(e?.week_Start_Date).format("DD/MM/YYYY")}</td>
                      <td>{moment(e?.week_End_Date).format("DD/MM/YYYY")}</td>
                      {/* <td>
                        <div className="flex">
                          <MdEdit className="m-auto cursor-pointer text-blue-500" />
                          <MdDelete
                            className="m-auto cursor-pointer text-red-500"
                            // onClick={() => deleteConfirmFunc(item?.id)}
                          />
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </ETable>
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default OrfQuestionListing