import { Dispatch, SetStateAction, createContext } from "react";
import { IDesignationByRoleId, IDistrictBlock, IRole } from "../../types";

export interface ISurveyFilter {
    selectedDistrict:IDistrictBlock[];
    setSelectedDistrict: Dispatch<SetStateAction<IDistrictBlock[]>>;
    selectedRole: IRole[];
    setSelectedRole: Dispatch<SetStateAction<IRole[]>>;
    searchButton?: boolean;
    isSearching?: boolean;
    setIsSearching?: Dispatch<SetStateAction<boolean>>;
    classForStudent?: string[];
    setClassForStudent?: Dispatch<SetStateAction<string[]>>;
    // handleLogout: () => void;
};

export const SurveyFilterContext = createContext<ISurveyFilter>({
    selectedDistrict : [] ,
    setSelectedDistrict : ()=>{},
    selectedRole : [],
    setSelectedRole :()=>{},
    isSearching :false,
    setIsSearching:()=>{},
    searchButton: false,
    classForStudent: [],
    setClassForStudent: ()=>{}
});
