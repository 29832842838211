import React from 'react'
import PageLayout from '../../../../PageLayout'
import { EButton, EPageTitle, ESectionDivider } from '../../../../libs/ui'
import { useNavigate, useParams } from 'react-router-dom'
import useEditTrainingSlot from '../hooks/useEditTrainingSlot'
import { Card, Checkbox, Text, Grid, Group, Skeleton, TransferList, TransferListItemComponent, TransferListItemComponentProps, TextInput, Button } from '@mantine/core'
import InfoLabel from '../../../../libs/ui/src/InfoLabel/InfoLabel'
import moment from 'moment'
import { FcLeft } from 'react-icons/fc'
import { BsArrowLeft } from 'react-icons/bs'

export default function EditTrainingSlot() {
  const navigate = useNavigate()
  const param = useParams()
  const {
    isLoading,
    isLoadingTeacherList,
    isLoadingBtn,
    setIsLoadingBtn,
    isAnyChangeDone,
    setIsAndChangeDone,
    transferList,
    setTransferList,
    teacherTrainingSlot,
    setTeacherTrainingSlot,
    handleSubmit
  } = useEditTrainingSlot(param?.id ? param.id : "")

   //  interface for teacher selection transfer list

   const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
     <Group noWrap>
       {/* <Avatar src={data.image} radius="xl" size="lg" /> */}
       <div style={{ flex: 1 }}>
         <Text size="sm" weight={500}>
           {data.label}
         </Text>
         <Text size="xs" color="dimmed" weight={400}>
           {data.description}
         </Text>
       </div>
       <Checkbox
         checked={selected}
         onChange={() => { } }
         tabIndex={-1}
         sx={{ pointerEvents: 'none' }} />
     </Group>
   );
  return (
    <>
     <Button
          size='xs'
          className="btn-blue"
          onClick={() => navigate(`/state/training-slot`)}
        ><p className='flex gap-1 items-center'>
          <BsArrowLeft size={18}/> {"  "} <span>Back</span> 
          </p>      
        </Button>
    <PageLayout footer={ 
      <div className='flex justify-end gap-3 p-3'>
        <EButton
          className="btn-light"
          onClick={() => navigate(`/state/training-slot`)}
        >
          Cancel
        </EButton>
        <EButton
          loading={isLoadingBtn}
          className="btn-blue"
          disabled={!isAnyChangeDone}
          onClick={handleSubmit}>
          Update Slot
        </EButton>
      </div>
    }>
      <EPageTitle
        title={`Edit Training Slot`}
        description='Teacher training from August'
        // end={}
      />
      {
        isLoading ? 
        <Skeleton height={186} radius="md" /> :
         <div>
             <Grid>
              <Grid.Col span={6}>
              <TextInput
               label="Title"
               size="md"
               value={teacherTrainingSlot?.schedule_Header_Title ?? ""}
               onChange={(e)=>{
                 setTeacherTrainingSlot({...teacherTrainingSlot ,schedule_Header_Title : e?.target.value})
                 setIsAndChangeDone(true)
               }}
              />
              </Grid.Col>
              <Grid.Col span={3}>
                <TextInput
                 type="date"
                 label="Start Date"
                 size='md'
                 value={teacherTrainingSlot?.start_Date ? moment(teacherTrainingSlot?.start_Date).format("YYYY-MM-DD") : ""}
                 onChange={(e)=>{
                   setTeacherTrainingSlot({...teacherTrainingSlot ,start_Date: e?.target.value})
                   setIsAndChangeDone(true)
                 }}
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <TextInput
                 type="date"
                 label="End Date"
                 size='md'
                 value={teacherTrainingSlot?.end_Date ? moment(teacherTrainingSlot?.end_Date).format("YYYY-MM-DD") : ""}
                 onChange={(e)=>{
                  setTeacherTrainingSlot({...teacherTrainingSlot ,end_Date: e?.target.value})
                  setIsAndChangeDone(true)
                 }}
                />
              </Grid.Col>
             </Grid>
          <div>
          <ESectionDivider classes='pt-0' title='Training Slot Tests'/> 
        {
         isLoadingTeacherList ?
         <Skeleton height={200} radius="md" /> :
        <TransferList
            value={transferList}
            onChange={(value)=>{
              setTransferList(value)
              setIsAndChangeDone(true)
            }}
            searchPlaceholder="Search test..."
            nothingFound="No one here"
            titles={['Tests list', 'Selected test list for training']}
            listHeight={300}
            breakpoint="md"
            itemComponent={ItemComponent}
            filter={(query, item) =>
              item.label.toLowerCase().includes(query.toLowerCase().trim()) ||
              item.description.toLowerCase().includes(query.toLowerCase().trim())
            }
          />}
          </div>
         </div>
      }
    </PageLayout>
    </>
  )
}
