import moment from 'moment';
import { CSVLink } from 'react-csv';
import { DatePicker } from '@mantine/dates';
import { Select, Skeleton } from '@mantine/core';

import { EButton, EInfoPanel, EVerticalFormSection } from '../../../../../libs/ui';

import useMonthlyHindiObservationReportNewQuestions from '../Hooks/useMonthlyHindiObservationReportNewQuestions';

export default function MonthlyHindiObservationReportNewQuestions() {

  const {
    isLoading,
    selectedMonth,
    selectStartDate,
    setSelectedStartDate,
    selectEndDate,
    setSelectedEndDate,
    handleSelectMonth,
    setSelectedMonth,
    schoolWiseClassroomHindiObservationReport,
    schoolWiseClassroomHindiObservationCSV,
    fetchSchoolWiseClassroomHindiObservation,
    selectedSession,
    setSelectedSession,
  } = useMonthlyHindiObservationReportNewQuestions();

  return (
    <div>
      {/* FILTERS */}
      <div className="filter-container">
        <div className="flex justify-between w-full items-end">
          <div className="flex gap-3 items-end ">
            {/* <DatePicker
              label="Date"
              placeholder='Select date'
              clearable={false}
              value={selectStartDate ? new Date(selectStartDate) : undefined}
              onChange={(e) => {
                setSelectedEndDate(undefined)
                setSelectedStartDate(moment(e).format('YYYY-MM-DD') || undefined)
              }}
            /> */}

            <Select
              label="Select Session"
              placeholder="Select month"
              data={[
                { label: "2023-2024", value: "2024" },
                { label: "2024-2025", value: "2025" },
              ]}
              value={selectedSession}
              onChange={(e) => setSelectedSession(String(e))}
            />

            <Select
              label="Select month"
              placeholder="Select month"
              data={[
                {
                  label: "April",
                  value: "04",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 4
                      ? true
                      : false,
                },
                {
                  label: "May",
                  value: "05",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 5
                      ? true
                      : false,
                },
                {
                  label: "June",
                  value: "06",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 6
                      ? true
                      : false,
                },
                {
                  label: "July",
                  value: "07",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 7
                      ? true
                      : false,
                },
                {
                  label: "August",
                  value: "08",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 8
                      ? true
                      : false,
                },
                {
                  label: "September",
                  value: "09",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 9
                      ? true
                      : false,
                },
                {
                  label: "October",
                  value: "10",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 10
                      ? true
                      : false,
                },
                {
                  label: "November",
                  value: "11",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 11
                      ? true
                      : false,
                },
                {
                  label: "December",
                  value: "12",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY") &&
                    parseInt(moment().format("MM"), 10) < 12
                      ? true
                      : false,
                },
                {
                  label: "January",
                  value: "01",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY")
                      ? true
                      : false,
                },
                {
                  label: "February",
                  value: "02",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY")
                      ? true
                      : false,
                },
                {
                  label: "March",
                  value: "03",
                  disabled:
                    selectedSession == moment().add(1, "year").format("YYYY")
                      ? true
                      : false,
                },
              ]}
              value={selectedMonth}
              onChange={(e: any) => handleSelectMonth(String(e))}
            />
          </div>
          <EButton
            className="btn-blue"
            disabled={!selectedMonth}
            loading={isLoading}
            onClick={fetchSchoolWiseClassroomHindiObservation}
          >
            Search
          </EButton>
        </div>
      </div>

      {/* DATA */}
      <div className="mt-5">
        {isLoading ? (
          <Skeleton radius={"md"} height={250} />
        ) : schoolWiseClassroomHindiObservationReport.length ? (
          <EVerticalFormSection
            title="School Wise Classroom Observation Hindi Report"
            rightPanel={<></>}
          >
            <div>
              <EInfoPanel title=" Your report is ready to download, Please click on Download File to download report">
                <CSVLink
                  className="text-blue-600 text-sm hover:underline"
                  data={schoolWiseClassroomHindiObservationCSV?.content ?? []}
                  headers={
                    schoolWiseClassroomHindiObservationCSV?.headers ?? []
                  }
                  filename={`School__wise_Classroom_Hindi_Observation_Report_for_${selectStartDate}-${selectEndDate}.csv`}
                >
                  Download File
                </CSVLink>
              </EInfoPanel>
            </div>
          </EVerticalFormSection>
        ) : (
          <EInfoPanel title="Please select date range and press search to download report" />
        )}
      </div>
    </div>
  );

}
