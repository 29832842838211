import { Table } from '@mantine/core';
import './ETable.scss';

type Props = {
  hasEndButtons?: boolean;
  children: JSX.Element | JSX.Element[];
};

export function ETable({ children, hasEndButtons }: Props) {
  return (
    <Table
      highlightOnHover
      verticalSpacing={'md'}
      align="center"
      withColumnBorders={true}
      className={'e-table text-center ' + (hasEndButtons ? 'end-btns' : '')}
    >
      {children}
    </Table>
  );
}
