import React from 'react'

interface IProps {
    value: string | number | boolean | undefined;
    label?: string;
    textSize?: "sm"|"md"|"lg";
    fullWidth?: boolean;
    end?: JSX.Element;
  }

export default function InfoLabel({ label, value, fullWidth = false, end , textSize = "md" }: IProps) {

    // useEffect(() => {
    //   fullWidth ? fullWidth : (fullWidth = false);
    // }, [fullWidth]);
  
    return (
      <div className={`${fullWidth && 'col-span-full'}`}>
         <p className={`text-slate-400 mt-2 font-bold text-${textSize}`}>
        {label ? <span className={`text-slate-400`}>{label}{" : "}</span> : <></>}
                  <span className={`text-dark ${textSize === "lg" ? "text-md" : textSize ==="md" ? "text-sm" : textSize ==="sm" ? "text-xs" :"" }`}>                
          {value || '-'}
          {end ? <div>{end}</div> : <></>}
                  </span></p>
            </div>
    );
  }
