import React, { useEffect, useState } from 'react'
import { IDesignationByRoleId, IDistrictBlock, INewSurvey, INewSurveyOptionList, INewSurveyQuestion, INewSurveyQuestionGroup, INewTarget, IRole } from '../../../../types';
import { handleValidNewQuestion, handleValidNewSurvey } from '../../../../utils/validators';
import { useSurveyData } from '../../../../libs/data-access';
import { ISurveyQuestionTypeIds, ISurveySubQuestionTypeId } from '../../../../libs/data-access/types';
import { showToast } from '../../../../utils/utility';
import { useNavigate } from 'react-router-dom';

const defaultNewSurveyValue ={
  district_Id: [],
  role_Id: [],
  class_Id: [],
  designation_Id: [],
  survey_Title: "",
  survey_Description: "",
  survey_Start_Date: "",
  survey_End_Date: "",
  question_Groups: [] 
}

const DefaultNewQuestion ={
  survey_Question_Title: "",
  survey_Question_Description: "",
  survey_Question_Instructions: "",
  survey_Question_Type_Id: 0,
  survey_Sub_Question_Type_Id: 0,
  survey_Character_Limit: 0,
  survey_Options_List: []
}

const DefaultNewQuestionGroup ={
  question_Section_Title: "",
  question_Section_Description: "",
  questions: []
}

export default function useCreateNewSurvey() {

  const {
    fetchSurveyData,
    postSurveyData
  } = useSurveyData()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
  const [isLoadingQuestionId, setIsLoadingQuestionId] = useState<boolean>(false)
  const [isSavingSurvey, setIsSavingSurvey] = useState<boolean>(false)
  const [isFirst, setIsFirst] = useState<boolean>(true)

  const [isOpenNewQuestionModal,setISOpenNewQuestionModal]=useState<boolean>(false)
  const [confirmSaveSchedule,setConfirmSaveSchedule] =useState<boolean>(false);

  const [selectedDistrict, setSelectedDistrict] = useState<IDistrictBlock[]>([])
  const [selectedRole, setSelectedRole] = useState<IRole[]>([])
  const [classForStudent, setClassForStudent]= useState<string[]>([])
  
  const [surveyQuestionTypeIds,setSurveyQuestionTypeIds]= useState<ISurveyQuestionTypeIds[]>([])
  const [selectedQuestionTypeIds,setSelectedQuestionTypeIds]= useState<number | undefined>(undefined)
  const [surveySubQuestionTypeIds,setSubSurveyQuestionTypeIds]= useState<ISurveySubQuestionTypeId[]>([])
  const [selectedSubQuestionTypeIds,setSelectedSubQuestionTypeIds]= useState<number| undefined>(undefined)
  const [newSurvey,setNewSurvey]= useState<INewSurvey>(defaultNewSurveyValue)
  const [newQuestionGroup,setNewQuestionGroup] = useState<INewSurveyQuestionGroup>(DefaultNewQuestionGroup)
  const [newQuestion,setNewQuestion] = useState<INewSurveyQuestion>(DefaultNewQuestion)
  const [newOption,setNewOption] =useState<string>("")

  useEffect(()=>{
    if(isFirst){
      setIsFirst(false)
    }
    else{
      getSurveyQuestionId()
    }
  },[isFirst])
    
  // useEffect(()=>{
  //   selectedQuestionTypeIds ?  getSurveySubQuestionId() : setSubSurveyQuestionTypeIds([])
  // },[selectedQuestionTypeIds])

  // Handler function

    const handleAddNewQuestion = () =>{
      const isValid = handleValidNewQuestion(newQuestion)
      if(isValid){
          setNewQuestionGroup(prev => ({ ...prev, questions: [...prev?.questions, newQuestion] }))
          setNewQuestion(DefaultNewQuestion)
          setNewOption("")
          setISOpenNewQuestionModal(false)
        
      }
    }
    
    const handleSaveSurvey = ()=>{ 

         const payload : INewSurvey =
           {
             district_Id: selectedDistrict?.map(e=>e?.name),
             role_Id: selectedRole?.map(e=>String(e?.name)),
             class_Id: classForStudent?.map(e=>String(e)),
             designation_Id: selectedRole?.map(e=>e?.designation?.map(item=>String(item))).flat(),
             survey_Title: newSurvey?.survey_Title,
             survey_Description: newSurvey?.survey_Description,
             survey_Start_Date: newSurvey?.survey_Start_Date,
             survey_End_Date: newSurvey?.survey_End_Date,
             question_Groups: newSurvey?.question_Groups
           }  
         const isValid = handleValidNewSurvey(payload)
         if(isValid){
           postSurvey(payload)
            // console.log("Payload for new survey is ready " ,payload)
         }
        } 


  //  APi function

  const getSurveyQuestionId =async()=>{
     setIsLoadingQuestionId(true)
     const res = await fetchSurveyData(`GetSurveyQuestionType`)
      if(res.isSuccess){
        setSurveyQuestionTypeIds(res?.data)
      }
      else{
        setSurveyQuestionTypeIds([])
        showToast({
          type:"error",
          message:res?.message || "Couldn't get survey question id"
        })
      }
     setIsLoadingQuestionId(false)
  }
  const getSurveySubQuestionId =async()=>{
     setIsLoadingQuestionId(true)
     const res = await fetchSurveyData(`GetSurveyQuestionsSubType/${selectedQuestionTypeIds}`)
      if(res.isSuccess){
        setSubSurveyQuestionTypeIds(res?.data)
      }
      else{
        setSubSurveyQuestionTypeIds([])
        showToast({
          type:"error",
          message:res?.message || "Couldn't get survey sub question id"
        })
      }
     setIsLoadingQuestionId(false)
  }

  const postSurvey =async(payload : INewSurvey)=>{
     setIsBtnLoading(true)
     const res = await postSurveyData(`CreateSurevy`,payload)
     if(res.isSuccess){
       showToast({
        type:"success",
        title:"Successfully",
        message:"New survey posted successfully"
       })
       navigate(`/survey`)
     }
     else{
      showToast({
        type:"error",
        message: res?.message ?? "Fail to create new survey"
      })
     }
     setIsBtnLoading(false)
  }

  const getSurveyList = () => {
     setIsLoading(true)
     const res = fetchSurveyData(`GetSurevyById?surevy_Id=1`)
     setIsLoading(false)
  }

  const postNewSurvey = () => {
    setIsSavingSurvey(true)
    setIsSavingSurvey(false)
  }

 
  return {
    isLoading,
    isSavingSurvey,
    confirmSaveSchedule,
    setConfirmSaveSchedule,
    isOpenNewQuestionModal,
    setISOpenNewQuestionModal,
    DefaultNewQuestion,
    DefaultNewQuestionGroup,
    newSurvey,
    setNewSurvey,
    newQuestionGroup,
    setNewQuestionGroup,
    newQuestion,
    setNewQuestion,
    newOption,
    setNewOption,
    handleSaveSurvey,
    handleAddNewQuestion,
    surveyQuestionTypeIds,
    surveySubQuestionTypeIds,
    selectedQuestionTypeIds,
    setSelectedQuestionTypeIds,
    selectedSubQuestionTypeIds,
    setSelectedSubQuestionTypeIds,
    selectedRole,
    setSelectedRole,
    selectedDistrict,
    setSelectedDistrict,
    classForStudent,
    setClassForStudent
  }
}
