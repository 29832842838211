import axios from "axios";
import { BASE_URL } from "../../constant";

import { handleFormatResponse, handleFormatResponseForError } from "../../utility";

export function useStudentData() {

    const postStudentData = async (
        endpoint: string,
        payload?: any
    ) => {
        try {

            let response = await axios.post(`${BASE_URL}Student/${endpoint}`, payload && payload);
            // console.log("Response: ", response?.data);
            const formattedResponse = handleFormatResponse(response);
            return formattedResponse;

        } catch (error: any) {

            // console.log("Error: ", error);
            const formattedError = handleFormatResponseForError(error);
            return formattedError;

        }
    };

  return {
    postStudentData
  };

}
