import moment from "moment";
import { createContext, useEffect, useState } from "react";
import { calculateDefaultSession } from "../../../../../utils/utility";
import useMasterData from "../../../../Hooks/useMasterData";

interface IFilter {
    periodicAssessmentID: number | null;
    periodic: number | null;
    district: number | null;
    block: number | null;
    cluster: number | null;
    school: number | null;
    month: string | null;
    subject: any[];
    grade: number | null;
    session: number | null;
};

const initialFilterValues = {
    periodicAssessmentID: null,
    periodic: null,
    district: null,
    block: null,
    cluster: null,
    school: null,
    grade: 1,
    subject: [2],
    month: moment().format("MM"),
    session: 1
};

interface IFilterContext extends IFilter {
    isPeriodicResultLoading: boolean;
    isSearching: boolean;
    handleIsSearching: (e: boolean) => void
    handleChangeFilters: (
        key: keyof IFilterContext,
        value: number | string | boolean | any[] | null | undefined
    ) => void;
};

const initialFilterContextValues = {
    periodicAssessmentID: null,
    periodic: null,
    district: null,
    block: null,
    cluster: null,
    school: null,
    grade: 1,
    subject: [2],
    month: moment().format("MM"),
    handleIsSearching: () => { },
    handleChangeFilters: () => { },
    isPeriodicResultLoading: false,
    isSearching: false,
    session:1
};

export const PeriodicAssessmentFilterContext = createContext<IFilterContext>(initialFilterContextValues);

export default function usePeriodicAssessment() {
const { getSession, sessions } = useMasterData();
    const [filters, setFilters] = useState<IFilter>(initialFilterValues);
    const [activeTab, setActiveTab] = useState<string | null>('1');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [isPeriodicResultLoading, setIsPeriodicResultLoading] = useState<boolean>(false);

    // HANDLER FUNCTIONS
    const handleChangeFilters = (
        key: keyof IFilterContext,
        value: number | string | boolean | any[] | null | undefined
    ) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    };

    const handleSearch = () => {
        console.log("handle search clicked");
    };

    const handleIsSearching = (e: boolean) => {
        setIsSearching(e)
    };

    const handleResetFilters = () => {
        setFilters(initialFilterValues);
        setIsSearching(false);

    };


        useEffect(() => {
          if (sessions?.length > 0) {
            setFilters((prev: any) => ({
              ...prev,
              session: String(
                sessions?.find(
                  (option) =>
                    option?.session_Name ==
                    calculateDefaultSession(parseInt(moment().format("M")))
                )?.session_Id
              ),
            }));
          }
        }, [sessions]);

        useEffect(() => {
          getSession(false);
        }, []);

    return {
        filters,
        handleChangeFilters,
        handleSearch,
        handleIsSearching,
        isSearching,
        setIsSearching,
        isPeriodicResultLoading,
        handleResetFilters,
        activeTab,
        setActiveTab
    };

};
