import React from 'react';
import moment from 'moment';
import { useDisclosure } from '@mantine/hooks';
import { FaRegEdit, FaSave } from 'react-icons/fa';
import { IoIosAddCircleOutline } from "react-icons/io";
import { Checkbox, Modal, Skeleton, TextInput } from '@mantine/core';

import PageLayout from '../../../PageLayout';
import { EButton, EPageTitle, ETable } from '../../../libs/ui';

import useWorkingDays from './Hooks/useWorkingDays';

export default function WorkingDaysIndex() {

    const {
        opened,
        open,
        close,
        isLoading,
        hasConfirmed,
        setHasConfirmed,
        availableSessions,
        sessionWorkingDaysPayload,
        handleUpdateSessionWorkingDaysPayload,
        handleSubmit
    } = useWorkingDays();

    return (
        <PageLayout>
            <EPageTitle
                title="Working Days"
                end={<EButton
                    disabled
                    className="btn-light"
                    rightIcon={<IoIosAddCircleOutline />}
                    onClick={() => {
                        handleUpdateSessionWorkingDaysPayload("type", "create");
                        handleUpdateSessionWorkingDaysPayload("Session_Id", 1);
                        open();
                    }}
                >Add Working Days for New Session</EButton>}
            />

            {/* AVAILABLE SESSION WISE WORKING DAYS */}
            {isLoading
                ?
                <Skeleton height={200} radius="md" />
                :
                <ETable>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>No. of Working Days</th>
                            <th>Created On</th>
                            <th>Updated On</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {availableSessions.map((each, index) => (
                            <tr key={index + "swd"}>
                                <td>{index + 1}</td>
                                <td>{each.NumberOfWorkingDay}</td>
                                <td>{moment(each.CreatedOn).format("MMM Do YYYY")}</td>
                                <td>{each.UpdatedOn ? moment(each.UpdatedOn).format("MMM Do YYYY") : " - "}</td>
                                <td>
                                    <FaRegEdit
                                        color="green"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            handleUpdateSessionWorkingDaysPayload("type", "edit");
                                            handleUpdateSessionWorkingDaysPayload("Session_Id", each.Session_Id);
                                            handleUpdateSessionWorkingDaysPayload("NumberOfWorkingDay", each.NumberOfWorkingDay);
                                            open();
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </ETable>}

            {/* ADD/EDIT SESSION WORKING DAYS */}
            <Modal opened={opened} onClose={close} title="Edit Working Days">
                <TextInput
                    required
                    label="No. of Working days"
                    placeholder="Enter No. of Working Days"
                    value={sessionWorkingDaysPayload.NumberOfWorkingDay}
                    onChange={e => handleUpdateSessionWorkingDaysPayload("NumberOfWorkingDay", +e.target.value)}
                />
                <div className="mt-3">
                    <Checkbox
                        label="Are you sure want to save changes?"
                        checked={hasConfirmed}
                        onChange={e => setHasConfirmed(e.target.checked)}
                    />
                    <div className="flex justify-end">
                        <EButton
                            disabled={!hasConfirmed}
                            loading={isLoading}
                            icon={<FaSave />}
                            className="btn-blue"
                            onClick={() => handleSubmit()}
                        >Save</EButton>
                    </div>
                </div>
            </Modal>
        </PageLayout>
    )
}
