/* API RESPONSE BODY */
export interface IResponse<T> {
    status: boolean;
    message: string;
    response: T;
};

export interface IResponseFormat<T> {
    isSuccess: boolean;
    data: T;
    error: T;
    message?: string;
};


export const res: IResponseFormat<undefined> = {
    isSuccess: false,
    data: undefined,
    error: undefined,
    message: '',
};


/* LOGIN RESPONSE */
export interface ILoginDetails {
    user_Id: number;
    role_Id: number;
    role_Name: string;
    details?: ISchoolLoginDetails[] | IDepartmentUserDetails[];
};

export interface ISchoolLoginDetails {
    state_Name: string;
    district_Id: number;
    district_Name: string;
    block_Id: number;
    block_Name: string;
    cluster_School_Id: number;
    cluster_School_Name: string;
    cluster_School_Code: string;
    cluster_UDISE_Code: string;
    school_Id: number;
    school_Name: string;
    school_Code: string;
    udisE_Code: string;
    school_Contact: string;
    school_Address: string;
    medium: string;
    medium_Id: number;
    board_Id: number;
    board_Name: string | null;
    email_Id: string | null;
    school_Image_Url: string | null;
    phone_No: string | null;
    mobile_No: string | null;
    school_Longitude: string | null;
    school_Latitude: string | null;
};

export interface IMasterData {
    id: number;
    name: string;
};
export interface SessionIMasterData {
    session_Id: number;
    session_Name: string;
};
export interface CompetencyListIMasterData {
    id: number;
    text: string;
};

export interface IMediaType {
    media_Type_Id: number;
    media_Type: string;
};

export interface IClusterSchool {
    cluster_School_Id: number;
    cluster_School_Name: string;
    udise_Code: string;
};

export interface ISchool {
    school_Id: number;
    school_Name: string;
};

export interface ICompetencyByClassSubject {
    competancy_Id: number;
    assessment_type_Id: number;
    assessment_Type: string;
    subject_Name: string;
    competancy: string;
};

export interface ISpotCompetencyList {
    competency_Id: number;
    competency: string;
    group?: string
};

// DEPARTMENT LOGIN
export interface IDepartmentUserDetails {
    state_Name: string | null;
    email_Id: any;
    mobile_No: any;
};
export interface IDistrictUserDetails {
    district_Id: number;
    district_Name: string;
    email_Id: string | null;
    mobile_No: string | null;
    state_Name: string;
};

export interface IHelpDeskUserDetails {
    full_Name: string;
    state_Name: string;
    district_Id: number | null;
    district_Name: string | null;
    email_Id: string | null;
    mobile_No: string | null;
};

// SCHOOL LOGIN
export interface ITeacherAllocationDetails {
    assignedClass: string | null;
    assignedSection: string | null;
    teacher: string;
};

// BLOCK LOGIN
export interface IBlockLoginDetails {
    block_Id: number;
    block_Name: string;
    district_Id: number;
    district_Name: string;
    email_Id: string | null;
    mobile_No: string | null;
    state_Name: string;
};


export interface IPeriodicAssessmentScheduleList {
    periodic_Assessment_Schedule_Id: number;
    periodic_Assessment_Id: number;
    class_Id: number;
    subject_Id: number;
    class_Name: string;
    subject_Name: string;
    total_Number_Of_Questions: number;
    startDate: string;
    endDate: string;
    assessment_Type: string;
    orfQuestions:
    {
        periodic_ORF_Question_Id?: number
        orF_Question_Text: string;
        min_Word_Read_Per_Minute: number;
        max_Seconds_To_Read: string;
    }[]
    scheduleCompetency:
    {
        periodic_Assessment_Schedule_Competency_Id?: number;
        competency_Id: number;
        competency: string;
        number_Of_Question: number;
    }[]
}

export interface IPeriodicAssessmentSchedules {
    periodic_Assessment_Schedule_Id: number;
    periodic_Assessment_Id: number;
    class_Id: number;
    subject_Id: number;
    class_Name: string;
    subject_Name: string;
    number_Of_Questions: number;
    assessment: string | null;
    startDate: string;
    endDate: string;
    scheduleCompetancy: {
        competancy_Id: number;
        number_Of_Question: string;
        competency_name?: string;
    }[] | [];
    scheduleORFQuestion: {
        orF_Question_Text: string;
        min_Word_Read_Per_Minute: string;
        max_Seconds_To_Read: string;
    }[] | []

};

export interface IScheduleORFQuestion {
    orF_Question_Text: string;
    min_Word_Read_Per_Minute: string;
    max_Seconds_To_Read: string;
}


// ALL TEACHER LIST BY SCHOOL ID
export interface IAllTeacherListBySchoolId {
    school_Teacher_Id: number;
    teacher_Id: number;
    employee_Code: string;
    employee_Name: string;
    designation: string;
    allocation: IAllocatedClassSection[];
    currentAllocation?: {
        selectedClass_id: number;
        selectedSection_id: number;
    }
};

export interface IAllocatedClassSection {
    class_Id: number;
    class_Name: string;
    section_Id: number;
    section_Name: string;
    teacher_Class_Section_Id: number;
};

// REPORTS
export interface IVisitCompliance {
    total_School_Visit: string | null;
    total_School_Visit_LastMonth: string | null;
    total_Unique_School_Visit: string | null;
    total_Unique_School_Visit_LastMonth: string | null;
    total_Class_Observation: string | null;
    total_Class_Observation_LastMonth: string | null;
    no_Of_Mentor_Completed_Visit: string | null;
    no_Of_Mentor_Completed_Visit_LastMonth: string | null;
};

export interface IClassroomObservationByMentorReport {
    total_Enrollment_By_Mentor: string | null;
    average_Attendance_By_Mentor: string | null;
    total_Enrollment_By_Mentor_LastMonth: string | null;
    average_Attendance_By_Mentor_LastMonth: string | null;
};

//  --- REPORTS  --> STUDENE LEARNING
export interface IORFResult {
    District_Name: string;
    Block_Name: string;
    Cluster_School_Name: string;
    School_Name: string;
    Class_Name: string;
    Section_Name: string;
    Mentor_Samiksha_Id: string;
    Mentor_Name: string;
    Srn_No: string;
    Student_Name: string;
    Father_Name: string;
    Word_Read_Per_Minute: string;
    ORF_Attempted_Date: string;
};

export interface IDist {
    name: string;
}
export interface ISpotAssessmentResultData {
    [IDist: string]: {

        District_Name: string;
        Competency: string;
        Subject_Name: string;
        Month_Name: string;
        Students_Spot_Tested: number;
        Master_Students: number;
        Mastery_Status_Percentage: number;
        type: string;
        ORF_Question_Text?: string;
        percentage?: string;
        Block_Name?: string;
        Cluster_Name?: string;
        School_Name?: string;
    }[];
};

export type ISpotAssessmentDownloadReport = {
    District_Name: string;
    Block_Name: string;
    School_Name: string;
    Competency: string;
    Subject_Name: string;
    Month_Name: string;
    Students_Spot_Tested: number;
    Master_Students: number;
    Mastery_Status_Percentage: number;
}

export type ISpotAssessmentCompletedData = {
  District_Name?: string;
  Block_Name?: string;
  Cluster_School_Name?: string;
  School_Name?: string;
  Total_Mentor: number;
  Total_Spot_Mentor: number;
  Total_Spot_Completed: number;
  TotalNumberOfMentorThoseCompletedTarget: number;
  PercentageOfSpotMentor: string;
};

// {
//     Total_Mentor: number
//     Total_Spot: number
//     Assessment_Student_Percentage: number
//     TargetSpotTested: number
//     District_Name?: string;
//     Block_Name?: string;
//     Cluster_School_Name?: string;
//     School_Name?: string;
// }

export type ISpotAssessmentCompleteExcelReport = {
    Mentor_Name: string;
    Subject_Name: string;
    School_Name: string;
    Cluster_School_Name: string;
    Block_Name: string;
    District_Name: string;
    TOTAL_SPOT_COMPLETED: number;
}

export type ISpotAssessmentCompletedMentorWiseExcelReport = {
    Mentor_Name: string
    Schools: string[];
    Clusters: string[];
    Blocks: string[];
    District_Name: string;
    TOTAL_SPOT_COMPLETED: number;
    TOTAL_SPOT_PERCENTAGE: number;
    TOTAL_SPOT_Target: number;
}

export type ISpotAssessmentUniqueStudentData = {
    Total_Student: number;
    Total_Assessment: number;
    Assessment_Student_Percentage: number;
    District_Name?: string;
    Block_Name?: string;
    Cluster_School_Name?: string;
    School_Name?: string;
}

export type ISpotAssessmentUniqueStudentExcelReport = {
    School_Name: string
    Cluster_School_Name: string;
    Block_Name: string;
    District_Name: string;
    Total_Student: number;
    Total_Assessment: number;
    Assessment_Student_Percentage: number;
}



//Ticket - Grievance
export interface ITicket {
    ticket_Id: number;
    ticket_Number: string;
    grievance_Category_Id: number;
    user_Id: number;
    name: string;
    role_Id: number;
    role_Name: string;
    grievanceForRole: string;
    category_Name: string;
    grievance_Query: string;
    date_Of_Issue: string;
    date_Of_Solved: string | null;
    contact_Number: string;
    ticket_Status: "Open" | "Closed";
    district_Name: string;
    block_Name: string;
}

export interface ITicketDetails extends Required<ITicket> {
    grievance_Reply: string;
}

export interface IGrievanceCategory {
    grievance_Category_Id: number;
    category_Name: string;
}

export interface IGrievanceTicket {
    ticket_Id: number;
    ticket_Number: string;
    grievance_Category_Id: number;
    grievanceForRole: string;
    category_Name: string;
    grievance_Query: string;
    grievance_Reply: string;
    date_Of_Issue: string;
    date_Of_Solved: string;
    ticket_Status: string;
}

/* REPORTS */
// export interface IVisitComplianceByDistrict {
//     district_Name: string;
//     number_Of_Mentors: string;
//     total_Classroom_Observations: string;
//     total_School_Visit: string;
//     total_Student_Spot_Tested: string;
//     total_Unique_School_Visit: string;
// };
export interface IVisitComplianceByDistrict {
    Name: string;
    Number_Of_Mentors: number;
    Total_Classroom_Observations: number;
    Total_Unique_School_Visit: number;
    Total_School_Visit: number;
    Total_Student_Spot_Tested: number;
};

export interface IMentorWiseVisitComplianceReportByMonth {
    District_Name: string;
    Block_Name: string;
    Cluster_School_Name: string;
    Unique_Code: string;
    Visit_Month: string;
    Total_Classroom_Observations: number;
    Total_Unique_School_Visit: number;
    Total_School_Visit: number;
    Total_Student_Spot_Tested: number;
}
export interface IDateWiseMentorVisitComplianceReportByDate {
    District_Name: string;
    Block_Name: string;
    Cluster_School_Name: string;
    School_Name: string;
    Unique_Code: string;
    Visit_Date: string;
    Total_Classroom_Observations: number;
    Total_Student_Spot_Tested: number;
}

export interface IClassroomHindiObservationReport {
    district_Name: string;
    q1_Option1: string;
    q1_Option2: string;
    q1_Option3: string;
    q2_Average_Week: string;
    q3_Option1: string;
    q3_Option2: string;
    q3_Option3: string;
    q4_Option1: string;
    q4_Option2: string;
    q4_Option3: string;
}
export interface IClassroomMathObservationReport {
    district_Name: string;
    q1_Option1: string;
    q1_Option2: string;
    q1_Option3: string;
    q2_Option1: string;
    q2_Option2: string;
    q3_Option1: string;
    q3_Option2: string;
    q3_Option3: string;
}
export interface ISchoolWiseClassroomMathObservationReport {
    district_Name: string;
    block_Name: string;
    cluster_School_Name: string;
    school_Name: string;
    udisE_Code: string;
    q1_Option1: string;
    q1_Option2: string;
    q1_Option3: string;
    q2_Option1: string;
    q2_Option2: string;
    q3_Option1: string;
    q3_Option2: string;
    q3_Option3: string;
}
export interface ISchoolWiseClassroomHindiObservationReport {
    district_Name: string;
    block_Name: string;
    cluster_School_Name: string;
    school_Name: string;
    udisE_Code: string;
    q1_Option1: string;
    q1_Option2: string;
    q1_Option3: string;
    q2_Average_Week: string;
    q3_Option1: string;
    q3_Option2: string;
    q3_Option3: string;
    q4_Option1: string;
    q4_Option2: string;
    q4_Option3: string;
};

export interface IClassroomObservationThresholdReport {
    district_Name: string;
    block_Name: string;
    mentor_Name: string;
    unique_Code: string;
    visit_Month: string;
    total_Classroom_Observations: number;
    target_Classroom_Observation: number;
    observation_Percentage: string;
};

export interface IClassroomObservationThresholdTableData {
    name: string;
    observation_Mentor_Number: number;
    total_Classroom_Observation: number;
    toatal_Mentor: number;
    total_Target_Classroom_Obervation: number;
    target_Observation_Percentage: string;
}

export interface IUniqueSchoolVisits {
    totalUniqSchool: number;
    totalSchool: number;
    total_percentage: string;
    report:
    {
        Name: string;
        Unique_School_Visit: number;
        Total_School: number;
        Percentage: string;
    }[]
}
export interface IUniqueSchoolMentorWiseVisitThresholdReport {
    district_Name: string;
    block_Name: string;
    mentor_Name: string;
    unique_Code: string;
    visit_Month: string;
    target_Unique_School_Visit: number;
    total_School_Visit: number;
    unique_School_Visit: number;
    unique_School_Visit_Percentage: string;
};

export interface IUniqueSchoolMentorWiseVisitThresholdReportTableData {
    name: string;
    total_School: string;
    total_Unique_School_Visit: string;
    total_School_Visit: string;
    unique_School_Target_Completed_By_Mentor: string;
}

export type IMentorWiseClassroomObservation =
    {
        district_Name: string
        block_Name: string
        mentor_Name: string
        unique_Code: string
        visit_Month: string
        total_Classroom_Observations: number;
        target_Classroom_Observation: number;
        observation_Percentage: string;
    }

export type IUniqueSchoolVisitCompliance = {
    district_Name: string;
    block_Name: string;
    mentor_Name: string;
    unique_Code: string;
    visit_Month: string;
    target_Unique_School_Visit: number;
    total_School_Visit: number;
    unique_School_Visit: number;
    school_Visit_Percentage: string;
}

// Teacher Training Report

export type ITrainingData = {
    Training_Description: string;
    Name: string;
    Targeted_Teacher: number;
    Enroll_Teacher_Percentage: number;
    Completion_Percentage: number;
}

// Teacher Training Slot 

export type INewTrainingSlot = {
    state_User_Id: number;
    schedule_Header_Title: string;
    start_Date: string;
    end_Date: string | undefined;
    description: string;
    no_Of_Days: number;
    headerTest: any[] |
    {
        teacher_Training_Test_Id: number;
    }[]
}

export type ITrainingSlotList = {
    training_Schedule_Header_Id: number;
    schedule_Header_Title: string;
    start_Date: string;
    end_Date: string;
    no_Of_Days: number;
    training_Description: string;
    tests:
    {
        schedule_Header_Test_Id: number;
        test_Name: string;
        test_Type: string;
        subject_Id: number;
        subject_Name: string;
    }[]
}

export type ITeacherTrainingSlotTestList = {
    teacher_Training_Test_Id: number;
    subject_Id: number;
    subject_Name: string;
    test_Name: string;
    test_Type: string;
    is_Active: boolean;
    description: string;
}

export type IScheduleList = {
    training_Schedule_Header_Id: number;
    schedule_Header_Title: string;
    no_Of_Days: number;
    start_Date: string;
    end_Date: string;
    subject_Details: {
        subject_Id: number;
        subject_Name: string;
    }[];
    scheduleDetails: ITeacherTrainingScheduleDetails[];
};

export interface ITeacherTrainingScheduleDetails {
    teacher_Training_Schedule_Id: number;
    training_Title: string;
    training_Start_Date: string;
    training_End_Date: string;
    training_Start_Time: string;
    training_End_Time: string | null;
    training_Place: string;
    is_Training_End: boolean;
    block_Name: string;
    training_Description: string;
    testDetails: ITeacherTrainingTetDetails[];
};

export interface ISelectedTeacherTrainingScheduleDetails {
    teacher_Training_Schedule_Id: number;
    training_Title: string;
    training_Start_Date: string;
    training_End_Date: string;
    training_Start_Time: string;
    training_End_Time: string;
    // is_Attendance_Done: boolean;
    is_Training_End: boolean;
    block_Name: string;
    training_Description: string;
    training_Place: string;
    tests: ITeacherTrainingTetDetails[];
};

export interface ITeacherTrainingTetDetails {
    teacher_Training_Schedule_Test_Id: number;
    test_Name: string;
    test_Type: "Pre" | "Post";
    subject_Id: number;
    subject_Name: string;
    is_Test_Done: boolean;
    is_Active: boolean;
};

export type IPutScheduleDetails = {
    teacher_Training_Schedule_Id: number;
    block_Admin_User_Id: number;
    training_Title: string;
    training_Start_Date: string;
    training_End_Date: string;
    training_Place: string;
    training_Start_Time: string;
    training_End_Time: string;
    training_Description: string;
}

export type IAllocatedTeacherList = {
    training_Schedule_Teacher_Id: number;
    teacher_Id: number;
    employee_Code: string;
    employee_Name: string;
    designation: string;
}

export type ITeacherList = {
    training_Schedule_Teacher_Id: number;
    teacher_Id: number;
    employee_Code: string;
    employee_Name: string;
    designation: string;
    is_Latest_Attendance_Done: boolean;
    is_Present: boolean;
}

export type ITeacherMarkAttendanceList = {
    training_Schedule_Attendance_Id: number;
    training_Title: string;
    attendance_Date: string;
    is_Attendance_Done: boolean;
}

export type ILastAttendanceDetails = {
    schedule_Teacher_Attendance_Id: number;
    employee_Code: string;
    employee_Name: string;
    designation: string;
    attendance_Date_Time: string;
    is_Present: boolean;
}

export type ITeacherAllocationPayload = {
    teacher_Training_Schedule_Id: number;
    block_Admin_User_Id: number;
    teacherSelection:
    {
        teacher_Id: number;
    }[]
}

export type IPostTeacherAttendance = {
    teacher_Training_Schedule_Id: number;
    block_Admin_User_Id: number;
    teacherSelection:
    {
        training_Schedule_Teacher_Id: number;
        is_Present: boolean;
    }[] | any[]
}

export type IEditTeacherAttendance = {
    training_Schedule_Attendance_Id: number;
    block_Admin_User_Id: number;
    teacherSelection:
    {
        training_Schedule_Teacher_Id: number;
        is_Present: boolean;
    }[]
}

//   Question Option Slot test
export type questionOptions =
    {
        base64OptionImage: string;
        option_Text: string;
        is_Correct: boolean
    }

export type testQuestions =
    {
        base64QuestionImage: string;
        question_Text: string;
        question_Type_Id: number;
        media_Type_Id: number;
        option_Media_Type_Id: number;
        question_Marks: number;
        question_Media_Url: string;
        questionOptions: questionOptions[]

    }

export type INewTestQuestionOption = {
    schedule_Header_Test_Id: number;
    question_Group_Name: string;
    state_Admin_User_Id: number;
    testQuestions: testQuestions[]
}

export type ISlotTestQuestionListQuestionOption = {
    base64OptionImage: string;
    question_Option_Id: number;
    option_Media_Type_Id: number;
    option_Media_Type: string;
    option_Text: string;
    option_Media_Url: string;
    is_Correct: boolean;
}
export type ISlotTestQuestionListQuestion =
    {
        question_Id: number;
        base64QuestionImage: string;
        question_Media_Url: string;
        question_Type: string;
        question_Text: string;
        question_Media_Type: string;
        questionsOptions: ISlotTestQuestionListQuestionOption[];

    }

export type ISlotTestQuestionList =
    {
        question_Group_Id: number;
        question_Group_Name: string;
        questions: ISlotTestQuestionListQuestion[];
    }

{/* Target manage types */ }

export type ITargetList = {
    monthly_Target_Id: number;
    target_Title: string;
    role_Id: number;
    designation_Id: number;
    role_Name: string;
    designation: string;
    target_Unique_School_Visit: number;
    target_Classroom_Observation: number;
    target_Spot_Assessment: number;
    target_Month: string;
    target_Year: string;
}

export type IEditTarget = {
    monthly_Target_Id: number;
    state_Admin_User_Id: number;
    target_Title: string;
    target_Unique_School_Visit: number;
    target_Classroom_Observation: number;
    target_Spot_Assessment: number;
}

{/* Survey page */ }

export type ISurveyQuestionTypeIds = {
    survey_Question_Type_Id: number;
    survey_Question_Type: string;
}

export type ISurveySubQuestionTypeId = {
    survey_Sub_Question_Type_Id: number;
    survey_Sub_Question_Type: string;
}

export type ISurveyList = {
    survey_Id: number;
    survey_Title: string;
    survey_Description: string;
    survey_Start_Date: string;
    survey_End_Date: string;
    role_Id: string[],
    designation_Id: string[],
    district_Id: string[]
}

export type ISurveyDetailsByID = {
    survey_Id: number;
    survey_Title: string;
    survey_Description: string;
    survey_Start_Date: string;
    survey_End_Date: string;
    role_Id: string[],
    designation_Id: string[],
    district_Id: string[]
    question_Groups:
    {
        question_Section_Title: string;
        question_Section_Description: string;
        questions:
        {
            survey_Question_Title: string;
            survey_Question_Description: string;
            survey_Question_Instructions: string;
            survey_Question_Type_Id: number;
            survey_Sub_Question_Type_Id: number;
            survey_Options_List:
            {
                survey_Options: string;
            }[]
            survey_Character_Limit: number;
        }[]
    }[]
}

{/* Dashboard page */ }


export type IDashboardMap = {
    title: string;
    state: number;
    map:
    {
        name: string;
        value: number
    }[]
}

export type IReportLevel = {
    levelNames: "state" | "district" | "block" | "cluster";
    districtName: string;
    districtId: number;
    blockName: string;
}

// Dashboard - Classroom Observation

export type IDashboardMapData = {
    OverAll: {
        Name: string;
        Targeted_classroom_observations: number;
        Total_classroom_observations_done_in_that_month: number;
        Total_unique_classroom_observations: number;
        Percentage_of_classrooms_observed: number;
    },
    Map:
    {
        Name: string;
        Targeted_classroom_observations: number;
        Total_classroom_observations_done_in_that_month: number;
        Total_unique_classroom_observations: number;
        Percentage_of_classrooms_observed: number;
    }[]
}

export type IClassroomObservationTeacherObservationCoverage = {
    OverAll: {
        Name: string;
        Total_teachers: number;
        Total_unique_teachers_covered: number;
        Percentage_teachers_observed: number;
    },
    Map:
    {
        Name: string;
        Total_Teacher: number;
        Total_unique_teachers_covered: number;
        Percentage_teachers_observed: number;
    }[]
}

export type IClassroomObservationMentorObservedCompliance = {
    OverAll: {
        Name: string;
        Total_mentors: number;
        Total_mentors_completing_their_targets: number;
        Percentage_mentor_completing_target: number;
    },
    Map:
    {
        Name: string
        Total_mentors: number;
        Total_mentors_completing_their_targets: number;
        Percentage_mentor_completing_target: number;
    }[]
}

// Dashboard - School Visits Compliance

export type ISchoolVisitsUniqueSchoolVisitCoverage = {
    OverAll: {
        Name: string;
        Total_schools: number;
        Total_school_visits_conducted: number;
        Total_unique_schools_visited: number;
        Percentage_of_Schools_visited: number;
    },
    Map:
    {
        Name: string;
        Total_schools: number;
        Total_school_visits_conducted: number;
        Total_unique_schools_visited: number;
        Percentage_of_Schools_visited: number;
    }[]
}

export type ISchoolVisitsMentorVisitsCompliance = {
    OverAll: {
        Name: string;
        Total_mentors: number;
        Total_mentors_completing_their_targets: number;
        Percentage_mentors_completing_targets: number;
    },
    Map:
    {
        Name: string;
        Total_mentors: number;
        Total_mentors_completing_their_targets: number;
        Percentage_mentors_completing_targets: number;
    }[]
}

// Dashboard - Mentoring Compliance

export type IAverageClassroomObservations = {
    OverAll: {
        Name: string;
        Targeted_classroom_observation: number;
        Total_classroom_observation: number;
        Average_Classroom_Observation: number;
    },
    Map:
    {
        Name: string;
        Targeted_classroom_observation: number;
        Total_classroom_observation: number;
        Average_Classroom_Observation: number;
    }[]
}

export type IAverageUniqueSchoolVisits = {
    OverAll: {
        Name: string;
        Targeted_school_visit: number;
        Total_school_visit: number;
        Average_School_Visit: number;
    },
    Map:
    {
        Name: string;
        Targeted_school_visit: number;
        Total_school_visit: number;
        Average_Classroom_Observation: number;
    }[]
}

export type IAverageSpotAssessments = {
    OverAll: {
        Name: string;
        Targeted_spot_assessment: number;
        Total_spot_assessment: number;
        Average_Total_spot_assessment: number;
    },
    Map:
    {
        Name: string;
        Targeted_spot_assessment: number;
        Total_spot_assessment: number;
        Average_spot_assessment: number;
    }[]
}

export type IMentorsCompletingTargetedClassroomObservations = {
    OverAll: {
        Name: string;
        Total_Mentor: number;
        Mentor_Completing_Target: number;
        Compliance_Percentage: number;
    },
    Map:
    {
        Name: string;
        Total_Mentor: number;
        Mentor_Completing_Target: number;
        Compliance_Percentage: number;
    }[]
}
export type IMentorsCompletingTargetedUniqueSchoolVisit = {
    OverAll: {
        Name: string;
        Total_Mentor: number;
        Mentor_Completing_Target: number;
        Compliance_Percentage: number;
    },
    Map:
    {
        Name: string;
        Total_Mentor: number;
        Mentor_Completing_Target: number;
        Compliance_Percentage: number;
    }[]
}

// Monitors

export type IAverageNoOfClassroomObservedByMonitorPercentage = {
    OverAll: {
        Name: string;
        Targeted_classroom_observation: number;
        Total_classroom_observation: number;
        Average_Classroom_Observation: number;
    },
    Map:
    {
        Name: string;
        Targeted_classroom_observation: number;
        Total_classroom_observation: number;
        Average_Classroom_Observation: number;
    }[]
}
export type ISchoolsVisitedByMonitorPercentage = {
    OverAll: {
        Name: string;
        Targeted_school_visit: number;
        Total_school_visit: number;
        Average_School_Visit: number;
    },
    Map:
    {
        Name: string;
        Targeted_school_visit: number;
        Total_school_visit: number;
        Average_Classroom_Observation: number;
    }[]
}
export type ISpotAssessmentConductedByMonitor = {
    OverAll: {
        Name: string;
        Targeted_spot_assessment: number;
        Total_spot_assessment: number;
        Average_Total_spot_assessment: number;
    },
    Map:
    {
        Name: string;
        Targeted_spot_assessment: number;
        Total_spot_assessment: number;
        Average_spot_assessment: number;
    }[]
}
export type ITargetedClassRoomObservation = {
    OverAll: {
        Name: string;
        Total_Monitor: number;
        Monitor_Completing_Target: number;
        Compliance_Percentage: number;
    },
    Map:
    {
        Name: string;
        Total_Monitor: number;
        Monitor_Completing_Target: number
        Compliance_Percentage: number;
    }[]
}
export type ITargetedSchoolVisit = {
    OverAll: {
        Name: string;
        Total_Monitor: number;
        Monitor_Completing_Target: number;
        Compliance_Percentage: number;
    },
    Map:
    {
        Name: string;
        Total_Monitor: number;
        Monitor_Completing_Target: number;
        Compliance_Percentage: number;
    }[]
}


export type ILearningIndicator = {
    [key: string]: {
        ColName: string;
        Question: string;
        Value: number;
    }[];
}

export type IClassroomAndSchoolCompliance = {
    Unique_School_Percentage: number;
    Classroom_Observation_Percentage: number;
    Month_Name: string;
    Year_Name: number;
    MonthD: number;
}

export type IObservationCompliance = {
    ColName: string;
    MonthD: number;
    Month_Name: string;
    Question: string;
    Year_Name: number;
    Value: number;
}

export type GroupedData = {
    [key: number]: IObservationCompliance[]; // Assuming each month's data is an array of DataItem
  }

  export type SeriesData = {
    name: string;
    data: number[];
    customText?: string; // Make customText optional
  }

export type StudentLearningDashboard = {
    class_Name:string;
    literacy:string;
    numeracy:string;
    both:string;
}

export type IHaryanaOverALlStats = {
    Data: string;
    Student: number;
    Employee: number;
    School: number;
};

export interface ISessionWiseWorkingDays {
    CreatedOn: string;
    WorkingSession_Id: number;
    Session_Id: number;
    NumberOfWorkingDay: number;
    UpdatedOn: string | null;
};