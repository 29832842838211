import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

import { EAppShell, ENavbar, ETopbar } from "./libs/ui";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";

import "./App.scss";

import { AppContext } from "./AppContext";
import useApp from "./Pages/Hooks/useApp";
import RootRoutes from "./Routes/RootRoutes";
import axios from "axios";

function App() {
  
  const targetRef = useRef<HTMLDivElement>(null);
  const {
    user,
    handleUser,
    handleUserDetails,
    isSidebarCollapsed,
    setIsSidebarCollapsed,
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken,
    role,
    setRole,
    handleLogout,
    handleContextStateUpdateOnMount,
    handleContextStateUpdateOnStateChange,
    currentRotes,
    setCurrentRoutes,
    scrollTriger,
    setScrollTriger
  } = useApp();

  useEffect(() => {
    handleContextStateUpdateOnMount();
  }, []);

  useEffect(() => {
    handleContextStateUpdateOnStateChange();
  }, [
    user,
    isSidebarCollapsed,
    isLoggedIn,
    token,
    role
  ]);


 
  

  const getToken = () => {
    let tkn = localStorage.getItem("token");
    return tkn || token;
  };

  // AXIOS INTERCEPTOR START
  // -- interceptor for each requests --
  axios.interceptors.request.use(
    (request: any) => {
      if (request.url.includes("Login")) {

      }else{
        request.headers['Authorization'] = `Bearer ${getToken()}`;
      }
      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  // -- interceptor for each responce --
  axios.interceptors.response.use(undefined, function axiosRetryIntercptor(err) {
    if (err?.response?.status === 401) {
      console.error("Oops...!", "You are unauthorized");
      console.log(err.response);
    }
    return Promise.reject(err);
  }
  );
  // AXIOS INTERCEPTOR ENDS

  return (
    // AppContext Wrapper
    <AppContext.Provider
      value={{
        user,
        handleUser,
        handleUserDetails,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isLoggedIn,
        setIsLoggedIn,
        token,
        setToken,
        handleLogout,
        role,
        setRole,
        currentRotes,
        setCurrentRoutes,
        targetRef,
        scrollTriger,
        setScrollTriger
      }}
    >
      {/* Mantine Wrapper */}
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          // Font sizes in px, other units are not supported
          fontFamily: 'Inter, sans-serif',
          fontFamilyMonospace: 'Monaco, Courier, monospace',
          fontSizes: {
            xs: 12,
            sm: 14,
            md: 16,
            lg: 18,
            xl: 22,
          },
        }}
      >
        {/* Mantine Notification Wrapper */}
        <NotificationsProvider position={"top-right"}>
          {/* App Level Error Boundary */}
          <ErrorBoundaryWrapper>
            {isLoggedIn && token ? (
              <EAppShell
                navbar={<ENavbar />}
                topbar={<ETopbar />}
              // footer={}
              >
                <RootRoutes />
              </EAppShell>
            ) : (
              <RootRoutes />
            )}
          </ErrorBoundaryWrapper>
        </NotificationsProvider>
      </MantineProvider>
    </AppContext.Provider>
  );
}

export default App;
