import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { MdDelete } from "react-icons/md";
import { Accordion, Grid, Badge } from '@mantine/core';

import { ETable } from '../../../../../../libs/ui';
import { IScheduleList, ITeacherTrainingScheduleDetails } from '../../../../../../libs/data-access/types';

interface IProps {
  schedule: IScheduleList;
  setIsOpenSideDrawer: Dispatch<React.SetStateAction<boolean>>;
  setSelectedSlot: Dispatch<React.SetStateAction<number | undefined>>;
  setSelectedSchedule: Dispatch<
    React.SetStateAction<ITeacherTrainingScheduleDetails | undefined>
  >;
  deleteSchedule:any
};

export default function ScheduleListAccordian({
  schedule,
  setIsOpenSideDrawer,
  setSelectedSlot,
  setSelectedSchedule,
  deleteSchedule,
}: IProps) {
  return (
    <>
      <Accordion.Item value={schedule.schedule_Header_Title || " - "}>
        <Accordion.Control>
          {/* SLOT DETAILS */}
          <Grid className="flex items-center">
            <Grid.Col span={4} className="flex flex-col">
              <span className="text-slate-400 text-sm">Title</span>
              <span className="text-slate-600">
                {schedule.schedule_Header_Title || " - "}
              </span>
            </Grid.Col>
            <Grid.Col span={4} className="flex flex-col">
              <span className="text-slate-400 text-sm">Start Date</span>
              <span className="font-semibold">
                {moment(schedule.start_Date).format("MMM Do YYYY") || " - "}
              </span>
            </Grid.Col>
            <Grid.Col span={4} className="flex flex-col">
              <span className="text-slate-400 text-sm">End Date</span>
              <span className="font-semibold">
                {moment(schedule.end_Date).format("MMM Do YYYY") || " - "}
              </span>
            </Grid.Col>
          </Grid>
          <Grid className="flex items-center mb-3">
            <Grid.Col span={4} className="flex flex-col">
              <span className="text-slate-400 text-sm">No. of Days</span>
              <span className="font-semibold">
                {schedule.no_Of_Days || " - "}
              </span>
            </Grid.Col>
            <Grid.Col span={6} className="flex flex-col">
              <span className="text-slate-400 text-sm">Subjects</span>
              <span className="flex flex-wrap gap-1">
                {schedule.subject_Details.map((each, index) => (
                  <Badge
                    key={"subject" + index}
                    variant="outline"
                    size="sm"
                    color="teal"
                  >
                    {each.subject_Name}
                  </Badge>
                ))}
              </span>
            </Grid.Col>
          </Grid>
        </Accordion.Control>

        <Accordion.Panel>
          {/* POSTINGS */}
          <ETable hasEndButtons>
            <thead>
              <tr>
                <th>Title</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Start Time</th>
                <th>Block</th>
                <th>Place</th>
                <th>Has Ended</th>
                <th>Tests</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {schedule.scheduleDetails.map((each, index) => (
                <tr key={"schedule" + index}>
                  <td>{each.training_Title || " - "}</td>
                  <td>
                    {moment(each.training_Start_Date).format("MMM Do YYYY") ||
                      " - "}
                  </td>
                  <td>
                    {moment(each.training_End_Date).format("MMM Do YYYY") ||
                      " - "}
                  </td>
                  <td>{each.training_Start_Time || " - "}</td>
                  <td>{each.block_Name || " - "}</td>
                  <td>{each.training_Place || " - "}</td>
                  <td>{each.is_Training_End || " - "}</td>
                  <td>
                    <div className="flex flex-wrap gap-1">
                      {each.testDetails.map((each, i) => (
                        <Badge key={"test" + i} variant="outline" size="sm">
                          {each.test_Name}
                        </Badge>
                      ))}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center justify-center">
                      <div
                        className="text-blue-500 text-sm cursor-pointer flex gap-1 items-center"
                        onClick={() => {
                          setIsOpenSideDrawer(true);
                          setSelectedSchedule(each);
                          setSelectedSlot(
                            schedule?.training_Schedule_Header_Id
                          );
                        }}
                      >
                        <span>Show details</span>
                        <HiOutlineArrowNarrowRight size={18} />
                      </div>
                      {/* <div>
                        {moment(new Date(each.training_Start_Date)).isAfter(
                          moment(),
                          "day"
                        ) ? (
                          <MdDelete
                            style={{ opacity: 1 }}
                            className="m-auto cursor-pointer text-red-500"
                            // onClick={() =>
                            //   deleteSchedule(each?.teacher_Training_Schedule_Id)
                            // }
                          />
                        ) : null}
                      </div> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </ETable>
        </Accordion.Panel>
      </Accordion.Item>
    </>
  );
}
