import React from "react";
import { ESectionDivider } from "../../../../libs/ui";
import useMonitoringCompliance from "./hook/useMonitoringCompliance";
import { Skeleton } from "@mantine/core";
import RingChartData from "../../Components/RingChartData";
import { ECard } from "../../../../libs/ui/src/Card/ECard";

export default function MonitoringCompliance() {
  const {
    isLoadingAverageNoOfClassroomObservedByMonitorPercentage,
    isLoadingSchoolsVisitedByMonitorPercentage,
    isLoadingSpotAssessmentConductedByMonitor,
    isLoadingTargetedClassRoomObservation,
    isLoadingTargetedSchoolVisit,
    reportLevel,
    averageNoOfClassroomObservedByMonitorPercentage,
    schoolsVisitedByMonitorPercentage,
    spotAssessmentConductedByMonitor,
    targetedClassRoomObservation,
    targetedSchoolVisit,
  } = useMonitoringCompliance();
  return (
    <div>
      <ECard title="Monitoring - Average Compliance">
        <div className="grid grid-cols-3 gap-6">
          <div>
            {isLoadingAverageNoOfClassroomObservedByMonitorPercentage ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Classroom Observations",
                    value:
                      averageNoOfClassroomObservedByMonitorPercentage?.OverAll
                        ?.Average_Classroom_Observation ?? 0,
                    overAll:
                      averageNoOfClassroomObservedByMonitorPercentage?.OverAll,
                    toolTipLabel:
                      "% of targeted classroom observations completed by the monitors",
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName: "",
                    districtId: 0,
                  }}
                  uniqueId={`classroomObservation`}
                  downloadableData={
                    averageNoOfClassroomObservedByMonitorPercentage
                  }
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingSchoolsVisitedByMonitorPercentage ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Unique  School Visits",
                    value:
                      schoolsVisitedByMonitorPercentage?.OverAll
                        ?.Average_School_Visit ?? 0,
                    overAll: schoolsVisitedByMonitorPercentage?.OverAll,
                    toolTipLabel:
                      "% of targeted unique school visits completed by the monitors",
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName: "",
                    districtId: 0,
                  }}
                  uniqueId={`schoolObservation`}
                  downloadableData={schoolsVisitedByMonitorPercentage}
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingSpotAssessmentConductedByMonitor ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Spot Assessments",
                    value:
                      spotAssessmentConductedByMonitor?.OverAll
                        ?.Average_Total_spot_assessment ?? 0,
                    overAll: spotAssessmentConductedByMonitor?.OverAll,
                    toolTipLabel:
                      "% of targeted spot assessments conducted by the monitors",
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName: "",
                    districtId: 0,
                  }}
                  uniqueId={`schoolObservation`}
                  downloadableData={spotAssessmentConductedByMonitor}
                />
              </div>
            )}
          </div>
        </div>
      </ECard>
      <ECard title="Monitoring - Target Completion Status">
        {/* <div className="grid grid-cols-3 gap-6"> */}
        <div className="flex justify-center gap-6">
          {/* <div> */}
          <div style={{ width: "33.33%" }}>
            {isLoadingTargetedClassRoomObservation ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label:
                      "Monitors completing targeted Classroom Observations",
                    value:
                      targetedClassRoomObservation?.OverAll
                        ?.Compliance_Percentage ?? 0,
                    overAll: targetedClassRoomObservation?.OverAll,
                    toolTipLabel:
                      "Monitors completing targeted classroom observations",
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName: "",
                    districtId: 0,
                  }}
                  uniqueId={`schoolObservation`}
                  downloadableData={targetedClassRoomObservation}
                />
              </div>
            )}
          </div>
          {/* <div> */}
          <div style={{ width: "33.33%" }}>
            {isLoadingTargetedSchoolVisit ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Monitors completing targeted Unique School Visits",
                    value:
                      targetedSchoolVisit?.OverAll?.Compliance_Percentage ?? 0,
                    overAll: targetedSchoolVisit?.OverAll,
                    toolTipLabel:
                      "Monitors completing targeted unique school visits",
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName: "",
                    districtId: 0,
                  }}
                  uniqueId={`schoolObservation`}
                  downloadableData={targetedSchoolVisit}
                />
              </div>
            )}
          </div>
        </div>
      </ECard>
    </div>
  );
}
