
import React, { useContext, useEffect, useState } from 'react'
import { IGrievanceCategory, IGrievanceTicket, ITicket } from '../../../libs/data-access/types'
import { showToast } from '../../../utils/utility'
import { useGrievanceData } from '../../../libs/data-access';
import moment, { now } from 'moment';
import { AppContext } from '../../../AppContext';
import { INewTicket } from '../../../types';
import { DefaultGrievanceTicket, DefaultNewTicket } from '../../../initialStates';

export default function useTicket() {

    const {
        role,
        user
    } = useContext(AppContext);

    const {
        fetchGrievanceData,
        postGrievanceData
    } = useGrievanceData();

    console.log("user",user)

    const [activeTab, setActiveTab] = useState<"open" | "closed">()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)
    const [inputError,setInputError] =useState<String>("")
    const [isOpenNewTicketModal, setIsOpenNewTicketModal] = useState<boolean>(false)
    const [selectedTicket, setSelectedTicket] = useState<IGrievanceTicket>(DefaultGrievanceTicket)
    const [newTicket, setNewTicket] = useState<INewTicket>(DefaultNewTicket)
    const [grievanceCategoryList, setGrievanceCategoryList] = useState<IGrievanceCategory[]>([{
        "grievance_Category_Id": 0,
        "category_Name": ""
    }])
    const [grievanceTicketList,setGrievanceTicketList] = useState<IGrievanceTicket[]>([DefaultGrievanceTicket])
    const [isFirst ,setIsFirst] =useState(true);

    useEffect(() => {
        if(isFirst)
        {
            setIsFirst(false)
        }
        else{
        // getGrievanceCategory(user?.role_Id == 6 ? 3 : user?.role_Id == 1 ?  2 : 0)
         getGrievanceCategory(
           user?.grievance_For_Role_Id ? user?.grievance_For_Role_Id : 0
         );
        // getGrievanceTicketList(user?.user_Id, user?.role_Id == 6 ? 6 : user?.role_Id == 8 ? 8 : user?.role_Id == 1 ?  1 : 0)
        getGrievanceTicketList(user?.user_Id, user?.role_Id);
    }},[isFirst])


   // hnadler function 

    const handleSubmitQuery = async () => {  
        const phoneNumberRegex = /^\d{10}$/;
        const isPhoneNumberValid = phoneNumberRegex.test(newTicket?.contact_Number);
        if (!isPhoneNumberValid) {
            setInputError('Phone number should contain only 10 digits.')
            // console.log('Phone number should contain only 10 digits.');
            return;
          }
         else{
            setInputError('')
        let payload: INewTicket = await { ...newTicket, date_Of_Issue: moment().format(), user_Id: user.user_Id , contact_Number: "+91"+newTicket?.contact_Number,grievance_For_Role_Id:user.grievance_For_Role_Id};
        postNewTicket(payload)
    }
};

    //API
    const getGrievanceCategory = async (id: number) => {
        setIsLoading(true)
        const response = await fetchGrievanceData(`/GetGrievanceCategoriesByGrievanceForRoleID/${id}`)
        if (response.isSuccess) {
            setGrievanceCategoryList(response?.data)
        } else {
            console.error("Error while fetching Grievance Category: ", response.error);
            // showToast({
            //     type: "error",
            //     title: "Something Went Wrong!",
            //     message: response.message || "Could not fetch Grievance Category."
            // });
        };
        setIsLoading(false);
    }

    const getGrievanceTicketList = async (userId: number , roleId:number) => {
        setIsLoading(true)
        const response = await fetchGrievanceData(`/GetGrievanceTicketListDetailRaisedByUser/${userId}/${roleId}`)
        if (response.isSuccess) {
            setGrievanceTicketList(response?.data)
        } else {
            console.error("Error while fetching Grievance Ticket: ", response.error);
            // showToast({
            //     type: "error",
            //     title: "Something Went Wrong!",
            //     message: response.message || "Could not fetch Grievance Ticket."
            // });
        };
        setIsLoading(false);
    }
    const postNewTicket = async (payload: INewTicket) => {
        setIsLoading(true)
        const response = await postGrievanceData(`/CreateGrievanceTicketByUser`, payload)
        if (response.isSuccess) {
            setNewTicket(DefaultNewTicket)
            setIsOpenNewTicketModal(false)
            // getGrievanceCategory(user?.role_Id == 6 ? 3 : user?.role_Id == 1 ?  2 : 0  )
            getGrievanceCategory(
              user?.grievance_For_Role_Id ? user?.grievance_For_Role_Id : 0
            );

// getGrievanceTicketList(
//   user?.user_Id,
//   user?.role_Id == 6 ? 6 : user?.role_Id == 8 ? 8 : user?.role_Id == 1 ? 1 : 0
// );
getGrievanceTicketList(
  user?.user_Id,
  user?.role_Id
);

        } else {
            console.error("Error while uploading new ticket: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message || "Could not uploaded new ticket."
            });
        };
        setIsLoading(false);
    }

    return {
        activeTab,
        setActiveTab,
        isLoading,
        setIsLoading,
        isOpenDrawer,
        setIsOpenDrawer,
        isOpenNewTicketModal,
        setIsOpenNewTicketModal,
        selectedTicket,
        setSelectedTicket,
        newTicket,
        setNewTicket,
        grievanceCategoryList,
        grievanceTicketList,
        handleSubmitQuery,
        inputError,
        setInputError
    }
}
