import { useReducer, useState } from "react";

import { showToast } from "../../../utils/utility";
import { useSchoolData, useTeacherData } from "../../../libs/data-access";
import { IAllocatedClassSection, IAllTeacherListBySchoolId } from "../../../libs/data-access/types";

interface ITeacher {
    school_Teacher_Id: number;
    teacher_Id: number;
    employee_Code: string;
    employee_Name: string;
    designation: string;
};

interface IState {
    teacher: ITeacher | undefined;
    selectedClassSectionToBeDeleted: IAllocatedClassSection | undefined;
    isDeletingAllocation: boolean;
};

type Action =
    | { type: "selectTeacher", payload: ITeacher }
    | { type: "selectClassSection", payload: IAllocatedClassSection }
    | { type: "cancel" };

const initialState: IState = {
    teacher: undefined,
    selectedClassSectionToBeDeleted: undefined,
    isDeletingAllocation: false
};

function reducer(
    state: IState,
    action: Action
) {
    switch (action.type) {
        case "selectTeacher":
            return { ...state, teacher: action.payload };
        case "selectClassSection":
            return { ...state, isDeletingAllocation: true, selectedClassSectionToBeDeleted: action.payload };
        case "cancel":
            return { isDeletingAllocation: false, teacher: undefined, selectedClassSectionToBeDeleted: undefined };
        default:
            throw new Error();
    };
};

interface IProps {
    getAllTeacherListBySchoolID: () => void;
};

export default function useTeacherAllocation({
    getAllTeacherListBySchoolID
}: IProps) {

    // const { deleteTeacherData } = useTeacherData();
    const { deleteSchoolData } = useSchoolData();

    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleSelectTeacherClassSection = (
        teacher: IAllTeacherListBySchoolId,
        selectedClassSectionIndex: number
    ) => {
        const classSection = teacher.allocation[selectedClassSectionIndex];
        dispatch({ type: "selectClassSection", payload: classSection });
        dispatch({
            type: "selectTeacher",
            payload: {
                school_Teacher_Id: teacher.school_Teacher_Id,
                teacher_Id: teacher.teacher_Id,
                employee_Code: teacher.employee_Code,
                employee_Name: teacher.employee_Name,
                designation: teacher.designation
            }
        });
    };

    /* API CALLS */
    const deleteClassSectionAllocationForSpecificTeacher = async () => {
        setIsLoading(true);
        const response = await deleteSchoolData(`/DeleteTeacherClassSectionByTeacherClassSectionId/${state.selectedClassSectionToBeDeleted?.teacher_Class_Section_Id}`);
        if (response.isSuccess) {
            // console.log("Response for delete allocation for teacher: ", response.data);
            getAllTeacherListBySchoolID();
        } else {
            console.log("Error for delete allocation for teacher: ", response.error);
            showToast({
                type: "error",
                title: "Oops!",
                message: response.message || "Could not delete allocation"
            });
        };
        setIsLoading(false);
    };

    return {
        state,
        dispatch,
        handleSelectTeacherClassSection,
        isLoading,
        deleteClassSectionAllocationForSpecificTeacher
    };
}
