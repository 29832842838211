import { Tooltip, Textarea, Image, Alert } from "@mantine/core";
import { GiCancel } from "react-icons/gi";
import { TiTickOutline } from "react-icons/ti";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineHideImage } from "react-icons/md";

import { ESelectFileForQuestionBank } from "../../../../libs/ui";

import { BASE64_INITIALS } from "../../../../initialStates";
import { IQuestionFilters, IQuestionOption } from "../../../../types";


interface IProps {
  questionFilters: IQuestionFilters;
  optionDetails: IQuestionOption;
  handleChangeOptionDetails: (key: keyof IQuestionOption, value: string | File | undefined) => void;
  optionList: IQuestionOption[];
  handleAddNewOption: (option: IQuestionOption) => void;
  removeOption: () => void;
};

export default function AddOptionFrame({
  questionFilters,
  optionDetails,
  handleChangeOptionDetails,
  optionList,
  handleAddNewOption,
  removeOption
}: IProps) {
  return (
    <div className="input-item">
      <div className="radio-btn">
        {/* <Radio
          size="md"
          color="green"
          checked={each.isCorrectOption}
          value={String(each.id)}
          name={questionFilters?.questionType == 2 ? "single" : ""}
          //   onChange={() => handleSetCorrectOption(Number(each.id))}
          onClick={() => handleSetCorrectOption(Number(each?.id))}
        /> */}
      </div>

      {/* SELECT IMAGE */}
      <div className="action-icons">
        { // INFO: if media type is "Image" or "Text/Image"
          questionFilters.option_Media_Type_Id != 1
            ?
            optionDetails.file
              ?
              <Tooltip
                withArrow
                color="red"
                label="Remove selected image"
              >
                <a>
                  <MdOutlineHideImage
                    onClick={() => {
                      handleChangeOptionDetails("file", undefined);
                    }}
                  />
                </a>
              </Tooltip>
              :
              <Tooltip
                withArrow
                label="Select image"
              >
                <a>
                  <ESelectFileForQuestionBank
                    maxSizeInKB={2000000}
                    onClear={() => { }}
                    onSelect={file => handleChangeOptionDetails("file", file)}
                    dimensionsToMatch={{ width: 500, height: 500 }}
                    onSelectPreview={preview => {
                      // handleChangeOptionDetails("url", preview); console.log(preview)
                    }}
                  />
                </a>
              </Tooltip>
            :
            <></>}

        {optionDetails.text || optionDetails.file
          ?
          <Tooltip
            withArrow
            label="Save option"
          >
            <a>
              <TiTickOutline
                size={20}
                className="cursor-pointer"
                onClick={() => optionDetails.text || optionDetails.file
                  ?
                  handleAddNewOption(optionDetails)
                  :
                  console.log("Option text or file not provided")}
              />
            </a>
          </Tooltip>
          :
          <></>}

        {!optionList.length
          ?
          <></>
          :
          <Tooltip
            withArrow
            color="red"
            label="Remove this option"
          >
            <a
              className="cursor-pointer"
              onClick={() => removeOption()}
            >
              <GiCancel
                size={20}
              />
            </a>
          </Tooltip>}
      </div>

      {optionDetails?.file
        ?
        <Image
          radius="md"
          src={`${BASE64_INITIALS}${optionDetails?.file}`}
          alt="Option Image"
          className="mt-5"
        />
        :
        <></>}

      {(questionFilters.option_Media_Type_Id == 3
        ||
        questionFilters.option_Media_Type_Id == 5)
        &&
        !optionDetails.file
        ?
        <Alert
          icon={<BsInfoCircle />}
          title="Missing Image!"
          color="orange"
        >
          You need to select an image for this options
        </Alert>
        :
        <></>}

      {questionFilters.option_Media_Type_Id != 3
        ?
        <Textarea
          size="lg"
          variant="unstyled"
          autoFocus
          className={`w-full ${questionFilters.option_Media_Type_Id != 3 ? "ml-2" : ""}`}
          placeholder="Enter option here..."
          value={optionDetails.text || ""}
          onChange={(e) =>
            handleChangeOptionDetails("text", e.target.value)
          }
        />
        :
        <></>}
    </div>
  )
}
