import React from "react";
import { ESectionDivider } from "../../../../libs/ui";
import useMentoringCompliance from "./hook/useMentoringCompliance";
import RingChartData from "../../Components/RingChartData";
import { Skeleton } from "@mantine/core";
import { ECard } from "../../../../libs/ui/src/Card/ECard";

export default function MentoringCompliance() {
  const {
    isLoadingAverageClassroomObservations,
    isLoadingAverageUniqueSchoolVisits,
    isLoadingAverageSpotAssessments,
    isLoadingMentorsCompletingTargetedClassroomObservations,
    isLoadingMentorsCompletingTargetedUniqueSchoolVisit,
    isLoadingMentorsCompletingTargetedSpotAssessment,
    averageClassroomObservations,
    averageUniqueSchoolVisits,
    averageSpotAssessments,
    mentorsCompletingTargetedClassroomObservations,
    mentorsCompletingTargetedUniqueSchoolVisit,
    mentorsCompletingTargetedSpotAssessment,
  } = useMentoringCompliance();
  return (
    <div>
      <ECard title="Mentoring - Average Compliance">
        <div className="grid grid-cols-3 gap-6">
          <div>
            {isLoadingAverageClassroomObservations ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Classroom Observations",
                    value:
                      averageClassroomObservations?.OverAll
                        ?.Average_Classroom_Observation ?? 0,
                    overAll: averageClassroomObservations?.OverAll,
                    toolTipLabel: "% of targeted classroom observations completed by the mentors"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName:"",
                    districtId:0
                  }}
                  uniqueId={`classroomObservation`}
                  downloadableData={averageClassroomObservations}
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingAverageUniqueSchoolVisits ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Unique School Visits",
                    value:
                      averageUniqueSchoolVisits?.OverAll
                        ?.Average_School_Visit ?? 0,
                    overAll: averageUniqueSchoolVisits?.OverAll,
                    toolTipLabel: "% of targeted unique school visits completed by the mentors"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName:"",
                    districtId:0
                  }}
                  uniqueId={`averageUniqueSchool`}
                  downloadableData={averageUniqueSchoolVisits}
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingAverageSpotAssessments ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Average Spot Assessments",
                    value:
                      averageSpotAssessments?.OverAll
                        ?.Average_Total_spot_assessment ?? 0,
                    overAll: averageSpotAssessments?.OverAll,
                    toolTipLabel: "% of targeted spot assessments conducted by the mentors"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName: "",
                    blockName:"",
                    districtId:0
                  }}
                  uniqueId={`SpotAssessments`}
                  downloadableData={averageSpotAssessments}
                />
              </div>
            )}
          </div>
        </div>
      </ECard>

      <ECard title="Mentoring - Target Completion Status">
        <div className="grid grid-cols-3 gap-6">
          <div>
            {isLoadingMentorsCompletingTargetedClassroomObservations ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Mentors Completing Targeted Classroom Observations",
                    value:
                      mentorsCompletingTargetedClassroomObservations?.OverAll
                        ?.Compliance_Percentage ?? 0,
                    overAll:
                      mentorsCompletingTargetedClassroomObservations?.OverAll,
                      toolTipLabel: "Mentors completing targeted classroom observations"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName:"",
                    districtId:0,
                    blockName:""
                  }}
                  uniqueId={`clas+`}
                  downloadableData={
                    mentorsCompletingTargetedClassroomObservations
                  }
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingMentorsCompletingTargetedUniqueSchoolVisit ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Mentors Completing Targeted Unique School Visits",
                    value:
                      mentorsCompletingTargetedUniqueSchoolVisit?.OverAll
                        ?.Compliance_Percentage ?? 0,
                    overAll:
                      mentorsCompletingTargetedUniqueSchoolVisit?.OverAll,
                      toolTipLabel: "Mentors completing targeted unique school visits"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName:"",
                    districtId:0,
                    blockName:""
                  }}
                  uniqueId={`uniq+`}
                  downloadableData={mentorsCompletingTargetedUniqueSchoolVisit}
                />
              </div>
            )}
          </div>
          <div>
            {isLoadingMentorsCompletingTargetedSpotAssessment ? (
              <>
                <Skeleton
                  className="m-10"
                  height={200}
                  width={200}
                  radius={360}
                />
              </>
            ) : (
              <div>
                <RingChartData
                  overAllStats={{
                    label: "Mentors Completing Targeted Spot Assessments",
                    value:
                      mentorsCompletingTargetedSpotAssessment?.OverAll
                        ?.Compliance_Percentage ?? 0,
                    overAll: mentorsCompletingTargetedSpotAssessment?.OverAll,
                    toolTipLabel: "Mentors completing targeted spot assessments"
                  }}
                  selectedDistrict={""}
                  reportLevel={{
                    levelNames: "state",
                    districtName:"",
                    districtId:0,
                    blockName:""
                  }}
                  uniqueId={`SpotTarget+`}
                  downloadableData={mentorsCompletingTargetedSpotAssessment}
                />
              </div>
            )}
          </div>
        </div>
      </ECard>
    </div>
  );
}
