import React from 'react';
import { BsPlus } from 'react-icons/bs';
import { Grid, Select, Tabs, TextInput } from '@mantine/core';

import PageLayout from '../../../PageLayout';
import { EPageTitle, EButton, EInfoPanel } from '../../../libs/ui';

import useMISDataSync from '../Hooks/useMISDataSync';

export default function MISDataSync() {

  const {
    activeTab,
    setActiveTab,
    isLoading,
    classes,
    studentParams,
    handleStudentParamsChange,
    syncStudentDataFromMIS,
    teacherParams,
    handleTeacherParamsChange,
    syncTeacherDataFromMIS
  } = useMISDataSync();

  return (
    <PageLayout>

      <EPageTitle
        title="MIS - Data Sync"
      />

      <Tabs
        color="teal"
        defaultValue={activeTab}
        onTabChange={(e: "student" | "teacher") => setActiveTab(e)}
      >
        <Tabs.List>
          <Tabs.Tab value="student">Student</Tabs.Tab>
          <Tabs.Tab value="teacher" color="blue">
            Teacher
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="student" pt="xs">
          <Grid>
            <Grid.Col span={6}>
              <EInfoPanel
                title="Sync Student Information"
                text="Please select a grade and enter the school UDISE code to sync student information for the mentioned school"
              >
                <form>
                  <div className="flex flex-col gap-3">
                    <Select
                      required
                      withAsterisk
                      label="Select Grade"
                      placeholder="Select"
                      value={studentParams.selectedGrade || null}
                      data={classes?.map(each => {
                        return {
                          value: String(each.id),
                          label: each.name
                        }
                      })}
                      onChange={value => handleStudentParamsChange("selectedGrade", value)}
                    />
                    <TextInput
                      required
                      withAsterisk
                      placeholder="Enter UDISE Code"
                      label="School UDISE Code"
                      value={studentParams.UDISE || undefined}
                      onChange={e => handleStudentParamsChange("UDISE", e.target.value)}
                    />

                    <EButton
                      className="btn-blue"
                      loading={isLoading}
                      disabled={(!studentParams.UDISE && !studentParams.selectedGrade) ? true : false}
                      onClick={() => syncStudentDataFromMIS()}
                    >Sync</EButton>
                  </div>
                </form>
              </EInfoPanel>
            </Grid.Col>
            <Grid.Col span={6}>
            </Grid.Col>
          </Grid>
        </Tabs.Panel>

        <Tabs.Panel value="teacher" pt="xs">
          <Grid>
            <Grid.Col span={6}>
              <EInfoPanel
                title="Sync Teacher Information"
                text="Please enter the school UDISE code to sync teacher information for the mentioned school"
              >
                <form>
                  <div className="flex flex-col gap-3">
                    <TextInput
                      required
                      withAsterisk
                      placeholder="Enter UDISE Code"
                      label="School UDISE Code"
                      value={teacherParams.UDISE || undefined}
                      onChange={e => handleTeacherParamsChange("UDISE", e.target.value)}
                    />

                    <EButton
                      className="btn-blue"
                      loading={isLoading}
                      disabled={!teacherParams.UDISE ? true : false}
                      onClick={() => syncTeacherDataFromMIS()}
                    >Sync</EButton>
                  </div>
                </form>
              </EInfoPanel>
            </Grid.Col>
            <Grid.Col span={6}>
            </Grid.Col>
          </Grid>
        </Tabs.Panel>
      </Tabs>

    </PageLayout>
  )
}
