import React, { useEffect, useState } from 'react'
import { ECard } from '../../../../../libs/ui/src/Card/ECard'
import { CSVLink } from 'react-csv'
import moment from 'moment'
import { EButton, ETable } from '../../../../../libs/ui'
import { BiDownload } from 'react-icons/bi'
import { EPlaceholder } from '../../../../../libs/ui/src/Placeholder/EPlaceholder'
import usePeriodicAnnualAssessment from '../Hooks/usePeriodicAnnualAssessment'
import { Skeleton } from '@mantine/core'
import InformationToolTip from '../../../../../libs/ui/src/Stat/InformationToolTip'

export default function PeriodicAnnualAssessment() {


    const {
        isSearching,
        periodicAnnualData,
        csvData
    } = usePeriodicAnnualAssessment()



    return (
      <div className="mt-4">
        {isSearching ? (
          <Skeleton radius={"md"} height={350} />
        ) : !periodicAnnualData?.length ? (
          <EPlaceholder
            iconSrc={
              require("../../../../../assets/icons/icon-placeholder.svg")
                .default
            }
            title="Create Report"
            description="Please select a month and other filters and press search to generate a report."
          />
        ) : (
          <ECard
            title="Filter Wise Periodic Assessment"
            customClass="overflow-auto"
            header={
              <>
                <CSVLink
                  style={{}}
                  className="text-blue-600 text-sm hover:underline"
                  data={csvData}
                  // data={spotAssessmentResultCSV?.content ?? []}
                  // headers={downloadHeader}
                  // headers={spotAssessmentResultCSV?.headers ?? []}
                  filename={`Periodic_Assessment_${moment().format(
                    "MMM"
                  )}-${moment().format("YYYY")}.csv`}
                >
                  <EButton className="btn-blue">
                    Download Report <BiDownload className="ml-2" size={22} />
                  </EButton>
                </CSVLink>
              </>
            }
          >
            <ETable>
              <thead>
                <tr>
                  <th style={{ minWidth: "160px" }}>
                    {periodicAnnualData[0]?.level_Name
                      ? periodicAnnualData[0]?.level_Name
                      : "District"}
                  </th>
                  <th style={{ minWidth: "160px" }}>Total students enrolled</th>
                  <th style={{ minWidth: "160px" }}>
                    Total students tested (All Subjects)
                  </th>

                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_English_Competency?.length >
                  0 ? (
                    <th style={{ minWidth: "160px" }}>
                      Total students tested in English
                    </th>
                  ) : null}
                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_Hindi_Competency?.length >
                  0 ? (
                    <th style={{ minWidth: "160px" }}>
                      Total students tested in Hindi
                    </th>
                  ) : null}
                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_Math_Competency?.length >
                  0 ? (
                    <th style={{ minWidth: "160px" }}>
                      Total students tested in Math
                    </th>
                  ) : null}

                  {/* {
                                        periodicAnnualData[0].percentage_Of_Students_Mastery_English_Competency?.length>0 && periodicAnnualData[0].percentage_Of_Students_Mastery_Hindi_Competency?.length>0 && periodicAnnualData[0].percentage_Of_Students_Mastery_Math_Competency?.length>0 ?
                                            <th >Students achieving mastery in All</th>
                                            : null
                                    } */}

                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_English_Competency?.length >
                  0 ? (
                    <th>
                      <tr>
                        <th
                          colSpan={
                            periodicAnnualData[0]
                              ?.percentage_Of_Students_Mastery_English_Competency
                              ?.length
                          }
                        >
                          Students achieving mastery in English
                        </th>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "180px" }}>
                          Aggregate{" "}
                          <InformationToolTip
                            customClassName="ml-2 inline-block"
                            toolTipLabel="Students achieving mastery in all competencies of English"
                          />
                        </th>
                        {periodicAnnualData[0]?.percentage_Of_Students_Mastery_English_Competency?.map(
                          (item: any) => {
                            return (
                              <th style={{ minWidth: "180px" }}>
                                {item?.subject_Competency}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </th>
                  ) : null}
                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_Hindi_Competency?.length >
                  0 ? (
                    <th>
                      <tr>
                        <th
                          colSpan={
                            periodicAnnualData[0]
                              ?.percentage_Of_Students_Mastery_Hindi_Competency
                              ?.length
                          }
                        >
                          Students achieving mastery in Literacy
                        </th>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "180px" }}>
                          Aggregate{" "}
                          <InformationToolTip
                            customClassName="ml-2 inline-block"
                            toolTipLabel="Students achieving mastery in all competencies of Hindi"
                          />
                        </th>
                        {periodicAnnualData[0]?.percentage_Of_Students_Mastery_Hindi_Competency?.map(
                          (item: any) => {
                            return (
                              <th style={{ minWidth: "180px" }}>
                                {item?.subject_Competency}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </th>
                  ) : null}
                  {periodicAnnualData[0]
                    .percentage_Of_Students_Mastery_Math_Competency?.length >
                  0 ? (
                    <th>
                      <tr>
                        <th
                          colSpan={
                            periodicAnnualData[0]
                              ?.percentage_Of_Students_Mastery_Math_Competency
                              ?.length
                          }
                        >
                          Students achieving mastery in Numeracy
                        </th>
                      </tr>
                      <tr>
                        <th style={{ minWidth: "180px" }}>
                          Aggregate{" "}
                          <InformationToolTip
                            customClassName="ml-2 inline-block"
                            toolTipLabel="Students achieving mastery in all competencies of Maths"
                          />
                        </th>
                        {periodicAnnualData[0]?.percentage_Of_Students_Mastery_Math_Competency?.map(
                          (item: any) => {
                            return (
                              <th style={{ minWidth: "180px" }}>
                                {item?.subject_Competency}
                              </th>
                            );
                          }
                        )}
                      </tr>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {periodicAnnualData.map((item) => {
                  return (
                    <tr>
                      <td>{item?.filter_By_Name}</td>
                      <td>{item?.total_Student_Enrolled}</td>
                      <td>{item?.total_Student_Tested}</td>

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_English_Competency
                        ?.length > 0 ? (
                        <td>{item.total_Student_Tested_English}</td>
                      ) : null}

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_Hindi_Competency
                        ?.length > 0 ? (
                        <td>{item.total_Student_Tested_Hindi}</td>
                      ) : null}

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_Math_Competency
                        ?.length > 0 ? (
                        <td>{item.total_Student_Tested_Math}</td>
                      ) : null}

                      {/* {
                                                    periodicAnnualData[0].percentage_Of_Students_Mastery_English_Competency?.length>0 && periodicAnnualData[0].percentage_Of_Students_Mastery_Hindi_Competency?.length>0 && periodicAnnualData[0].percentage_Of_Students_Mastery_Math_Competency?.length>0 ?
                                                        <td >{item.aggregate_Student_Mastery_All_Tested_Competency}</td>
                                                        : null
                                                } */}

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_English_Competency
                        ?.length > 0 ? (
                        <td>
                          <tr>
                            <td style={{ minWidth: "180px" }}>
                              {
                                item.aggregate_Student_Mastery_All_Competency_English
                              }
                            </td>
                            {item.percentage_Of_Students_Mastery_English_Competency.map(
                              (child: any) => {
                                return (
                                  <td style={{ minWidth: "180px" }}>
                                    {
                                      child.percentage_Students_Mastery_English_Competency
                                    }
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        </td>
                      ) : null}

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_Hindi_Competency
                        ?.length > 0 ? (
                        <td>
                          <tr>
                            <td style={{ minWidth: "180px" }}>
                              {
                                item.aggregate_Student_Mastery_All_Competency_Hindi
                              }
                            </td>
                            {item.percentage_Of_Students_Mastery_Hindi_Competency.map(
                              (child: any) => {
                                return (
                                  <td style={{ minWidth: "180px" }}>
                                    {
                                      child.percentage_Students_Mastery_Hindi_Competency
                                    }
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        </td>
                      ) : null}

                      {periodicAnnualData[0]
                        .percentage_Of_Students_Mastery_Math_Competency
                        ?.length > 0 ? (
                        <td>
                          <tr>
                            <td style={{ minWidth: "180px" }}>
                              {
                                item.aggregate_Student_Mastery_All_Competency_Math
                              }
                            </td>
                            {item.percentage_Of_Students_Mastery_Math_Competency.map(
                              (child: any) => {
                                return (
                                  <td style={{ minWidth: "180px" }}>
                                    {
                                      child.percentage_Students_Mastery_Math_Competency
                                    }
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        </td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </ETable>
          </ECard>
        )}
      </div>
    );
}
