import React from 'react'
import { BsInfoCircle } from 'react-icons/bs';
import { Tooltip } from '@mantine/core';

const InformationToolTip = ({toolTipLabel,customClassName}:any) => {
  return (
    <div className={`${customClassName} h-max` ?? "h-max"}>
      <Tooltip label={toolTipLabel ?? ""} zIndex={999999999999} multiline w={220}>
      <p><BsInfoCircle/></p>
    </Tooltip>
      </div>
  )
}

export default InformationToolTip