import React from 'react'
import PageLayout from '../../../PageLayout'
import { EButton, EEmpty, EModal, EPageTitle, ESectionDivider, ETable } from '../../../libs/ui'
import { BsPlus } from 'react-icons/bs'
import useTeacherTrainingSlot from './hooks/useTeacherTrainingSlot'
import { Checkbox, Grid, Group, Text, Modal, Skeleton, TextInput, Title, TransferList, TransferListItemComponent, TransferListItemComponentProps, Textarea, MultiSelect, Badge } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '@mantine/dates'
import moment from 'moment'
import { FiDelete, FiEdit } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import { handleCheckDateRange, handleCheckEndDateRange } from '../../../utils/utility'

export default function TeacherTrainingSlot() {

  const navigate = useNavigate()
  const {
    isLoading,
    isLoadingTeacherList,
    teacherTrainingSlotTestList,
    trainingSlotList,
    isSlotModalOpen,
    isSlotEditModalOpen,
    setIsSlotEditModalOpen,
    isSlotDeleteModalOpen,
    setIsSlotDeleteModalOpen,
    setSelectedSlotTest,
    selectedSlotTest,
    setIsSlotModalOpen,
    handelModalClose,
    handleSubmitSlot,
    handleEditSlot,
    deleteTrainingSlot,
    newTrainingSlot,
    setNewTrainingSlot,
    selectedTrainingSlot,
    setSelectedTrainingSlot,
    setEditSlotTestForDelete
  } = useTeacherTrainingSlot();

  //  interface for teacher selection transfer list

  const ItemComponent: TransferListItemComponent = ({
    data,
    selected,
  }: TransferListItemComponentProps) => (
    <Group noWrap>
      {/* <Avatar src={data.image} radius="xl" size="lg" /> */}
      <div style={{ flex: 1 }}>
        <Text size="sm" weight={500}>
          {data.label}
        </Text>
        <Text size="xs" color="dimmed" weight={400}>
          {data.description}
        </Text>
      </div>
      <Checkbox
        checked={selected}
        onChange={() => { }}
        tabIndex={-1}
        sx={{ pointerEvents: 'none' }} />
    </Group>
  );

  return (
    <PageLayout>
      <EPageTitle
        title="Teacher Training Slot List"
        end={<EButton className='bg-blue-600 ' onClick={() => setIsSlotModalOpen(true)}> <BsPlus size={23} />Create New Slot</EButton>}
      />
      {
        isLoading ?
          <Skeleton height={180} radius="md" />
          :
          trainingSlotList.length ?
            <ETable>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>No. of Days</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th></th>
                  {/* <th></th> */}
                </tr>
              </thead>
              <tbody>
                {
                  trainingSlotList.map((e) =>
                    <tr>
                      <td>{e?.schedule_Header_Title || " - "}</td>
                      <td>{e?.no_Of_Days || " - "}</td>
                      <td>{e?.start_Date ? moment(e?.start_Date).format("DD-MM-YYYY") : "-"}</td>
                      <td>{e?.end_Date ? moment(e?.end_Date).format("DD-MM-YYYY") : "-"}</td>
                      {
                         handleCheckDateRange(e?.start_Date)?
                         <td>
                         {/* incoming */}
                         <p
                        className='cursor-pointer text-blue-500 flex text-sm items-center ml-2 gap-1'
                        onClick={() => {
                          setSelectedSlotTest(Array.from(new Set(e?.tests.map((e => e?.subject_Name)))))
                          setSelectedTrainingSlot(e)
                          setIsSlotEditModalOpen(true)
                          // navigate(`/edit-slot/${e?.training_Schedule_Header_Id}`)
                        }}>
                        <span>Edit</span> <FiEdit />
                        </p>
                         </td>
                         :
                         handleCheckEndDateRange(e?.end_Date) ?
                         <td>
                          {/* Expire */}
                          <Badge color='red'>Expired</Badge>
                          </td>
                        :
                        <td>
                          {/* ONgoing */}
                          <Badge color='green'>On going</Badge>
                      </td>
                      }
                      {/* <td
                        className='cursor-pointer text-red-500 items-center gap-2'
                        onClick={() =>{
                          setSelectedTrainingSlot(e)
                          setIsSlotDeleteModalOpen(true)
                        }}>
                        <MdDelete size={20} />
                      </td> */}

                    </tr>)
                }</tbody>
            </ETable>
            :
            <EEmpty
              title='No any training slot available'
            />
      }

      {/* New Slot Modal */}
      <Modal
        closeOnClickOutside={false}
        opened={isSlotModalOpen}
        size='xl'
        onClose={handelModalClose}
        title="New Training Slot"
      >
        <div className='flex flex-col gap-2'>
          <TextInput
            size='md'
            minLength={6}
            value={newTrainingSlot?.schedule_Header_Title}
            onChange={(e) => setNewTrainingSlot({ ...newTrainingSlot, schedule_Header_Title: e.target.value })}
            label='Title'
            maxLength={60}
            placeholder='Enter Title'
            required
          />
          <Textarea
            size='md'
            className=''
            label='Description'
            placeholder='Enter Description'
            value={newTrainingSlot?.description}
            onChange={(e) => setNewTrainingSlot({ ...newTrainingSlot, description: e.target.value })}
          />
          <Grid>
            <Grid.Col span={6}>
              <DatePicker
                size='md'
                label='Start Date'
                placeholder='Select date'
                required
                minDate={new Date(moment().format('YYYY,MM,DD'))}
                value={newTrainingSlot?.start_Date ? new Date(newTrainingSlot?.start_Date) : null}
                onChange={(e: Date) => {
                  setNewTrainingSlot({ ...newTrainingSlot, end_Date: undefined, start_Date: e ? moment(e).format('YYYY-MM-DD') : "", })
                }}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DatePicker
                size='md'
                label='End Date'
                placeholder='Select date'
                required
                minDate={new Date(moment(newTrainingSlot?.start_Date).format('YYYY,MM,DD'))}
                value={newTrainingSlot?.end_Date ? new Date(newTrainingSlot?.end_Date) : null}
                onChange={
                  (e: Date) =>
                    setNewTrainingSlot({ ...newTrainingSlot, end_Date: e ? moment(e).format('YYYY-MM-DD') : "" })
                }
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <MultiSelect
                size='md'
                label="Subject"
                placeholder='Select'
                required
                value={selectedSlotTest}
                data={
                  Array.from(new Set(teacherTrainingSlotTestList.map((e => e?.subject_Name))))
                }
                onChange={(e) => setSelectedSlotTest(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                required
                size='md'
                type="number"
                // maxLength={10}
                label='Minimum no. of days for training'
                placeholder='Enter no. of days. ex: 2'
                value={newTrainingSlot?.no_Of_Days}
                onChange={(e) => setNewTrainingSlot({ ...newTrainingSlot, no_Of_Days: +e.target.value })}
              />
            </Grid.Col>
          </Grid>

          <div className='flex flex-col mt-4 gap-3'>
            <div className='flex justify-end'>
              <EButton
                loading={isLoading}
                className="bg-blue-600 "
                disabled={selectedSlotTest?.length && newTrainingSlot?.schedule_Header_Title && newTrainingSlot?.start_Date && newTrainingSlot?.end_Date && newTrainingSlot?.no_Of_Days ? false : true}
                onClick={() => handleSubmitSlot()}>Submit Slot</EButton>
            </div>
          </div>
        </div>
      </Modal>


      {/* Edit Slot Modal */}
      <Modal
        closeOnClickOutside={false}
        opened={isSlotEditModalOpen}
        size='xl'
        onClose={() => {
          setEditSlotTestForDelete([])
          setSelectedSlotTest([])
          setIsSlotEditModalOpen(false)
        }}
        title="Edit Training Slot"
      >
        <div className='flex flex-col gap-2'>
          <TextInput
            size='md'
            value={selectedTrainingSlot?.schedule_Header_Title}
            onChange={(e) => setSelectedTrainingSlot({ ...selectedTrainingSlot, schedule_Header_Title: e.target.value })}
            description='Title'
            maxLength={60}
            placeholder='Enter Title'
            required
          />
          <Textarea
            size='md'
            className=''
            description='Description'
            placeholder='Enter Description'
            required
            value={selectedTrainingSlot?.training_Description}
            onChange={(e) => setSelectedTrainingSlot({ ...selectedTrainingSlot, training_Description: e.target.value })}
          />
          <Grid className=''>
            <Grid.Col span={6}>
              <DatePicker
                size='md'
                description='Start Date'
                placeholder='Select Date'
                minDate={new Date(moment().format('YYYY,MM,DD'))}
                value={selectedTrainingSlot?.start_Date ? new Date(selectedTrainingSlot?.start_Date) : null}
                onChange={(e: Date) => setSelectedTrainingSlot({ ...selectedTrainingSlot, start_Date: e ? moment(e).format('YYYY-MM-DD') : "", end_Date: "" })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <DatePicker
                size='md'
                description='End Date'
                placeholder='Select date'
                value={selectedTrainingSlot?.end_Date ? new Date(selectedTrainingSlot?.end_Date) : null}
                minDate={new Date(moment(selectedTrainingSlot?.start_Date).format('YYYY,MM,DD'))}
                onChange={(e: Date) => setSelectedTrainingSlot({ ...selectedTrainingSlot, end_Date: e ? moment(e).format('YYYY-MM-DD') : "" })}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col span={6}>
              <MultiSelect
                size='md'
                description="Subject"
                placeholder='Select'
                value={selectedSlotTest}
                data={
                  Array.from(new Set(teacherTrainingSlotTestList.map((e => e?.subject_Name))))
                }
                onChange={(e) => setSelectedSlotTest(e)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                required
                size='md'
                type="number"
                maxLength={10}
                label='Minimum no. of days for training'
                placeholder='Enter no. of days. ex: 2'
                value={selectedTrainingSlot?.no_Of_Days}
                onChange={(e) => setSelectedTrainingSlot({ ...selectedTrainingSlot, no_Of_Days: +e.target.value  })}
              />
            </Grid.Col>
          </Grid>

          <div className='flex flex-col mt-4 gap-3'>
            <div className='flex justify-end'>
              <EButton
                loading={isLoading}
                className="bg-blue-600 "
                disabled={selectedSlotTest?.length && selectedTrainingSlot?.schedule_Header_Title && selectedTrainingSlot?.start_Date && selectedTrainingSlot?.end_Date && selectedTrainingSlot?.no_Of_Days ? false : true}
                // disabled={true}
                onClick={() => handleEditSlot()}>Update Slot</EButton>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Slot Modal */}
      <EModal
        title='Are you sure you want to delete ?'
        isOpen={isSlotDeleteModalOpen}
        setIsOpen={() => {
          setIsSlotDeleteModalOpen(false)
        }}
        children={<div className='flex justify-end gap-3'>
          <EButton
            className="btn-light"
            onClick={() => setIsSlotDeleteModalOpen(false)}
          >Cancel</EButton>
          <EButton
            className="btn-red"
            onClick={() => deleteTrainingSlot()}
          >Delete</EButton>
        </div>} />
    </PageLayout>
  )
}
