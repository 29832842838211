import React from 'react';
import { Tooltip } from '@mantine/core';
import { CiCircleRemove } from 'react-icons/ci';
import { ImCross } from "react-icons/im";
import { IAllocatedClassSection } from '../../../libs/data-access/types';

interface IProps {
    allocatedClassSection: IAllocatedClassSection;
    handleClick: () => void;
};

export default function AllocatedClassSectionBadge({
    allocatedClassSection,
    handleClick
}: IProps) {
    return (
        <>
         {
            allocatedClassSection?.class_Name?.length>0 ?
        <div
            className="w-fit border border-solid px-3 py-1 rounded-md text-sm flex gap-1 bg-white items-center"
        >
            <span>{allocatedClassSection?.class_Name} - {allocatedClassSection?.section_Name}</span>
            <Tooltip
                color="red"
                label="Remove this allocation"
            >
                <span>
                    <ImCross
                    className="text-slate-500 hover:text-slate-700 cursor-pointer"
                    onClick={() => handleClick()}
                    size={10}
                    />
                    {/* <CiCircleRemove
                        className="text-red-400 hover:text-red-600 cursor-pointer"
                        onClick={() => handleClick()}
                        size={20}
                    /> */}
                </span>
            </Tooltip>
        </div>
        :
        null
        }
        </>
       

    )
}
