import { useContext, useEffect, useState } from "react";

import { calculateYearForDashboard, showToast } from "../../../../../utils/utility";
import useMasterData from "../../../../Hooks/useMasterData";
import { useMentorReports } from "../../../../../libs/data-access";
import { IClassroomObservationThresholdReport, IClassroomObservationThresholdTableData,IUniqueSchoolMentorWiseVisitThresholdReport,IUniqueSchoolMentorWiseVisitThresholdReportTableData,IUniqueSchoolVisits } from "../../../../../libs/data-access/types";
import moment from "moment";
import { AppContext } from "../../../../../AppContext";
import { ROLES } from "../../../../../Routes/routes";

export default function useClassroomObservation() {

    const {
        user,
        role
    } =useContext(AppContext)
    
    const {
        fetchMentorReport,
        fetchMentorFastApiReport
    } = useMentorReports();

    const {
        getDistrictByStateId,
        districts,
        getBlocksByDistrictId,
        blocks,
    } = useMasterData();

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingThreshHoldTable, setIsLoadingThreshHoldTable] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = useState<string | undefined>(moment().format("MM"));
     const [selectedSession, setSelectedSession] = useState<string | undefined>(
       parseInt(moment().format("MM"), 10) > 4
         ? moment().add(1, "year").format("YYYY")
         : moment().format("YYYY")
     );
    const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(undefined);
    const [selectedBlock, setSelectedBlock] = useState<string | undefined>(undefined);
    const [classroomObservationReportData, setClassroomObservationReportData] = useState<IClassroomObservationThresholdTableData[]>([]);
    const [ClassroomHindiObservationCSV, setClassroomHindiObservationCSV] = useState<{ headers: any[], content: any[] }>();
    const [uniqueSchoolVisits,setUniqueSchoolVisits] =useState<IUniqueSchoolVisits | undefined>(undefined)
    const [uniqueSchoolMentorWiseVisitReportData, setUniqueSchoolMentorWiseVisitReportData] = useState<IUniqueSchoolMentorWiseVisitThresholdReportTableData[]>([]);
    const [uniqueSchoolMentorWiseVisitCSV, setUniqueSchoolMentorWiseVisitCSV] = useState<{ headers: any[], content: any[] }>();

    useEffect(() => {

        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            getDistrictByStateId(1);
            if (role?.includes(ROLES.BLOCK)) {
                setSelectedDistrict(String(user?.details?.blockUserDetails?.district_Id))
                setSelectedBlock(String(user?.details?.blockUserDetails?.block_Id))
            }
            if (role?.includes(ROLES.DISTRICT)) {
                setSelectedDistrict(String(user?.details?.districtUserDetails?.district_Id))
            } 
        };

    }, [isFirstLoad]);

    useEffect(()=>{
        if(selectedDistrict) {  
       getBlocksByDistrictId(+selectedDistrict)
    }
    },[selectedDistrict]);
    // FORMATTER FUNCTIONS

    const handelClearFilter =()=>{
        setSelectedBlock(undefined)
        setSelectedDistrict(undefined)
    };

    const formatClassroomHindiObservationReport = (classroomObservation: IClassroomObservationThresholdReport[]) => {

        const headers = [
            { label: "District", key: "district" },
            { label: "Block", key: "block_Name" },
            { label: "Mentor Name", key: "mentor_Name" },
            { label: "Unique Code", key: "unique_Code" },
            { label: "Month", key: "visit_Month" },
            { label: "Total Classroom Observations", key: "total_Classroom_Observations" },
            { label: "Target Classroom Observation", key: "target_Classroom_Observation" },
            { label: "Observation Percentage", key: "observation_Percentage" }
        ];

        const data = classroomObservation?.map(each => Object.assign({
            district: each.district_Name || "",
            block_Name: each?.block_Name || "",
            mentor_Name: each?.mentor_Name || "",
            unique_Code: each?.unique_Code || "",
            visit_Month: each?.visit_Month || "",
            total_Classroom_Observations: each?.total_Classroom_Observations ?? 0,
            target_Classroom_Observation: each?.target_Classroom_Observation ?? 0,
            observation_Percentage: each?.observation_Percentage || "0%"
        }));

        setClassroomHindiObservationCSV({
            headers: headers,
            content: data
        });

    };

    const formatUniqueSchoolMentorWiseVisitReport = (uniqueSchoolMentorWiseVisit: IUniqueSchoolMentorWiseVisitThresholdReport[]) => {

        const headers = [
            { label: "District", key: "district" },
            { label: "Block", key: "block_Name" },
            { label: "Mentor Name", key: "mentor_Name" },
            { label: "Unique Code", key: "unique_Code" },
            { label: "Month", key: "visit_Month" },
            { label: "Target Unique School Visit", key: "target_Unique_School_Visit" },
            { label: "Total School Visit", key: "total_School_Visit" },
            { label: "Unique School Visit", key: "unique_School_Visit" },
            { label: "Unique School Visit Percentage", key: "unique_School_Visit_Percentage" },
        ];

        const data = uniqueSchoolMentorWiseVisit?.map(each => Object.assign({
            district: each.district_Name || "",
            block_Name: each?.block_Name || "",
            mentor_Name: each?.mentor_Name || "",
            unique_Code: each?.unique_Code || "",
            visit_Month: each?.visit_Month || "",
            target_Unique_School_Visit: each?.target_Unique_School_Visit ?? 0,
            total_School_Visit: each?.total_School_Visit ?? 0,
            unique_School_Visit: each?.unique_School_Visit ?? 0,
            unique_School_Visit_Percentage: each?.unique_School_Visit_Percentage || "0%"
        }));

        setUniqueSchoolMentorWiseVisitCSV({
            headers: headers,
            content: data
        });

    };



    // API CALLS
    const fetchThresholdTableDataForClassroomObservation = async () => {
        setIsLoadingThreshHoldTable(true);
        setClassroomObservationReportData([])
        const response = await fetchMentorReport(
          `GetClassroomObservationPercentageReportByFilter/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}?DistrictId=${selectedDistrict ?? 0}&BlockId=${
            selectedBlock ?? 0
          }`
        );

        if (response.isSuccess) {

            const data = response.data;
            console.log("Response for classroom observation threshold report: ", data);
            setClassroomObservationReportData(data);

        } else {

            console.log("Error in fetching classroom observation threshold report: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message ? response.message : "Please try again later."
            });

        };
        setIsLoadingThreshHoldTable(false);

    };

    const fetchThresholdForClassroomObservation = async () => {

        setIsLoading(true);
        // setClassroomObservationReportData([])
        setClassroomHindiObservationCSV({
            headers: [],
            content: []
        });
        const response = await fetchMentorReport(
          `GetClassroomObservationPercentageReport/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}?DistrictId=${selectedDistrict ?? 0}&BlockId=${
            selectedBlock ?? 0
          }`
        );

        if (response.isSuccess) {

            const data = response.data;
            console.log("Response for classroom observation threshold report: ", data);
            // setClassroomObservationReportData(data);
            formatClassroomHindiObservationReport(data);

        } else {

            console.log("Error in fetching classroom observation threshold report: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message ? response.message : "Please try again later."
            });

        };
        setIsLoading(false);

    };

    const fetchThresholdForUniqueSchoolVisits = async () => {

        setIsLoadingThreshHoldTable(true);
        setUniqueSchoolVisits(undefined)
        const response = await fetchMentorFastApiReport(
          `threshold/UniqueSchoolVisit?GetMonthYearFromDate=${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}&DistrictId=${selectedDistrict ?? 0}&BlockId=${
            selectedBlock ?? 0
          }`
        );

        if (response.isSuccess) {

            const data = response.data;
            // console.log("Response for unique school visit threshold report: ", data);
            setUniqueSchoolVisits(data);

        } else {

            console.log("Error in fetching unique school visit threshold report: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message ? response.message : "Please try again later."
            });

        };
        setIsLoadingThreshHoldTable(false);

    };

    const fetchThresholdForUniqueMentorWiseSchoolVisitTableData = async () => {

        setIsLoadingThreshHoldTable(true);
        setUniqueSchoolMentorWiseVisitReportData([])
        const response = await fetchMentorReport(
          `GetSchoolVisitPercentageReportByFilter/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }}-${selectedMonth}-01`}?DistrictId=${
            selectedDistrict ?? 0
          }&BlockId=${selectedBlock ?? 0}`
        );

        if (response.isSuccess) {

            const data = response.data;
            // console.log("Response for unique school visit threshold report: ", data);
            setUniqueSchoolMentorWiseVisitReportData(data);

        } else {

            console.log("Error in fetching unique school visit threshold report: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message ? response.message : "Please try again later."
            });

        };
        setIsLoadingThreshHoldTable(false);

    };

    const fetchThresholdForUniqueMentorWiseSchoolVisit = async () => {

        setIsLoading(true);
        // setUniqueSchoolMentorWiseVisitReportData([])
        setUniqueSchoolMentorWiseVisitCSV({
            headers: [],
            content: []
        })
        const response = await fetchMentorReport(
          `GetSchoolVisitPercentageReport/${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}?DistrictId=${selectedDistrict ?? 0}&BlockId=${
            selectedBlock ?? 0
          }`
        );

        if (response.isSuccess) {

            const data = response.data;
            // console.log("Response for unique school visit threshold report: ", data);
            // setUniqueSchoolMentorWiseVisitReportData(data);
            formatUniqueSchoolMentorWiseVisitReport(data);

        } else {

            console.log("Error in fetching unique school visit threshold report: ", response.error);
            showToast({
                type: "error",
                title: "Something Went Wrong!",
                message: response.message ? response.message : "Please try again later."
            });

        };
        setIsLoading(false);

    };

    return {
      isLoading,
      isLoadingThreshHoldTable,
      isFirstLoad,
      setIsFirstLoad,
      selectedMonth,
      setSelectedMonth,
      districts,
      selectedDistrict,
      setSelectedDistrict,
      getDistrictByStateId,
      blocks,
      selectedBlock,
      setSelectedBlock,
      getBlocksByDistrictId,
      handelClearFilter,

      fetchThresholdForClassroomObservation,
      fetchThresholdTableDataForClassroomObservation,
      classroomObservationReportData,
      ClassroomHindiObservationCSV,

      uniqueSchoolVisits,
      fetchThresholdForUniqueSchoolVisits,

      fetchThresholdForUniqueMentorWiseSchoolVisit,
      fetchThresholdForUniqueMentorWiseSchoolVisitTableData,
      uniqueSchoolMentorWiseVisitReportData,
      uniqueSchoolMentorWiseVisitCSV,
      selectedSession,
      setSelectedSession,
    };
}