import { useState } from "react";

import { useMentorReports } from "../../../../../libs/data-access";
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from "../../../../../utils/utility";
import { IVisitComplianceByDistrict } from "../../../../../libs/data-access/types";
import useDashboardVisitComplianceReports from "./useDashboardVisitComplianceReports";
import moment from "moment";

export default function useDistrictWiseCompliance() {

    const {
        fetchMentorReport,
        fetchMentorFastApiReport
    } = useMentorReports();

    const {
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
    } = useDashboardVisitComplianceReports();

    const [districtWiseComplianceReport, setDistrictWiseComplianceReport] = useState<IVisitComplianceByDistrict[]>([]);
    // data for CSV download
    const [districtWiseComplianceCSV, setDistrictWiseComplianceCSV] = useState<{ headers: any[], content: any[] }>();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleSelectMonth = (value: string) => {
        setSelectedMonth(value);
        setDistrictWiseComplianceReport([]);
    };

    const formatVisitComplianceByDistrictReport = (visitCompliance: IVisitComplianceByDistrict[]) => {

        const headers = [
            { label: "District", key: "district" },
            { label: "Number of mentors", key: "number_of_mentors" },
            { label: "Total Classroom Observations", key: "total_classroom_observations" },
            { label: "Total Unique School Visits", key: "total_unique_school_visits" },
            // { label: "Total school visits", key: "total_school_visits" },
            { label: "Total Number of Students Spot Tested", key: "total_number_of_students_spot_tested" },
        ];

        const data = visitCompliance?.map(each => Object.assign({
            district: each.Name || "",
            number_of_mentors: each.Number_Of_Mentors ?? "",
            total_classroom_observations: each.Total_Classroom_Observations || "",
            total_unique_school_visits: each.Total_Unique_School_Visit || "",
            // total_school_visits: each.Total_School_Visit || "",
            total_number_of_students_spot_tested: each.Total_Student_Spot_Tested || ""
        }));

        setDistrictWiseComplianceCSV({
            headers: headers,
            content: data
        });

    };

    /* API CALLS */
    const fetchDistrictWiseCompliance = async () => {

        setIsLoading(true);
        const response = await fetchMentorFastApiReport(
          `VisitCompliance/VisitComplianceFilterWise?GetMonthYearFromDate=${`${
            selectedMonth
              ? calculateYearForDashboard(
                  +selectedMonth,
                  Number(selectedSession)
                )
              : moment().format("YYYY")
          }-${selectedMonth}-01`}&DistrictId=${selectedDistrict ?? 0}&BlockId=${
            selectedBlock ?? 0
          }`
        );

        if (response.isSuccess) {

            // console.log("Response for report: ", response.data);
            setDistrictWiseComplianceReport(response.data);
            formatVisitComplianceByDistrictReport(response.data);

        } else {

            console.log("Error while fetching report: ", response.error);
            showToast({
                type: "error",
                title: "Error while fetching report",
                message: response.message || "Something went wrong!"
            });

        };
        setIsLoading(false);

    };

    return {
      isLoading,
      handleSelectMonth,
      districtWiseComplianceReport,
      districtWiseComplianceCSV,
      fetchDistrictWiseCompliance,
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
    };
}
