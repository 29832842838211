import { useState, useEffect } from "react";

import { showToast } from "../../../utils/utility";
import useMasterData from "../../Hooks/useMasterData";
import { useStateAdmin } from "../../../libs/data-access";
import { IMediaType } from "../../../libs/data-access/types";
import { questionFiltersParams } from "../../../initialStates";
import { IQuestionFilters, TQuestionFilters } from "../../../types";

interface IProps {
    handleGetSelectedParameters: (params: IQuestionFilters) => void;
};

export default function useQuestionBankFilter({
    handleGetSelectedParameters
}: IProps) {

    const MEDIA_TYPE_QUESTION = 0;
    const MEDIA_TYPE_OPTION = 1;

    const {
        fetchStateAdmin
    } = useStateAdmin();
    const {
        assessmentTypes,
        getAssessmentTypes,
        subjectList,
        getSubject,
        classes,
        getClasses,
        competencyList,
        getCompetency,
        getCompetencyBasedOnClassAndSubject,
        competencyByClassSubject,
        questionType,
        GetQuestionType,
        spotAssessmentData,
        getWeeksData,
    } = useMasterData();

    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [mediaTypeForQuestion, setMediaTypeForQuestion] = useState<IMediaType[]>([]);
    const [mediaTypeForOptions, setMediaTypeForOptions] = useState<IMediaType[]>([]);
    const [questionFilters, setQuestionFilters] = useState<any>(
        questionFiltersParams
    );

    console.log("question filter in useBankfilter",questionFilters)

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);
        } else {
            getSubject();
            getClasses();
            GetQuestionType();
            getAssessmentTypes();
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_QUESTION);
            handleFetchMediaTypeForQuestion(MEDIA_TYPE_OPTION);
        }
    }, [isFirstLoad]);

    useEffect(() => {
        if (String(questionFilters.typeOfQuestionBank) === "4") {
            getWeeksData();
        }
    }, [questionFilters.typeOfQuestionBank]);

    useEffect(() => {
        if (questionType.length) {
            const singleOptionID = questionType.find(each => String(each.id).trim() === "2")?.id;
            handleChangeQuestionFilterParams("questionType", singleOptionID ? String(singleOptionID) : undefined);
        }
    }, [questionType]);

    useEffect(() => {
        if (questionFilters.selectedSubject && questionFilters?.selectedGrade) {
            // getCompetency(
            //     questionFilters.selectedSubject,
            //     questionFilters.selectedSubject
            // );
            getCompetencyBasedOnClassAndSubject(
                questionFilters.selectedGrade,
                questionFilters.selectedSubject
            );
            handleChangeQuestionFilterParams("selectedCompetencyLevel", "");
        }
    }, [questionFilters.selectedSubject , questionFilters?.selectedGrade]);
    // useEffect(() => {
    //     if (questionFilters.selectedSubject) {
    //         getCompetency(
    //             questionFilters.selectedSubject,
    //             questionFilters.selectedSubject
    //         );
    //         handleChangeQuestionFilterParams("selectedCompetencyLevel", "");
    //         handleChangeQuestionFilterParams("selectedSubject", "");
    //     }
    // }, [questionFilters.selectedGrade]);

    useEffect(() => {
        // console.log("Question FIlters: ", questionFilters);
        handleGetSelectedParameters(questionFilters);
    }, [questionFilters]);

    // for question filter management
    const handleChangeQuestionFilterParams = (
        key: TQuestionFilters,
        value: string | undefined
    ) => {
        setQuestionFilters((prev:any) => ({ ...prev, [key]: value }));
    };

    const handleClearQuestionFilters = () => {
        setQuestionFilters({
            typeOfQuestionBank: null,
            assessmentSeq: 0,
            week: 0,
            selectedGrade: 0,
            selectedSubject: 0,
            selectedTopic: 0,
            selectedCompetencyLevel: 0,
            questionType: 0,
            media_Type_Id: undefined,
            option_Media_Type_Id: undefined
        });
    };

    /* API CALLS */
    const handleFetchMediaTypeForQuestion = async (mediaType: number) => {
        const response = await fetchStateAdmin(`Question/GetMediaTypeForQuestionOrOption/${mediaType}`);
        if (response.isSuccess) {
            // console.log("Response for media type: ", response.data);
            const data: IMediaType[] | undefined = response.data;
            if (data) {
                mediaType === 0
                    ?
                    setMediaTypeForQuestion(data)
                    :
                    setMediaTypeForOptions(data);
            }
        } else {
            showToast({
                type: "error",
                title: "Error Ocurred!",
                message: "Could not fetch media types"
            });
        }
    };

    return {
        assessmentTypes,
        subjectList,
        classes,
        competencyList,
        competencyByClassSubject,
        questionType,
        spotAssessmentData,
        mediaTypeForQuestion,
        mediaTypeForOptions,

        questionFilters,
        handleChangeQuestionFilterParams,
        handleClearQuestionFilters
    };
}
