import React from 'react'
import useSchoolWiseClassroomHindiObservation from '../Hooks/useSchoolWiseClassroomHindiObservation';
import { Select, Skeleton } from '@mantine/core';
import { EButton, EInfoPanel, EVerticalFormSection } from '../../../../../libs/ui';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { DatePicker } from '@mantine/dates';

export default function SchoolWiseClassroomHindiObservation() {

    const {
        isLoading,
        selectEndDate,
        selectStartDate,
        setSelectedStartDate,
        setSelectedEndDate,
        schoolWiseClassroomHindiObservationCSV,
        schoolWiseClassroomHindiObservationReport,
        fetchSchoolWiseClassroomHindiObservation
    } = useSchoolWiseClassroomHindiObservation();
    return (
        <div>

            {/* FILTERS */}
            <div className="filter-container">
                <div className='flex justify-between w-full items-end'>
                <div className="flex gap-3 items-end ">
                    <DatePicker
                    label="Date"
                    placeholder='Select date'
                    clearable={false}
                    value={selectStartDate ? new Date(selectStartDate) : undefined }
                    onChange={(e)=> {
                        setSelectedEndDate(undefined)
                        setSelectedStartDate(moment(e).format('YYYY-MM-DD') || undefined)}}
                    />
                    {/* <DatePicker
                    label="End Date"
                    placeholder='Select date'
                    clearable={false}
                    disabled={!selectStartDate}
                    minDate={selectStartDate ? new Date(selectStartDate) : undefined}
                    maxDate={selectStartDate ? new Date (moment(selectStartDate).add(2,"days").format()) : undefined }
                    value={selectEndDate ? new Date(selectEndDate) : undefined}
                    onChange={(e)=>setSelectedEndDate(moment(e).format('YYYY-MM-DD') || undefined)}
                    /> */}
                </div>
                    <EButton
                        className="btn-blue"
                        disabled={!selectStartDate}
                        loading={isLoading}
                        onClick={fetchSchoolWiseClassroomHindiObservation}
                    >Search</EButton>
                </div>
            </div>

            {/* DATA */}
            <div className="mt-5">
                {isLoading
                    ?
                    <Skeleton radius={"md"} height={250} />
                    :
                    schoolWiseClassroomHindiObservationReport.length
                        ?
                        <EVerticalFormSection
                            title="School Wise Classroom Observation Hindi Report"
                            rightPanel={<>

                            </>}
                        >
                            <div>


                                <EInfoPanel
                                    title=" Your report is ready to download, Please click on Download File to download report"
                                >
                                    <CSVLink
                                        className="text-blue-600 text-sm hover:underline"
                                        data={schoolWiseClassroomHindiObservationCSV?.content ?? []}
                                        headers={schoolWiseClassroomHindiObservationCSV?.headers ?? []}
                                        filename={`School__wise_Classroom_Hindi_Observation_Report_for_${selectStartDate}-${selectEndDate}.csv`}
                                    >Download File</CSVLink>
                                </EInfoPanel>
                            </div>
                        </EVerticalFormSection>
                        :
                        <EInfoPanel
                            title="Please select date range and press search to download report"
                        />}
            </div>
        </div>
    )
}

