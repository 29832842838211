import { useContext, useState } from 'react';

import { ILoginParams, IUser } from '../../../types';
import { AppContext } from '../../../AppContext';
import { useAuth } from '../../../libs/data-access';
import { showToast } from './../../../utils/utility';
import { handleValidateLoginParams } from '../../../utils/validators';
import { ILoginDetails, ISchoolLoginDetails } from '../../../libs/data-access/types';

export default function useAuthentication() {

    const {
        setRole,
        setToken,
        setIsLoggedIn,
        handleUser,
        handleUserDetails,
    } = useContext(AppContext);

    const {
        handleLogin
    } = useAuth();

    const [loginParams, setLoginParams] = useState<ILoginParams>({
        username: "",
        password: ""
    });

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    /* HANDLER FUNCTIONS */
    const handleLoginPayloadChange = (
        key: keyof ILoginParams,
        value: string
    ) => {
        setLoginParams(prev => ({ ...prev, [key]: value }));
    };

    /* API CALLS */
    const handleUserLogin = async () => {

        const isValidated = handleValidateLoginParams(loginParams);
        if (isValidated) {

            setIsLoggingIn(true);
            const payload: ILoginParams = {
                username: loginParams.username.trim(),
                password: loginParams.password.split("/").join("")
            };

            const response = await handleLogin(payload);
            if (response.isSuccess) {

                // console.log("Response for login: ", response.data);
                const res = response.data[0];
                
                const user = Object.assign({
                    user_Id: res.user_Id,
                    role_Id: res.role_Id,
                    role_Name: res.role_Name,
                    grievance_For_Role_Id:res.grievance_For_Role_Id
                });

                if (
                    res.role_Id === 1
                ) {

                    handleUser(user);
                    handleUserDetails("schoolUserDetails", res?.details[0]);
                    setRole(["School Administrator"]);
                    setToken(res?.details[0]?.token);
                    setIsLoggedIn(true);
                    window.location.reload()

                } else if (res.role_Id === 8) {

                    handleUser(user);
                    handleUserDetails("blockUserDetails", res?.details[0]);
                    setRole(["Block Administrator"]);
                    setToken(res?.details[0]?.token);
                    setIsLoggedIn(true);
                    window.location.reload()

                } else if (res.role_Id === 7) {

                    handleUser(user);
                    handleUserDetails("districtUserDetails", res?.details[0]);
                    setRole(["District Administrator"]);
                    setToken(res?.details[0]?.token);
                    setIsLoggedIn(true);
                    window.location.reload()

                }else if (res.role_Id === 6) {

                    handleUser(user);
                    handleUserDetails("departmentUserDetails", res?.details[0]);
                    setRole(["State Administrator"]);
                    setToken(res?.details[0]?.token);
                    setIsLoggedIn(true);
                    window.location.reload()

                }  else if (res.role_Id === 9) {

                    handleUser(user);
                    handleUserDetails("helpDeskUserDetails", res?.details[0]);
                    setRole(["HelpDesk User"]);
                    setToken(res?.details[0]?.token);
                    setIsLoggedIn(true);
                    window.location.reload()

                } else {

                    console.log("Role not found!");
                    showToast({
                        type: "warning",
                        title: "Role Not Found!",
                        message: "We could not retrieve any role associated to this credentials"
                    });

                };

            } else {

                console.error("Error while login: ", response.error?.response?.status);
                showToast({
                    type: "error",
                    title: response.message || "Something Went Wrong!",
                    message: `Could not process login ${response.error?.response?.status ?? ""}`
                });
            }

        };
        setIsLoggingIn(false);

    };

    return {
        loginParams,
        isLoggingIn,
        handleLoginPayloadChange,
        handleUserLogin
    };
}
